import { OCol, OLoader, OOption, ORFieldSelect } from "@maestro/react";
import { RefreshGridButton } from "components/refresh-grid-button";
import { useCollateralIdentification } from "./collateral-identification-field.hook";

export const CollateralIdentificationField = () => {
  const { collaterals, getCollaterals, hasFetchError, loading } =
    useCollateralIdentification();

  return (
    <OCol xs={12} md={6} align="center" className="flex-fill">
      <ORFieldSelect
        dataAction="geral:select:garantia"
        dataLabel="garantia"
        id="collateralIdentification"
        name="collateralIdentification"
        label="Garantia"
        description="Limite de garantia vigente para esta estratégia"
        placeholder="Selecionar"
        disabled={hasFetchError || loading}
        required
        key={collaterals?.length}
      >
        {collaterals?.map(({ identification }) => (
          <OOption key={identification} value={identification}>
            {identification}
          </OOption>
        ))}
      </ORFieldSelect>
      {loading && <OLoader size="sm" />}
      {hasFetchError && !loading && (
        <RefreshGridButton
          dataAction="garantia:botao:tentar_novamente"
          dataLabel="tentar_novamente"
          onClick={() => getCollaterals()}
        />
      )}
    </OCol>
  );
};
