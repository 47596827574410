import {
  OButton,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OOption,
  ORFieldSelect,
  OTypography,
  modalManager,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { FormProvider } from "react-hook-form";
import { ModalWithSelectOverflow } from "../../../../../../../../../../components/modal-with-select-overflow";
import { DownloadFileButton } from "../value-cell/_compose/document-cell/download-file-button.component";
import { DiligenceDocumentSelector } from "./_compose";
import { moveFileFormFields } from "./move-file-modal.form";
import { useMoveFileModal } from "./move-file-modal.hook";
import { moveFileModalId } from "./move-file-modal.utils";

interface MoveFileModalProps {
  reload: () => void;
}

export const MoveFileModal = ({ reload }: MoveFileModalProps) => {
  const {
    files,
    form,
    selectedFile,
    submit,
    submitLoading,
    // for useDiligenceDocumentSelector()
    getDocumentsCallService,
    getDocumentsLoading,
    getDocumentsValue,
    getDocumentsHasError,
  } = useMoveFileModal(reload);

  return (
    <ModalWithSelectOverflow id={moveFileModalId} position="center">
      <OModalHeader>
        <OTypography size="lg">Mover arquivos</OTypography>
      </OModalHeader>
      <OModalBody>
        <FormProvider {...form}>
          <div className="d-flex align-items-center gap-2">
            <div style={{ minWidth: 0 }} className="flex-fill">
              <ORFieldSelect
                {...moveFileFormFields.documentFileId}
                key={files.length}
              >
                {files.map((file) => (
                  <OOption key={file.id} value={file.id}>
                    {file.name}
                  </OOption>
                ))}
              </ORFieldSelect>
            </div>
            <div>
              <DownloadFileButton
                fileName={selectedFile?.name ?? "Selecione um arquivo"}
                fileKey={selectedFile?.key ?? "none"}
                disabled={!selectedFile}
              />
            </div>
          </div>
          <DiligenceDocumentSelector
            id="diligence-document-selector"
            getDocumentsCallService={getDocumentsCallService}
            getDocumentsLoading={getDocumentsLoading}
            getDocumentsValue={getDocumentsValue}
            getDocumentsHasError={getDocumentsHasError}
          />
        </FormProvider>
      </OModalBody>
      <OModalFooter>
        <div className="d-flex justify-content-end gap-3">
          <OButton
            type="dark"
            outline
            onClick={() => modalManager.hide(moveFileModalId)}
          >
            Cancelar
          </OButton>
          <LoadingButton loading={submitLoading} onClick={submit}>
            Mover arquivo
          </LoadingButton>
        </div>
      </OModalFooter>
    </ModalWithSelectOverflow>
  );
};
