export const assembleDescription = (
  disabled: boolean,
  discountValidityInDays?: number,
) => {
  if (disabled) return;

  const daysLabel = `${
    discountValidityInDays
      ? discountValidityInDays === 1
        ? "1 dia"
        : `${discountValidityInDays} dias`
      : "N dias"
  } antes da data de vencimento`;
  return `O desconto será válido até ${daysLabel}`;
};
