import {
  OCheckbox,
  OCol,
  OLabel,
  OOptionMultiple,
  ORFieldCheckboxGroup,
  ORFieldInput,
  ORFieldSelectMultiple,
  ORow,
  OTypography,
} from "@maestro/react";
import { SelectSearchField } from "components/select-search";
import { GetAdminWorkflowConfigViewsByWorkflowConfigIdResponse } from "services/hubcreditmanager/models/responses";
import { orqIcons } from "../../../../../../../../../../configuracoes/workflow-configuration.utils";
import { useProcessorConfigChannels } from "./processor-config-channel.hook";

interface ProcessorConfigChannelsProps {
  isWfEntryWatch: string[];
  workflowConfigViews:
    | GetAdminWorkflowConfigViewsByWorkflowConfigIdResponse
    | undefined;
}

export const ProcessorConfigChannels = ({
  isWfEntryWatch,
  workflowConfigViews,
}: ProcessorConfigChannelsProps) => {
  const { channelList } = useProcessorConfigChannels();

  return (
    <div className="d-flex flex-column gap-4">
      <OTypography size="lg">Canais</OTypography>
      <ORow rowGap={4}>
        <OCol sm={12}>
          <ORFieldCheckboxGroup id="isWfEntry" name="isWfEntry">
            <div className="d-flex align-items-center gap-2 mb-2">
              <OCheckbox size="xs" id="entry-checkbox" value="1" />
              <OLabel htmlFor="entry-checkbox">
                Entrada de dados inicial do workflow?
              </OLabel>
            </div>
          </ORFieldCheckboxGroup>
        </OCol>
        {!!isWfEntryWatch?.length && (
          <>
            <OCol md={6} sm={12}>
              <ORFieldSelectMultiple
                id="channels"
                name="channels"
                label="Canais"
                labelSize="md"
              >
                <div>
                  {channelList?.map((c) => (
                    <OOptionMultiple
                      key={`processor-config-channel-option-${c.id}`}
                      value={c.id.toString()}
                    >
                      {c.name}
                    </OOptionMultiple>
                  ))}
                </div>
              </ORFieldSelectMultiple>
            </OCol>
            <OCol md={6} sm={12}>
              <ORFieldSelectMultiple
                id="entryViews"
                name="entryViews"
                label="Ação disponível em qual view?"
                labelSize="md"
              >
                <div>
                  {workflowConfigViews?.map((wfView) => (
                    <OOptionMultiple key={wfView.id} value={wfView.view.type}>
                      {wfView.view.name}
                    </OOptionMultiple>
                  ))}
                </div>
              </ORFieldSelectMultiple>
            </OCol>
            <OCol md={6} sm={12}>
              <ORFieldInput
                id="cardActionButtonDescription"
                name="cardActionButtonDescription"
                tag="text"
                label="Nome no botão de ação"
                labelSize="md"
              />
            </OCol>
            <OCol md={6} sm={12}>
              <SelectSearchField
                id="entryIcon"
                name="entryIcon"
                label="Ícone"
                labelSize="md"
                options={orqIcons.map((icon) => ({
                  label: icon,
                  value: icon,
                }))}
              />
            </OCol>
            <OCol md={6} sm={12}>
              <ORFieldCheckboxGroup
                id="entryShowOnActions"
                name="entryShowOnActions"
              >
                <div className="d-flex align-items-center gap-2">
                  <OCheckbox size="xs" id="showOnActions-checkbox" value="1" />
                  <OLabel htmlFor="showOnActions-checkbox">
                    Mostrar em ações?
                  </OLabel>
                </div>
              </ORFieldCheckboxGroup>
            </OCol>
            <OCol md={6} sm={12}>
              <ORFieldCheckboxGroup id="entryShowOnTabs" name="entryShowOnTabs">
                <div className="d-flex align-items-center gap-2">
                  <OCheckbox size="xs" id="showOnTabs-checkbox" value="1" />
                  <OLabel htmlFor="showOnTabs-checkbox">
                    Mostrar em abas?
                  </OLabel>
                </div>
              </ORFieldCheckboxGroup>
            </OCol>
          </>
        )}
      </ORow>
    </div>
  );
};
