import { OToastManager } from "@maestro/react";
import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { service } from "services";
import { useWorkflow } from "../../../../../../../../../../../../components/hooks/use-workflow.context";

export const useProcessorConfigChannels = () => {
  const {
    processorConfig: { value: selectedProcessorConfig },
  } = useWorkflow();

  const {
    value: channelList,
    callService: getAllChannels,
    error,
  } = useServiceCall(service.hubCreditManager.getChannels);

  useEffect(() => {
    if (!selectedProcessorConfig?.processor?.canBeWfEntry) return;
    getAllChannels();
  }, [getAllChannels, selectedProcessorConfig]);

  useEffect(() => {
    error &&
      OToastManager.danger("Não foi possível buscar as opções de formulário.");
  }, [error]);

  return { channelList };
};
