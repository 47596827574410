import { OToastManager, modalManager } from "@maestro/core";
import { useGridRef } from "hooks/grid-ref";
import { useCallback, useMemo, useState } from "react";
import { ReceivablesTitle } from "services/quickfin/models";
import { service } from "services/service";
import { approvalConfirmationModalId } from "./modal-aprovacoes/approvals-modal.utils";
import { approvalsGrid } from "./approvals.grid";

export const useApprovals = () => {
  const [haveHedge, setHaveHedge] = useState("");
  const [selectedReceivables, setSelectedReceivables] =
    useState<ReceivablesTitle[]>();
  const [loading, setLoading] = useState(false);

  const gridRef = useGridRef();

  const grid = useMemo(
    () => approvalsGrid(setSelectedReceivables),
    [setSelectedReceivables],
  );

  const reducedReceivables = useMemo(() => {
    if (!selectedReceivables) {
      return [];
    }

    const list: ReceivablesTitle[] = [];

    const receivablesCopy = structuredClone(selectedReceivables);

    receivablesCopy.forEach((receivable) => {
      const found = list.find(
        (query) =>
          query.Sacado === receivable.Sacado &&
          query.Cedente === receivable.Cedente &&
          query.TipoProduto === receivable.TipoProduto,
      );
      if (found) {
        found.ValorVencimento += receivable.ValorVencimento;
      } else {
        list.push(receivable);
      }
    });

    return list;
  }, [selectedReceivables]);

  const totalSum = useMemo(() => {
    return reducedReceivables.reduce<number>(
      (sum, receivable) => sum + receivable.ValorVencimento,
      0,
    );
  }, [reducedReceivables]);

  const hasReceivableWithHedgeIndication = useMemo(() => {
    return (
      selectedReceivables?.some(
        (receivable) => receivable.ExigeIndicacaoHedge,
      ) ?? false
    );
  }, [selectedReceivables]);

  const resetHaveHedge = () => {
    setHaveHedge("");
  };

  const approveReceivables = useCallback(async () => {
    if (selectedReceivables) {
      const receivablesId: number[] = selectedReceivables.map(
        (receivable) => receivable.ReceivableId,
      );
      const body = hasReceivableWithHedgeIndication
        ? {
            receivablesId,
            receivablesHaveHedge: haveHedge === "yes",
          }
        : {
            receivablesId,
          };

      setLoading(true);

      try {
        await service.quickfin.postReceivableApprovals(body);
        gridRef.current?.instance.clearSelection();
        gridRef.current?.instance.getDataSource().reload();
        setSelectedReceivables([]);
        OToastManager.success("Recebíveis aprovados com sucesso!");
      } catch {
        OToastManager.danger("Não podemos realizar essa operação!");
      } finally {
        setLoading(false);
        resetHaveHedge();
        modalManager.hide(approvalConfirmationModalId);
      }
    }
  }, [
    gridRef,
    hasReceivableWithHedgeIndication,
    haveHedge,
    selectedReceivables,
    setSelectedReceivables,
  ]);

  return {
    approveReceivables,
    grid,
    gridRef,
    hasReceivableWithHedgeIndication,
    haveHedge,
    loading,
    reducedReceivables,
    resetHaveHedge,
    selectedReceivables,
    setHaveHedge,
    totalSum,
  };
};
