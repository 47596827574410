import {
  ODataGridGeneratorConfig,
  removeKeyFromDataSourceItems,
} from "components/data-grid";
import { gridStorage } from "utils/storage";

export const pendenciesGrid = (
  onChange: (
    pendencies: HubEnergy.DiligenceStepPendencyConfigResponse[],
  ) => void,
  stepPendencyOptions: HubEnergy.DiligenceStepPendencyTypeResponse[],
): ODataGridGeneratorConfig<HubEnergy.DiligenceStepPendencyConfigResponse> => {
  return {
    datagrid: {
      noDataText: "Nenhuma pendência",
      stateStoring: gridStorage("pendenciesGrid"),
      editing: {
        allowDeleting: true,
        allowUpdating: true,
      },
      onRowUpdated: (row) => {
        onChange?.(
          removeKeyFromDataSourceItems(row.component.getDataSource().items()),
        );
      },
      onRowInserted: (row) => {
        onChange?.(
          removeKeyFromDataSourceItems(row.component.getDataSource().items()),
        );
      },
      onRowRemoved: (row) => {
        onChange?.(
          removeKeyFromDataSourceItems(row.component.getDataSource().items()),
        );
      },
      onInitNewRow: (e) => {
        e.data.isAutomation = false;
      },
      onEditorPreparing: (e) => {
        e.editorOptions = {
          ...e.editorOptions,
          dropDownOptions: {
            ...e.editorOptions?.dropDownOptions,
            minWidth: 400,
          },
          itemTemplate: (itemData, _, itemElement) => {
            (itemElement as HTMLElement).setAttribute("title", itemData.label);
            return itemData.label;
          },
        };
      },
      columnResizingMode: "nextColumn",
    },
    columns: [
      {
        dataField: "typeId",
        dataType: "string",
        caption: "Tipo da pendência",
        validationRules: [{ type: "required" }],
        lookup: {
          dataSource: stepPendencyOptions.map(({ name, id }) => ({
            value: id,
            label: name,
          })),
          displayExpr: "label",
          valueExpr: "value",
        },
      },
      {
        dataField: "typeId",
        dataType: "string",
        name: "description",
        caption: "Descrição",
        allowEditing: false,
        lookup: {
          dataSource: stepPendencyOptions.map(({ description, id }) => ({
            value: id,
            label: description,
          })),
          displayExpr: "label",
          valueExpr: "value",
        },
      },
      {
        dataField: "message",
        dataType: "string",
        caption: "Mensagem",
      },
      {
        dataField: "isAutomation",
        caption: "Automação",
        dataType: "boolean",
      },
    ],
  };
};
