import { modalManager, OToastManager } from "@maestro/core";
import { OTypography, OButton } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { DetailsCard } from "components/details-card";
import { IconButton } from "components/icon-button";
import { PageTitle } from "components/page-title";
import { NextStepButton } from "components/steps-builder";
import { useGridRef } from "hooks/grid-ref";
import { GridTemplate } from "templates/grid-template";

import { useCallback, useMemo } from "react";
import { helpers } from "@maestro/utils";
import { service } from "services";
import { useRoles } from "hooks/roles";
import {
  buildOperationDetails,
  uncapitalizeObjectKeys,
} from "../../../../../utils";
import { SearchModal, searchModalClientId } from "./_compose";
import { QuoteSuggestProvider, useQuoteSuggest } from "./quote-suggest.context";
import { quoteSuggestGrid } from "./quote-suggest.grid";
import { remapSuggestions } from "./quote-suggest.utils";

const QuoteSuggestStepComponent = () => {
  const {
    dataSource,
    requestedAmounts,
    suggestions,
    updateParentContext,
    searchPayload,
  } = useQuoteSuggest();

  const { hasRole } = useRoles();

  const showNextButton = useMemo(() => {
    return hasRole("RecebiveisCartao:Agendas.Admin");
  }, [hasRole]);

  const disableBotaoExportarCotacao =
    !searchPayload || searchPayload.cedentes.length > 1;

  const gridRef = useGridRef();

  const downloadQuotationFile = useCallback(async () => {
    try {
      if (!searchPayload) return [];

      const currentDate = new Date();
      const formattedDate =
        currentDate.getFullYear() +
        "" +
        (currentDate.getMonth() + 1) +
        "" +
        currentDate.getDate();

      const { data } = await service.quickfin.downloadSuggestQuotationFile({
        bandeiras: searchPayload.bandeiras,
        cedentes: searchPayload.cedentes,
        dataVencimentoFinal: searchPayload.dataVencimentoFinal,
        dataVencimentoInicial: searchPayload.dataVencimentoInicial,
        sacados: searchPayload.sacados,
        valorDesembolsoDesejado: searchPayload.valorDesembolsoDesejado,
      });

      const filename = `Exportar_cotacao_${data.identification}_${formattedDate}.xlsx`;
      helpers.downloadBase64(data.quotation, filename);
    } catch {
      OToastManager.danger("Não foi possível fazer o download do arquivo");
    }
  }, [searchPayload]);

  return (
    <>
      <div className="d-flex flex-column gap-4">
        <PageTitle
          title="Sugestão de recebíveis de cartão disponíveis para cedente"
          description="Obtenha uma sugestão de lista de recebíveis de cartão a serem antecipados para atender à solicitação de desembolso de um cedente sobre um montante total específico. A sugestão é feita priorizando-se os recebíveis de menor taxa total. Como segundo critério, dá-se preferência aos recebíveis de maior valor de vencimento. Insira parâmetros abaixo para restringir a escolha dos recebíveis (apenas o cedente e valor de desembolso são campos obrigatórios)."
        />
        <DetailsCard
          emptyStateComponent={<OTypography>Nenhum recebível</OTypography>}
          value={remapSuggestions(suggestions ?? []).map(
            uncapitalizeObjectKeys,
          )}
          fields={buildOperationDetails(requestedAmounts, {}, {})}
        />
        <GridTemplate
          actions={
            <IconButton
              icon={{ category: "far", icon: "fa-search" }}
              onClick={() => modalManager.show(searchModalClientId)}
            >
              Abrir busca
            </IconButton>
          }
          gridRef={gridRef}
          showRefreshButton
          showExportButton={disableBotaoExportarCotacao}
        >
          <ODataGridGenerator
            gridRef={gridRef}
            grid={quoteSuggestGrid}
            dataSource={dataSource}
          />
        </GridTemplate>
        <div className="d-flex justify-content-end gap-3 mt-4 w-100">
          <OButton
            onClick={downloadQuotationFile}
            disabled={disableBotaoExportarCotacao}
          >
            Exportar Cotação
          </OButton>
          {showNextButton && (
            <NextStepButton
              onClick={() => {
                updateParentContext();
              }}
            />
          )}
        </div>
      </div>
      <SearchModal />
    </>
  );
};

export const QuoteSuggestStep = () => (
  <QuoteSuggestProvider>
    <QuoteSuggestStepComponent />
  </QuoteSuggestProvider>
);
