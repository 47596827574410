import { OToastManager } from "@maestro/react";
import { masks } from "@maestro/utils";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { SetEligibilityButton } from "./_compose";
import { EligibilityOffer } from "./eligibility-offers.types";

export const dataSource = dataSourceCustomStoreGenerator<EligibilityOffer>(
  (loadOptions) =>
    service.eligibilityengine
      .getOffers(loadOptions as any)
      .then(({ data }) => ({
        data: data.data,
        totalCount: data.totalCount,
        groupCount: data.groupCount,
      }))
      .catch(() => {
        const errorMessage = "Erro ao buscar as ofertas";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
);

const buildEligibilityDetailsLink = ({
  governmentId,
  offerId,
}: EligibilityOffer) =>
  corporateRouter.routes.cartoes.product.eligibility.offers.details.path({
    taxId: governmentId,
    offerId,
  });

export const eligibilityOffersGrid: ODataGridGeneratorConfig<EligibilityOffer> =
  {
    datagrid: {
      noDataText: "Nenhuma oferta.",
      filterRow: { visible: true },
      headerFilter: { visible: true },
      pager: { showPageSizeSelector: true },
      groupPanel: { visible: true },
      grouping: { autoExpandAll: false },
      remoteOperations: true,
    },
    columns: [
      {
        caption: "Detalhes",
        role: roles.cartoes.product.eligibilityOffersDetailsPage.role,
        cellRender: ({ data }) =>
          data.snapshot && data.snapshot !== "{}" ? (
            <DetailsLink href={buildEligibilityDetailsLink(data)} />
          ) : (
            "Indisponível"
          ),
      },
      {
        dataField: "governmentId",
        dataType: "string",
        caption: "Documento",
        format: (value) => masks.cpfCnpj(value),
        calculateFilterExpression: calculateTaxIdFilterExpression,
        allowHeaderFiltering: false,
      },
      {
        dataField: "productDescription",
        dataType: "string",
        caption: "Produto",
      },
      {
        dataField: "variantName",
        dataType: "string",
        caption: "Variante",
      },
      {
        dataField: "isEligible",
        dataType: "boolean",
        caption: "Elegível",
      },
      {
        dataField: "hasAccepted",
        dataType: "boolean",
        caption: "Oferta aceita",
      },
      {
        dataField: "createdAt",
        dataType: "datetime",
        format: "shortDate",
        caption: "Data da oferta",
        allowHeaderFiltering: false,
      },
      {
        dataField: "expirationDate",
        dataType: "datetime",
        format: "shortDate",
        caption: "Data de expiração",
        allowHeaderFiltering: false,
      },
      {
        caption: "Ações",
        cellRender: ({ data: { id, isEligible, governmentId } }) => (
          <SetEligibilityButton
            id={id}
            isEligible={isEligible}
            taxId={governmentId}
            reloadGrid={() => dataSource.reload()}
          />
        ),
      },
    ],
  };
