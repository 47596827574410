import { YesNoRadioField } from "./yes-no-radio-field.component";

export const HasPayerValidationField = () => {
  return (
    <YesNoRadioField
      label="Habilitar a validação de sacados?"
      name="hasPayerValidation"
    />
  );
};
