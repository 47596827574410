import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { SelectSearchFieldV2Option } from "../select-search-v2";
import { ServiceMethod } from "./api-selector.types";

export const useApiSelector = <T, V>(
  serviceMethod: ServiceMethod<T>,
  fieldName: string,
  optionMapper: (apiResponse: T | undefined) => SelectSearchFieldV2Option<V>[],
) => {
  const { callService, loading, value, hasError } =
    useServiceCall(serviceMethod);

  const { clearErrors, setError } = useFormContext();

  const fetch = useCallback(async () => {
    const { success } = await callService();
    if (success) clearErrors(fieldName);
    else
      setError(fieldName, {
        message:
          "Erro ao buscar as opções. Clique no botão ao lado para tentar novamente.",
      });
  }, [callService, clearErrors, fieldName, setError]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const options = useMemo(() => optionMapper(value), [optionMapper, value]);

  return {
    fetch,
    loading,
    hasError,
    options,
  };
};
