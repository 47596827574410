import { YesNoRadioField } from "./yes-no-radio-field.component";

export const HasGuarantorsField = () => {
  return (
    <YesNoRadioField
      label="Possui avalistas/devedores solidários"
      name="hasGuarantors"
    />
  );
};
