import { DeepNullable, MaestroFields, YupObjectSchema } from "utils/types";
import * as yup from "yup";

export interface PendencyTypeForm {
  name: string;
  description: string;
}

export const pendencyTypeFormFields = {
  name: {
    id: "name",
    name: "name",
    tag: "text",
    label: "Nome",
    placeholder: "Preencher",
    required: true,
  },
  description: {
    id: "description",
    name: "description",
    tag: "text",
    label: "Descrição",
    placeholder: "Preencher",
    required: true,
  },
} as const satisfies MaestroFields<PendencyTypeForm>;

const stringRequired = yup.string().required("Campo obrigatório");

export const pendencyTypeFormValidationSchema = yup.object<
  PendencyTypeForm,
  YupObjectSchema<PendencyTypeForm>
>({
  name: stringRequired,
  description: stringRequired,
});

export const pendencyTypeFormDefaultValues = {
  description: null,
  name: null,
} satisfies Partial<
  DeepNullable<PendencyTypeForm>
> as unknown as PendencyTypeForm;
