import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FerramentasProdutoUsuariosByUUIDRouteParams } from "pages/ferramentas/routes/ferramentas.route-params";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { GatekeeperProfileForm } from "../_compose";
import { useGatekeeperProfilesGeneralInformation } from "./general-information.hook";

export const GatekeeperProfilesGeneralInformation = () => {
  const { uuid } = useParams<FerramentasProdutoUsuariosByUUIDRouteParams>();
  if (!uuid) throw new Error("No uuid");

  const [profileName, setProfileName] = useState<string>();

  const loadProfileName = useCallback(async () => {
    const { data } = await service.gatekeeper.getProfileDetails({
      profiles: [{ uuid }],
    });

    setProfileName(data[0].name);
  }, []);

  useEffect(() => {
    loadProfileName();
  }, [loadProfileName]);

  const { submit, form, loading } = useGatekeeperProfilesGeneralInformation(
    uuid,
    profileName,
  );

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Perfil Gatekeeper"
          description="Visualize e edite os dados do perfil selecionado"
        />
      }
      actions={
        <LoadingButton
          loading={loading}
          dataAction="informacoes_gerais:botao:atualizar"
          dataLabel="atualizar"
          onClick={submit}
        >
          Atualizar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <GatekeeperProfileForm form={form} />
      </CardTemplate>
    </FormTemplate>
  );
};
