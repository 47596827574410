import * as yup from "yup";
import { LoanAgreementFormValues } from "./loan-agreement-form.component";

export const loanAgreementValidationFormSchema: yup.ObjectSchema<LoanAgreementFormValues> =
  yup.object({
    name: yup.string().required("Este campo é obrigatório"),
    defaultGracePeriod: yup
      .number()
      .transform((value) => (value === null ? 0 : value))
      .required("Este campo é obrigatório"),
    maxGracePeriod: yup
      .number()
      .transform((value) => value || null)
      .required("Este campo é obrigatório"),
    partialLimitConsumeAllowed: yup
      .mixed((input): input is boolean => typeof input === "boolean")
      .transform(
        (value) => ({ true: true, false: false }[value as string] ?? null),
      )
      .required("Este campo é obrigatório"),
    paymentDayType: yup.string().required("Este campo é obrigatório"),
    isIofExempt: yup
      .mixed((input): input is boolean => typeof input === "boolean")
      .transform(
        (value) => ({ true: true, false: false }[value as string] ?? null),
      )
      .required("Este campo é obrigatório"),
    iofFinance: yup
      .mixed((input): input is boolean => typeof input === "boolean")
      .transform(
        (value) => ({ true: true, false: false }[value as string] ?? null),
      )
      .required("Este campo é obrigatório"),
    hasGuarantee: yup
      .mixed((input): input is boolean => typeof input === "boolean")
      .transform(
        (value) => ({ true: true, false: false }[value as string] ?? null),
      )
      .required("Este campo é obrigatório"),
    isSocialImpact: yup
      .mixed((input): input is boolean => typeof input === "boolean")
      .transform(
        (value) => ({ true: true, false: false }[value as string] ?? null),
      )
      .required("Este campo é obrigatório"),
    product: yup.string().required("Este campo é obrigatório"),
    valorizationMethod: yup.string().required("Este campo é obrigatório"),
    lockDisbursement: yup.string().nullable(),
    bookCr: yup
      .number()
      .transform((value) => value || null)
      .required("Este campo é obrigatório"),
    strategyCr: yup
      .number()
      .transform((value) => value || null)
      .required("Este campo é obrigatório"),
    minimumGuarantors: yup
      .number()
      .transform((value) => value || null)
      .nullable(),
    maximumLimitForSimpleGuarantor: yup
      .number()
      .transform((value) => value || null)
      .nullable(),
    minimumParticipationForGuarantor: yup
      .number()
      .transform((value) => value || null)
      .nullable(),
    settlementAgencyCode: yup
      .number()
      .transform((value) => value || null)
      .required("Este campo é obrigatório"),
    amortizationFrequencyCode: yup
      .string()
      .required("Este campo é obrigatório"),
    spreadDayCountCode: yup.string().required("Este campo é obrigatório"),
    automaticallyApproves: yup
      .mixed((input): input is boolean => typeof input === "boolean")
      .transform(
        (value) => ({ true: true, false: false }[value as string] ?? null),
      )
      .required("Este campo é obrigatório"),
    useInstallmentFlow: yup
      .mixed((input): input is boolean => typeof input === "boolean")
      .transform(
        (value) => ({ true: true, false: false }[value as string] ?? null),
      )
      .required("Este campo é obrigatório"),
    minRate: yup
      .number()
      .transform((value) => value || null)
      .nullable(),
    maxRate: yup
      .number()
      .transform((value) => value || null)
      .nullable(),
    minAmount: yup
      .number()
      .transform((value) => value || null)
      .nullable(),
    maxAmount: yup
      .number()
      .transform((value) => value || null)
      .nullable(),
    minTerm: yup
      .number()
      .transform((value) => value || null)
      .nullable(),
    maxTerm: yup
      .number()
      .transform((value) => value || null)
      .nullable(),
  });
