import { OCol, OOptionMultiple, ORFieldSelectMultiple } from "@maestro/react";

interface FilesForOperationFieldProps {
  disabled?: boolean;
}

const filesForReceivablesOptions = [
  "Nota",
  "Contrato",
  "Garantia",
  "Adesao",
  "Auxiliar",
];

export const FilesForOperationField = ({ disabled }: FilesForOperationFieldProps) => {
  return (
    <OCol xs={12} md={6}>
      <ORFieldSelectMultiple
        dataAction="geral:texto:arquivos_operacao"
        dataLabel="arquivos_operacao"
        id="filesForOperation"
        name="filesForOperation"
        label="Arquivos obrigatórios para a solicitação da operação"
        disabled={disabled}
      >
        {filesForReceivablesOptions.map((option) => (
          <OOptionMultiple key={option} value={option}>
            {option}
          </OOptionMultiple>
        ))}
      </ORFieldSelectMultiple>
    </OCol>
  );
};
