import {
  OIcon,
  OTab,
  OTabDropdown,
  OTabHeader,
  OTypography,
} from "@maestro/react";
import { useState } from "react";
import { CardStatus, ICard } from "../cards.types";
import { CardContainer } from "./already-approved.styles";
import {
  IAlreadyApprovedProps,
  MapperCardModalityAndType,
} from "./already-approved.types";
import { Description } from "./description/description.component";
import { Functions } from "./functionsAndSetups/functions.component";
import { Setups } from "./functionsAndSetups/setups.component";
import { Tracking } from "./tracking/tracking.component";

const numberOfVisibleTabs = 4;

export const AlreadyApproved = ({
  selectedCompany,
  cardAccountData,
  invoicesData,
  loadCards,
}: IAlreadyApprovedProps) => {
  const [indexView, setIndexView] = useState<number>(0);

  const renderMenuItemContent = (data: ICard) => {
    const { last4Digits, status, modality } = data;

    const info = MapperCardModalityAndType[modality];

    return (
      <div className="d-flex flex-row align-items-start gap-2">
        <OTypography type="dark">{info}</OTypography>
        <div className="d-flex flex-row gap-2">
          <OTypography type="dark">(Final {last4Digits})</OTypography>
          {status === CardStatus.Normal ||
          status === CardStatus.Created ||
          status === CardStatus.Sleeping ? (
            <OIcon type="dark" category="fal" icon="fa-credit-card" />
          ) : status === CardStatus.Requested ||
            status === CardStatus.Expired ? (
            <OIcon type="dark" category="fal" icon="fa-clock" />
          ) : (
            <OIcon type="dark" category="fal" icon="fa-lock-alt" />
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      {cardAccountData && (
        <>
          <OTabHeader>
            {cardAccountData.cards
              .slice(0, numberOfVisibleTabs)
              .map((data, index) => (
                <OTab
                  dataAction={`cartoes-fisicos:tab:cartao-${data?.last4Digits}`}
                  dataLabel={`cartao-${data?.last4Digits}`}
                  key={`menu-item-AlreadyApproved-${index}`}
                  handleClick={() => setIndexView(index)}
                >
                  {renderMenuItemContent(data)}
                </OTab>
              ))}
            {cardAccountData.cards.length > numberOfVisibleTabs && (
              <OTabDropdown
                dataAction="cartoes-fisicos:tab:tab_dropdown"
                dataLabel="tab_dropdown"
              >
                <OIcon category="orq" icon="orq-more-options" size="xl" />

                <div slot="list">
                  {cardAccountData.cards
                    .slice(numberOfVisibleTabs)
                    .map((data, index) => (
                      <OTab
                        dataAction={`cartoes-fisicos:tab:cartao-${data?.last4Digits}`}
                        dataLabel={`cartao-${data?.last4Digits}`}
                        key={`menu-item-AlreadyApproved-${
                          index + numberOfVisibleTabs
                        }`}
                        handleClick={() =>
                          setIndexView(index + numberOfVisibleTabs)
                        }
                      >
                        {renderMenuItemContent(data)}
                      </OTab>
                    ))}
                </div>
              </OTabDropdown>
            )}
          </OTabHeader>
          <CardContainer className="py-2">
            <Description
              cardData={cardAccountData.cards[indexView]}
              cardProgramData={cardAccountData.program}
              cardInvoiceData={
                invoicesData &&
                invoicesData.find((invoice) => invoice.isCurrent === true)
              }
              selectedCompany={selectedCompany}
              loadCards={loadCards}
            />
            <Tracking
              cardData={cardAccountData.cards[indexView]}
              selectedCompany={selectedCompany}
            />
            <Functions
              cardData={cardAccountData.cards[indexView]}
              selectedCompany={selectedCompany}
            />
            <Setups cardData={cardAccountData.cards[indexView]} />
          </CardContainer>
        </>
      )}
    </div>
  );
};
