import {
  OCard,
  OCardBody,
  OCheckbox,
  OCol,
  OLabel,
  OOption,
  OOptionMultiple,
  ORFieldCheckboxGroup,
  ORFieldInput,
  ORFieldSelect,
  ORFieldSelectMultiple,
  ORow,
  OTypography,
} from "@maestro/react";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { SelectSearchField } from "components/select-search/field/select-search-field.component";
import { FormProvider } from "react-hook-form";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { orqIcons } from "../../../../configuracoes/workflow-configuration.utils";
import { useEditWorkflowConfigMainData } from "./edit-workflow-config-main-data.hook";
import { defaultUserNotificationTypeData } from "./edit-workflow-config-main-data.utils";

export const EditWorkflowConfigMainData = () => {
  const {
    allowedChildWorkflowConfigsOptions,
    books,
    creditLines,
    detailsPageTypeData,
    form,
    hasError,
    loading,
    loadingEditWorkflowConfig,
    minimumCriteriaOptions,
    reusableWorkflowConfigsOptions,
    workflowConfig,
    getAllData,
    updateWorkflow,
  } = useEditWorkflowConfigMainData();

  const { handleSubmit, setValue } = form;

  const iconOptions = orqIcons.map((icon) => ({
    value: icon,
    label: icon,
    onSelect: (value: string) => setValue("icon", value),
  }));

  return (
    <FormTemplate
      pageTitle={
        <PageTitle title="Editar configuração de workflow - Dados principais" />
      }
      actions={
        <LoadingButton
          loading={loadingEditWorkflowConfig}
          onClick={handleSubmit(updateWorkflow)}
          disabled={hasError}
        >
          Salvar
        </LoadingButton>
      }
    >
      <ContentTemplate
        hasError={hasError}
        loading={loading}
        noValue
        errorComponent={
          <ErrorComponent
            messageTitle="Não foi possível carregar detalhes do processador"
            messageParagraph="Clique no botão para tentar novamente."
          >
            <TryAgainButton onClick={getAllData} />
          </ErrorComponent>
        }
        render={() => (
          <FormProvider {...form}>
            <form spellCheck="false">
              <OCard className="justify-content-between my-3">
                <OCardBody>
                  <ORow>
                    <div className="d-flex justify-content-between align-items-center my-2">
                      <OTypography size="lg">Tela Inicial</OTypography>
                    </div>
                    <OCol sm={3} className="d-flex align-items-right">
                      <ORFieldInput
                        id="name"
                        name="name"
                        tag="text"
                        label="Nome"
                        labelSize="md"
                        tooltip="Label utilizado como título na listagem dos cards e na home do workflow."
                        tooltipPosition="top-right"
                      />
                    </OCol>
                    <OCol sm={6}>
                      <ORFieldInput
                        id="description"
                        name="description"
                        tag="text"
                        label="Descrição"
                        labelSize="md"
                        tooltip="Descrição utilizada na listagem dos cards e na home do workflow."
                        tooltipPosition="top-right"
                      />
                    </OCol>
                    <OCol sm={3}>
                      <ORFieldInput
                        id="gridTitle"
                        name="gridTitle"
                        tag="text"
                        label="Título da Grid"
                        labelSize="md"
                        tooltip="Título que aparece acima da grid. Exemplo: clientes, propostas, etc."
                        tooltipPosition="top-right"
                      />
                    </OCol>
                    <OCol sm={3}>
                      <ORFieldInput
                        id="tabTitle"
                        name="tabTitle"
                        tag="text"
                        label="Subtítulo"
                        labelSize="md"
                        tooltip="Título de apoio que aparece em cima da barra de busca."
                        tooltipPosition="top-right"
                      />
                    </OCol>
                    <OCol sm={6}>
                      <ORFieldInput
                        id="tabDescription"
                        name="tabDescription"
                        tag="text"
                        label="Descrição Subtítulo"
                        labelSize="md"
                        tooltip="Descrição que aparece abaixo do título de apoio."
                        tooltipPosition="top-right"
                      />
                    </OCol>
                    <OCol sm={3}>
                      <SelectSearchField
                        id="icon"
                        name="icon"
                        label="Ícone"
                        labelSize="md"
                        tooltip="Ícone utilizado na listagem dos cards."
                        tooltipPosition="top-right"
                        placeholder="Selecionar"
                        options={iconOptions}
                        value={workflowConfig?.icon ?? ""}
                        onClear={() => setValue("icon", "")}
                        maxOptions={50}
                      />
                    </OCol>
                  </ORow>
                </OCardBody>
              </OCard>
              <OCard className="justify-content-between my-3">
                <OCardBody>
                  <ORow>
                    <div className="d-flex justify-content-between align-items-center my-2">
                      <OTypography size="lg">Hierarquia</OTypography>
                    </div>
                    <OCol sm={4}>
                      <ORFieldInput
                        id="tabName"
                        name="tabName"
                        tag="text"
                        label="Título da Tab"
                        labelSize="md"
                        tooltip="Label utilizado quando o workflow faz parte de uma hierarquia. É utilizado para identificar qual workflow da hierarquia está sendo utilizado."
                        tooltipPosition="top-right"
                      />
                    </OCol>
                    <OCol sm={6}>
                      <ORFieldSelectMultiple
                        id="allowedChildWorkflowConfigs"
                        name="allowedChildWorkflowConfigs"
                        label="Workflows da Hierarquia"
                        labelSize="md"
                        tooltip="Workflows adicionais listados na home do workflow atual."
                        tooltipPosition="top-right"
                        key={allowedChildWorkflowConfigsOptions
                          ?.map((x) => x.id)
                          .join("_")}
                      >
                        {allowedChildWorkflowConfigsOptions?.map((x) => (
                          <OOptionMultiple
                            value={String(x.id)}
                            key={`child-workflow-configs-option-${x.id}`}
                          >
                            <OTypography>{x.name}</OTypography>
                          </OOptionMultiple>
                        ))}
                      </ORFieldSelectMultiple>
                    </OCol>
                  </ORow>
                </OCardBody>
              </OCard>
              <OCard className="justify-content-between my-3">
                <OCardBody>
                  <ORow>
                    <div className="d-flex justify-content-between align-items-center my-2">
                      <OTypography size="lg">
                        Workflow Reutilizáveis
                      </OTypography>
                    </div>
                    <OCol sm={6}>
                      <ORFieldSelectMultiple
                        id="reusableWorkflowConfigs"
                        name="reusableWorkflowConfigs"
                        label="Workflows Reutilizáveis"
                        labelSize="md"
                        tooltip="Workflows cujos leads podem ser reutilizados"
                        tooltipPosition="top-right"
                        key={reusableWorkflowConfigsOptions
                          ?.map((r) => r.id)
                          .join("_")}
                      >
                        {reusableWorkflowConfigsOptions?.map((r) => (
                          <OOptionMultiple
                            value={String(r.id)}
                            key={`child-workflow-configs-option-${r.id}`}
                          >
                            <OTypography>{r.name}</OTypography>
                          </OOptionMultiple>
                        ))}
                      </ORFieldSelectMultiple>
                    </OCol>
                  </ORow>
                </OCardBody>
              </OCard>
              <OCard className="justify-content-between my-3">
                <OCardBody>
                  <ORow>
                    <div className="d-flex justify-content-between align-items-center my-2">
                      <OTypography size="lg">Outros</OTypography>
                    </div>
                    <OCol sm={4}>
                      <ORFieldSelectMultiple
                        id="minimumCriteria"
                        name="minimumCriteria"
                        label="Critérios mínimos"
                        labelSize="md"
                        tooltip="Critérios visíveis no pré-formulário e alguns com validação que pode ser antes ou após iniciado o lead."
                        tooltipPosition="top-right"
                        key={minimumCriteriaOptions?.map((x) => x.id).join("_")}
                      >
                        {minimumCriteriaOptions?.map((x) => (
                          <OOptionMultiple
                            value={String(x.id)}
                            key={`minimum-criteria-option-${x.id}`}
                          >
                            <OTypography>{x.title}</OTypography>
                          </OOptionMultiple>
                        ))}
                      </ORFieldSelectMultiple>
                    </OCol>
                    <OCol sm={4}>
                      <ORFieldSelectMultiple
                        id="defaultCreditLines"
                        name="defaultCreditLines"
                        label="Linhas de crédito padrão"
                        labelSize="md"
                        tooltip="Linha de crédito para ser adicionada automaticamente nas linhas de créditos desejadas do lead (WorflowLeadCreditLine)."
                        tooltipPosition="top-right"
                        key={creditLines
                          ?.map((cl) => cl.identification)
                          .join("_")}
                      >
                        {creditLines?.map(({ identification }) => (
                          <OOptionMultiple
                            value={identification}
                            key={`credit-lines-option-${identification}`}
                          >
                            <OTypography>{identification}</OTypography>
                          </OOptionMultiple>
                        ))}
                      </ORFieldSelectMultiple>
                    </OCol>
                    <OCol sm={4}>
                      <ORFieldSelect
                        id="bookCr"
                        name="bookCr"
                        label="Book"
                        labelSize="md"
                        tooltip="Book da linha de crédito principal."
                        tooltipPosition="top-right"
                        key={books?.map((book) => book.bookCr).join("_")}
                      >
                        {books?.map((book) => (
                          <OOption
                            value={book.bookCr}
                            key={`book-cr-${book.bookCr}`}
                          >
                            {book.bookName}
                          </OOption>
                        ))}
                      </ORFieldSelect>
                    </OCol>
                    <OCol sm={4}>
                      <ORFieldInput
                        id="nameId"
                        name="nameId"
                        tag="text"
                        label="Name Id"
                        labelSize="md"
                        tooltip="Nome sistêmico do Workflow, utilizado para definir Roles necessários para visualização e utilizado em integrações."
                        tooltipPosition="top-right"
                      />
                    </OCol>
                    <OCol sm={4}>
                      <ORFieldInput
                        id="identification"
                        name="identification"
                        tag="text"
                        label="Identificação do WF"
                        labelSize="md"
                        tooltip="Utilizado em integrações para identificar o workflow."
                        tooltipPosition="top-right"
                        readonly
                        disabled
                      />
                    </OCol>
                    <OCol sm={4}>
                      <ORFieldSelect
                        id="detailsPageType"
                        name="detailsPageType"
                        label="Tipo da página de detalhes"
                        labelSize="md"
                        key={detailsPageTypeData?.join("_")}
                      >
                        {detailsPageTypeData?.map((detailsPageType) => (
                          <OOption
                            value={String(detailsPageType)}
                            key={`details-page-type-${detailsPageType}`}
                          >
                            <OTypography>{detailsPageType}</OTypography>
                          </OOption>
                        ))}
                      </ORFieldSelect>
                    </OCol>
                    <OCol sm={4}>
                      <ORFieldSelect
                        id="defaultUserNotificationType"
                        name="defaultUserNotificationType"
                        label="Tipo de notificação padrão"
                        labelSize="md"
                        tooltip={`Opção "Nenhum" decide se manda por Whatsapp ou e-mail`}
                        tooltipPosition="top-right"
                        key={defaultUserNotificationTypeData?.join("_")}
                      >
                        {defaultUserNotificationTypeData?.map(
                          ({ value, label }) => (
                            <OOption
                              value={value}
                              key={`details-page-type-${label}`}
                            >
                              <OTypography>{label}</OTypography>
                            </OOption>
                          ),
                        )}
                      </ORFieldSelect>
                    </OCol>
                    <OCol>
                      <ORFieldCheckboxGroup
                        id="finishPreviousBigNumberOnStartNewOne"
                        name="finishPreviousBigNumberOnStartNewOne"
                        label="Etapas"
                        labelSize="md"
                        tooltip="Se selecionado, as etapas e status existentes em um lead serão encerrados assim que o lead entrar em outra etapa."
                        tooltipPosition="top-right"
                      >
                        <div className="d-flex flex-row gap-2 align-items-center">
                          <OCheckbox
                            size="xs"
                            id="finishable-status-checkbox"
                            value="true"
                          />
                          <OLabel htmlFor="finishable-status-checkbox">
                            Etapas síncronas?
                          </OLabel>
                        </div>
                      </ORFieldCheckboxGroup>
                    </OCol>
                  </ORow>
                </OCardBody>
              </OCard>
            </form>
          </FormProvider>
        )}
      />
    </FormTemplate>
  );
};
