import { OCol, ORFieldInput } from "@maestro/react";

export const PnlStrategyField = () => {
  return (
    <OCol xs={12} md={6}>
      <ORFieldInput
        dataAction="geral:texto:pnl_estrategia"
        dataLabel="pnl_estrategia"
        id="pnlStrategy"
        name="pnlStrategy"
        tag="text"
        placeholder="Preencher"
        label="Estratégia PNL"
      />
    </OCol>
  );
};
