/* eslint-disable no-param-reassign */
import { isAxiosError } from "axios";
import { service } from "services";
import { logger } from "utils/logger";
import { UserCommand } from "./types/command";
import { User } from "./user";

export class GetUserCommand implements UserCommand {
  async execute(user: User) {
    try {
      user.state = "LOADING";

      const { data: userData } = await service.clerk.getUserCompleteSelf();

      if (!userData) throw new Error("Could not get user");

      user.value = userData;
      user.state = "AUTHENTICATED";
    } catch (err) {
      if (!isAxiosError(err)) logger.error(err);
      user.value = undefined;
      user.state = "ERROR";
    }
  }
}
