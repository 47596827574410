import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { validationSchema } from "../_compose/profile-form/profile-form.form";
import { FormValuesType } from "../_compose/profile-form/profile-form.types";

export const useGatekeeperProfilesGeneralInformation = (
  uuid?: string,
  name?: string,
) => {
  const navigate = useNavigate();
  const { routes } = corporateRouter;

  const [loading, setLoading] = useState(false);

  const form = useForm<FormValuesType>({
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, reset } = form;

  const submit = handleSubmit(async (values) => {
    try {
      setLoading(true);

      const {
        isBuiltIn,
        isCustom,
        portal,
        product,
        profile,
        unavailablePermissionsDescription: unavailable,
      } = values;

      const payload = {
        profile: {
          ...values,
          group: values.groupName,
          unavailablePermissionsDescription: unavailable ?? "",
          name: `${portal}:${product}.${profile}`,
          isAvailableForCustomProfiles: isCustom === "true",
          isBuiltIn: isBuiltIn === "true",
        },
      };

      const { data } = await service.gatekeeper.addProfile(payload);

      OToastManager.success("Profile alterado com sucesso");

      if (!uuid) throw new Error("No uuid");

      navigate(
        routes.ferramentas.product.gatekeeper.profiles.profile.generalInformation.path(
          { uuid: data.uuid },
        ),
      );
    } catch (err) {
      OToastManager.danger("Erro para salvar os dados");
    } finally {
      setLoading(false);
    }
  });

  const load = useCallback(async () => {
    try {
      setLoading(true);

      if (!name) return;

      const { data } = await service.gatekeeper.getProfileByName(name);

      const [portal, rest] = name.split(":") as string[];
      const {
        isAvailableForCustomProfiles,
        isBuiltIn,
        availablePermissionsDescription: available,
        unavailablePermissionsDescription: unavailable,
        type,
        nameLabel,
        groupLabel,
        groupName,
      } = data;

      const resetValues = {
        portal,
        product: rest && rest.split(".")[0],
        profile: rest && rest.split(".")[1],
        type: type ?? "",
        nameLabel: nameLabel ?? "",
        availablePermissionsDescription: available ?? "",
        unavailablePermissionsDescription: unavailable ?? "",
        isBuiltIn: isBuiltIn ? "true" : "false",
        isCustom: isAvailableForCustomProfiles ? "true" : "false",
        groupLabel: groupLabel ?? "",
        groupName: groupName ?? "",
      };

      reset(resetValues);
    } catch (err) {
      OToastManager.danger("Erro para buscar os dados");
    } finally {
      setLoading(false);
    }
  }, [name, reset]);

  useEffect(() => {
    load();
  }, [load]);

  return {
    form,
    submit,
    loading,
  };
};
