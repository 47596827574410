import { YesNoRadioField } from "./yes-no-radio-field.component";

export const NotificacaoTedDevolvidaCedenteField = () => {
  return (
    <YesNoRadioField
      label="Notificações de TED devolvida Cedente"
      name="notificacaoTedDevolvidaCedente"
      description="E-mail de notificação ao cedente quando ocorrer erros na TED de desembolso"
    />
  );
};
