import { Options } from "devextreme/data/data_source";
import uniqBy from "lodash/uniqBy";
import { InternalValueOf, NestedKeyOf } from "./header-filter.types";

type Filter = (string | null | Filter)[];

/**
 * Given 2 arrays of matching keys and values, generates a
 * datagrid filter expression of the type
 * ```
 * [[key1, "=", value1], "and", ..., [key_n, "=", value_n]]
 * ```
 * When the arrays have length 1, it returns the simplified expression
 * ```
 * [key1, "=", value1]
 * ```
 */
const generateFilter = (keys: readonly string[], values: string[]): Filter => {
  if (keys.length === 1) {
    return [keys[0], "=", values[0]];
  }

  const filter = keys.reduce((_filter, key, idx) => {
    _filter.push([key, "=", values[idx]]);
    _filter.push("and");
    return _filter;
  }, [] as Filter);

  filter.pop(); // remove trailing "and"

  return filter;
};

/**
 * Access a nested property of an object via a string containing the "path"
 * ```
 * const obj = {
 *   a: {
 *     b: {
 *       c: 4,
 *     },
 *   },
 * };
 *
 * // const innerProperty = 4
 * const innerProperty = access(obj, "a.b.c");
 * ```
 */
export const access = <T, K extends NestedKeyOf<T>>(
  obj: T,
  path: K,
): InternalValueOf<T, K> => {
  const keys = path.split(".");
  let _obj = obj;
  for (const key of keys) {
    _obj = (_obj as any)?.[key];
  }
  return _obj as InternalValueOf<T, K>;
};

/**
 * Takes an array of keys of an object and maps to an array of the respective property values
 * ```
 * interface Obj {
 *   a: TypeA;
 *   b: TypeB;
 *   c: TypeC;
 * }
 *
 * // type Values = [TypeA, TypeB, TypeC]
 * type Values = MapToInternalValue<Obj, ["a", "b", "c"]>;
 * ```
 */
type MapToInternalValue<T, U> = {
  [K in keyof U]: InternalValueOf<T, U[K]>;
};

/**
 * Generates helpers to use in the HeaderFilter of the datagrid.
 * Takes an array with the desired dataFields and a callback that
 * returns the string of the HeaderFilter items
 *
 * It's a function that returns a function to get around the Typescript limitation
 * that it can't pass one type parameter and infer the other
 *
 * @param fields Array with the `dataFields` that will be used to calculate the text
 * @param calculateTextcallback callback that takes the items from `fields` and returns the label of the HeaderFilter items
 * @returns An array with two helpers:
 * - `applyPostProcess` - To be used in `headerFilter: { dataSource: (options) => { applyPostProcess(options) } }`
 * to apply the label mapping
 * - `calculateCellValue` - To be used in `calculateCellValue` to generate the appropriate value for the post processing
 */
export const configureHeaderFilterDataSource =
  <T>() =>
  <U extends readonly NestedKeyOf<T>[]>(
    fields: U,
    calculateText: (...args: MapToInternalValue<T, U>) => string,
  ): [
    (options: { dataSource?: Options | null | undefined }) => void,
    (rowData: T) => unknown,
  ] => {
    return [
      function applyPostProcess(options) {
        if (options.dataSource) {
          // eslint-disable-next-line no-param-reassign
          options.dataSource.postProcess = (results) => {
            const newresults = uniqBy(
              results.map((item: { value: MapToInternalValue<T, U> }) => ({
                key: calculateText(...item.value),
                text: calculateText(...item.value),
                value: generateFilter(fields, item.value as string[]),
              })),
              "key",
            );
            newresults.sort((a, b) => a.text.localeCompare(b.text));
            return newresults;
          };
        }
      },
      function calculateCellValue(data) {
        return fields.map((key: any) => access(data, key));
      },
    ];
  };

/**
 * Disables header filter pagination to force a single request to fetch data.
 * The pagination was broken in some odata datagrids, fetching all the data multiple times.
 * Used in `headerFilter: { dataSource: (options) => { disableHeaderFilterPagination(options) } }`
 */
export const disableHeaderFilterPagination = (options: {
  dataSource?: Options | null | undefined;
}) => {
  if (options.dataSource) {
    // eslint-disable-next-line no-param-reassign
    options.dataSource.paginate = false;
  }
};
