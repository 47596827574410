import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import {
  AssociatedCondition,
  Condition,
  ConditionDestination,
} from "services/hubcreditmanager/models/types/workflow/workflow.types";
import { WorkflowProdutoWorkflowConfigById } from "../../routes/workflow.route-params";

interface WorkflowContextProps {
  conditionDestination: ConditionDestination;
  setConditionDestination: (newSate: ConditionDestination) => void;
  selectedConditions: AssociatedCondition[];
  setSelectedConditions: React.Dispatch<
    React.SetStateAction<AssociatedCondition[]>
  >;
  conditionOrderParam: string;
  setConditionOrderParam: React.Dispatch<React.SetStateAction<string>>;
  gridCondition?: Condition;
  setGridCondition: (newState?: Condition) => void;
  bigNumbers: ReturnType<
    typeof useServiceCall<
      Parameters<typeof service.hubCreditManager.getBigNumbers>,
      Awaited<ReturnType<typeof service.hubCreditManager.getBigNumbers>>["data"]
    >
  >;
  processorConfig: ReturnType<
    typeof useServiceCall<
      Parameters<typeof service.hubCreditManager.getProcessorConfigDetails>,
      Awaited<
        ReturnType<typeof service.hubCreditManager.getProcessorConfigDetails>
      >["data"]
    >
  >;
  conditions: ReturnType<
    typeof useServiceCall<
      Parameters<typeof service.hubCreditManager.getConditions>,
      Awaited<ReturnType<typeof service.hubCreditManager.getConditions>>["data"]
    >
  >;
  batchTemplates: ReturnType<
    typeof useServiceCall<
      Parameters<typeof service.hubCreditManager.getBatchFileTemplates>,
      Awaited<
        ReturnType<typeof service.hubCreditManager.getBatchFileTemplates>
      >["data"]
    >
  >;
  configurableStatus: ReturnType<
    typeof useServiceCall<
      Parameters<typeof service.hubCreditManager.getConfigurableStatus>,
      Awaited<
        ReturnType<typeof service.hubCreditManager.getConfigurableStatus>
      >["data"]
    >
  >;
  workflowConfigViews: ReturnType<
    typeof useServiceCall<
      Parameters<
        typeof service.hubCreditManager.getWorkflowConfigViewsByWorkflowConfigId
      >,
      Awaited<
        ReturnType<
          typeof service.hubCreditManager.getWorkflowConfigViewsByWorkflowConfigId
        >
      >["data"]
    >
  >;
}

export const WorkflowContext = createContext({} as WorkflowContextProps);

interface WorkflowProviderProps {
  children: ReactNode;
}

export const WorkflowProvider = ({ children }: WorkflowProviderProps) => {
  const { id } = useParams<WorkflowProdutoWorkflowConfigById>();

  const [conditionDestination, setConditionDestination] =
    useState<ConditionDestination>("PROCESSOR_CONFIG");
  const [selectedConditions, setSelectedConditions] = useState<
    AssociatedCondition[]
  >([]);
  const [gridCondition, setGridCondition] = useState<Condition>();
  const [conditionOrderParam, setConditionOrderParam] =
    useState<string>("Description");

  const bigNumbers = useServiceCall(service.hubCreditManager.getBigNumbers);
  const processorConfig = useServiceCall(
    service.hubCreditManager.getProcessorConfigDetails,
  );
  const conditions = useServiceCall(service.hubCreditManager.getConditions);
  const configurableStatus = useServiceCall(
    service.hubCreditManager.getConfigurableStatus,
  );

  const batchTemplates = useServiceCall(
    service.hubCreditManager.getBatchFileTemplates,
  );

  const workflowConfigViews = useServiceCall(
    service.hubCreditManager.getWorkflowConfigViewsByWorkflowConfigId,
  );

  const { callService: getBigNumbers } = bigNumbers;
  const { hasError: hasErrorProcessorConfig } = processorConfig;
  const { callService: getConditions } = conditions;
  const { callService: getConfigurableStatus } = configurableStatus;
  const { callService: getBatchFileTemplates } = batchTemplates;
  const { callService: getWorkflowConfigViews } = workflowConfigViews;

  useEffect(() => {
    getConfigurableStatus();
  }, [getConfigurableStatus]);

  useEffect(() => {
    getBatchFileTemplates();
  }, [getBatchFileTemplates]);

  useEffect(() => {
    getBigNumbers();
  }, [getBigNumbers]);

  useEffect(() => {
    getConditions(conditionOrderParam);
  }, [conditionOrderParam, getConditions]);

  useEffect(() => {
    if (!id) return;
    getWorkflowConfigViews(id);
  }, [getWorkflowConfigViews, id]);

  useEffect(() => {
    hasErrorProcessorConfig &&
      OToastManager.danger(
        "Não foi possível carregar detalhes do processador.",
      );
  }, [hasErrorProcessorConfig]);

  const value = useMemo(
    () => ({
      bigNumbers,
      processorConfig,
      conditions,
      batchTemplates,
      configurableStatus,
      conditionDestination,
      setConditionDestination,
      selectedConditions,
      setSelectedConditions,
      conditionOrderParam,
      setConditionOrderParam,
      gridCondition,
      setGridCondition,
      workflowConfigViews,
    }),
    [
      bigNumbers,
      processorConfig,
      conditions,
      batchTemplates,
      configurableStatus,
      conditionDestination,
      selectedConditions,
      conditionOrderParam,
      gridCondition,
      workflowConfigViews,
    ],
  );

  return (
    <WorkflowContext.Provider value={value}>
      {children}
    </WorkflowContext.Provider>
  );
};

export const useWorkflow = () => useContext(WorkflowContext);
