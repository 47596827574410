import { OCol, ORFieldInput } from "@maestro/react";

interface PenaltyFeeFieldProps {
  required?: boolean;
}

export const PenaltyFeeField = ({ required }: PenaltyFeeFieldProps) => {
  return (
    <OCol xs={12} md={6}>
      <ORFieldInput
        dataAction="geral:texto:multa"
        dataLabel="multa"
        id="penaltyFee"
        name="penaltyFee"
        tag="percentage"
        label="Multa"
        placeholder="Preencher"
        required={required}
      />
    </OCol>
  );
};
