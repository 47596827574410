import {
  OCheckbox,
  OCol,
  OLabel,
  ORFieldCheckboxGroup,
  ORow,
  OTypography,
} from "@maestro/react";

export const ProcessorConfigSettings = () => {
  return (
    <div className="d-flex flex-column gap-4">
      <OTypography size="lg">Configurações</OTypography>
      <ORow>
        <OCol sm={6}>
          <ORFieldCheckboxGroup
            id="executeOnSingleParentOutput"
            name="executeOnSingleParentOutput"
          >
            <div className="d-flex align-items-center gap-2 mb-2">
              <OCheckbox size="xs" id="single-parent-checkbox" value="1" />
              <OLabel htmlFor="single-parent-checkbox">
                Executa com saída de apenas um pai?
              </OLabel>
            </div>
          </ORFieldCheckboxGroup>
        </OCol>
        <OCol sm={6}>
          <ORFieldCheckboxGroup id="leadsCanBeCreated" name="leadsCanBeCreated">
            <div className="d-flex align-items-center gap-2 mb-2">
              <OCheckbox
                size="xs"
                id="leads-can-be-created-checkbox"
                value="1"
              />
              <OLabel htmlFor="leads-can-be-created-checkbox">
                Outros leads podem ser iniciados?
              </OLabel>
            </div>
          </ORFieldCheckboxGroup>
        </OCol>
        <OCol sm={6}>
          <ORFieldCheckboxGroup id="leadsCanBeDeleted" name="leadsCanBeDeleted">
            <div className="d-flex align-items-center gap-2 mb-2">
              <OCheckbox
                size="xs"
                id="leads-can-be-deleted-checkbox"
                value="1"
              />
              <OLabel htmlFor="leads-can-be-deleted-checkbox">
                Lead neste processor pode ser excluído?
              </OLabel>
            </div>
          </ORFieldCheckboxGroup>
        </OCol>
        <OCol sm={6}>
          <ORFieldCheckboxGroup id="leadsCanBeExpired" name="leadsCanBeExpired">
            <div className="d-flex align-items-center gap-2 mb-2">
              <OCheckbox
                size="xs"
                id="leads-can-be-expired-checkbox"
                value="1"
              />
              <OLabel htmlFor="leads-can-be-expired-checkbox">
                Leads neste processador podem ser expirados?
              </OLabel>
            </div>
          </ORFieldCheckboxGroup>
        </OCol>
      </ORow>
    </div>
  );
};
