import { OCol } from "@maestro/react";
import { useUnsafeCustomer } from "contexts/customer";
import { ContractTemplateSelector } from "../../contract-template-selector";

interface ContractTemplateIdFieldProps {
  required?: boolean;
}

export const ContractTemplateIdField = ({
  required,
}: ContractTemplateIdFieldProps) => {
  const { customer } = useUnsafeCustomer();

  return (
    <OCol xs={12} md={6}>
      <ContractTemplateSelector
        companyId={customer?.quickfinId ?? null}
        name="contractTemplateId"
        label="Template de contrato"
        required={required}
      />
    </OCol>
  );
};
