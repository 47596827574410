import { OToastManager } from "@maestro/core";
import {
  ODataGridGenerator,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useCustomer } from "contexts/customer";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { PostAdminLoaAllUserResponse } from "services/onboarding-relationships/models";
import { GridTemplate } from "templates/grid-template";
import { lettersOfAttorneyGrid } from "../../../../cadastro/pages/cliente/procuracoes/fisicas/letters-of-attorney.grid";
import { UsuariosClienteByIdentificationRouteParams } from "../../../routes/usuarios.route-params";

export const UserLettersOfAttorneyPage = () => {
  const { identification: userTaxId } =
    useParams<UsuariosClienteByIdentificationRouteParams>();
  if (!userTaxId) throw new Error("No identification");

  const {
    customer: { identification },
  } = useCustomer();

  const gridRef = useGridRef();

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator<PostAdminLoaAllUserResponse[number]>(() =>
        service.onboardingRelationships
          .getUserLoaWorkflows({ taxId: identification, userTaxId })
          .then(({ data }) => data)
          .catch(() => {
            const errorMessage = "Erro ao buscar as procurações";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          }),
      ),
    [identification, userTaxId],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Procurações físicas" />}
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={lettersOfAttorneyGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
