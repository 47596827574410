import { OCol } from "@maestro/react";
import { InputMultiple } from "../../input-multiple/input-multiple.component";

export const DiasCampanhaNotasNaoAntecipadasCedenteField = () => {
  return (
    <OCol xs={12} md={6}>
      <InputMultiple
        dataAction="geral:texto:dias_vencimento"
        dataLabel="dias_vencimento"
        id="diasCampanhaNotasNaoAntecipadasCedente"
        name="diasCampanhaNotasNaoAntecipadasCedente"
        label="Notificar depois de (dias) nota não antecipada"
        description="Quantidade de dias antes do vencimento para receber notificação"
      />
    </OCol>
  );
};
