import { YesNoRadioField } from "./yes-no-radio-field.component";

export const SacadoDefineContaDesembolsoField = () => {
  return (
    <YesNoRadioField
      label="Sacado define conta de desembolso"
      name="sacadoDefineContaDesembolso"
      description="Trava na escolha de conta de desembolso da antecipação"
    />
  );
};
