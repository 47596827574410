export const loanProductOptions = [
  {
    value: "KGIRO",
    text: "Capital de Giro",
  },
  {
    value: "KGIROPEAC",
    text: "Capital de Giro PEAC",
  },
  {
    value: "CARTAO_DE_CREDITO",
    text: "Cartão de Crédito",
  },
  {
    value: "CCB",
    text: "CCB",
  },
  {
    value: "CCC",
    text: "Cessão com Coobrigação",
  },
  {
    value: "COMPRA_VENDA_IMOVEL",
    text: "Compra e venda de imóvel",
  },
  {
    value: "AGRO",
    text: "Crédito Agro",
  },
  {
    value: "AGRO_COSTING",
    text: "Crédito Agro - Custeio",
  },
  {
    value: "AGRO_INVESTMENT",
    text: "Crédito Agro - Investimento",
  },
  {
    value: "AGRO_MACHINERY",
    text: "Crédito Agro - Equipamento",
  },
  {
    value: "AGRO_USED_MACHINERY",
    text: "Crédito Agro - Equipamento usado",
  },
  {
    value: "SOLAR",
    text: "Crédito Solar",
  },
  {
    value: "FUMACA",
    text: "Crédito Fumaça",
  },
  {
    value: "FIDC",
    text: "FIDC",
  },
  {
    value: "FUMACAPEAC",
    text: "Fumaça PEAC",
  },
  {
    value: "SOFTWARE_FINANCING",
    text: "Financiamento de Software",
  },
  {
    value: "FORMALIZACAO_GARANTIAS",
    text: "Formalização de garantias",
  },
  {
    value: "LIMITE_MAIS",
    text: "Limite da conta - Cheque Especial",
  },
  {
    value: "IMPACTO_SOCIAL",
    text: "Impacto Social",
  },
  {
    value: "RISCOSACADO",
    text: "Risco Sacado",
  },
];
