import { SelectSearchFieldOption } from "components/select-search/field/select-search-field.types";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";
import { StepConfigForm } from "../../step-config-form.types";

export const useStepTypeSelector = (field: keyof StepConfigForm) => {
  const { callService, loading, value, hasError } = useServiceCall(
    service.hubEnergy.getDiligenceStepType,
  );

  const { clearErrors, setError } = useFormContext<StepConfigForm>();

  const getStepTypes = useCallback(async () => {
    const { success } = await callService();
    if (success) clearErrors(field);
    else
      setError(field, {
        message:
          "Erro ao buscar os tipos de step. Clique no botão ao lado para tentar novamente.",
      });
  }, [callService, clearErrors, field, setError]);

  useEffect(() => {
    getStepTypes();
  }, [getStepTypes]);

  const stepTypes = useMemo(
    () =>
      value?.response
        .map<SelectSearchFieldOption<number>>(({ name, id }) => ({
          label: name,
          value: id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)) ?? [],
    [value?.response],
  );

  return {
    stepTypes,
    getStepTypes,
    loading,
    hasFetchError: hasError,
  };
};
