import { OButton, OTypography, modalManager } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { useGridRef } from "hooks/grid-ref";
import { useRoles } from "hooks/roles";
import { GridTemplate } from "templates/grid-template";
import { energiaRoles } from "../../../../../../../../roles/energia.roles";
import { useDiligenceStep } from "../../diligence-step.context";
import {
  AddStepPropertyModal,
  EditPropertyValueModal,
  EditStepPropertyModal,
  MoveFileModal,
  MoveStepPropertyModal,
  addStepPropertyModalId,
} from "./_compose";
import { stepPropertiesGrid } from "./step-properties.grid";

export const StepProperties = () => {
  const gridRef = useGridRef();
  const { hasRole } = useRoles();

  const { dataSource, stepId } = useDiligenceStep();

  return (
    <>
      <GridTemplate
        pageTitle={
          <OTypography type="dark" size="lg">
            Propriedades
          </OTypography>
        }
        actions={
          hasRole(energiaRoles.customer.migrationStepsAddProperty.role) && (
            <OButton onClick={() => modalManager.show(addStepPropertyModalId)}>
              Adicionar
            </OButton>
          )
        }
        gridRef={gridRef}
        showRefreshButton
        showClearFiltersButton
      >
        <ODataGridGenerator
          gridRef={gridRef}
          grid={stepPropertiesGrid}
          dataSource={dataSource}
        />
      </GridTemplate>
      <AddStepPropertyModal
        diligenceStepId={stepId}
        reload={() => dataSource.reload()}
      />
      <EditStepPropertyModal reload={() => dataSource.reload()} />
      <EditPropertyValueModal reload={() => dataSource.reload()} />
      <MoveStepPropertyModal
        stepId={Number(stepId)}
        reload={() => dataSource.reload()}
      />
      <MoveFileModal reload={() => dataSource.reload()} />
    </>
  );
};
