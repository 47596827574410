import { Roles } from "roles/roles.types";

export const recebiveisRoles = {
  customer: {
    tab: {
      role: "admin:ReceivablesTabCustomer.View",
      description: "Visualização da tab 'Recebíveis' na visão cliente",
    },
    infoPage: {
      role: "admin:ReceivablesInfoPageCustomer.View",
      description: "Visualização da página 'Informações' na visão cliente",
    },
    uploadReceivablesPage: {
      role: "admin:ReceivablesUploadReceivablesPageCustomer.View",
      description: "Visualização da página 'Recebíveis' na visão cliente",
    },
    cedentesPage: {
      role: "admin:ReceivablesCedentesPageCustomer.View",
      description: "Visualização da página 'Cedentes' na visão cliente",
    },
    cedentesAddCedentePage: {
      role: "admin:ReceivablesCedentesAddCedentePageCustomer.View",
      description:
        "Visualização da página 'Cedentes - Adicionar cedente' na visão cliente",
    },
    cedentesDetailsPage: {
      role: "admin:ReceivablesCedentesDetailsPageCustomer.View",
      description:
        "Visualização da página 'Cedentes - Detalhes' na visão cliente",
    },
    contractComplementPage: {
      role: "admin:ReceivablesCedentesDetailsPageCustomer.View",
      description:
        "Visualização da página 'Cedentes - Complemento de Contrato' na visão cliente",
    },
    cedentesBatchImportPage: {
      role: "admin:ReceivablesCedentesBatchImportPageCustomer.View",
      description:
        "Visualização da página 'Cedentes - Importar cedentes em lote' na visão cliente",
    },
    sacadosPage: {
      role: "admin:ReceivablesSacadosPageCustomer.View",
      description: "Visualização da página 'Sacados' na visão cliente",
    },
    sacadosAddSacadoPage: {
      role: "admin:ReceivablesSacadosAddSacadoPageCustomer.View",
      description:
        "Visualização da página 'Sacados - Adicionar sacado' na visão cliente",
    },
    sacadosDetailsPage: {
      role: "admin:ReceivablesSacadosDetailsPageCustomer.View",
      description:
        "Visualização da página 'Sacados - Detalhes' na visão cliente",
    },
    sacadosBatchImportPage: {
      role: "admin:ReceivablesSacadosBatchImportPageCustomer.View",
      description:
        "Visualização da página 'Sacados - Importar sacados em lote' na visão cliente",
    },
    estrategiaComercialPage: {
      role: "admin:ReceivablesEstrategiaComercialPageCustomer.View",
      description:
        "Visualização da página 'Estratégia comercial' na visão cliente",
    },
    estrategiaComercialAddEstrategiaPage: {
      role: "admin:ReceivablesEstrategiaComercialAddEstrategiaPageCustomer.View",
      description:
        "Visualização da página 'Estratégia comercial - Adicionar estratégia' na visão cliente",
    },
    estrategiaComercialByEstrategiaIdTaxasViewPage: {
      role: "admin:ReceivablesEstrategiaComercialByEstrategiaIdTaxasViewPageCustomer.View",
      description:
        "Visualização da página 'Estratégia comercial - Estratégia comercial - Taxas - Visualizar taxas' na visão cliente",
    },
    estrategiaComercialByEstrategiaIdTaxasConfigurePage: {
      role: "admin:ReceivablesEstrategiaComercialByEstrategiaIdTaxasConfigurePageCustomer.View",
      description:
        "Visualização da página 'Estratégia comercial - Estratégia comercial - Taxas - Configurar taxas' na visão cliente",
    },
    estrategiaComercialByEstrategiaIdTaxasCalculatorPage: {
      role: "admin:ReceivablesEstrategiaComercialByEstrategiaIdTaxasCalculatorPageCustomer.View",
      description:
        "Visualização da página 'Estratégia comercial - Estratégia comercial - Taxas - Calculadora' na visão cliente",
    },
    estrategiaComercialByEstrategiaIdOnboardingRelationshipSettingsPage: {
      role: "admin:ReceivablesEstrategiaComercialByEstrategiaIdOnboardingRelationshipSettingsPageCustomer.View",
      description:
        "Visualização da página 'Estratégia comercial - Estratégia comercial - Cadastro de relacionamento' na visão cliente",
    },
    estrategiaComercialByEstrategiaIdEstrategiaDetailsPage: {
      role: "admin:ReceivablesEstrategiaComercialByEstrategiaIdEstrategiaDetailsPageCustomer.View",
      description:
        "Visualização da página 'Estratégia comercial - Estratégia comercial - Detalhes' na visão cliente",
    },
    contractTemplatesPage: {
      role: "admin:ReceivablesContractTemplatesPageCustomer.View",
      description:
        "Visualização da página 'Templates de contrato' na visão cliente",
    },
    contractTemplatesAddPage: {
      role: "admin:ReceivablesContractTemplatesAddPageCustomer.View",
      description:
        "Visualização da página 'Templates de contrato - Adicionar' na visão cliente",
    },
    contractTemplatesByTemplateIdPage: {
      role: "admin:ReceivablesContractTemplatesByTemplateIdPageCustomer.View",
      description:
        "Visualização da página 'Templates de contrato - Editar' na visão cliente",
    },
    signaturesPage: {
      role: "admin:ReceivablesSignaturesPageCustomer.View",
      description: "Visualização da página 'Assinaturas' na visão cliente",
    },
    signaturesNewRulePage: {
      role: "admin:ReceivablesSignaturesNewRulePageCustomer.View",
      description:
        "Visualização da página 'Assinaturas - Criar regra de assinaturas' na visão cliente",
    },
    signaturesByRuleIdAddPage: {
      role: "admin:ReceivablesSignaturesByRuleIdAddPageCustomer.View",
      description:
        "Visualização da página 'Assinaturas - Adicionar grupo de assinaturas' na visão cliente",
    },
    signaturesByRuleIdByGroupIdDetailsPage: {
      role: "admin:ReceivablesSignaturesByRuleIdByGroupIdDetailsPageCustomer.View",
      description:
        "Visualização da página 'Assinaturas - Grupo de assinaturas - Detalhes' na visão cliente",
    },
    signaturesByRuleIdByGroupIdEditPage: {
      role: "admin:ReceivablesSignaturesByRuleIdByGroupIdEditPageCustomer.View",
      description:
        "Visualização da página 'Assinaturas - Grupo de assinaturas - Editar' na visão cliente",
    },
    feeSacadoPage: {
      role: "admin:ReceivablesFeeSacadoPageCustomer.View",
      description: "Visualização da página 'Fees' na visão cliente",
    },
    feesRegisterPage: {
      role: "admin:ReceivablesFeeSacadoAddPageCustomer.View",
      description: "Visualização da página 'Fees - Adicionar' na visão cliente",
    },
  },
  product: {
    tab: {
      role: "admin:ReceivablesTabProduct.View",
      description: "Visualização da tab 'Recebíveis' na visão produto",
    },
    approvalsPage: {
      role: "admin:ReceivablesApprovalsPageProduct.View",
      description: "Visualização da página 'Aprovações' na visão produto",
    },
    complementContractPage: {
      role: "admin:ComplementContractPageProduct.View",
      description:
        "Visualização da página 'Contratos pendentes de complemento' na visão produto",
    },
    fillComplementContractPage: {
      role: "admin:FillComplementContractPage.View",
      description:
        "Visualização da página 'Contratos pendentes de complemento - Preencher' na visão produto",
    },
    cancellingPage: {
      role: "admin:ReceivablesCancellingPageProduct.View",
      description: "Visualização da página 'Cancelamento' na visão produto",
    },
    rateCurvePage: {
      role: "admin:ReceivablesRateCurvePageProduct.View",
      description: "Visualização da página 'Curva de Taxas' na visão produto",
    },
    rateCurveAddPage: {
      role: "admin:ReceivablesRateCurveAddPageProduct.View",
      description:
        "Visualização da página 'Curva de Taxas - Adicionar' na visão produto",
    },
    operationsPage: {
      role: "admin:ReceivablesOperationsPageProduct.View",
      description: "Visualização da página 'Operações' na visão produto",
    },
    operationsDetalhesPage: {
      role: "admin:ReceivablesOperationsDetalhesPageProduct.View",
      description:
        "Visualização da página 'Operações - Detalhes' na visão produto",
    },
    titlesSchedulesPage: {
      role: "admin:ReceivablesTitlesSchedulesPageProduct.View",
      description:
        "Visualização da página 'Titulos - Agendamentos' na visão produto",
    },
    titlesImportedBatchesPage: {
      role: "admin:ReceivablesTitlesImportedBatchesPageProduct.View",
      description:
        "Visualização da página 'Titulos - Lotes Importados' na visão produto",
    },
    titlesImportedBatchesDetailsPage: {
      role: "admin:ReceivablesTitlesImportedBatchesDetailsPageProduct.View",
      description:
        "Visualização da página 'Titulos - Lotes Importados - Detalhe da Importação' na visão produto",
    },
    titlesUploadPage: {
      role: "admin:ReceivablesTitlesUploadPageProduct.View",
      description: "Visualização da página 'Titulos - Entuba' na visão produto",
    },
    titlesNoteNotePage: {
      role: "admin:ReceivablesTitlesNoteNotePageProduct.View",
      description:
        "Visualização da página 'Titulos - Nota a Nota' na visão produto",
    },
    titlesNoteNoteDetailsShowChildrenPage: {
      role: "admin:ReceivablesTitlesNoteNoteDetailsShowChildrenPageProduct.View",
      description:
        "Visualização da página 'Titulos - Nota a Nota - Detalhes' na visão produto",
    },
    titlesNoteNoteDetailsReceivablePage: {
      role: "admin:ReceivablesTitlesNoteNoteDetailsReceivablePageProduct.View",
      description:
        "Visualização da página 'Titulos - Nota a Nota - Detalhes - Recebível' na visão produto",
    },
    titlesNoteNoteDetailsStateMachinePage: {
      role: "admin:ReceivablesTitlesNoteNoteDetailsStateMachinePageProduct.View",
      description:
        "Visualização da página 'Titulos - Nota a Nota - Detalhes - Máquina de Estados' na visão produto",
    },
    titlesNoteNoteDetailsTradePage: {
      role: "admin:ReceivablesTitlesNoteNoteDetailsTradePageProduct.View",
      description:
        "Visualização da página 'Titulos - Nota a Nota - Detalhes - Trade' na visão produto",
    },
    titlesNoteNoteDetailsEventPage: {
      role: "admin:ReceivablesTitlesNoteNoteDetailsEventPageProduct.View",
      description:
        "Visualização da página 'Titulos - Nota a Nota - Detalhes - Eventos' na visão produto",
    },
    titlesNoteNoteDetailsGroupPage: {
      role: "admin:ReceivablesTitlesNoteNoteDetailsGroupPageProduct.View",
      description:
        "Visualização da página 'Titulos - Nota a Nota - Detalhes - Grupo' na visão produto",
    },
    titlesNoteNoteDetailsAdditionsPage: {
      role: "admin:ReceivablesTitlesNoteNoteDetailsAdditionsPageProduct.View",
      description:
        "Visualização da página 'Titulos - Nota a Nota - Detalhes - Aditamentos' na visão produto",
    },
    titlesNoteNoteDetailsAdditionsDetailPage: {
      role: "admin:ReceivablesTitlesNoteNoteDetailsAdditionsDetailPageProduct.View",
      description:
        "Visualização da página 'Titulos - Nota a Nota - Detalhes - Aditamentos - Detalhe de Aditamento' na visão produto",
    },
    titlesNoteNoteLegacyPage: {
      role: "admin:ReceivablesTitlesNoteNoteLegacyPageProduct.View",
      description:
        "Visualização da página 'Titulos - Nota a Nota (antiga)' na visão produto",
    },
    titlesNoteNoteLegacyDetailsShowChildrenPage: {
      role: "admin:ReceivablesTitlesNoteNoteLegacyDetailsShowChildrenPageProduct.View",
      description:
        "Visualização da página 'Titulos - Nota a Nota (antiga) - Detalhes' na visão produto",
    },
    titlesNoteNoteLegacyDetailsReceivablePage: {
      role: "admin:ReceivablesTitlesNoteNoteLegacyDetailsReceivablePageProduct.View",
      description:
        "Visualização da página 'Titulos - Nota a Nota (antiga) - Detalhes - Recebível' na visão produto",
    },
    titlesNoteNoteLegacyDetailsStateMachinePage: {
      role: "admin:ReceivablesTitlesNoteNoteLegacyDetailsStateMachinePageProduct.View",
      description:
        "Visualização da página 'Titulos - Nota a Nota (antiga) - Detalhes - Máquina de Estados' na visão produto",
    },
    titlesNoteNoteLegacyDetailsTradePage: {
      role: "admin:ReceivablesTitlesNoteNoteLegacyDetailsTradePageProduct.View",
      description:
        "Visualização da página 'Titulos - Nota a Nota (antiga) - Detalhes - Trade' na visão produto",
    },
    titlesNoteNoteLegacyDetailsEventPage: {
      role: "admin:ReceivablesTitlesNoteNoteLegacyDetailsEventPageProduct.View",
      description:
        "Visualização da página 'Titulos - Nota a Nota (antiga) - Detalhes - Eventos' na visão produto",
    },
    titlesNoteNoteLegacyDetailsGroupPage: {
      role: "admin:ReceivablesTitlesNoteNoteLegacyDetailsGroupPageProduct.View",
      description:
        "Visualização da página 'Titulos - Nota a Nota (antiga) - Detalhes - Grupo' na visão produto",
    },
    titlesNoteNoteLegacyDetailsAdditionsPage: {
      role: "admin:ReceivablesTitlesNoteNoteLegacyDetailsAdditionsPageProduct.View",
      description:
        "Visualização da página 'Titulos - Nota a Nota (antiga) - Detalhes - Aditamentos' na visão produto",
    },
    titlesNoteNoteLegacyDetailsAdditionsDetailPage: {
      role: "admin:ReceivablesTitlesNoteNoteLegacyDetailsAdditionsDetailPageProduct.View",
      description:
        "Visualização da página 'Titulos - Nota a Nota (antiga) - Detalhes - Aditamentos - Detalhe de Aditamento' na visão produto",
    },
    titlesTradesCorrectionPage: {
      role: "admin:ReceivablesTitlesTradesCorrectionPageProduct.View",
      description:
        "Visualização da página 'Titulos - Correção de Trades' na visão produto",
    },
    titlesTradesCorrectionImportCorrectionsPage: {
      role: "admin:ReceivablesTitlesTradesCorrectionImportCorrectionsPageProduct.View",
      description:
        "Visualização da página 'Titulos - Correção de Trades - Importar Correções' na visão produto",
    },
    commercialStrategiesPage: {
      role: "admin:ReceivablesCommercialStrategiesPageProduct.View",
      description:
        "Visualização da página 'Estratégias Comerciais' na visão produto",
    },
    calendarsPage: {
      role: "admin:ReceivablesCalendarsPageProduct.View",
      description: "Visualização da página 'Calendários' na visão produto",
    },
    calendarsAddPage: {
      role: "admin:ReceivablesCalendarsAddPageProduct.View",
      description:
        "Visualização da página 'Calendários - Adicionar' na visão produto",
    },
    calendarsDetailsPage: {
      role: "admin:ReceivablesCalendarsDetailsPageProduct.View",
      description:
        "Visualização da página 'Calendários - Detalhes' na visão produto",
    },
    batchReportsRequestForReportsPage: {
      role: "admin:ReceivablesBatchReportsRequestForReportsPageProduct.View",
      description:
        "Visualização da página 'Relatórios em Lote - Solicitação de Relatórios' na visão produto",
    },
    batchReportsReportsRequestedPage: {
      role: "admin:ReceivablesBatchReportsReportsRequestedPageProduct.View",
      description:
        "Visualização da página 'Relatórios em Lote - Relatórios Solicitados' na visão produto",
    },
    settlementStrategiesPage: {
      role: "admin:ReceivablesSettlementStrategiesPageProduct.View",
      description:
        "Visualização da página 'Estratégias de Liquidação' na visão produto",
    },
    settlementStrategiesAddPage: {
      role: "admin:ReceivablesSettlementStrategiesAddPageProduct.View",
      description:
        "Visualização da página 'Estratégias de Liquidação - Adicionar' na visão produto",
    },
    settlementStrategiesEditPage: {
      role: "admin:ReceivablesSettlementStrategiesEditPageProduct.View",
      description:
        "Visualização da página 'Estratégias de Liquidação - Editar' na visão produto",
    },
    assignorsPage: {
      role: "admin:ReceivablesAssignorsPageProduct.View",
      description: "Visualização da página 'Cedentes' na visão produto",
    },
    relationshipsPage: {
      role: "admin:ReceivablesRelationshipsPageProduct.View",
      description:
        "Visualização da página 'Relacionamento Risco Sacado' na visão produto",
    },
    dashboardPage: {
      role: "admin:ReceivablesDashboardPageProduct.View",
      description:
        "Visualização da página 'Dashboard Onboardings Risco Sacado' na visão produto",
    },
    dashboardOnboardingDetailsPage: {
      role: "admin:ReceivablesDashboardOnboardingDetailsPageProduct.View",
      description:
        "Visualização da página 'Dashboard Onboardings Risco Sacado - Dashboard Onboardings Risco Sacado - Detalhes' na visão produto",
    },
    withdrawPage: {
      role: "admin:ReceivablesWithdrawPageProduct.View",
      description: "Visualização da página 'Sacados' na visão produto",
    },
    withdrawAddPage: {
      role: "admin:ReceivablesWithdrawAddPageProduct.View",
      description:
        "Visualização da página 'Sacados - Adicionar' na visão produto",
    },
    overduePositionsPage: {
      role: "admin:ReceivablesOverduePositionsPageProduct.View",
      description:
        "Visualização da página 'Posições em Atraso' na visão produto",
    },
    contractTemplatesPage: {
      role: "admin:ReceivablesContractTemplatesPageProduct.View",
      description:
        "Visualização da página 'Templates de Contrato' na visão produto",
    },
    contractTemplatesAddPage: {
      role: "admin:ReceivablesContractTemplatesAddPageProduct.View",
      description:
        "Visualização da página 'Templates de Contrato - Adicionar' na visão produto",
    },
    contractTemplatesEditPage: {
      role: "admin:ReceivablesContractTemplatesEditPageProduct.View",
      description:
        "Visualização da página 'Templates de Contrato - Editar' na visão produto",
    },
    estrategiaTemplatesPage: {
      role: "admin:ReceivablesEstrategiaTemplatesPageProduct.View",
      description:
        "Visualização da página 'Templates de estratégia' na visão produto",
    },
    estrategiaTemplatesAddEstrategiaPage: {
      role: "admin:ReceivablesEstrategiaTemplatesAddEstrategiaPageProduct.View",
      description:
        "Visualização da página 'Templates de estratégia - Adicionar estratégia' na visão produto",
    },
    estrategiaTemplatesByEstrategiaIdTaxasPage: {
      role: "admin:ReceivablesEstrategiaTemplatesByEstrategiaIdTaxasPageProduct.View",
      description:
        "Visualização da página 'Templates de estratégia - Templates de estratégia - Taxas' na visão produto",
    },
    estrategiaTemplatesByEstrategiaIdOnboardingRelationshipSettingsPage: {
      role: "admin:ReceivablesEstrategiaTemplatesByEstrategiaIdOnboardingRelationshipSettingsPageProduct.View",
      description:
        "Visualização da página 'Templates de estratégia - Templates de estratégia - Cadastro de relacionamento' na visão produto",
    },
    estrategiaTemplatesByEstrategiaIdEstrategiaDetailsPage: {
      role: "admin:ReceivablesEstrategiaTemplatesByEstrategiaIdEstrategiaDetailsPageProduct.View",
      description:
        "Visualização da página 'Templates de estratégia - Templates de estratégia - Detalhes' na visão produto",
    },
    restrictionsListPage: {
      role: "admin:ReceivablesRestrictionsListPageProduct.View",
      description:
        "Visualização da página 'Lista de Restrições' na visão produto",
    },
    restrictionsListAddPage: {
      role: "admin:ReceivablesRestrictionsListAddPageProduct.View",
      description:
        "Visualização da página 'Lista de Restrições - Adicionar' na visão produto",
    },
    onboardingStrategiesPage: {
      role: "admin:ReceivablesOnboardingStrategiesPageProduct.View",
      description:
        "Visualização da página 'Estratégias de Onboarding' na visão produto",
    },
    onboardingStrategiesAddPage: {
      role: "admin:ReceivablesOnboardingStrategiesAddPageProduct.View",
      description:
        "Visualização da página 'Estratégias de Onboarding - Adicionar' na visão produto",
    },
    onboardingStrategiesEditPage: {
      role: "admin:ReceivablesOnboardingStrategiesEditPageProduct.View",
      description:
        "Visualização da página 'Estratégias de Onboarding - Editar' na visão produto",
    },
    tcoStrategiesPage: {
      role: "admin:ReceivablesOnboardingStrategiesPageProduct.View",
      description:
        "Visualização da página 'Estratégias de TCO' na visão produto",
    },
    tcoStrategiesAddPage: {
      role: "admin:ReceivablesOnboardingStrategiesAddPageProduct.View",
      description:
        "Visualização da página 'Estratégias de TCO - Adicionar' na visão produto",
    },
    tcoStrategiesEditPage: {
      role: "admin:ReceivablesOnboardingStrategiesEditPageProduct.View",
      description:
        "Visualização da página 'Estratégias de TCO - Editar' na visão produto",
    },
  },
} satisfies Roles;
