import { OOption, ORFieldInput, ORFieldSelect } from "@maestro/react";
import { useMemo } from "react";
import { getValueFromMap } from "utils/get-value-from-map";
import { getProcessorPropertyFieldId } from "../../../utils";
import { GenericPropertyFieldProps } from "../../property-field.types";
import { processorPropertyTypeMap } from "../../property-field.utils";
import { useGenericPropertyField } from "./generic-property-field.hook";

export const GenericPropertyField = ({
  processorConfigProperty,
  handleSubmitProperty,
}: GenericPropertyFieldProps) => {
  const { processorProperty, id } = processorConfigProperty;

  useGenericPropertyField({
    processorConfigProperty,
    handleSubmitProperty,
  });

  const processorPropertyFieldId = getProcessorPropertyFieldId(
    processorProperty.name,
    id,
  );

  const genericPropertyField = useMemo(() => {
    return (
      <>
        {!processorProperty.allowedValues.length ? (
          <ORFieldInput
            key={processorProperty.name + id}
            id={processorPropertyFieldId}
            name={processorPropertyFieldId}
            tag={
              getValueFromMap(
                processorPropertyTypeMap,
                processorProperty.type,
              ) ?? "text"
            }
            label={processorProperty.description}
            labelSize="md"
          />
        ) : (
          <ORFieldSelect
            key={processorProperty.name + id}
            id={processorPropertyFieldId}
            name={processorPropertyFieldId}
            label={processorProperty.description}
            labelSize="md"
            placeholder="Selecione"
            tooltip={
              processorProperty.isList
                ? "É uma lista (separar valores por vírgula)"
                : ""
            }
            tooltipMaxWidth="100px"
            tooltipPosition="right-top"
          >
            <div>
              {processorProperty.allowedValues?.map((option) => (
                <OOption key={option} value={option}>
                  {option}
                </OOption>
              ))}
              <OOption
                value=""
                key={`${processorProperty.name.toLowerCase()}-generic-property-null`}
              >
                NENHUM
              </OOption>
            </div>
          </ORFieldSelect>
        )}
      </>
    );
  }, [id, processorProperty]);

  return <>{genericPropertyField}</>;
};
