import { OOption, ORFieldSelect } from "@maestro/react";
import { useMemo } from "react";
import { getProcessorPropertyFieldId } from "../../../utils";
import { GenericPropertyFieldProps } from "../../property-field.types";
import { useConditionPropertyField } from "./condition-property-field.hook";

export const ConditionPropertyField = ({
  processorConfigProperty,
  handleSubmitProperty,
}: GenericPropertyFieldProps) => {
  const { processorProperty, id } = processorConfigProperty;
  const { allConditions } = useConditionPropertyField({
    processorConfigProperty,
    handleSubmitProperty,
  });

  const processorPropertyFieldId = getProcessorPropertyFieldId(
    processorProperty.name,
    id,
  );

  const formPropertyField = useMemo(() => {
    return (
      <ORFieldSelect
        key={processorProperty.name + id}
        id={processorPropertyFieldId}
        name={processorPropertyFieldId}
        placeholder="Selecione"
        labelSize="md"
        label={processorProperty.description}
      >
        <div>
          {allConditions?.map((cond) => (
            <OOption key={cond.conditionName} value={cond.conditionName}>
              {cond.description}
            </OOption>
          ))}
          <OOption
            value=""
            key={`${processorProperty.name.toLowerCase()}-condition-property-null`}
          >
            Nenhum
          </OOption>
        </div>
      </ORFieldSelect>
    );
  }, [processorProperty, allConditions, id]);

  return <>{formPropertyField}</>;
};
