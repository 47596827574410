import * as yup from "yup";
import { FormValuesType } from "./profile-form.types";

export const validationSchema: yup.ObjectSchema<FormValuesType> = yup.object({
  unavailablePermissionsDescription: yup.string(),
  isBuiltIn: yup.string().required("Este campo é obrigatório"),
  isCustom: yup.string().required("Este campo é obrigatório"),
  nameLabel: yup.string().required("Este campo é obrigatório"),
  portal: yup.string().required("Este campo é obrigatório"),
  product: yup.string().required("Este campo é obrigatório"),
  profile: yup.string().required("Este campo é obrigatório"),
  type: yup.string().required("Este campo é obrigatório"),
  availablePermissionsDescription: yup
    .string()
    .required("Este campo é obrigatório"),
  groupName: yup.string().required("Este campo é obrigatório"),
});
