import { OToastManager } from "@maestro/core";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { useMemo } from "react";
import { service } from "services";
import { AdvisoredEntity } from "services/gatekeeper/models/responses/get-gatekeeper-advisored-by.response";

const grid: ODataGridGeneratorConfig<AdvisoredEntity> = {
  datagrid: {
    noDataText: "Nenhum assessorado",
    filterRow: { visible: true },
    remoteOperations: false,
    pager: {
      showPageSizeSelector: true,
    },
    paging: {
      pageSize: 20,
    },
  },
  columns: [
    {
      dataField: "account_owner.name",
      dataType: "string",
      caption: "Nome",
      alignment: "center",
    },
    {
      dataField: "account_owner.tax_id",
      dataType: "string",
      caption: "CPF",
    },
    {
      dataField: "branch",
      caption: "Agência",
      dataType: "string",
    },
    {
      dataField: "account",
      caption: "Conta",
      dataType: "string",
    },
  ],
};

const buildDataSource = (taxId: string) =>
  dataSourceCustomStoreGenerator<AdvisoredEntity>(async () => {
    if (taxId)
      try {
        const data = await service.gatekeeper.getAdvisoredEntities(taxId);
        return {
          data: data,
          totalCount: data.length,
        };
      } catch {
        const errorMessage = "Erro ao carregar os assessores!";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }

    return Promise.resolve({ data: [], totalCount: 0 });
  });

export const AdvisoredEntitiesMasterDetail = ({
  data: {
    data: {
      user: { cpf },
    },
  },
}: any) => {
  const dataSource = useMemo(() => buildDataSource(cpf), [cpf]);

  return <ODataGridGenerator grid={grid} dataSource={dataSource} />;
};
