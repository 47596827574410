import { OBadge, OIcon } from "@maestro/react";
import styled, { createGlobalStyle, css } from "styled-components";
import { customColors } from "./augmented-status-badge.utils";

export const CustomTokens = createGlobalStyle`
  html {
    --theme-custom-gray: #484848;
    --theme-custom-gray-light: #e5e5e5;

    &[data-theme="dark"] {
      --theme-custom-gray: #e5e5e5;
      --theme-custom-gray-light: #484848;
    }
  }
`;

export const AugmentedBadge = styled(OBadge)<{
  $customColor: keyof typeof customColors;
}>`
  ${({ $customColor, type }) => css`
    .o-badge--background-${type} {
      background-color: ${customColors[$customColor].color};
      color: ${customColors[$customColor].contrastColor};
    }

    .o-badge--outline-${type} {
      border: 1px solid ${customColors[$customColor].color};
      color: ${customColors[$customColor].color};
    }
  `}
`;

export const AugmentedIcon = styled(OIcon)<{
  $customColor: keyof typeof customColors;
}>`
  ${({ $customColor, type }) => css`
    .o-icon--type-${type} {
      color: ${customColors[$customColor].color};
    }
  `}
`;
