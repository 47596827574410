import { modalManager, OButton } from "@maestro/react";
import {
  dataSourceODataStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { endpoints } from "services/endpoints";
import { GetOdataAprovacaoOdataViewResponse } from "services/quickfin/models";
import { GridTemplate } from "templates/grid-template";
import { useApprovals } from "./approvals.hook";
import { ApprovalsModal } from "./modal-aprovacoes";
import { approvalConfirmationModalId } from "./modal-aprovacoes/approvals-modal.utils";

const dataSource = dataSourceODataStoreGenerator<
  GetOdataAprovacaoOdataViewResponse["value"][number]
>(endpoints.quickfin.odata.aprovacaoODataView);

export const Approvals = () => {
  const {
    approveReceivables,
    grid,
    gridRef,
    hasReceivableWithHedgeIndication,
    haveHedge,
    loading,
    reducedReceivables,
    resetHaveHedge,
    selectedReceivables,
    setHaveHedge,
    totalSum,
  } = useApprovals();

  return (
    <GridTemplate
      pageTitle={
        <PageTitle
          title="Aprovações"
          description="Visualize todos os títulos pendentes de aprovação"
        />
      }
      gridRef={gridRef}
      actions={
        <OButton
          dataAction="aprovacao_recebiveis:botao:abrir_modal_confirmacao"
          dataLabel="abrir_modal_confirmacao"
          disabled={!(selectedReceivables && selectedReceivables.length > 0)}
          onClick={() => modalManager.show(approvalConfirmationModalId)}
        >
          Aprovar
        </OButton>
      }
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource}
      />
      <ApprovalsModal
        approveReceivables={approveReceivables}
        hasReceivableWithHedgeIndication={hasReceivableWithHedgeIndication}
        haveHedge={haveHedge}
        loading={loading}
        reducedReceivables={reducedReceivables}
        resetHaveHedge={resetHaveHedge}
        selectedReceivables={selectedReceivables ?? []}
        setHaveHedge={setHaveHedge}
        totalSum={totalSum}
      />
    </GridTemplate>
  );
};
