import { WorkflowView } from "services/hubcreditmanager/models/types/workflow/workflow.types";

export const getSimultaneousMonitoringId = (view: WorkflowView) =>
  `simultaneous-processor-config-${view.id}`;

export const getOutputComponentsIds = (
  view: WorkflowView,
  outputId: string | number,
) => ({
  type: `processor-output-type-${view.type}-${outputId}`,
  status: `processor-output-status-${view.type}-${outputId}`,
  bigNumber: `processor-output-big-numbers-${view.type}-${outputId}`,
});
