import type { ColumnLookup } from "components/data-grid";
import { pendencyStatusMap } from "../status-maps";

const automationStatuses = [
  "CRIADA",
  "PENDENTE",
  "PENDENTE_ENVIO_AUTOMACAO",
  "EM_AUTOMACAO",
  "ANOTACAO_NAO_LIDA",
  "CONCLUIDA",
] satisfies HubEnergy.EDiligenceStepPendencyStatus[] as string[];
const nonAutomationStatuses = [
  "CRIADA",
  "PENDENTE",
  "EM_ANALISE",
  "CONCLUIDA",
] satisfies HubEnergy.EDiligenceStepPendencyStatus[] as string[];

export const pendencyStatusLookup = <
  T extends Pick<
    HubEnergy.DiligenceStepPendencyResponse,
    "isAutomation" | "status"
  >,
>(): ColumnLookup<T> => ({
  dataSource: (options) =>
    Object.entries(pendencyStatusMap)
      .filter(([key]) =>
        !("data" in options) || options.data.isAutomation
          ? automationStatuses.includes(key)
          : nonAutomationStatuses.includes(key),
      )
      .map(([key, value]) => ({
        value: key,
        label: value.text,
      })),
  displayExpr: "label",
  valueExpr: "value",
});
