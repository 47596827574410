import { OButton, OTypography } from "@maestro/react";
import styled from "styled-components";

export const StyledOButton = styled(OButton)`
  button {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  width: 200px;
  height: 48px;
`;

export const Subheader = styled(OTypography)`
  margin-top: 12px;

  p {
    font-size: 14px !important;
  }
`;
