import { OCol, OLoader, OOption, ORFieldSelect } from "@maestro/react";
import { RefreshGridButton } from "components/refresh-grid-button";
import { useIdContaDeRetirada } from "./id-conta-de-retirada-field.hook";

interface IdContaDeRetiradaFieldProps {
  required?: boolean;
}

export const IdContaDeRetiradaField = ({
  required,
}: IdContaDeRetiradaFieldProps) => {
  const { accounts, getAccounts, loading, hasFetchError } =
    useIdContaDeRetirada();

  return (
    <OCol xs={12} md={6} align="center" className="flex-fill">
      <ORFieldSelect
        dataAction="geral:select:conta_retirada"
        dataLabel="conta_retirada"
        id="idContaDeRetirada"
        name="idContaDeRetirada"
        label="Conta de retirada"
        placeholder="Selecionar"
        disabled={hasFetchError || loading}
        required={required}
        key={accounts?.length}
      >
        {accounts?.length
          ? accounts
              ?.filter(({ businessAccount }) => businessAccount)
              .map(({ id, agency, number, service }) => (
                <OOption key={id} value={id}>
                  Agência: {agency}, Conta: {number}, Serviço:{" "}
                  {service ?? "Outros"}
                </OOption>
              ))
          : null}
      </ORFieldSelect>
      {loading && <OLoader size="sm" />}
      {hasFetchError && !loading && (
        <RefreshGridButton
          dataAction="conta_retirada:botao:tentar_novamente"
          dataLabel="tentar_novamente"
          onClick={() => getAccounts()}
        />
      )}
    </OCol>
  );
};
