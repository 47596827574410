import { YesNoRadioField } from "./yes-no-radio-field.component";

export const RebateRebarbaSacadoField = () => {
  return (
    <YesNoRadioField
      label="Rebarba do rebate volta para o sacado"
      name="rebateRebarbaSacado"
      description="Configuração que define para quem vai o que sobra do rebate em prazo. Caso esteja ativada (Sim), volta para o sacado. Caso contrário (Não), volta para o BTG"
    />
  );
};
