import { YesNoRadioField } from "./yes-no-radio-field.component";

export const NotificacaoRecebiveisAntecipadosField = () => {
  return (
    <YesNoRadioField
      label="Notificações de recebíveis antecipados"
      name="notificacaoRecebiveisAntecipados"
      description="E-mail de descritivo de antecipações desembolsadas enviados aos fornecedores"
    />
  );
};
