import { OCheckbox } from "@maestro/react";
import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import dayjs from "dayjs";
import { Contract } from "./renegociacao-clientes.type";

export const renegGrid: ODataGridGeneratorConfig<Contract> = {
  datagrid: {
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
    },
  },
  columns: [
    {
      width: 64,
      alignment: "center",
      cellRender: ({ data }) => (
        <OCheckbox
          id={data.contract}
          size="xs"
          value={data.contract}
          className="d-inline-block"
        />
      ),
    },
    { dataField: "name", caption: "Produto" },
    {
      dataField: "cros_contract",
      caption: "Contrato",
    },
    {
      dataField: "balance.curve_value",
      caption: "Valor atual",
      customizeText: ({ value }) => masks.currency(value, "R$", "."),
    },
    {
      dataField: "balance.principal_value",
      caption: "Valor Original",
      customizeText: ({ value }) => masks.currency(value, "R$", "."),
    },
    {
      dataField: "due_days",
      sortOrder: "asc",
      caption: "Status",
      customizeText: ({ value }) => {
        const daysText = value > 1 ? "dias" : "dia";
        const maturityDate = dayjs().add(-value, "day");
        return value
          ? `Vencido há ${value} ${daysText} (${maturityDate.format(
              "DD/MM/YYYY",
            )})`
          : "Em dia";
      },
    },
  ],
};
