import { env } from "./env";

// Doing it this way instead of `env.PROJECT_ENV !== "prod"` is safer
// in case something weird happens with env vars.
// Also, made it a function to get around webpack optimizations that
// might give the wrong result after tokenization.
export const isNotProd = () =>
  ["dev", "uat"].some((environment) => environment === env.PROJECT_ENV);

export const isProd = () => env.PROJECT_ENV === "prod";
