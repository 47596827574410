import { OOption, ORFieldSelect } from "@maestro/react";
import { useMemo } from "react";
import { getProcessorPropertyFieldId } from "../../../utils";
import { GenericPropertyFieldProps } from "../../property-field.types";
import { useBatchTemplatePropertyField } from "./batch-template-field.hook";

export const BatchTemplatePropertyField = ({
  processorConfigProperty,
  handleSubmitProperty,
}: GenericPropertyFieldProps) => {
  const { processorProperty, id } = processorConfigProperty;
  const { allBatchTemplates } = useBatchTemplatePropertyField({
    processorConfigProperty,
    handleSubmitProperty,
  });

  const processorPropertyFieldId = getProcessorPropertyFieldId(
    processorProperty.name,
    id,
  );

  const formPropertyField = useMemo(() => {
    return (
      <ORFieldSelect
        key={processorProperty.name + id}
        id={processorPropertyFieldId}
        name={processorPropertyFieldId}
        placeholder="Selecione"
        labelSize="md"
        label={processorProperty.description}
      >
        <div>
          {allBatchTemplates?.map((template) => (
            <OOption
              key={template.identification}
              value={template.identification}
            >
              {template.name}
            </OOption>
          ))}
        </div>
      </ORFieldSelect>
    );
  }, [processorProperty, allBatchTemplates, id]);

  return <>{formPropertyField}</>;
};
