import { OLoader, OOption, ORFieldSelect } from "@maestro/react";
import { useMemo } from "react";
import { getProcessorPropertyFieldId } from "../../../utils";
import { GenericPropertyFieldProps } from "../../property-field.types";
import { useCreditLineField } from "./credit-line-field.hook";

export const CreditLineField = ({
  processorConfigProperty,
  handleSubmitProperty,
}: GenericPropertyFieldProps) => {
  const { processorProperty, id } = processorConfigProperty;
  const { creditLines, loadingCreditLines } = useCreditLineField({
    processorConfigProperty,
    handleSubmitProperty,
  });

  const processorPropertyFieldId = getProcessorPropertyFieldId(
    processorProperty.name,
    id,
  );

  const creditLineField = useMemo(() => {
    return (
      <div className="d-flex align-items-center">
        <ORFieldSelect
          key={processorProperty.name + id}
          id={processorPropertyFieldId}
          name={processorPropertyFieldId}
          placeholder="Selecione"
          label={processorProperty.description}
        >
          <div>
            {creditLines?.map(({ identification }) => (
              <OOption
                value={identification}
                key={`credit-lines-option-${identification}`}
              >
                {identification}
              </OOption>
            ))}
          </div>
        </ORFieldSelect>
        {loadingCreditLines && <OLoader />}
      </div>
    );
  }, [processorProperty, id, creditLines, loadingCreditLines]);

  return <>{creditLineField}</>;
};
