import { OLoader } from "@maestro/react";
import { RefreshGridButton } from "components/refresh-grid-button";
import { SelectSearchField } from "components/select-search";
import { StepConfigForm } from "../../step-config-form.types";
import { useStepTypeSelector } from "./step-type-selector.hook";

interface StepTypeSelectorProps {
  id: string;
  field: keyof StepConfigForm;
  label: string;
}

export const StepTypeSelector = ({
  id,
  field,
  label,
}: StepTypeSelectorProps) => {
  const { getStepTypes, hasFetchError, loading, stepTypes } =
    useStepTypeSelector(field);

  return (
    <div className="d-flex align-items-center flex-fill">
      <SelectSearchField
        options={stepTypes}
        id={id}
        name={field}
        label={label}
        placeholder="Selecionar"
      />

      {loading && <OLoader size="sm" />}
      {hasFetchError && !loading && (
        <RefreshGridButton onClick={getStepTypes} />
      )}
    </div>
  );
};
