import { OToastManager } from "@maestro/core";
import { useCallback, useEffect, useState } from "react";
import { service } from "services";
import { GetCompanyProductsResponse } from "services/hubloan/models";

export const useOffers = () => {
  const [loading, setLoading] = useState(false);
  const [availableProducts, setAvailableProducts] = useState<
    GetCompanyProductsResponse[]
  >([]);

  const getAvailableProducts = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await service.hubLoan.getAvailableProducts();

      setAvailableProducts(data);
    } catch (e) {
      const errorMessage = "Erro ao buscar os produtos";
      OToastManager.danger(errorMessage);
      throw new Error(errorMessage);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getAvailableProducts();
  }, [getAvailableProducts]);

  return {
    availableProducts,
    loading,
  };
};
