import { DeepNullable, MaestroFields, YupObjectSchema } from "utils/types";
import * as yup from "yup";
import { StepConfigForm } from "./step-config-form.types";

export const stepConfigFormFields = {
  name: {
    id: "name",
    name: "name",
    tag: "text",
    label: "Nome",
    placeholder: "Preencher",
    required: true,
  },
  isPublic: {
    id: "isPublic",
    name: "isPublic",
  },
  needsPreApproval: {
    id: "needsPreApproval",
    name: "needsPreApproval",
  },
  maximumDuration: {
    id: "maximumDuration",
    name: "maximumDuration",
    tag: "number",
    label: "Prazo",
    placeholder: "Preencher",
    required: true,
  },
  maximumDurationType: {
    id: "maximumDurationType",
    name: "maximumDurationType",
    label: "Tipo do prazo",
    placeholder: "Selecionar",
    required: true,
  },
} as const satisfies MaestroFields<StepConfigForm>;

const stringRequired = yup.string().required("Campo obrigatório");
const numberRequired = yup.number().required("Campo obrigatório");

export const stepConfigFormValidationSchema = yup.object<
  StepConfigForm,
  YupObjectSchema<StepConfigForm>
>({
  name: stringRequired,
  maximumDuration: numberRequired,
  typeId: numberRequired,
});

export const checkboxValue = "true";

export const stepConfigFormDefaultValues = {
  name: null,
  isPublic: [],
  needsPreApproval: [],
  maximumDuration: null,
  maximumDurationType: null,
  dependencyTypeIds: [],
  typeId: null,
  approvedPendencyTypeIds: [],
  defaultPendenciesConfigs: [],
  propertiesConfigs: [],
} satisfies Partial<DeepNullable<StepConfigForm>> as unknown as StepConfigForm;
