import { YesNoRadioField } from "./yes-no-radio-field.component";

export const SefazValidationField = () => {
  return (
    <YesNoRadioField
      label="Habilita validação SEFAZ"
      name="sefazValidation"
      description="Habilita fluxo de validação de nota fiscal nesta estratégia"
    />
  );
};
