import { OButton, OIcon } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { GridTemplate } from "templates/grid-template";
import { StepConfigForm } from "../../step-config-form.types";
import { pendenciesGrid } from "./pendencies-grid.grid";

interface PendenciesGridProps {
  pendencyTypes: HubEnergy.DiligenceStepPendencyTypeResponse[];
}

export const PendenciesGrid = ({ pendencyTypes }: PendenciesGridProps) => {
  const gridRef = useGridRef();

  const { setValue, watch } = useFormContext<StepConfigForm>();

  const watchPendencies = watch("defaultPendenciesConfigs");

  const grid = useMemo(
    () =>
      pendenciesGrid((pendencies) => {
        setValue("defaultPendenciesConfigs", pendencies);
      }, pendencyTypes),
    [pendencyTypes, setValue],
  );

  return (
    <GridTemplate
      actions={
        <OButton onClick={() => gridRef.current?.instance.addRow()}>
          <OIcon category="orq" icon="orq-add-plus" />
        </OButton>
      }
      gridRef={gridRef}
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={watchPendencies ?? []}
      />
    </GridTemplate>
  );
};
