import { ODivider } from "@maestro/react";
import { MenuItem } from "components/menu/_compose";
import { FormProvider } from "react-hook-form";
import {
  ProcessorConfigBasicData,
  ProcessorConfigChannels,
  ProcessorConfigMonitoringView,
  ProcessorConfigProperties,
  ProcessorConfigSettings,
  ProcessorConfigUserActions,
} from "./_compose";
import {
  MonitoringItemDefaultValue,
  useProcessorConfigMenu,
} from "./processor-config-menu.hook";
import {
  MenuItems,
  ProcessorConfigMenuProps,
} from "./processor-config-menu.types";

export const ProcessorConfigMenu = ({ form }: ProcessorConfigMenuProps) => {
  const {
    activeItem,
    activeMonitoringItem,
    buttonTypeOptions,
    iconOptions,
    isWfEntryWatch,
    selectedProcessorConfig,
    workflowConfigViews,
    resetActiveSubMenus,
    setActiveItem,
    setActiveMonitoringItem,
  } = useProcessorConfigMenu({ form });

  return (
    <div className="d-flex gap-4">
      <div className="d-flex flex-column gap-2">
        <MenuItem
          onClick={() => resetActiveSubMenus(MenuItems.Default)}
          label={MenuItems.Default}
          link=""
          active={activeItem === MenuItems.Default}
        />

        <MenuItem
          onClick={() => {
            setActiveItem(MenuItems.Monitoramento);
            setActiveMonitoringItem(MonitoringItemDefaultValue);
          }}
          label={MenuItems.Monitoramento}
          link=""
          active={activeItem === MenuItems.Monitoramento}
        />

        {activeItem === MenuItems.Monitoramento &&
          !!workflowConfigViews &&
          workflowConfigViews.map((wcView) => (
            <div
              key={`visualizacao-${wcView.view.type}`}
              className="w-95 align-self-end"
            >
              <MenuItem
                onClick={() => setActiveMonitoringItem(wcView.view.type)}
                label={wcView.view.type}
                link=""
                active={activeMonitoringItem === wcView.view.type}
              />
            </div>
          ))}

        {!!selectedProcessorConfig?.processor.canBeWfEntry && (
          <MenuItem
            onClick={() => resetActiveSubMenus(MenuItems.Canais)}
            label={MenuItems.Canais}
            link=""
            active={activeItem === MenuItems.Canais}
          />
        )}

        <MenuItem
          onClick={() => resetActiveSubMenus(MenuItems.Configuracoes)}
          label={MenuItems.Configuracoes}
          link=""
          active={activeItem === MenuItems.Configuracoes}
        />

        {!!selectedProcessorConfig?.processor.hasUserAction && (
          <MenuItem
            onClick={() => resetActiveSubMenus(MenuItems.AcoesUsuario)}
            label={MenuItems.AcoesUsuario}
            link=""
            active={activeItem === MenuItems.AcoesUsuario}
          />
        )}

        <MenuItem
          onClick={() => resetActiveSubMenus(MenuItems.Propriedades)}
          label={MenuItems.Propriedades}
          link=""
          active={activeItem === MenuItems.Propriedades}
        />
      </div>

      <ODivider size="xxs" position="vertical" />

      <FormProvider {...form}>
        <form className="w-100">
          {activeItem === MenuItems.Default && <ProcessorConfigBasicData />}

          {!!activeMonitoringItem && (
            <ProcessorConfigMonitoringView
              workflowConfigView={workflowConfigViews?.find(
                (wcView) => wcView.view.type === activeMonitoringItem,
              )}
              form={form}
            />
          )}

          {activeItem === MenuItems.Canais && (
            <ProcessorConfigChannels
              isWfEntryWatch={isWfEntryWatch}
              workflowConfigViews={workflowConfigViews}
            />
          )}
          {activeItem === MenuItems.AcoesUsuario && (
            <ProcessorConfigUserActions
              workflowConfigViews={workflowConfigViews}
              processorConfig={selectedProcessorConfig}
              iconOptions={iconOptions}
              buttonTypeOptions={buttonTypeOptions}
            />
          )}

          {activeItem === MenuItems.Configuracoes && (
            <ProcessorConfigSettings />
          )}

          {activeItem === MenuItems.Propriedades && (
            <ProcessorConfigProperties />
          )}
        </form>
      </FormProvider>
    </div>
  );
};
