import { YesNoRadioField } from "./yes-no-radio-field.component";

export const SendErrorCallbackField = () => {
  return (
    <YesNoRadioField
      label="Envia callback de rejeição"
      name="sendErrorCallback"
      description="Envia callback de rejeição para o turtle road"
    />
  );
};
