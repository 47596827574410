import { DataGridAction } from "components/datagrid-action";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { EstrategiaComercial } from "../../estrategia-templates.types";

interface GridActionsProps {
  estrategia: EstrategiaComercial;
  reload: () => void;
}

export const GridActions = ({ estrategia }: GridActionsProps) => {
  return (
    <DataGridAction
      actions={[
        {
          icon: { category: "far", icon: "fa-percent" },
          label: "Taxas",
          role: roles.recebiveis.product
            .estrategiaTemplatesByEstrategiaIdTaxasPage.role,
          route:
            corporateRouter.routes.recebiveis.product.estrategiaTemplates.byEstrategiaId.taxas.path(
              {
                estrategiaId: estrategia.id,
              },
            ),
        },
        {
          icon: { category: "far", icon: "fa-address-card" },
          label: "Cadastro de relacionamento",
          role: roles.recebiveis.product
            .estrategiaTemplatesByEstrategiaIdOnboardingRelationshipSettingsPage
            .role,
          route:
            corporateRouter.routes.recebiveis.product.estrategiaTemplates.byEstrategiaId.onboardingRelationshipSettings.path(
              {
                estrategiaId: estrategia.id,
              },
            ),
          visible: !["RiscoCedente", "FundingProprio", "CCC"].includes(
            estrategia.tipoProduto,
          ),
        },
        {
          icon: { category: "far", icon: "fa-sliders-h" },
          label: "Visualizar estratégia",
          role: roles.recebiveis.product
            .estrategiaTemplatesByEstrategiaIdEstrategiaDetailsPage.role,
          route:
            corporateRouter.routes.recebiveis.product.estrategiaTemplates.byEstrategiaId.estrategiaDetails.path(
              {
                estrategiaId: estrategia.id,
              },
            ),
        },
      ]}
    />
  );
};
