import { modalManager } from "@maestro/core";
import { OIcon } from "@maestro/react";
import {
  ODataGridGeneratorConfig,
  removeKeyFromDataSourceItems,
} from "components/data-grid";
import { UnstyledButton } from "components/unstyled-button";
import { gridStorage } from "utils/storage";
import {
  DocumentTemplateModal,
  documentTemplateModalId,
} from "../../../../../../../../components/document-template-modal";

export const propertiesGrid = (
  onChange: (
    pendencies: HubEnergy.DiligenceStepPropertyConfigResponse[],
  ) => void,
  propertyTypes: HubEnergy.DiligenceStepPropertyTypeResponse[],
  documentTypes: HubEnergy.DocumentTypeResponse[],
): ODataGridGeneratorConfig<HubEnergy.DiligenceStepPropertyConfigResponse> => {
  return {
    datagrid: {
      noDataText: "Nenhuma propriedade",
      stateStoring: gridStorage("propertiesGrid"),
      editing: {
        confirmDelete: true,
        allowDeleting: true,
        allowUpdating: true,
      },
      onInitNewRow: (e) => {
        e.data.isPublic = false;
        e.data.minimumAmount = 1;
      },
      onRowUpdated: (row) => {
        onChange?.(
          removeKeyFromDataSourceItems(row.component.getDataSource().items()),
        );
      },
      onRowInserted: (row) => {
        onChange?.(
          removeKeyFromDataSourceItems(row.component.getDataSource().items()),
        );
      },
      onRowRemoved: (row) => {
        onChange?.(
          removeKeyFromDataSourceItems(row.component.getDataSource().items()),
        );
      },
      onEditorPreparing: (e) => {
        e.editorOptions = {
          ...e.editorOptions,
          dropDownOptions: {
            ...e.editorOptions?.dropDownOptions,
            minWidth: 400,
          },
          itemTemplate: (itemData, _, itemElement) => {
            (itemElement as HTMLElement).setAttribute("title", itemData.label);
            return itemData.label;
          },
        };
      },
      columnResizingMode: "nextColumn",
    },
    columns: [
      {
        caption: "Público",
        dataField: "isPublic",
        dataType: "boolean",
        width: 100,
        validationRules: [
          {
            type: "custom",
            validationCallback: ({ value }) => typeof value === "boolean",
          },
        ],
      },
      {
        caption: "Tipo",
        dataField: "typeId",
        dataType: "string",
        validationRules: [{ type: "required" }],
        lookup: {
          dataSource: propertyTypes.map(({ name, id, label }) => ({
            value: id,
            label: `${label} - [${name}]`,
          })),
          displayExpr: "label",
          valueExpr: "value",
        },
      },
      {
        caption: "Tipo do documento",
        dataField: "documentTypeId",
        dataType: "string",
        lookup: {
          dataSource: documentTypes.map(({ name, id, label }) => ({
            value: id,
            label: `${label} - [${name}]`,
          })),
          allowClearing: true,
          displayExpr: "label",
          valueExpr: "value",
        },
      },
      {
        caption: "Valor Mínimo",
        dataField: "minimumAmount",
        width: "auto",
        dataType: "number",
      },
      {
        type: "buttons",
        buttons: ["edit", "delete", { template: "documentTemplateButton" }],
      },
    ],
    templates: [
      {
        name: "documentTemplateButton",
        render: ({ data }) => {
          if (!data.id || data.type.mask !== "DOCUMENT") return null;
          return (
            <>
              <UnstyledButton
                style={{ verticalAlign: "bottom" }}
                className="d-inline"
                title="Template do documento"
                onClick={() =>
                  modalManager.show(documentTemplateModalId(data.id))
                }
              >
                <OIcon category="orq" icon="orq-file" className="d-inline" />
              </UnstyledButton>
              <DocumentTemplateModal
                propertyConfigId={data.id}
                documentTemplate={data.documentTemplate}
              />
            </>
          );
        },
      },
    ],
  };
};
