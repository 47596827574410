import { OLoader } from "@maestro/react";
import { RefreshGridButton } from "components/refresh-grid-button";
import {
  SelectSearchFieldV2,
  SelectSearchFieldV2Option,
} from "../select-search-v2";
import { useApiSelector } from "./api-selector.hook";
import { ServiceMethod } from "./api-selector.types";

interface ApiSelectorProps<T, V> {
  serviceMethod: ServiceMethod<T>;
  optionMapper: (apiResponse: T | undefined) => SelectSearchFieldV2Option<V>[];
  name: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  multiple?: boolean;
  dropdownPosition?: "top" | "bottom";
}

export const ApiSelector = <T, V extends string | number>({
  serviceMethod,
  optionMapper,
  label,
  name,
  placeholder,
  required,
  disabled,
  multiple,
  dropdownPosition,
}: ApiSelectorProps<T, V>) => {
  const { fetch, hasError, loading, options } = useApiSelector(
    serviceMethod,
    name,
    optionMapper,
  );

  return (
    <div className="d-flex align-items-center flex-fill">
      <SelectSearchFieldV2
        options={options}
        id={name}
        name={name}
        placeholder={placeholder}
        label={label}
        required={required}
        multiple={multiple}
        dropdownPosition={dropdownPosition}
        disabled={disabled}
      />
      {loading && <OLoader size="sm" />}
      {hasError && !loading && <RefreshGridButton onClick={() => fetch()} />}
    </div>
  );
};
