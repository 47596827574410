import { OCol, ORFieldInput } from "@maestro/react";

export const FundConfigField = () => {
  return (
    <OCol xs={12} md={6}>
      <ORFieldInput
        dataAction="geral:texto:identificador_fundo"
        dataLabel="identificador_fundo"
        id="fundConfig"
        name="fundConfig"
        tag="text"
        label="Indentificador interno do Fundo"
        placeholder="Ex: SKIP"
      />
    </OCol>
  );
};
