import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useParseFromSearchParams } from "hooks/parse-from-search-params";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import {
  LoanAgreementForm,
  LoanAgreementFormValues,
} from "../../../../../components/loan-agreement/loan-agreement-form.component";
import { loanAgreementValidationFormSchema } from "../../../../../components/loan-agreement/loan-agreement-form.schemas";
import { EmprestimosProdutoConveniosByIdEditarRouteQueryParams } from "../../../../../routes/emprestimos.route-params";

type LoanEditAgreementFormValues = LoanAgreementFormValues & { id?: number };

export const LoanEditAgreementPage = () => {
  const [loading, setLoading] = useState(false);

  const { value, hasError } =
    useParseFromSearchParams<EmprestimosProdutoConveniosByIdEditarRouteQueryParams>(
      "data",
    );

  const form = useForm<LoanEditAgreementFormValues>({
    resolver: yupResolver(loanAgreementValidationFormSchema),
  });

  const { handleSubmit, reset } = form;

  const editAgreement = useCallback(
    async (values: LoanEditAgreementFormValues) => {
      try {
        if (!values.id) return;
        setLoading(true);

        await service.hubLoan.editAgreement({ ...values, id: values.id });

        OToastManager.success("Convênio editado com sucesso");
      } catch {
        OToastManager.danger("Ocorreu algum erro. Tente novamente mais tarde");
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  useEffect(() => {
    value && reset(value as LoanEditAgreementFormValues);
  }, [reset, value]);

  return (
    <ContentTemplate
      hasError={hasError}
      loading={!value && !hasError}
      noValue
      render={() => (
        <FormTemplate
          pageTitle={<PageTitle title="Editar convênio" />}
          actions={
            <LoadingButton
              loading={loading}
              dataAction="emprestimos_cadastrar_perfis:botao:salvar"
              dataLabel="salvar"
              onClick={handleSubmit(editAgreement)}
            >
              Salvar
            </LoadingButton>
          }
        >
          <CardTemplate>
            <FormProvider {...form}>
              <LoanAgreementForm />
            </FormProvider>
          </CardTemplate>
        </FormTemplate>
      )}
    />
  );
};
