import { YesNoRadioField } from "./yes-no-radio-field.component";

export const RequiresPMEAccountField = () => {
  return (
    <YesNoRadioField
      label="Requer abertura de conta"
      name="requiresPMEAccount"
      description="Necessidade de abertura de conta do fornecedor para liquidação das operações"
    />
  );
};
