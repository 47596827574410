import { OCol, ORFieldInput } from "@maestro/react";

export const RebateToleranceField = () => {
  return (
    <OCol xs={12} md={6}>
      <ORFieldInput
        dataAction="geral:texto:tolerancia_rebate"
        dataLabel="tolerancia_rebate"
        id="rebateTolerance"
        name="rebateTolerance"
        tag="number"
        placeholder="Preencher"
        label="Tolerância de rebate"
        description="Definição de valor para tolerância de rebate em casos de Sem rebate com trava"
      />
    </OCol>
  );
};
