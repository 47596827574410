import { YesNoRadioField } from "./yes-no-radio-field.component";

export const NotificacaoOnboardingSacadoField = () => {
  return (
    <YesNoRadioField
      label="Notificações de onboarding"
      name="notificacaoOnboardingSacado"
      description="E-mail de notificação de aprovação ou pendenciamento de documentação de cadastro de fornecedores"
    />
  );
};
