import * as yup from "yup";

export const addProposalWorkflowFormValidationSchema = yup.object().shape({
  name: yup.string().trim().required("Campo obrigatório"),
  tabName: yup.string().trim().required("Campo obrigatório"),
  description: yup.string().trim().required("Campo obrigatório"),
  detailsPageType: yup.string().required("Este campo é obrigatório"),
  icon: yup.string().required("Este campo é obrigatório"),
});

export const addProposalWorkflowFormDefaultValues = {
  name: "",
  tabName: "",
  description: "",
  detailsPageType: "",
  icon: "",
};
