import { Template } from "devextreme-react/core/template";
import {
  Column,
  FilterPanel,
  Grouping,
  MasterDetail,
  Paging,
  Selection,
} from "devextreme-react/data-grid";
import { useRoles } from "hooks/roles";
import merge from "lodash/merge";
import { memo } from "react";
import { ODataGrid } from "./o-data-grid.component";
import {
  OColumnProps,
  ODataGridGeneratorConfig,
  ODataGridGeneratorProps,
} from "./o-data-grid.types";

const defaultGridProps = <T,>() =>
  ({
    datagrid: {
      noDataText: "Nenhum item",
      columnAutoWidth: true,
      hoverStateEnabled: true,
      pager: {
        allowedPageSizes: [10, 20, 30],
        showInfo: true,
        infoText: "Página {0} de {1} ({2} itens)",
        showPageSizeSelector: true,
      },
      scrolling: {
        showScrollbar: "always",
      },
      rowAlternationEnabled: true,
      allowColumnResizing: true,
      columnResizingMode: "widget",
    },
    paging: {
      defaultPageSize: 10,
    },
    columns: [{}],
  } as ODataGridGeneratorConfig<T>);

const columnsGenerator = (
  columns: OColumnProps<any>[] | undefined,
  hasRole: ReturnType<typeof useRoles>["hasRole"],
) => {
  return columns?.map(
    ({ columns: extraColumns, ...column }, index) =>
      hasRole(column.role) && (
        // eslint-disable-next-line react/no-array-index-key
        <Column key={index} {...column}>
          {columnsGenerator(extraColumns, hasRole)}
        </Column>
      ),
  );
};

const ODataGridGeneratorComponent = <T,>({
  grid,
  loading,
  dataSource,
  children,
  gridRef,
}: ODataGridGeneratorProps<T>) => {
  const { hasRole } = useRoles();

  // deep merge with default props
  const mergedProps = merge({}, defaultGridProps<T>(), grid);
  const {
    datagrid,
    columns,
    masterDetail,
    paging,
    selection,
    filterPanel,
    grouping,
    templates,
  } = mergedProps;

  return (
    <ODataGrid
      {...datagrid}
      ref={gridRef}
      loading={loading}
      dataSource={dataSource || datagrid?.dataSource}
    >
      {children}

      {columnsGenerator(columns as any, hasRole)}

      {masterDetail && <MasterDetail key="master-detail" {...masterDetail} />}
      {paging && <Paging key="paging" {...paging} />}
      {selection && <Selection key="select" {...selection} />}
      {filterPanel && <FilterPanel key="filterPanel" {...filterPanel} />}
      {grouping && <Grouping key="grouping" {...grouping} />}
      {templates &&
        templates.map((template) => (
          <Template key={`template-${template.name}`} {...template} />
        ))}
    </ODataGrid>
  );
};

export const ODataGridGenerator = memo(
  ODataGridGeneratorComponent,
) as typeof ODataGridGeneratorComponent;
