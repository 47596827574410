import { OToastManager } from "@maestro/core";
import {
  OButton,
  OCard,
  OCardBody,
  OCardHeader,
  OIcon,
  OLoader,
  OTypography,
} from "@maestro/react";
import { isAxiosError } from "axios";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { client } from "../../../../../services/client";
import { logger } from "../../../../../utils/logger";
import { IViewContentProps } from "../salesforce.types";
import { MoneyMask } from "../utils";
import { endpoints } from "../utils/endpoints";
import { AccountList } from "./account-list/account-list.component";
import { AlreadyApproved } from "./already-approved/already-approved.component";
import { ColumnGap, DollarPriceContent } from "./cards.styles";
import {
  AccountModality,
  CardStatus,
  ContestationStatus,
  Currency,
  IAccount,
  IContestation,
  IDebitAccount,
  IInvoice,
} from "./cards.types";
import { CollapseItem } from "./compose/colapse-item/collapse-item.component";
import { CreditOffers } from "./credit-offers/credit-offers.component";
import { Invoices } from "./invoices/invoices.component";
import { LimitAnalysisHistory } from "./limit-analysis-history/limit-analysis-history.component";
import { Limits } from "./limits/limits.component";
import { Timeline } from "./timeline/timeline.component";
import { ITimelineItem } from "./timeline/timeline.types";
import { Virtuals } from "./virtuals/virtuals.component";
import { IVirtualCardItem } from "./virtuals/virtuals.types";
import { Wallets } from "./wallets/wallets.component";

export const CardComponent = ({ selectedCompany }: IViewContentProps) => {
  const [activeDebitAccount, setActiveDebitAccount] = useState<IDebitAccount>();
  const [cardAccountData, setCardAccountData] = useState<IAccount>();
  const [hasAccount, setHasAccount] = useState<boolean | undefined>();
  const [invoicesData, setInvoicesData] = useState<Array<IInvoice>>();
  const [dollarPrice, setDollarPrice] = useState<string>();

  const defaultPaginationData = { page: 1, hasNext: false };

  const [debitTimelineData, setDebitTimelineData] = useState<ITimelineItem[]>();
  const [debitTimelinePaginationData, setDebitTimelinePaginationData] =
    useState(defaultPaginationData);

  const [creditTimelineData, setCreditTimelineData] =
    useState<ITimelineItem[]>();

  const [creditTimelinePaginationData, setCreditTimelinePaginationData] =
    useState(defaultPaginationData);

  const [openContestations, setOpenContestations] = useState<string[]>();

  const [virtualCardItemsData, setVirtualCardItemsData] =
    useState<IVirtualCardItem[]>();

  const loadContestations = useCallback(
    async (status: ContestationStatus) => {
      try {
        if (!selectedCompany) return;
        const { data } = await client.get(
          endpoints.bankinghub.getContestations(selectedCompany, status),
          {
            headers: {
              "x-identification": selectedCompany,
            },
          },
        );
        if (data) {
          const ids = data.contestations.map(
            (contestation: IContestation) =>
              contestation.externalAuthorizationId,
          );
          setOpenContestations(ids);
        }
      } catch (error) {
        setOpenContestations([]);
        OToastManager.danger("Erro ao carregar dados de Contestações");
      }
    },
    [selectedCompany],
  );

  const loadInvoices = useCallback(
    async (accountId: string) => {
      try {
        if (!selectedCompany) return;
        const { data } = await client.get(
          endpoints.bankinghub.getInvoices(accountId),
          {
            headers: {
              "x-identification": selectedCompany,
            },
          },
        );
        if (data) {
          setInvoicesData(data.invoices);
        }
      } catch (error) {
        setInvoicesData([]);
        OToastManager.danger(
          "Erro ao carregar Lista de faturas, tente novamente",
        );
      }
    },
    [selectedCompany],
  );

  const loadTimeline = useCallback(
    async (accountId: string, page: number, debitAccount?: IDebitAccount) => {
      try {
        if (!selectedCompany) return;
        const { data } = await client.get(
          endpoints.bankinghub.getTimelines(
            accountId,
            300,
            page,
            true,
            debitAccount,
          ),
          {
            headers: {
              "x-identification": selectedCompany,
            },
          },
        );
        if (data) {
          const { items, hasNext } = data;
          if (debitAccount) {
            setDebitTimelineData((prev) => (prev ?? []).concat(items));
            setDebitTimelinePaginationData({ page, hasNext });
          } else {
            setCreditTimelineData((prev) => (prev ?? []).concat(items));
            setCreditTimelinePaginationData({ page, hasNext });
          }
        }
      } catch (error) {
        setDebitTimelineData([]);
        setCreditTimelineData([]);
        OToastManager.danger("Erro ao carregar dados da Linha do tempo");
      }
    },
    [selectedCompany],
  );

  const loadDollarPrice = useCallback(async () => {
    const datetimeNow = moment().format("YYYY-MM-DD");
    try {
      if (!selectedCompany) return;
      const { data } = await client.get(
        endpoints.bankinghub.getDollarPrice(datetimeNow),
        {
          headers: {
            "x-identification": selectedCompany,
          },
        },
      );
      if (data) {
        setDollarPrice(data.value);
      }
    } catch (error) {
      OToastManager.danger("Erro ao carregar Valor do dolar, tente novamente");
    }
  }, [selectedCompany, setDollarPrice]);

  const loadComponentsData = useCallback(
    async (
      accountId: string,
      requireCreditComponents: boolean,
      debitAccount?: IDebitAccount,
    ) => {
      const promises: Promise<void>[] = [];
      if (requireCreditComponents) {
        setInvoicesData(undefined);
        setCreditTimelineData(undefined);
        promises.push(loadInvoices(accountId));
        promises.push(loadTimeline(accountId, 1));
      }
      if (!!debitAccount) {
        setDebitTimelineData(undefined);
        promises.push(loadTimeline(accountId, 1, debitAccount));
      }
      setOpenContestations(undefined);
      promises.push(loadContestations(ContestationStatus.Sent));
      await Promise.all(promises);
    },
    [loadContestations, loadDollarPrice, loadInvoices, loadTimeline],
  );

  const virtualCardItemsFromCardAccountData = useCallback(
    (cardAccountData: IAccount) => {
      const items: IVirtualCardItem[] = [];
      const validStatus = [
        CardStatus.Normal,
        CardStatus.Sleeping,
        CardStatus.BlockedInternal,
        CardStatus.BlockedByPaymentDefault,
      ];
      cardAccountData.cards.forEach((card) => {
        card.virtualCards.forEach((virtualCard) => {
          if (validStatus.includes(virtualCard.status)) {
            items.push({ ...virtualCard, ownerName: card.owner.name });
          }
        });
        setVirtualCardItemsData(items);
      });
    },
    [],
  );

  const loadCards = useCallback(async () => {
    setCardAccountData(undefined);
    setHasAccount(undefined);
    setDollarPrice(undefined);
    setVirtualCardItemsData(undefined);
    try {
      if (!selectedCompany) return;
      const { data } = await client.get<IAccount>(
        endpoints.bankinghub.getCards,
        {
          headers: {
            "x-identification": selectedCompany,
          },
        },
      );
      if (data) {
        setCardAccountData(data);
        setHasAccount(true);

        const [firstDebitAccount] = data?.debitAccounts;
        setActiveDebitAccount(firstDebitAccount);

        const { program } = data;
        const requireCreditComponents =
          program?.modality !== AccountModality.Debit;
        if (requireCreditComponents) virtualCardItemsFromCardAccountData(data);

        await loadComponentsData(
          data?.id,
          requireCreditComponents,
          firstDebitAccount,
        );
      }
    } catch (err) {
      if (isAxiosError(err)) {
        if (err.response?.status === 404) {
          setHasAccount(false);
        }
      } else {
        logger.error(err);
      }
    }
  }, [
    selectedCompany,
    virtualCardItemsFromCardAccountData,
    loadComponentsData,
  ]);

  useEffect(() => {
    loadDollarPrice();
    loadCards();
  }, [loadDollarPrice, loadCards]);

  const renderDollarPrice = (loadFunction: () => Promise<void>) => {
    return (
      <DollarPriceContent>
        <OTypography type="primary">
          Dólar: {MoneyMask(dollarPrice, Currency.BRL)}
        </OTypography>
        <OButton
          outline
          bordered={false}
          dataAction="cartao:botao:recarregar"
          dataLabel="Recarregar informações"
          title="Recarregar dados"
          onClick={async () => {
            await loadDollarPrice();
            await loadFunction();
          }}
        >
          <OIcon category="fal" icon="fa-undo" />
        </OButton>
      </DollarPriceContent>
    );
  };

  if (hasAccount === undefined) {
    return (
      <div style={{ minHeight: "100vh" }}>
        <OLoader type="primary" absolute backdrop />
      </div>
    );
  }

  return (
    <OCard>
      <OCardHeader>
        <OTypography weight="bold" size="xl">
          Cartões
        </OTypography>
      </OCardHeader>
      <OCardBody>
        <ColumnGap rowGap={20}>
          {hasAccount && cardAccountData ? (
            <>
              <AccountList
                cardAccountData={cardAccountData}
                activeDebitAccount={activeDebitAccount}
                setActiveDebitAccount={setActiveDebitAccount}
                renderDollarPrice={() => renderDollarPrice(loadCards)}
              />
              <CollapseItem
                title="Cartões físicos"
                key="physical-cards"
              >
                <AlreadyApproved
                  selectedCompany={selectedCompany}
                  cardAccountData={cardAccountData}
                  invoicesData={invoicesData}
                  loadCards={loadCards}
                />
              </CollapseItem>
              {cardAccountData.program.modality !== AccountModality.Debit && (
                <CollapseItem
                  title="Cartões virtuais"
                  key="virtual-cards"
                  loading={!virtualCardItemsData}
                >
                  <Virtuals
                    selectedCompany={selectedCompany}
                    virtualCardItemsData={virtualCardItemsData ?? []}
                    loadCards={loadCards}
                  />
                </CollapseItem>
              )}
              <CollapseItem title="Wallets" key="wallets">
                <Wallets selectedCompany={selectedCompany} />
              </CollapseItem>
              {cardAccountData.program.modality !== AccountModality.Credit && (
                <CollapseItem
                  title="Linha do tempo de Débito"
                  key="credit-timeline"
                  loading={!debitTimelineData}
                >
                  <Timeline
                    timelineData={debitTimelineData ?? []}
                    selectedCompany={selectedCompany}
                    upgradeTimelineDataSize={() =>
                      loadTimeline(
                        cardAccountData.id,
                        debitTimelinePaginationData.page + 1,
                        activeDebitAccount,
                      )
                    }
                    isUpgradeDataButtonActive={
                      debitTimelinePaginationData.hasNext
                    }
                  />
                </CollapseItem>
              )}
              {cardAccountData.program.modality !== AccountModality.Debit && (
                <>
                  <CollapseItem
                    title="Linha do tempo de Crédito"
                    key="credit-timeline"
                  >
                    <Timeline
                      timelineData={creditTimelineData ?? []}
                      selectedCompany={selectedCompany}
                      openContestations={openContestations ?? []}
                      setOpenContestations={setOpenContestations}
                      upgradeTimelineDataSize={() =>
                        loadTimeline(
                          cardAccountData.id,
                          creditTimelinePaginationData.page + 1,
                        )
                      }
                      isUpgradeDataButtonActive={
                        creditTimelinePaginationData.hasNext
                      }
                    />
                  </CollapseItem>
                  <CollapseItem title="Limites" key="limits">
                    <Limits creditLimitData={cardAccountData.creditLimit} />
                  </CollapseItem>
                  <CollapseItem
                    title="Faturas"
                    key="invoices"
                    loading={!invoicesData}
                  >
                    <Invoices
                      invoicesData={invoicesData ?? []}
                      accountId={cardAccountData.id}
                      selectedCompany={selectedCompany}
                      openContestations={openContestations ?? []}
                      setOpenContestations={setOpenContestations}
                    />
                  </CollapseItem>
                </>
              )}
              <CollapseItem
                title="Histórico de análises de crédito"
                key="limit-analysis-history"
              >
                <LimitAnalysisHistory selectedCompany={selectedCompany} />
              </CollapseItem>
            </>
          ) : (
            !hasAccount && (
              <>
                <CreditOffers
                  selectedCompany={selectedCompany}
                  renderDollarPrice={renderDollarPrice}
                />
                <CollapseItem
                  title="Histórico de análises de crédito"
                  key="limit-analysis-history"
                >
                  <LimitAnalysisHistory selectedCompany={selectedCompany} />
                </CollapseItem>
              </>
            )
          )}
        </ColumnGap>
      </OCardBody>
    </OCard>
  );
};
