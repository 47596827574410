import { OCol, ORFieldInput } from "@maestro/react";

export const PnlBookField = () => {
  return (
    <OCol xs={12} md={6}>
      <ORFieldInput
        dataAction="geral:texto:pnl_book"
        dataLabel="pnl_book"
        id="pnlBook"
        name="pnlBook"
        tag="number"
        placeholder="Preencher"
        label="Book PNL"
      />
    </OCol>
  );
};
