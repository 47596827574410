import { FieldValues, UseFormReturn } from "react-hook-form";

export enum MenuItems {
  Default = "Dados básicos",
  Monitoramento = "Monitoramento",
  Canais = "Canais",
  AcoesUsuario = "Ações de usuário",
  Configuracoes = "Configurações",
  Propriedades = "Propriedades",
}

export interface ProcessorConfigMenuProps {
  form: UseFormReturn<FieldValues>;
}
