import { YesNoRadioField } from "./yes-no-radio-field.component";

export const ExigeAprovacaoBancoField = () => {
  return (
    <YesNoRadioField
      label="Aprovação BTG"
      name="exigeAprovacaoBanco"
      description="Configuração que represa os recebíveis que serão disponibilizados no portal. Etapa que 'segura' os recebíveis, logo após a aprovação das notas pelo sacado"
      required
    />
  );
};
