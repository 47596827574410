import { OButton, OIcon } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { GridTemplate } from "templates/grid-template";
import { StepConfigForm } from "../../step-config-form.types";
import { propertiesGrid } from "./properties-grid.grid";
import { usePropertiesGrid } from "./properties-grid.hook";

export const PropertiesGrid = () => {
  const gridRef = useGridRef();
  const {
    documentTypesLoading,
    documentTypesValue,
    propertyTypesLoading,
    propertyTypesValue,
  } = usePropertiesGrid();

  const { setValue, watch } = useFormContext<StepConfigForm>();

  const watchProperties = watch("propertiesConfigs");

  const grid = useMemo(
    () =>
      propertiesGrid(
        (properties) => {
          setValue("propertiesConfigs", properties);
        },
        propertyTypesValue?.response ?? [],
        documentTypesValue?.response ?? [],
      ),
    [documentTypesValue?.response, propertyTypesValue?.response, setValue],
  );

  return (
    <GridTemplate
      actions={
        <OButton onClick={() => gridRef.current?.instance.addRow()}>
          <OIcon category="orq" icon="orq-add-plus" />
        </OButton>
      }
      gridRef={gridRef}
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={watchProperties ?? []}
        loading={documentTypesLoading || propertyTypesLoading}
      />
    </GridTemplate>
  );
};
