import { OCol, ORFieldInput } from "@maestro/react";

interface InterestArrearsRateFieldProps {
  required?: boolean;
}

export const InterestArrearsRateField = ({
  required,
}: InterestArrearsRateFieldProps) => {
  return (
    <OCol xs={12} md={6}>
      <ORFieldInput
        dataAction="geral:texto:mora"
        dataLabel="mora"
        id="interestArrearsRate"
        name="interestArrearsRate"
        tag="percentage"
        label="Mora"
        placeholder="Preencher"
        required={required}
      />
    </OCol>
  );
};
