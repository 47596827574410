import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { DetailsCard } from "components/details-card";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useUser } from "contexts/user";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { service } from "services";
import { DetailsTemplate } from "templates/details-template";
import { EmprestimosProdutoGestorLimitesById } from "../../../../../../routes/emprestimos.route-params";
import {
  LimitDetailsFormValues,
  limitDetailsFormValidationsSchema,
} from "./limit-details-form.schemas";
import { limitDetailsGenerator } from "./limit-details.details";
import { ReadinessStatus } from "./readiness-status.component";

const today = new Date();

export const LimitDetailsPage = () => {
  const [loading, setLoading] = useState({
    status: false,
    expirationDate: false,
    approval: false,
    recalculating: false,
    deactivating: false,
  });

  const { user } = useUser();
  const { id } = useParams<EmprestimosProdutoGestorLimitesById>();

  const form = useForm<LimitDetailsFormValues>({
    resolver: yupResolver(limitDetailsFormValidationsSchema),
  });

  const {
    watch,
    handleSubmit,
    reset,
    formState: { dirtyFields },
    resetField,
  } = form;

  if (!id) throw new Error("No id");

  const {
    callService: getLimitRequest,
    value: limitRequest,
    hasError: hasErrorLimitRequest,
    loading: loadingLimitRequest,
  } = useServiceCall(service.hubCreditManager.getLimitRequestMinified);

  const navigate = useNavigate();
  const dateWatcher = watch("expirationDate");
  const expirationDate = dateWatcher ? new Date(dateWatcher) : undefined;

  const handleReevaluate = useCallback(async () => {
    try {
      setLoading((previousLoading) => ({
        ...previousLoading,
        recalculating: true,
      }));

      await service.hubCreditManager.reevaluateLimit({
        limitId: limitRequest?.limitId,
      });
      getLimitRequest(id);

      OToastManager.success(`Limite recalculado com sucesso`);
    } catch {
      OToastManager.danger(
        `Houve um erro ao recalcular o limite. Tente novamente.`,
      );
    } finally {
      setLoading((previousLoading) => ({
        ...previousLoading,
        recalculating: false,
      }));
    }
  }, [getLimitRequest, id, limitRequest]);

  const handleDeactivate = useCallback(async () => {
    try {
      setLoading((previousLoading) => ({
        ...previousLoading,
        deactivating: true,
      }));

      await service.hubCreditManager.deactivateLimit({
        limitId: limitRequest?.limitId,
      });
      getLimitRequest(id);

      OToastManager.success(`Limite desativado com sucesso`);
    } catch {
      OToastManager.danger(
        `Houve um erro ao inativar o limite. Tente novamente.`,
      );
    } finally {
      setLoading((previousLoading) => ({
        ...previousLoading,
        deactivating: false,
      }));
    }
  }, [getLimitRequest, id, limitRequest]);

  const handleApproval = useCallback(
    async (approval: "REJECTED" | "APPROVED") => {
      try {
        setLoading((previousLoading) => ({
          ...previousLoading,
          approval: true,
        }));

        await service.hubCreditManager.approvalLimits({
          approver: {
            name: user.name ?? "",
            taxId: user.cpf ?? "",
          },
          limitRequestId: +id,
          approval,
        });

        const message = approval === "APPROVED" ? "aprovado" : "reprovado";

        OToastManager.success(`Limite ${message} com sucesso`);

        resetField("status", {
          defaultValue: approval,
        });

        getLimitRequest(id);
      } catch {
        const message = approval === "APPROVED" ? "aprovar" : "reprovar";
        OToastManager.danger(
          `Houve um erro ao ${message} o limite. Tente novamente.`,
        );
      } finally {
        setLoading((previousLoading) => ({
          ...previousLoading,
          approval: false,
        }));
      }
    },
    [getLimitRequest, id, resetField, user.cpf, user.name],
  );

  const updateLimitRequest = useMemo(
    () =>
      handleSubmit(async (values) => {
        if (values.expirationDate && dirtyFields?.expirationDate) {
          try {
            setLoading((previousLoading) => ({
              ...previousLoading,
              expirationDate: true,
            }));

            await service.hubCreditManager.limitRequestsUpdateExpirationDate({
              limitRequestId: +id,
              newExpirationDate: values.expirationDate,
            });

            resetField("expirationDate", {
              defaultValue: values.expirationDate,
            });

            OToastManager.success("Data atualizada com sucesso!");

            getLimitRequest(id);
          } catch {
            OToastManager.danger(
              "Não foi possível atualizar a data, verifique os erros!",
            );
          } finally {
            setLoading((previousLoading) => ({
              ...previousLoading,
              expirationDate: false,
            }));
          }
        }

        if (values.status && dirtyFields?.status) {
          try {
            setLoading((previousLoading) => ({
              ...previousLoading,
              status: true,
            }));

            await service.hubCreditManager.limitRequestsUpdateStatus({
              limitRequestId: +id,
              newStatus: values.status,
            });

            resetField("status", {
              defaultValue: values.status,
            });

            OToastManager.success("Status atualizado com sucesso!");
          } catch (e) {
            OToastManager.warning(
              "Não foi possível atualizar o status, verifique os erros!",
            );
          } finally {
            setLoading((previousLoading) => ({
              ...previousLoading,
              status: false,
            }));
          }
        }
      }),
    [
      dirtyFields?.expirationDate,
      dirtyFields?.status,
      getLimitRequest,
      handleSubmit,
      id,
      resetField,
    ],
  );

  useEffect(() => {
    getLimitRequest(id);
  }, [getLimitRequest, id]);

  useEffect(() => {
    limitRequest &&
      reset({
        expirationDate: limitRequest.expirationDate,
        status: limitRequest.status,
      });
  }, [limitRequest, reset]);

  const promoveLimit = useMemo(
    () =>
      handleSubmit(async (values) => {
        if (values.expirationDate) {
          try {
            setLoading((previousLoading) => ({
              ...previousLoading,
              status: true,
            }));

            await service.hubCreditManager.limitRequestsInactivesUpdateCreatingLimitStatus(
              {
                limitRequestId: +id,
                newExpirationDate: values.expirationDate,
              },
            );

            OToastManager.success("Status atualizado com sucesso!");
            navigate(`/emprestimos/produto/gestor-de-limites/limites-inativos`);
          } catch (e) {
            OToastManager.warning(
              "Não foi possível atualizar o status, verifique os erros!",
            );
          } finally {
            setLoading((previousLoading) => ({
              ...previousLoading,
              status: false,
            }));
          }
        }
      }),
    [handleSubmit, id, navigate],
  );

  return (
    <DetailsTemplate
      pageTitle={<PageTitle title="Resumo" />}
      actions={
        <>
          <LoadingButton
            type="danger"
            loading={loading.deactivating || loading.status}
            onClick={() => handleDeactivate()}
          >
            Desativar Limite
          </LoadingButton>
          {(!limitRequest?.currentLimit || !limitRequest?.limitId) && (
            <LoadingButton
              loading={loading.expirationDate || loading.status}
              disabled={!expirationDate || expirationDate < today}
              onClick={promoveLimit}
            >
              Promover para vigente
            </LoadingButton>
          )}
          {limitRequest?.limitId &&
            limitRequest?.currentLimit &&
            limitRequest?.status === "PENDING_APPROVAL" && (
              <LoadingButton
                loading={loading.approval}
                type="success"
                onClick={() => handleApproval("APPROVED")}
                outline
              >
                Aprovar
              </LoadingButton>
            )}
          {limitRequest?.limitId &&
            limitRequest?.currentLimit &&
            limitRequest?.status !== "REJECTED" && (
              <LoadingButton
                loading={loading.approval}
                type="danger"
                onClick={() => handleApproval("REJECTED")}
                outline
              >
                Reprovar
              </LoadingButton>
            )}
          <LoadingButton
            loading={loading.recalculating || loading.status}
            onClick={() => handleReevaluate()}
          >
            Recalcular
          </LoadingButton>
          {limitRequest?.limitId && limitRequest?.currentLimit && (
            <LoadingButton
              loading={loading.expirationDate || loading.status}
              onClick={updateLimitRequest}
            >
              Salvar
            </LoadingButton>
          )}
        </>
      }
    >
      <div className="d-flex flex-column gap-2">
        <FormProvider {...form}>
          <DetailsCard
            loading={loadingLimitRequest}
            errorComponent={
              <ErrorComponent
                messageTitle="Não foi possível carregar os dados do limite."
                messageParagraph="Tente novamente mais tarde."
              >
                <TryAgainButton onClick={() => getLimitRequest(id)} />
              </ErrorComponent>
            }
            hasError={hasErrorLimitRequest}
            value={limitRequest}
            fields={limitDetailsGenerator}
          />
        </FormProvider>

        {limitRequest?.currentLimit?.customer.legalEntity.taxId && (
          <ReadinessStatus
            taxId={limitRequest?.currentLimit?.customer.legalEntity.taxId}
          />
        )}
      </div>
    </DetailsTemplate>
  );
};
