import dayjs from "dayjs";
import type { RowPreparedEvent } from "devextreme/ui/data_grid";

export const highlightNearExpirationDate = ({
  data,
  rowElement,
  rowType,
}: RowPreparedEvent<HubEnergy.DiligenceTaskODataResponse>) => {
  if (rowType === "data" && data?.stepExpirationDate) {
    const today = dayjs();
    const expirationDate = dayjs(data.stepExpirationDate);

    if (expirationDate.isBefore(today)) {
      rowElement.style.setProperty(
        "background-color",
        "var(--theme-danger-light-80)",
      );
      rowElement.style.setProperty(
        "color",
        "var(--theme-danger-light-inverse)",
      );
    } else if (expirationDate.diff(today, "days") < 7) {
      rowElement.style.setProperty(
        "background-color",
        "var(--theme-warning-light-80)",
      );
      rowElement.style.setProperty(
        "color",
        "var(--theme-warning-light-inverse)",
      );
    }
  }
};
