import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";
import { getProcessorPropertyFieldId } from "../../../utils";
import { GenericPropertyFieldProps } from "../../property-field.types";

export const useCreditLineField = ({
  processorConfigProperty: { processorProperty, id, value },
  handleSubmitProperty,
}: GenericPropertyFieldProps) => {
  const fieldInitialized = useRef(false);
  const { watch, setValue, reset } = useFormContext();
  const {
    value: creditLines,
    callService: getCreditLines,
    loading: loadingCreditLines,
    hasError: hasErrorCreditLines,
  } = useServiceCall(service.hubCreditManager.getCreditLines);

  const processorPropertyFieldId = getProcessorPropertyFieldId(
    processorProperty.name,
    id,
  );

  const fieldWatch = watch(processorPropertyFieldId);

  useEffect(() => {
    hasErrorCreditLines &&
      OToastManager.danger(
        "Não foi possivel buscar as linhas de crédito. Por favor, tente novamente mais tarde.",
      );
  }, [hasErrorCreditLines]);

  useEffect(() => {
    if (!creditLines) {
      getCreditLines();
      return;
    }

    if (!value) return;
    setValue(processorPropertyFieldId, value);
  }, [
    creditLines,
    id,
    processorProperty,
    value,
    getCreditLines,
    reset,
    setValue,
  ]);

  useEffect(() => {
    if (!fieldInitialized.current) {
      fieldInitialized.current = true;
      return;
    }

    if (!fieldWatch || fieldWatch === value) return;

    handleSubmitProperty(fieldWatch, id);
  }, [fieldWatch, processorProperty, handleSubmitProperty, id, value]);

  return { creditLines, loadingCreditLines };
};
