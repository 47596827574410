import { OCol, OOptionMultiple, ORFieldSelectMultiple } from "@maestro/react";

interface FilesForReceivablesFieldProps {
  disabled?: boolean;
}

const filesForReceivablesOptions = [
  "Nota",
  "Contrato",
  "Garantia",
  "Adesao",
  "Auxiliar",
];

export const FilesForReceivablesField = ({ disabled }: FilesForReceivablesFieldProps) => {
  return (
    <OCol xs={12} md={6}>
      <ORFieldSelectMultiple
        dataAction="geral:texto:arquivos_bordero"
        dataLabel="arquivos_bordero"
        id="filesForReceivables"
        name="filesForReceivables"
        label="Arquivos obrigatórios para o borderô"
        disabled={disabled}
      >
        {filesForReceivablesOptions.map((item) => (
          <OOptionMultiple key={item} value={item}>
            {item}
          </OOptionMultiple>
        ))}
      </ORFieldSelectMultiple>
    </OCol>
  );
};
