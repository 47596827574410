import { OToastManager } from "@maestro/core";
import { dataSourceCustomStoreGenerator } from "components/data-grid";
import DataSource from "devextreme/data/data_source";
import { useServiceCall } from "hooks/service-call";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { service } from "services";
import { ILimitBag, ILimitBagsResponse } from "services/limit-control/models/responses";
import { CedenteSearchForm } from "./compose/search-modal";

export interface LimiteCedenteContext {
  sacadosResponse: { label: string; value: string; customKey: number }[];
  sacadosHasError: boolean;
  sacadosLoading: boolean;
  buscaCedente: CedenteSearchForm | undefined;
  setBuscaCedente: React.Dispatch<
    React.SetStateAction<CedenteSearchForm | undefined>
  >;
  dataSource: DataSource<ILimitBag>;
}

interface LimiteCedenteProps {
  children: React.ReactNode;
}
const limiteCedenteContext = createContext({} as LimiteCedenteContext);

export const LimiteCedenteProvider = ({ children }: LimiteCedenteProps) => {
  const [buscaCedente, setBuscaCedente] = useState<CedenteSearchForm>();
  const {
    callService: listSacados,
    hasError: sacadosHasError,
    loading: sacadosLoading,
    value: sacadosFull,
  } = useServiceCall(service.quickfin.listSacados);


  const sacadosResponse = useMemo(
    () =>
      sacadosFull?.value.map((sacado, index) => ({
        label: sacado.OfficialName ?? "",
        value: sacado.Cnpj ?? "",
        customKey: index,
      })) ?? [],
    [sacadosFull?.value],
  );


  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator<ILimitBagsResponse["limitBags"][number]>(
        async (loadOptions) => {
          const body = {
            skip: loadOptions.skip ?? 0,
            take: loadOptions.take ?? 50,
            depth: "0",
            search: buscaCedente?.cedentes
          };

          return service.quickfin
            .getCartaoLimits(body)
            .then(({ data }) => ({
              data: data.limitBags?.filter(x => x.id.startsWith("Cartao:V2")) || [],
              totalCount: data.totalCount || 0,
            }))
            .catch(() => {
              const errorMessage = "Erro ao buscar os limites";
              OToastManager.danger(errorMessage);
              throw new Error(errorMessage);
            });
        },
      ),
    [buscaCedente],
  );

  useEffect(() => {
    listSacados();
  }, [listSacados]);


  const value = useMemo(
    () => ({
      sacadosResponse,
      sacadosHasError,
      sacadosLoading,
      buscaCedente,
      setBuscaCedente,
      dataSource
    }),
    [sacadosResponse, sacadosHasError, sacadosLoading, buscaCedente, setBuscaCedente, dataSource],
  );

  return (
    <limiteCedenteContext.Provider value={value}>
      {children}
    </limiteCedenteContext.Provider>
  );
};

export const useLimiteCedenteContext = () => useContext(limiteCedenteContext);
