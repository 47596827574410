export const operationStatusLibrary: Record<string, string> = {
  Pending: "Pendente",
  PartiallyExecuted: "Parcialmente processado",
  Completed: "Desembolsado",
  WaitingContractSignature: "Assinatura pendente",
  WaitingSacadoApproval: "Aguardando aprovação do sacado",
  Canceled: "Recusado",
  WaitingSolicitation: "Aguardando envio para desembolso",
  InDisbursement: "Em desembolso",
  PartiallySigned: "Parcialmente assinada",
  PartiallyCanceled: "Parcialmente recusado",
  PartiallyDisbursed: "Parcialmente desembolsado",
  DisbursedWithSomeCancels: "Desembolsado com algumas recusas",
  PartiallyDisbursedWithSomeCancels:
    "Parcialmente desembolsado com algumas recusas",
  WaitingRegister: "Aguardando registro",
};

export const operationTintMap: {
  [key: string]: "warning-light" | "success-light" | "danger-light";
} = {
  Pending: "warning-light",
  PartiallyExecuted: "success-light",
  Completed: "success-light",
  WaitingContractSignature: "warning-light",
  WaitingSacadoApproval: "warning-light",
  Canceled: "danger-light",
  WaitingSolicitation: "warning-light",
  InDisbursement: "warning-light",
  PartiallySigned: "warning-light",
  PartiallyCanceled: "success-light",
  PartiallyDisbursed: "success-light",
  DisbursedWithSomeCancels: "success-light",
  PartiallyDisbursedWithSomeCancels: "success-light",
  WaitingRegister: "warning-light",
};
