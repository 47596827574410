import { YesNoRadioField } from "./yes-no-radio-field.component";

export const OfertaCCCField = () => {
  return (
    <YesNoRadioField
      label="Notificações diárias de oferta"
      name="reporteDiarioCCC"
      description="E-mail de notificação ao cedente das ofertas disponíveis"
    />
  );
};
