import { OToastManager } from "@maestro/react";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useState } from "react";
import { service } from "services";
import {
  OutputConfigBigNumberItem,
  OutputConfigStatusItem,
} from "services/hubcreditmanager/models/requests";
import { ProcessorConfigView } from "services/hubcreditmanager/models/types/workflow/workflow.types";
import { useWorkflow } from "../../../../../../../../../../../../components/hooks/use-workflow.context";
import { ProcessorConfigMonitoringContentProps } from "./processor-config-view.types";
import {
  getOutputComponentsIds,
  getSimultaneousMonitoringId,
} from "./processor-config-view.utils";

export const useProcessorConfigMonitoringView = ({
  workflowConfigView,
  form,
}: ProcessorConfigMonitoringContentProps) => {
  if (!workflowConfigView) throw new Error("WorkflowConfigView not found");

  const {
    bigNumbers: { value: bigNumbers },
    configurableStatus: { value: configurableStatus },
    processorConfig: { value: processorConfig },
  } = useWorkflow();

  const { getValues, setValue } = form;

  const simultaneousMonitoringId = getSimultaneousMonitoringId(
    workflowConfigView.view,
  );

  const [currentProcessorConfigView, setCurrentProcessorConfigView] =
    useState<ProcessorConfigView>();
  const [saving, setSaving] = useState(false);

  const {
    callService: callUpsertSimultaneousProcessorConfig,
    loading: loadingUpsertSimultaneousProcessorConfig,
  } = useServiceCall(
    service.hubCreditManager.upsertSimultaneousProcessorConfig,
  );

  const {
    callService: callUpsertOutputConfigBigNumbers,
    loading: loadingUpsertOutputConfigBigNumbers,
  } = useServiceCall(service.hubCreditManager.upsertOutputConfigBigNumbers);

  const {
    callService: callUpsertOutputConfigStatus,
    loading: loadingUpsertOutputConfigStatus,
  } = useServiceCall(service.hubCreditManager.upsertOutputConfigStatus);

  const { callService: getProcessorConfig } = useServiceCall(
    service.hubCreditManager.getProcessorConfigDetails,
  );

  const upsertProcessorConfigSimultaneousMonitoring = useCallback(
    async ({
      simultaneousProcessorConfigIds,
      workflowConfigViewId,
      processorConfigId,
    }: {
      simultaneousProcessorConfigIds: number[];
      workflowConfigViewId: number;
      processorConfigId: number;
    }) => {
      const { error } = await callUpsertSimultaneousProcessorConfig({
        processorConfigId,
        workflowConfigViewId,
        simultaneousProcessorConfigIds,
      });

      if (error) {
        OToastManager.warning(
          "Um erro ocorreu ao tentar salvar a configuração de monitoramento simultâneo.",
        );
      }
    },
    [callUpsertSimultaneousProcessorConfig],
  );

  const upsertOutputConfigStatus = useCallback(
    async ({
      workflowConfigViewId,
      processorConfigId,
      outputConfigs,
    }: {
      workflowConfigViewId: number;
      processorConfigId: number;
      outputConfigs: {
        configurableStatusId: number | null;
        outputId: number;
      }[];
    }) => {
      const { error } = await callUpsertOutputConfigStatus({
        processorConfigId,
        workflowConfigViewId,
        outputConfigs,
      });

      if (error) {
        OToastManager.warning(
          "Um erro ocorreu ao tentar salvar uma das saídas desta configuração de visualização.",
        );
      }
    },
    [callUpsertOutputConfigStatus],
  );

  const upsertOutputConfigBigNumbers = useCallback(
    async ({
      outputConfigs,
      workflowConfigViewId,
      processorConfigId,
    }: {
      workflowConfigViewId: number;
      processorConfigId: number;
      outputConfigs: {
        bigNumberIds: number[];
        outputId: number;
      }[];
    }) => {
      const { error } = await callUpsertOutputConfigBigNumbers({
        processorConfigId,
        workflowConfigViewId,
        outputConfigs,
      });

      if (error) {
        OToastManager.warning(
          "Um erro ocorreu ao tentar salvar os big numbers desta configuração.",
        );
      }
    },
    [callUpsertOutputConfigBigNumbers],
  );

  const updateProcessorConfigViewProperties = useCallback(async () => {
    if (!processorConfig?.processor.outputs) return;

    try {
      setSaving(true);

      const outputConfigStatus: OutputConfigStatusItem[] = [];
      const outputConfigBigNumbers: OutputConfigBigNumberItem[] = [];

      processorConfig.processor.outputs.forEach((output) => {
        const outputComponentsIds = getOutputComponentsIds(
          workflowConfigView.view,
          output.id,
        );

        const statusId = getValues(outputComponentsIds.status);

        outputConfigStatus.push({
          configurableStatusId: statusId > 0 ? statusId : null,
          outputId: output.id,
        });

        const bigNumberIds = getValues(outputComponentsIds.bigNumber).map(
          (id: string) => Number(id),
        );

        outputConfigBigNumbers.push({
          bigNumberIds,
          outputId: output.id,
        });
      });

      // Chamadas para a API em série para não criar múltiplas entidades
      await upsertProcessorConfigSimultaneousMonitoring({
        simultaneousProcessorConfigIds: getValues(simultaneousMonitoringId).map(
          (id: string) => Number(id),
        ),
        workflowConfigViewId: workflowConfigView.id,
        processorConfigId: Number(processorConfig?.id),
      });

      await upsertOutputConfigStatus({
        outputConfigs: outputConfigStatus,
        workflowConfigViewId: workflowConfigView.id,
        processorConfigId: Number(processorConfig?.id),
      });

      await upsertOutputConfigBigNumbers({
        outputConfigs: outputConfigBigNumbers,
        workflowConfigViewId: workflowConfigView.id,
        processorConfigId: Number(processorConfig?.id),
      });

      getProcessorConfig(processorConfig.id);

      OToastManager.success("Configuração de monitoramento salva com sucesso.");
    } catch {
      OToastManager.danger(
        "Um erro ocorreu ao tentar salvar a configuração de monitoramento. Por favor, tente novamente mais tarde.",
      );
    } finally {
      setSaving(false);
    }
  }, [
    processorConfig,
    simultaneousMonitoringId,
    workflowConfigView,
    getProcessorConfig,
    getValues,
    upsertOutputConfigBigNumbers,
    upsertOutputConfigStatus,
    upsertProcessorConfigSimultaneousMonitoring,
  ]);

  useEffect(() => {
    if (!processorConfig || !workflowConfigView) return;

    setValue(getSimultaneousMonitoringId(workflowConfigView.view), []);

    processorConfig.processor.outputs.forEach((output) => {
      const outputComponentIds = getOutputComponentsIds(
        workflowConfigView.view,
        output.id,
      );

      setValue(outputComponentIds.status, 0);
      setValue(outputComponentIds.bigNumber, []);
    });
  }, [processorConfig, workflowConfigView, setValue]);

  useEffect(() => {
    if (!processorConfig || !workflowConfigView) return;

    processorConfig.processor.outputs.forEach((output) => {
      const outputComponentIds = getOutputComponentsIds(
        workflowConfigView.view,
        output.id,
      );

      setValue(outputComponentIds.type, output.type);
    });
  }, [processorConfig, setValue, workflowConfigView]);

  useEffect(() => {
    if (!processorConfig || !workflowConfigView.view.type) return;

    const currentProcessorConfigViewAux =
      processorConfig.processorConfigViews.find(
        (pcView) =>
          pcView.workflowConfigView.view.type === workflowConfigView.view.type,
      );

    if (currentProcessorConfigViewAux)
      setCurrentProcessorConfigView(currentProcessorConfigViewAux);
  }, [processorConfig, setValue, workflowConfigView]);

  useEffect(() => {
    if (!processorConfig || !workflowConfigView || !currentProcessorConfigView)
      return;

    const simultaneousMonitoringIdData = getSimultaneousMonitoringId(
      workflowConfigView.view,
    );

    setValue(
      simultaneousMonitoringIdData,
      currentProcessorConfigView.simultaneousProcessorConfigs.map((pc) =>
        String(pc.monitoringProcessorConfig.id),
      ),
    );

    // Dados das saídas e status
    currentProcessorConfigView.outputConfigStatus.forEach(
      (outputConfigStatus) => {
        const outputComponentIds = getOutputComponentsIds(
          workflowConfigView.view,
          outputConfigStatus.processorOutput.id,
        );

        setValue(
          outputComponentIds.status,
          outputConfigStatus.configurableStatus?.id ?? 0,
        );
      },
    );

    currentProcessorConfigView.outputConfigBigNumbers.forEach(
      (outputBigNumber) => {
        setValue(
          getOutputComponentsIds(
            workflowConfigView.view,
            outputBigNumber.processorOutput.id,
          ).bigNumber,
          outputBigNumber.bigNumbers.map((bigNumber) => String(bigNumber.id)),
        );
      },
    );
  }, [
    currentProcessorConfigView,
    setValue,
    workflowConfigView,
    processorConfig,
  ]);

  return {
    bigNumbers,
    configurableStatus,
    loadingUpsertOutputConfigBigNumbers,
    loadingUpsertOutputConfigStatus,
    loadingUpsertSimultaneousProcessorConfig,
    processorConfig,
    saving,
    simultaneousMonitoringId,
    updateProcessorConfigViewProperties,
  };
};
