import {
  OCard,
  OCardBody,
  OCardHeader,
  OCheckbox,
  OCol,
  OLabel,
  OOption,
  ORFieldCheckboxGroup,
  ORFieldInput,
  ORFieldSelect,
  ORow,
  OTypography,
} from "@maestro/react";
import { maximumDurationTypeMap } from "../../../../../../utils";
import {
  DependencySelector,
  PendenciesGrid,
  PendencyTypeSelector,
  PropertiesGrid,
  StepTypeSelector,
  usePendencyTypeSelector,
} from "./_compose";
import { checkboxValue, stepConfigFormFields } from "./step-config-form.form";

export const StepConfigFormComponent = () => {
  const {
    getPendencyTypes,
    hasFetchError,
    loading,
    pendencyTypeOptions,
    pendencyTypes,
  } = usePendencyTypeSelector();

  return (
    <div className="d-flex flex-column gap-4">
      <OCard>
        <OCardHeader>
          <OTypography>Dados gerais</OTypography>
        </OCardHeader>
        <OCardBody>
          <ORow>
            <OCol xs={12} lg={6}>
              <ORFieldInput {...stepConfigFormFields.name} />
            </OCol>
          </ORow>
          <ORow rowGap={5}>
            <OCol xs={12} sm="auto">
              <ORFieldCheckboxGroup {...stepConfigFormFields.isPublic}>
                <div className="d-flex align-items-center gap-2">
                  <OCheckbox
                    id="isPublic-checkbox"
                    value={checkboxValue}
                    size="sm"
                  />
                  <OLabel htmlFor="isPublic-checkbox">Público</OLabel>
                </div>
              </ORFieldCheckboxGroup>
            </OCol>
            <OCol xs={12} lg={6}>
              <ORFieldCheckboxGroup {...stepConfigFormFields.needsPreApproval}>
                <div className="d-flex align-items-center gap-2">
                  <OCheckbox
                    id="needsPreApproval-checkbox"
                    value={checkboxValue}
                    size="sm"
                  />
                  <OLabel htmlFor="needsPreApproval-checkbox">
                    Precisa de pré-aprovação
                  </OLabel>
                </div>
              </ORFieldCheckboxGroup>
            </OCol>
          </ORow>
          <ORow>
            <OCol xs={12} lg={6}>
              <ORFieldInput {...stepConfigFormFields.maximumDuration} />
            </OCol>
            <OCol xs={12} lg={6}>
              <ORFieldSelect {...stepConfigFormFields.maximumDurationType}>
                {Object.entries(maximumDurationTypeMap).map(
                  ([value, label]) => (
                    <OOption key={value} value={value}>
                      {label}
                    </OOption>
                  ),
                )}
              </ORFieldSelect>
            </OCol>
          </ORow>
          <ORow align="start">
            <OCol xs={12} lg={6}>
              <StepTypeSelector
                id="typeId"
                field="typeId"
                label="Tipo de step"
              />
            </OCol>
          </ORow>
          <ORow align="start">
            <OCol xs={12} lg={6}>
              <DependencySelector
                id="dependencyTypeIds"
                field="dependencyTypeIds"
                label="Depende de"
              />
            </OCol>
            <OCol xs={12} lg={6}>
              <PendencyTypeSelector
                id="typeId"
                getPendencyTypes={getPendencyTypes}
                hasFetchError={hasFetchError}
                loading={loading}
                pendencyTypeOptions={pendencyTypeOptions}
              />
            </OCol>
          </ORow>
        </OCardBody>
      </OCard>
      <OCard>
        <OCardHeader>
          <OTypography>Pendências iniciais</OTypography>
        </OCardHeader>
        <OCardBody>
          <PendenciesGrid pendencyTypes={pendencyTypes} />
        </OCardBody>
      </OCard>
      <OCard>
        <OCardHeader>
          <OTypography>Propriedades</OTypography>
        </OCardHeader>
        <OCardBody>
          <PropertiesGrid />
        </OCardBody>
      </OCard>
    </div>
  );
};
