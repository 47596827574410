import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { masks } from "@maestro/utils";
import dayjs from "dayjs";
import debounce from "lodash/debounce";
import moment from "moment";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FormProvider, UseFormReturn, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services";
import { mapCurrencySymbol } from "utils/currency";
import { useGuarantorsSetup } from "../../../offers-guarantors-hook.component";
import { BankAccount } from "../../../offers.type";
import { useSummary } from "./simulation-summary.hook";
import { formDefaultValues, validationSchema } from "./simulation.form";
import {
  ProductItemInterface,
  SimulationFormFields,
  SimulationPayload,
  SimulationResult,
  SimulationSummary,
  TermOption,
} from "./simulation.types";
import { formatValue } from "./simulation.utils";

export interface SimulationContextInterface {
  disabledDates: (current: moment.Moment) => boolean;
  form: UseFormReturn<SimulationFormFields>;
  hideForm: boolean;
  loading: boolean;
  summaryLoading: boolean;
  summary: SimulationSummary | undefined;
  simulationResult: SimulationResult[];
  selectedSimulationResult?: SimulationResult;
  termOptions: TermOption[];
  isAvailable: boolean;
  bankAccountsList: BankAccount[];
  setBankAccountsList: React.Dispatch<React.SetStateAction<BankAccount[]>>;
  selectedBank: BankAccount | undefined;
  setSelectedBank: React.Dispatch<
    React.SetStateAction<BankAccount | undefined>
  >;
  simulationProduct: ProductItemInterface | undefined;
  setSimulationProduct: React.Dispatch<
    React.SetStateAction<ProductItemInterface | undefined>
  >;
  selectedSettlementBank?: BankAccount;
  handleChangeSettlementBank: (e: string) => void;
  selectedSettlementType?: string;
  setSettlementType: React.Dispatch<React.SetStateAction<string>>;
}

interface SimulationProps {
  children: ReactNode;
}

export const SimulationContext = createContext(
  {} as SimulationContextInterface,
);

export const SimulationProvider = ({ children }: SimulationProps) => {
  const { type, id } = useParams<{ type: string; id: string }>();

  const [loading, setLoading] = useState(false);
  const [simulationResult, setSimulationResult] = useState<SimulationResult[]>(
    [],
  );
  const [termOptions, setTermOptions] = useState<TermOption[]>([]);
  const [selectedBank, setSelectedBank] = useState<BankAccount | undefined>();
  const [bankAccountsList, setBankAccountsList] = useState<BankAccount[]>([]);
  const [simulationProduct, setSimulationProduct] = useState<
    ProductItemInterface | undefined
  >();
  const [isValidAmount, SetIsValidAmount] = useState<boolean>(false);
  const [selectedSettlementBank, setSelectedSettlementBank] = useState<
    BankAccount | undefined
  >();
  const [selectedSettlementType, setSettlementType] =
    useState<string>("DebitoEmConta");

  const initialized = useRef(false);
  const amountRef = useRef<number | undefined>(undefined);
  const firstPaymentDateRef = useRef<string>();

  const { setGuarantorsSetup, guarantors } = useGuarantorsSetup();
  const { summary, hideForm, summaryLoading } = useSummary({
    type: type?.toUpperCase(),
    id,
  });

  const form = useForm<SimulationFormFields>({
    resolver: yupResolver(validationSchema(summary, type)),
    defaultValues: formDefaultValues,
  });

  const { getValues, setValue, watch, trigger } = form;

  const amountWatch = watch("amount");
  const firstPaymentDateWatch = watch("firstPaymentDate");
  const termWatch = watch("termOptions");

  const selectedSimulationResult = useMemo(
    () => simulationResult.find((s) => s.identification === termWatch),
    [simulationResult, termWatch],
  );

  const isAvailable = useMemo(() => {
    const allGuarantorsAreApproved =
      !selectedSimulationResult?.guarantors?.requiredGuarantors?.length ||
      guarantors?.every((guarantor) => guarantor.portalStatus === "CONCLUIDO");

    return (
      !!isValidAmount &&
      !!allGuarantorsAreApproved &&
      !!selectedSimulationResult &&
      (!!selectedBank ||
        !!simulationResult[0]?.disbursementInstructions.length) &&
      !!selectedSettlementType
    );
  }, [
    selectedSimulationResult,
    guarantors,
    selectedBank,
    simulationResult,
    isValidAmount,
    selectedSettlementType,
  ]);

  const handleChangeSettlementBank = useCallback(
    (value: string) => {
      const selectedSettlementBankAccount = bankAccountsList.find(
        (b) => b.id.toString() === value,
      );

      if (selectedSettlementBankAccount) {
        setSelectedSettlementBank(selectedSettlementBankAccount);
      }
    },
    [bankAccountsList],
  );

  const disabledDates = useCallback(
    (current: moment.Moment) => {
      if (
        !!summary?.paymentDatesIntervals.length &&
        summary.isFirstPaymentDateChangeAvailable
      ) {
        return !summary?.paymentDatesIntervals.some((interval) =>
          current.isBetween(
            interval.minDate,
            moment(interval.maxDate).endOf("day"),
            undefined,
            "[]",
          ),
        );
      }
      return false;
    },
    [summary],
  );

  const handleSimulation = useCallback(async () => {
    const isValid = await trigger();
    SetIsValidAmount(isValid);

    if (!isValid) return;

    if (summary && type) {
      const { amount, firstPaymentDate } = getValues();

      const payload: SimulationPayload = {
        amount: formatValue(amount.toString()),
        product: type.toUpperCase(),
        firstPaymentDate: firstPaymentDate
          ? dayjs(firstPaymentDate).format("YYYY-MM-DD")
          : null,
      };

      try {
        setLoading(true);

        const { data } = await service.hubLoan.postSimulation(payload);

        setSimulationResult(data);

        if (!initialized.current) {
          data.sort((a, b) => b.term - a.term);

          setValue("termOptions", data[0].identification);

          const parsedFirstPaymentDate = data[0].settlement.installments.sort(
            (a, b) => a.code - b.code,
          )?.[0]?.maturityDate;

          setValue(
            "firstPaymentDate",
            dayjs(parsedFirstPaymentDate).format("YYYY-MM-DD"),
          );
          initialized.current = true;
        }

        if (data[0]?.guarantors?.requiredGuarantors?.length)
          setGuarantorsSetup(data[0].guarantors);
      } catch (e) {
        OToastManager.danger(
          "Falha no carregamento das parcelas. Por favor, tente novamente mais tarde.",
        );
      } finally {
        setLoading(false);
      }
    }
  }, [summary, type, getValues, setGuarantorsSetup, setValue, trigger]);

  const debouncedSearch = useMemo(() => {
    return debounce(handleSimulation, 1000);
  }, [handleSimulation]);

  useEffect(() => {
    if (summary && !initialized.current) {
      setValue("amount", summary.limit);
      handleSimulation();
    }
  }, [handleSimulation, setValue, summary]);

  useEffect(() => {
    if (firstPaymentDateWatch === undefined) return;

    if (
      !!firstPaymentDateRef.current &&
      firstPaymentDateRef.current !== firstPaymentDateWatch
    ) {
      handleSimulation();
    }

    firstPaymentDateRef.current = firstPaymentDateWatch;
  }, [
    simulationResult,
    setValue,
    firstPaymentDateWatch,
    getValues,
    handleSimulation,
  ]);

  useEffect(() => {
    if (!simulationResult.length) return;
    const options: TermOption[] = [];

    simulationResult.sort((a, b) => b.term - a.term);
    simulationResult.forEach((simulation) => {
      options.push({
        value: simulation.identification,
        amount: `${masks.currency(
          simulation.settlement.installments[0].amount,
          mapCurrencySymbol(simulation.currency),
          ".",
        )}`,
        rate: `com taxa de ${
          simulation.interest.indexCode === "CDIE" ? "CDI + " : ""
        }${masks.percentage(simulation.rate)} (a.m)`,
        terms:
          simulation.term < 10
            ? `0${simulation.term} x`
            : `${simulation.term} x`,
      });
    });

    setValue("termOptions", simulationResult[0].identification);
    setTermOptions(options);
  }, [simulationResult, setTermOptions, setValue]);

  useEffect(() => {
    if (amountWatch === undefined) return;

    if (!!amountRef.current && amountRef.current !== amountWatch) {
      debouncedSearch();
      amountRef.current = amountWatch;

      return () => {
        debouncedSearch.cancel();
      };
    }

    amountRef.current = amountWatch;
  }, [amountWatch, debouncedSearch]);

  useEffect(() => {
    if (summary?.allowedSettlementTypes.length === 1) {
      setSettlementType(summary.allowedSettlementTypes[0]);
    }
  }, [setSettlementType, summary?.allowedSettlementTypes]);

  const contextValue = useMemo(
    () => ({
      form,
      summary,
      disabledDates,
      loading,
      summaryLoading,
      simulationResult,
      termOptions,
      hideForm,
      selectedSimulationResult,
      guarantors,
      isAvailable,
      bankAccountsList,
      setBankAccountsList,
      selectedBank,
      setSelectedBank,
      simulationProduct,
      setSimulationProduct,
      selectedSettlementBank,
      handleChangeSettlementBank,
      selectedSettlementType,
      setSettlementType,
    }),
    [
      form,
      summary,
      disabledDates,
      loading,
      summaryLoading,
      simulationResult,
      termOptions,
      hideForm,
      selectedSimulationResult,
      guarantors,
      isAvailable,
      bankAccountsList,
      selectedBank,
      simulationProduct,
      selectedSettlementBank,
      handleChangeSettlementBank,
      selectedSettlementType,
    ],
  );

  return (
    <SimulationContext.Provider value={contextValue}>
      <FormProvider {...form}>{children}</FormProvider>
    </SimulationContext.Provider>
  );
};

export const useSimulation = () => useContext(SimulationContext);
