import * as yup from "yup";

export interface DeskAccountConfigFormValues {
  deskOnboardingAccountInfoUuid: string;
  deskOnboardingConfigIdentifier: string;
  shouldBeAvailableOnBtgEmpresas: boolean;
  defaultToOpen: boolean;
  managedPortfolio: boolean;
  optinTemplate?: string | null;
  shouldOverrideDeskConfig: boolean;
  officerId?: number | null;
  segmentGroupId?: number | null;
  newSegmentGroupId?: number | null;
  businessCategory?: string | null;
  isPrimary: boolean;
  shouldUseOnAdmin: boolean;
  possibleAccountsToOpenQuantity: number;
  obligatoryHasRelationships: boolean;
  contractFileConfigIds: string[];
  isActivatedOn?: string | null;
}

export const deskAccountConfigFormValidationSchema: yup.ObjectSchema<DeskAccountConfigFormValues> =
  yup.object({
    deskOnboardingAccountInfoUuid: yup
      .string()
      .required("Este campo é obrigatório"),
    deskOnboardingConfigIdentifier: yup
      .string()
      .required("Este campo é obrigatório"),
    shouldBeAvailableOnBtgEmpresas: yup
      .mixed((input): input is boolean => typeof input === "boolean")
      .transform(
        (value) => ({ true: true, false: false }[value as string] ?? null),
      )
      .required("Este campo é obrigatório"),
    defaultToOpen: yup
      .mixed((input): input is boolean => typeof input === "boolean")
      .transform(
        (value) => ({ true: true, false: false }[value as string] ?? null),
      )
      .required("Este campo é obrigatório"),
    managedPortfolio: yup
      .mixed((input): input is boolean => typeof input === "boolean")
      .transform(
        (value) => ({ true: true, false: false }[value as string] ?? null),
      )
      .required("Este campo é obrigatório"),
    optinTemplate: yup
      .string()
      .transform((value) => value || null)
      .optional()
      .nullable(),
    shouldOverrideDeskConfig: yup
      .mixed((input): input is boolean => typeof input === "boolean")
      .transform(
        (value) => ({ true: true, false: false }[value as string] ?? null),
      )
      .required("Este campo é obrigatório"),
    obligatoryHasRelationships: yup
      .mixed((input): input is boolean => typeof input === "boolean")
      .transform(
        (value) => ({ true: true, false: false }[value as string] ?? null),
      )
      .required("Este campo é obrigatório"),
    possibleAccountsToOpenQuantity: yup
      .number()
      .required("Este campo é obrigatório"),
    officerId: yup
      .number()
      .transform((value) => value || undefined)
      .optional()
      .nullable(),
    segmentGroupId: yup
      .number()
      .transform((value) => value || undefined)
      .optional()
      .nullable(),
    newSegmentGroupId: yup
      .number()
      .transform((value) => value || null)
      .optional()
      .nullable(),
    businessCategory: yup
      .string()
      .transform((value) => value || null)
      .optional()
      .nullable(),
    isPrimary: yup
      .mixed((input): input is boolean => typeof input === "boolean")
      .transform(
        (value) => ({ true: true, false: false }[value as string] ?? null),
      )
      .required("Este campo é obrigatório"),
    shouldUseOnAdmin: yup
      .mixed((input): input is boolean => typeof input === "boolean")
      .transform(
        (value) => ({ true: true, false: false }[value as string] ?? null),
      )
      .required("Este campo é obrigatório"),
    contractFileConfigIds: yup.array().required("Este campo é obrigatório"),
    isActivatedOn: yup.string().nullable(),
  });
