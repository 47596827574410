import { useServiceCall } from "hooks/service-call";
import uniqBy from "lodash/uniqBy";
import debounce from "lodash/debounce";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useRoles } from "hooks/roles";
import { useForm, useFormContext } from "react-hook-form";
import { service } from "services";
import { customer } from "contexts/customer";
import { SelectedCompanies } from "./company-search.types";
import {
  buildCompanyOptions,
  cleanCompanies,
  generateAggregates,
} from "./company-search.utils";

export const useCompanySearch = (mode: "Sacado" | "Cedente", name: string) => {
  const { hasRole } = useRoles();

  const addTipoProdutoToPayload = useMemo(() => {
    return hasRole("RecebiveisCartao:Agendas.Admin");
  }, [hasRole]);

  const initialSacados: SelectedCompanies[] = [
    {
      identification: "01425787000104",
      label: "REDECARD INSTITUICAO DE PAGAMENTO S.A.",
    },
    {
      identification: "10440482000154",
      label:
        "GETNET ADQUIRENCIA E SERVICOS PARA MEIOS DE PAGAMENTO S.A. - INSTITUICAO DE PAGAMENTO",
    },
    {
      identification: "01027058000191",
      label: "CIELO S.A - INSTITUICAO DE PAGAMENTO",
    },
  ];
  const [selected, setSelected] = useState<SelectedCompanies[]>(
    mode === "Cedente"
      ? [
          {
            identification: customer.value?.identification || "",
            label: customer.value?.officialName || "",
          },
        ]
      : initialSacados,
  );

  const { callService, hasError, loading, value } = useServiceCall(
    service.quickfin.getAllCompanies,
  );

  const outerForm = useFormContext();

  const {
    setValue: outerSetValue,
    formState: {
      errors: { [name]: outerError },
    },
  } = outerForm;

  const localForm = useForm<{ input: string | undefined }>();

  const {
    watch: localWatch,
    setError: localSetError,
    setValue: localSetValue,
    clearErrors: localClearErrors,
  } = localForm;

  const debouncedGetCompanies = useMemo(
    () => debounce(callService, 500),
    [callService],
  );

  const inputWatcher = localWatch("input");

  const selectCompany = useCallback((identification: string, label: string) => {
    setSelected((oldSelected) =>
      uniqBy(
        [
          ...oldSelected,
          {
            identification,
            label,
          },
        ],
        "identification",
      ),
    );
  }, []);

  const removeCompany = useCallback((identification: string) => {
    setSelected((oldSelected) =>
      oldSelected.filter(
        (company) => company.identification !== identification,
      ),
    );
  }, []);

  const hasntFetched =
    value === undefined && loading === false && hasError === false;

  useEffect(() => {
    if (
      (mode === "Sacado" && hasntFetched) ||
      (mode === "Cedente" && inputWatcher)
    ) {
      debouncedGetCompanies({
        CompanyType: mode,
        CompanyFilter: inputWatcher,
        TipoProduto: addTipoProdutoToPayload ? undefined : "Cartao",
      });
    }
  }, [
    debouncedGetCompanies,
    inputWatcher,
    mode,
    hasntFetched,
    addTipoProdutoToPayload,
  ]);

  useEffect(() => {
    if (hasError)
      localSetError("input", {
        message: "Erro ao buscar as empresas",
      });
  }, [hasError, localSetError]);

  useEffect(() => {
    localSetValue("input", undefined);
    outerSetValue(
      name,
      selected.map((company) => company.identification),
    );
  }, [selected, localSetValue, outerSetValue, name]);

  useEffect(() => {
    if (outerError?.message) {
      localSetError("input", {
        message: outerError.message as string,
      });
    } else {
      localClearErrors("input");
    }
  }, [outerError, localSetError, localClearErrors]);

  const options = useMemo(() => {
    const cleanedCompanies = cleanCompanies(value);
    const aggregates =
      mode === "Cedente" ? generateAggregates(cleanedCompanies) : [];

    return buildCompanyOptions(
      [...aggregates, ...cleanedCompanies],
      selectCompany,
    );
  }, [mode, selectCompany, value]);

  return {
    loading,
    localForm,
    options,
    removeCompany,
    selected,
  };
};
