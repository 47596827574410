import { OTypography } from "@maestro/react";
import { Auth, auth } from "contexts/auth";
import { ChangeBehaviorCommand, behaviorManager } from "contexts/behavior";
import { Behaviors } from "contexts/behavior/types";
import { customerManager, GetCustomerCommand } from "contexts/customer";
import { GetUserCommand, useUnsafeUser, userManager } from "contexts/user";
import { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { logger } from "utils/logger";

export const SingleSignOnCallback = () => {
  const { user, isAuthenticated, error } = useAuth();
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);
  const { user: loggedUser } = useUnsafeUser();

  useEffect(() => {
    const load = async () => {
      if (isAuthenticated) {
        if (user) {
          auth.value = user.access_token;
          Auth.storage.set(user.access_token);
        }
        await behaviorManager.execute(
          new ChangeBehaviorCommand(Behaviors.Corban),
        );
        await userManager.execute(new GetUserCommand());
      }
    };

    load();
  }, [user, isAuthenticated]);

  useEffect(() => {
    if (loggedUser?.roles) {
      if (user?.state) {
        const { taxId } = user.state as { taxId?: string };
        if (taxId) {
          service.gatekeeper
            .decryptRmAdminTaxId({
              e_base64: taxId,
            })
            .then(({ data }) => {
              const arr = data.result.split(":");
              const kind = arr[0];
              const value = arr[1];
              return { kind, value };
            })
            .then(({ kind, value }) => {
              switch (kind) {
                case "cnpj":
                  return customerManager.execute(new GetCustomerCommand(value));
                default:
                  return Promise.reject(new Error("Invalid kind"));
              }
            })
            .then(() => {
              navigate(corporateRouter.routes.cadastro.customer.details.path);
            })
            .catch((err) => {
              logger.error(err);
              setIsError(true);
            });
        } else {
          navigate(corporateRouter.routes.workflowRmAdmin.product.path);
        }
      }
    }
  }, [loggedUser?.roles, navigate, user?.state]);

  if (error) {
    return (
      <div className="container py-4">
        <OTypography type="danger">Oops... {error.message}</OTypography>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="container py-4">
        <OTypography type="danger">
          Oops... Ocorreu um erro inesperado. Por favor, tente novamente mais
          tarde
        </OTypography>
      </div>
    );
  }

  return (
    <div className="container py-4">
      <OTypography tag="h1" type="dark" size="xl">
        Carregando...
      </OTypography>
    </div>
  );
};
