import { OCheckbox, OLabel, ORFieldCheckboxGroup } from "@maestro/react";
import { useMemo } from "react";
import { useBooleanPropertyField } from "./boolean-property-field.hook";
import { GenericPropertyFieldProps } from "../../property-field.types";
import { getProcessorPropertyFieldId } from "../../../utils";

export const BooleanPropertyField = ({
  processorConfigProperty,
  handleSubmitProperty,
}: GenericPropertyFieldProps) => {
  const { processorProperty, id } = processorConfigProperty;

  useBooleanPropertyField({
    processorConfigProperty,
    handleSubmitProperty,
  });

  const processorPropertyFieldId = getProcessorPropertyFieldId(
    processorProperty.name,
    id,
  );

  const genericPropertyField = useMemo(() => {
    return (
      <ORFieldCheckboxGroup
        key={processorProperty.name + id}
        id={processorPropertyFieldId}
        name={processorPropertyFieldId}
      >
        <div className="d-flex align-items-center gap-2 mb-2">
          <OCheckbox
            size="xs"
            id={`single-parent-checkbox-${processorProperty.name}`}
            value="true"
          />
          <OLabel htmlFor={`single-parent-checkbox-${processorProperty.name}`}>
            {processorProperty.description}
          </OLabel>
        </div>
      </ORFieldCheckboxGroup>
    );
  }, [id, processorProperty]);

  return <>{genericPropertyField}</>;
};
