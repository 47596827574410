import { Roles } from "roles/roles.types";

export const antecipacaoDeCartoesRoles = {
  product: {
    tab: {
      role: "admin:CardAnticipationTabProduct.View",
      description:
        "Visualização da tab 'Antecipacão de Cartões' na visão produto",
    },
    operationsPage: {
      role: "admin:CardAnticipationOperationsPageProduct.View",
      description: "Visualização da página 'Operações' na visão produto",
    },
    operationsDetailsPage: {
      role: "admin:CardAnticipationOperationsDetailsPageProduct.View",
      description:
        "Visualização da página 'Operações - Detalhes da operação' na visão produto",
    },
    intubateOperationsFilePage: {
      role: "admin:CardAnticipationIntubateOperationsFilePageProduct.View",
      description:
        "Visualização da página 'Entuba de operações - Busca via submissão Excel' na visão produto",
    },
    intubateOperationsListPage: {
      role: "admin:CardAnticipationIntubateOperationsListPageProduct.View",
      description:
        "Visualização da página 'Entuba de operações - Busca via listagem de recebíveis' na visão produto",
    },
    intubateOperationsSuggestPage: {
      role: "admin:CardAnticipationIntubateOperationsSuggestPageProduct.View",
      description:
        "Visualização da página 'Entuba de operações - Busca via sugestão de recebíveis' na visão produto",
    },
    registersMonitorSolicitationsPage: {
      role: "admin:CardAnticipationRegistersMonitorSolicitationsPageProduct.View",
      description:
        "Visualização da página 'Monitor de registros - Solicitações' na visão produto",
    },
    registersMonitorRegistersPage: {
      role: "admin:CardAnticipationRegistersMonitorRegistersPageProduct.View",
      description:
        "Visualização da página 'Monitor de registros - Registros' na visão produto",
    },
    registersMonitorIntubateAddPage: {
      role: "admin:CardAnticipationRegistersMonitorIntubateAddPageProduct.View",
      description:
        "Visualização da página 'Monitor de registros - Entuba - Inclusão' na visão produto",
    },
    registersMonitorIntubateEditPage: {
      role: "admin:CardAnticipationRegistersMonitorIntubateEditPageProduct.View",
      description:
        "Visualização da página 'Monitor de registros - Entuba - Alteração' na visão produto",
    },
    registersMonitorIntubateCancelPage: {
      role: "admin:CardAnticipationRegistersMonitorIntubateCancelPageProduct.View",
      description:
        "Visualização da página 'Monitor de registros - Entuba - Cancelamento' na visão produto",
    },
    relocationRelocatePage: {
      role: "admin:CardAnticipationRelocationRelocatePageProduct.View",
      description:
        "Visualização da página 'Realocação - Simular e executar realocação' na visão produto",
    },
    relocationAutoPage: {
      role: "admin:CardAnticipationRelocationAutoPageProduct.View",
      description:
        "Visualização da página 'Realocação - Configurar realocação automática' na visão produto",
    },
    enquadramentoTiersPage: {
      role: "admin:CardAnticipationEnquadramentoTiersPageProduct.View",
      description:
        "Visualização da página 'Enquadramento de Carteira - Tiers' na visão produto",
    },
    enquadramentoTiersAddRelationshipPage: {
      role: "admin:CardAnticipationEnquadramentoTiersAddRelationshipPageProduct.View",
      description:
        "Visualização da página 'Enquadramento de Carteira - Tiers - Adicionar Relacionamento' na visão produto",
    },
    enquadramentoCedenteLimitsPage: {
      role: "admin:CardAnticipationEnquadramentoCedenteLimitsPageProduct.View",
      description:
        "Visualização da página 'Enquadramento de Carteira - Limite de Cedente' na visão produto",
    },
    enquadramentoCedenteLimitsAddPage: {
      role: "admin:CardAnticipationEnquadramentoCedenteLimitsAddPageProduct.View",
      description:
        "Visualização da página 'Enquadramento de Carteira - Limite de Cedente - Adicionar' na visão produto",
    },
    enquadramentoSacadoTierPage: {
      role: "admin:CardAnticipationEnquadramentoSacadoTierPageProduct.View",
      description:
        "Visualização da página 'Enquadramento de Carteira - Configuração de Tier Mínimo do Sacado' na visão produto",
    },
    enquadramentoSacadoTierAddPage: {
      role: "admin:CardAnticipationEnquadramentoSacadoTierAddPageProduct.View",
      description:
        "Visualização da página 'Enquadramento de Carteira - Configuração de Tier Mínimo do Sacado - Adicionar' na visão produto",
    },
    enquadramentoUploadPage: {
      role: "admin:CardAnticipationEnquadramentoUploadPageProduct.View",
      description:
        "Visualização da página 'Enquadramento de Carteira - Upload de Tiers' na visão produto",
    },
    quotationPage: {
      role: "admin:CardAnticipationQuotationPageProduct.View",
      description: "Visualização da página 'Cotação' na visão produto",
    },
    tradesPage: {
      role: "admin:CardAnticipationTradesPageProduct.View",
      description: "Visualização da página 'Trades' na visão produto",
    },
    agendasAgendasPage: {
      role: "admin:CardAnticipationAgendasAgendasPageProduct.View",
      description:
        "Visualização da página 'Agendas - Agendas' na visão produto",
    },
    agendasGroupedAgendasPage: {
      role: "admin:CardAnticipationAgendasGroupedAgendasPageProduct.View",
      description:
        "Visualização da página 'Agendas - Valores agrupados por cedente/sacado' na visão produto",
    },
    agendasReceivableDetailsPage: {
      role: "admin:CardAnticipationAgendasReceivableDetailsPageProduct.View",
      description:
        "Visualização da página 'Agendas - Detalhes dos recebíveis no portal' na visão produto",
    },
    agendasImportAgendasPage: {
      role: "admin:CardAnticipationAgendasImportAgendasPageProduct.View",
      description:
        "Visualização da página 'Agendas - Importação manual de agendas CIP' na visão produto",
    },
    companyGroupsPage: {
      role: "admin:CardAnticipationCompanyGroupsPageProduct.View",
      description:
        "Visualização da página 'Agrupamento de CNPJs' na visão produto",
    },
    optinPage: {
      role: "admin:CardAnticipationOptinPageProduct.View",
      description: "Visualização da página 'Optin' na visão produto",
    },
    emailCotacaoPage: {
      role: "admin:CardAnticipationEmailCotacaoPageProduct.View",
      description: "Visualização da página 'Emails cotação' na visão produto",
    },
    relatorioOperacoesPage: {
      role: "admin:CardAnticipationRelatorioOperacoesPageProduct.View",
      description:
        "Visualização da página 'Relatório de operações' na visão produto",
    },
    deflatorsPage: {
      role: "admin:CardAnticipationDeflatorsPageProduct.View",
      description:
        "Visualização da página 'Deflatores de Recebíveis' na visão produto",
    },
    deflatorsNewDeflatorPage: {
      role: "admin:CardAnticipationDeflatorsNewDeflatorPageProduct.View",
      description:
        "Visualização da página 'Deflatores de Recebíveis - Criar deflator' na visão produto",
    },
    deflatorsGlobalDeflatorPage: {
      role: "admin:CardAnticipationDeflatorsGlobalDeflatorPageProduct.View",
      description:
        "Visualização da página 'Deflatores de Recebíveis - Deflator global' na visão produto",
    },
    conveniosPage: {
      role: "admin:CardAnticipationConveniosPageProduct.View",
      description: "Visualização da página 'Convênios' na visão produto",
    },
    autoEmailsPage: {
      role: "admin:CardAnticipationAutoEmailsPageProduct.View",
      description:
        "Visualização da página 'E-mails automáticos' na visão produto",
    },
    autoEmailsCreatePage: {
      role: "admin:CardAnticipationAutoEmailsCreatePageProduct.View",
      description:
        "Visualização da página 'E-mails automáticos - Criar e-mail automático' na visão produto",
    },
    controladorLimiteCedente: {
      role: "admin:ControladorLimiteCedente.View",
      description: "Visualização dos Limites Cedentes",
    },
    contractSignatoriesPage: {
      role: "admin:CardAnticipationContractSignatoriesPageProduct.View",
      description:
        "Visualização da página 'Assinantes de contratos' na visão produto",
    },
    contractSignatoriesAddPage: {
      role: "admin:CardAnticipationContractSignatoriesAddPageProduct.View",
      description:
        "Visualização da página 'Assinantes de contratos - Adicionar' na visão produto",
    },
    contractSignatoriesEditPage: {
      role: "admin:CardAnticipationContractSignatoriesEditPageProduct.View",
      description:
        "Visualização da página 'Assinantes de contratos - Editar' na visão produto",
    },
    toolsWhitelistsPage: {
      role: "admin:CardAnticipationToolsWhitelistsPageProduct.View",
      description:
        "Visualização da página 'Ferramentas - Agendas priorizadas' na visão produto",
    },
    toolsJobsPage: {
      role: "admin:CardAnticipationToolsJobsPageProduct.View",
      description:
        "Visualização da página 'Ferramentas - Jobs' na visão produto",
    },
    toolsCrontabPage: {
      role: "admin:CardAnticipationToolsCrontabPageProduct.View",
      description:
        "Visualização da página 'Ferramentas - Crontab' na visão produto",
    },
    toolsGuaranteeRelocationPage: {
      role: "admin:CardAnticipationToolsGuaranteeRelocationPageProduct.View",
      description:
        "Visualização da página 'Ferramentas - Realocação de garantias' na visão produto",
    },
    toolsHealthCheckGeneralHealthPage: {
      role: "admin:CardAnticipationToolsHealthCheckGeneralHealthPageProduct.View",
      description:
        "Visualização da página 'Ferramentas - Health check - Visão geral' na visão produto",
    },
    toolsHealthCheckTradeHealthPage: {
      role: "admin:CardAnticipationToolsHealthCheckTradeHealthPageProduct.View",
      description:
        "Visualização da página 'Ferramentas - Health check - Trade Health' na visão produto",
    },
    dashboardProcessoPage: {
      role: "admin:CardAnticipationOperationsPageProduct.View",
      description:
        "Visualização da página 'Dashboard rotina matinal' na visão produto",
    },
    companyTraitsPage: {
      role: "admin:CardAnticipationOperationsPageProduct.View",
      description:
        "Visualização da página 'Propriedade das empresas' na visão do produto",
    },
  },
  customer: {
    tab: {
      role: "admin:CardAnticipationTabCustomer.View",
      description:
        "Visualização da tab 'Antecipação de Cartões' na visão cliente",
    },
    detailsPage: {
      role: "admin:CardAnticipationDetailsPageClient.View",
      description: "Visualização da página 'Detalhes' na visão cliente",
    },
    simulateQuotationPage: {
      role: "admin:CardAnticipationSimulateQuotationPageClient.View",
      description: "Visualização da página 'Simular cotação' na visão cliente",
    },
    liquidationExtractPage: {
      role: "admin:LiquidationExtractPageClient.View",
      description:
        "Visualização da página 'Extrato de liquidação e posição' na visão cliente",
    },
  },
} satisfies Roles;
