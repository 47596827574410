import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import {
  PatchEstrategiaComercialByCompanyIdBody,
  PostPortalEstrategiaComercialBody,
} from "services/quickfin/models";
import { getValueFromMap } from "utils/get-value-from-map";
import { isAxiosError } from "axios";
import {
  EstrategiaForm,
  addEstrategiaFormDefaultValues,
  addEstrategiaFormValidationSchema,
  productFormMap,
} from "../../../../../../components/estrategia-form";
import { useEstrategiaComercial } from "../../_compose";
import { buildPayload } from "../adicionar/add-company-estrategia.utils";
import { buildInitialValues } from "./company-estrategia-details.utils";

export const useCompanyEstrategiaDetails = () => {
  const { estrategia, loading, quickfinId } = useEstrategiaComercial();

  const { callService: editCompanyEstrategia, loading: submitLoading } =
    useServiceCall(service.quickfin.editCompanyEstrategia);

  const { callService: editPortalEstrategia } = useServiceCall(
    service.quickfin.createorUpdatePortalEstrategiaComercial,
  );

  const createUpdatePortalEstrategiaComercial = useCallback(
    async (estrategiaId: number, portais: string[]) => {
      const payload = {
        estrategiaComercialId: estrategiaId,
        portalCodes: portais,
      };
      const { error } = await editPortalEstrategia(
        payload as PostPortalEstrategiaComercialBody,
      );
      if (error) {
        OToastManager.danger(
          "Não foi possível criar as relações de links taggeados",
        );
      }
    },
    [editPortalEstrategia],
  );

  const navigate = useNavigate();

  const form = useForm<EstrategiaForm>({
    defaultValues: addEstrategiaFormDefaultValues,
    resolver: yupResolver(addEstrategiaFormValidationSchema),
  });

  const { handleSubmit, reset, watch } = form;

  const tipoProdutoWatch = watch("tipoProduto");

  const Form = getValueFromMap(productFormMap, tipoProdutoWatch);

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success, error } = await editCompanyEstrategia(
          quickfinId,
          buildPayload(values, true) as PatchEstrategiaComercialByCompanyIdBody,
        );
        if (values.linkTaggeado && values.id) {
          createUpdatePortalEstrategiaComercial(values.id, values.linkTaggeado);
        }
        if (success) {
          OToastManager.success("Estratégia editada com sucesso");
          navigate(
            corporateRouter.routes.recebiveis.customer.estrategiaComercial.path,
          );
        } else {
          let message = "";
          if (isAxiosError(error)) {
            message = error.response?.data.message ?? "";
          }
          OToastManager.danger(
            "Não foi possível editar a estratégia. " + message,
          );
        }
      }),
    [
      createUpdatePortalEstrategiaComercial,
      editCompanyEstrategia,
      handleSubmit,
      navigate,
      quickfinId,
    ],
  );

  useEffect(() => {
    if (estrategia) {
      reset(buildInitialValues(estrategia) as any);
    }
  }, [estrategia, reset]);

  return {
    Form,
    form,
    loading,
    submit,
    submitLoading,
  };
};
