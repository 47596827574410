import { OToastManager, modalManager } from "@maestro/core";
import { OConfirmationModal, OTypography } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import type { DataGrid } from "devextreme-react/data-grid";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useState } from "react";
import { service } from "services";
import { getValidationMessages, pendencyStatusMap } from "../../../../../utils";

const modalId = "batch-letter-of-complaint-confirmation-modal";

interface BatchLetterOfComplaintButtonProps {
  gridRef: React.RefObject<DataGrid<HubEnergy.DiligenceTaskODataResponse>>;
  reload: () => void;
}

export const BatchLetterOfComplaintButton = ({
  gridRef,
  reload,
}: BatchLetterOfComplaintButtonProps) => {
  const [loading, setLoading] = useState(false);

  const { callService } = useServiceCall(
    service.hubEnergy.updatePendencyStatus,
  );

  const sendLetterOfComplaint = useCallback(async () => {
    const selected =
      (await gridRef.current?.instance.getSelectedRowsData()) ?? [];

    if (!selected.length)
      return OToastManager.warning("Nenhuma pendência selecionada");

    if (
      selected.some(
        ({ pendencyTypeName }) => pendencyTypeName !== "ENVIAR_CARTA_DENUNCIA",
      )
    )
      return OToastManager.warning(
        "Só é possível denunciar pendências com o tipo 'ENVIAR_CARTA_DENUNCIA'",
      );

    if (
      selected.some(
        ({ pendencyStatus }) => pendencyStatus !== "PENDENTE_ENVIO_AUTOMACAO",
      )
    )
      return OToastManager.warning(
        `Só é possível denunciar pendências com o status '${pendencyStatusMap.PENDENTE_ENVIO_AUTOMACAO.text}'`,
      );

    const promises = selected.map(({ pendencyId }) =>
      callService({
        diligenceStepPendencyId: pendencyId,
        status: "EM_AUTOMACAO",
      }),
    );
    setLoading(true);
    const results = await Promise.all(promises);
    const failedResults = results.filter(({ success }) => success === false);

    if (failedResults.length) {
      failedResults.forEach(({ error }) => {
        // always true
        if (error) {
          getValidationMessages(error)?.forEach((failure) => {
            OToastManager.danger(failure.ErrorMessage);
          });
        }
      });
      OToastManager.warning("Erro ao fazer as denúncias");
    } else {
      OToastManager.success("As denúncias foram feitas com sucesso");
      gridRef.current?.instance.clearSelection();
    }
    setLoading(false);
    reload();
  }, [callService, gridRef, reload]);

  return (
    <>
      <LoadingButton
        loading={loading}
        onClick={() => modalManager.show(modalId)}
      >
        Enviar carta denúncia
      </LoadingButton>
      <OConfirmationModal
        modalId={modalId}
        title="Enviar carta denúncia"
        divider={false}
        onConfirm={() => {
          sendLetterOfComplaint();
          modalManager.hide(modalId);
        }}
        className="position-absolute"
      >
        <OTypography>
          Tem certeza que deseja enviar as cartas denúncia das unidades
          consumidoras selecionadas?
        </OTypography>
      </OConfirmationModal>
    </>
  );
};
