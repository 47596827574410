import { OLoader, OOption, ORFieldSelect } from "@maestro/react";
import { useMemo } from "react";
import { getProcessorPropertyFieldId } from "../../../utils";
import { GenericPropertyFieldProps } from "../../property-field.types";
import { useWorkflowConfigField } from "./workflow-config-field.hook";

export const WorkflowConfigField = ({
  processorConfigProperty,
  handleSubmitProperty,
}: GenericPropertyFieldProps) => {
  const { processorProperty, id } = processorConfigProperty;
  const { chidWorkflowConfigs, loading } = useWorkflowConfigField({
    processorConfigProperty,
    handleSubmitProperty,
  });

  const processorPropertyFieldId = getProcessorPropertyFieldId(
    processorProperty.name,
    id,
  );

  const workflowConfigField = useMemo(() => {
    return (
      <div className="d-flex align-items-center">
        <ORFieldSelect
          key={processorProperty.name + id}
          id={processorPropertyFieldId}
          name={processorPropertyFieldId}
          placeholder="Selecione"
          label={processorProperty.description}
        >
          <div>
            {chidWorkflowConfigs?.map((config) => (
              <OOption
                key={config.identification}
                value={config.identification}
              >
                {config.name}
              </OOption>
            ))}
          </div>
        </ORFieldSelect>
        {loading && <OLoader />}
      </div>
    );
  }, [processorProperty, id, chidWorkflowConfigs, loading]);

  return <>{workflowConfigField}</>;
};
