import {
  OButton,
  OField,
  OFieldError,
  OIcon,
  OSwitch,
  OTypography,
} from "@maestro/react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { getValueFromMap } from "utils/get-value-from-map";
import { EditTaxasForm } from "../../edit-taxas.types";
import { TermField } from "./term-field.component";
import { taxaPrazoMap } from "./term-fields.utils";
import { useEffect, useState } from "react";

export const TermFields = () => {
  const form = useFormContext<EditTaxasForm>();
  const [useDatesForTerm, setUseDatesForTerm] = useState<boolean>(false);

  const {
    control,
    formState: {
      errors: { taxaPrazos },
    },
    watch,
  } = form;

  const configTypeWatch = watch("configType");
  const taxaPrazoType = getValueFromMap(taxaPrazoMap, configTypeWatch);

  const fieldArray = useFieldArray({
    control,
    name: "taxaPrazos",
  });

  const { append, fields } = fieldArray;

  useEffect(() => {
    setUseDatesForTerm(fieldArray.fields[0]?.fromDate != undefined);
  }, [taxaPrazoType, fieldArray.fields[0]?.fromDate]);

  useEffect(() => {
    if (
      (useDatesForTerm && fieldArray.fields[0]?.fromDate != undefined) ||
      (!useDatesForTerm && fieldArray.fields[0]?.fromDay != undefined)
    ) {
      return;
    }

    fieldArray.remove();
    fieldArray.append({
      fromDay: null,
      toDay: null,
      fromDate: null,
      toDate: null,
      pnlStrategyOverride: null,
    } as any);
  }, [useDatesForTerm]);

  return taxaPrazoType ? (
    <div className="d-flex flex-column flex-fill">
      <OTypography>Prazos</OTypography>
      <OField
        htmlFor="useDatesForTerm"
        label="Usar datas para prazo"
        className="d-flex flex-column align-items-end"
      >
        <OSwitch
          id="useDatesForTerm"
          size="sm"
          value={useDatesForTerm}
          onInput={() => setUseDatesForTerm((oldValue) => !oldValue)}
        />
      </OField>
      {!!taxaPrazos?.message && (
        <OFieldError visible>{taxaPrazos.message}</OFieldError>
      )}
      {fields.map((field, index) => (
        <TermField
          key={field.id}
          index={index}
          fieldArray={fieldArray}
          fieldType={taxaPrazoType.value}
          label={taxaPrazoType.label}
          useDatesForTerm={useDatesForTerm}
          watch={watch}
        />
      ))}
      <div className="d-flex justify-content-end">
        <OButton
          dataAction="campo_emails:botao:adicionar"
          dataLabel="adicionar"
          onClick={() =>
            append({
              fromDay: null,
              toDay: null,
              fromDate: null,
              toDate: null,
              pnlStrategyOverride: null,
            } as any)
          }
        >
          <OIcon category="fa" icon="fa-plus" />
        </OButton>
      </div>
    </div>
  ) : null;
};
