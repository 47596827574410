import { DeepNullable, MaestroFields, YupObjectSchema } from "utils/types";
import * as yup from "yup";

export interface AddPendencyForm {
  stepId: number;
  typeId: number;
  propertyId: number;
  isAutomation: false;
  isPublic: [string];
  message: string | null;
  status: string;
  _consumerUnitId: number | null;
}

export const addPendencyFormFields = {
  isPublic: {
    id: "isPublic",
    name: "isPublic",
  },
  message: {
    id: "message",
    name: "message",
    label: "Mensagem",
    tag: "textarea",
    adaptHeight: true,
  },
  status: {
    id: "status",
    name: "status",
    label: "Status",
    placeholder: "Selecionar",
    required: true,
  },
} as const satisfies MaestroFields<AddPendencyForm>;

export const addPendencyFormValidationSchema = yup.object<
  AddPendencyForm,
  YupObjectSchema<AddPendencyForm>
>({
  stepId: yup.number().required("Campo obrigatório"),
  typeId: yup.number().required("Campo obrigatório"),
  status: yup.string().required("Campo obrigatório"),
});

export const addPendencyFormDefaultValues = {
  stepId: null,
  typeId: null,
  propertyId: null,
  isAutomation: false,
  isPublic: [],
  message: null,
  status: null,
  _consumerUnitId: null,
} satisfies Partial<
  DeepNullable<AddPendencyForm>
> as unknown as AddPendencyForm;

export const checkboxValue = "true";
