import { OTypography } from "@maestro/react";
import { ReactNode } from "react";

export interface EmptyStateProps {
  messageTitle?: string;
  messageParagraph?: string;
  children?: ReactNode;
  icon?: JSX.Element;
}

export const EmptyState = ({
  messageTitle,
  messageParagraph,
  children,
  icon,
}: EmptyStateProps) => {
  return (
    <div className="d-flex flex-column align-items-start gap-2 ms-4">
      {icon}
      <div>
        {messageTitle && (
          <OTypography tag="p" type="dark" size="lg" weight="normal">
            {messageTitle}
          </OTypography>
        )}
        {messageParagraph && (
          <OTypography tag="p" type="dark" size="md" weight="normal">
            {messageParagraph}
          </OTypography>
        )}
        {children}
      </div>
    </div>
  );
};
