import { OCol, ORFieldInput } from "@maestro/react";

export const PrazoLimiteOperacaoField = () => {
  return (
    <OCol xs={12} md={6}>
      <ORFieldInput
        dataAction="geral:texto:prazo_limite_operacao"
        dataLabel="prazo_limite_operacao"
        id="prazoLimiteOperacao"
        name="prazoLimiteOperacao"
        tag="number"
        placeholder="Preencher"
        label="Prazo limite para operação"
        description="(N) dias antes do vencimento, que a nota se torna indisponível para antecipação"
        required
      />
    </OCol>
  );
};
