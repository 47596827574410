import { env } from "utils/environments";

export const hubEnergyEndpoints = {
  api: {
    adequacyOption: {
      distributor: {
        byDistributorId: (distributorId: number | string) =>
          `${env.API_HUBENERGY}/api/adequacy-option/distributor/${distributorId}`,
      },
    },
    admin: {
      adequacyOptions: {
        _: `${env.API_HUBENERGY}/api/admin/adequacy-options`,
        byAdequacyOptionId: {
          _: (adequacyOptionId: number | string) =>
            `${env.API_HUBENERGY}/api/admin/adequacy-options/${adequacyOptionId}`,
          distributors: (adequacyOptionId: number | string) =>
            `${env.API_HUBENERGY}/api/admin/adequacy-options/${adequacyOptionId}/distributors`,
        },
        recommendToDistributors: `${env.API_HUBENERGY}/api/admin/adequacy-options/recommend-to-distributors`,
      },
      billingReports: {
        _: `${env.API_HUBENERGY}/api/admin/billing-reports`,
        byTaxId: (taxId: number | string) =>
          `${env.API_HUBENERGY}/api/admin/billing-reports/${taxId}`,
        notifyInvoiced: `${env.API_HUBENERGY}/api/admin/billing-reports/notify-invoiced`,
        syncWithNes: {
          byBillingReportId: (billingReportId: number | string) =>
            `${env.API_HUBENERGY}/api/admin/billing-reports/sync-with-nes/${billingReportId}`,
        },
      },
      common: {
        _: `${env.API_HUBENERGY}/api/admin/common`,
        byGroup: {
          byKey: (group: string, key: string) =>
            `${env.API_HUBENERGY}/api/admin/common/${group}/${key}`,
        },
      },
      contacts: {
        adequacyOption: `${env.API_HUBENERGY}/api/admin/contacts/adequacy-option`,
        byContactId: {
          adequacyOption: {
            byAdequacyOptionId: (
              contactId: number | string,
              adequacyOptionId: number | string,
            ) =>
              `${env.API_HUBENERGY}/api/admin/contacts/${contactId}/adequacy-option/${adequacyOptionId}`,
          },
          customer: {
            byCustomerId: (
              contactId: number | string,
              customerId: number | string,
            ) =>
              `${env.API_HUBENERGY}/api/admin/contacts/${contactId}/customer/${customerId}`,
          },
          distributor: {
            byDistributorId: (
              contactId: number | string,
              distributorId: number | string,
            ) =>
              `${env.API_HUBENERGY}/api/admin/contacts/${contactId}/distributor/${distributorId}`,
          },
        },
        customer: `${env.API_HUBENERGY}/api/admin/contacts/customer`,
        distributor: `${env.API_HUBENERGY}/api/admin/contacts/distributor`,
      },
      customers: {
        consumerUnits: {
          _: `${env.API_HUBENERGY}/api/admin/customers/consumer-units`,
          byConsumerUnitId: (consumerUnitId: number | string) =>
            `${env.API_HUBENERGY}/api/admin/customers/consumer-units/${consumerUnitId}`,
        },
        consumerUnitsMonitor: `${env.API_HUBENERGY}/api/admin/customers/consumer-units-monitor`,
        simplified: `${env.API_HUBENERGY}/api/admin/customers/simplified`,
      },
      diligence: {
        _: `${env.API_HUBENERGY}/api/admin/diligence`,
        addStep: `${env.API_HUBENERGY}/api/admin/diligence/add-step`,
        approveConclusion: `${env.API_HUBENERGY}/api/admin/diligence/approve-conclusion`,
        approveStart: `${env.API_HUBENERGY}/api/admin/diligence/approve-start`,
        buildSteps: `${env.API_HUBENERGY}/api/admin/diligence/build-steps`,
        configs: {
          _: `${env.API_HUBENERGY}/api/admin/diligence/configs`,
          byDiligenceConfigId: (diligenceConfigId: number | string) =>
            `${env.API_HUBENERGY}/api/admin/diligence/configs/${diligenceConfigId}`,
          stepConfig: {
            _: `${env.API_HUBENERGY}/api/admin/diligence/configs/step-config`,
            byDiligenceStepConfigId: (diligenceStepConfigId: number | string) =>
              `${env.API_HUBENERGY}/api/admin/diligence/configs/step-config/${diligenceStepConfigId}`,
          },
          stepPendencyType: {
            _: `${env.API_HUBENERGY}/api/admin/diligence/configs/step-pendency-type`,
            byStepPendencyTypeId: (stepPendencyTypeId: number | string) =>
              `${env.API_HUBENERGY}/api/admin/diligence/configs/step-pendency-type/${stepPendencyTypeId}`,
          },
          stepPropertyConfig: {
            byDiligenceStepPropertyConfigId: {
              documentTemplate: (
                diligenceStepPropertyConfigId: number | string,
              ) =>
                `${env.API_HUBENERGY}/api/admin/diligence/configs/step-property-config/${diligenceStepPropertyConfigId}/document-template`,
            },
            documentTemplate: `${env.API_HUBENERGY}/api/admin/diligence/configs/step-property-config/document-template`,
          },
          stepPropertyType: {
            _: `${env.API_HUBENERGY}/api/admin/diligence/configs/step-property-type`,
            byStepPropertyTypeId: (stepPropertyTypeId: number | string) =>
              `${env.API_HUBENERGY}/api/admin/diligence/configs/step-property-type/${stepPropertyTypeId}`,
            masks: `${env.API_HUBENERGY}/api/admin/diligence/configs/step-property-type/masks`,
          },
          stepType: {
            _: `${env.API_HUBENERGY}/api/admin/diligence/configs/step-type`,
            byStepTypeId: (stepTypeId: number | string) =>
              `${env.API_HUBENERGY}/api/admin/diligence/configs/step-type/${stepTypeId}`,
          },
        },
        consumerUnits: {
          byConsumerUnitId: {
            forceRecreateDiligence: (consumerUnitId: number | string) =>
              `${env.API_HUBENERGY}/api/admin/diligence/consumer-units/${consumerUnitId}/force-recreate-diligence`,
          },
        },
        documents: `${env.API_HUBENERGY}/api/admin/diligence/documents`,
        event: {
          action: {
            _: `${env.API_HUBENERGY}/api/admin/diligence/event/action`,
            byDiligenceEventActionId: (
              diligenceEventActionId: number | string,
            ) =>
              `${env.API_HUBENERGY}/api/admin/diligence/event/action/${diligenceEventActionId}`,
          },
          rule: {
            _: `${env.API_HUBENERGY}/api/admin/diligence/event/rule`,
            byDiligenceEventRuleId: (diligenceEventRuleId: number | string) =>
              `${env.API_HUBENERGY}/api/admin/diligence/event/rule/${diligenceEventRuleId}`,
          },
        },
        letterOfComplaint: {
          pressForResponse: `${env.API_HUBENERGY}/api/admin/diligence/letter-of-complaint/press-for-response`,
        },
        moveStepProperty: `${env.API_HUBENERGY}/api/admin/diligence/move-step-property`,
        property: {
          bills: {
            _: `${env.API_HUBENERGY}/api/admin/diligence/property/bills`,
            byDiligenceBillId: (diligenceBillId: number | string) =>
              `${env.API_HUBENERGY}/api/admin/diligence/property/bills/${diligenceBillId}`,
          },
          value: `${env.API_HUBENERGY}/api/admin/diligence/property/value`,
        },
        step: {
          _: `${env.API_HUBENERGY}/api/admin/diligence/step`,
          batchStatus: `${env.API_HUBENERGY}/api/admin/diligence/step/batch-status`,
          byDiligenceStepId: {
            _: (diligenceStepId: number | string) =>
              `${env.API_HUBENERGY}/api/admin/diligence/step/${diligenceStepId}`,
            approve: (diligenceStepId: number | string) =>
              `${env.API_HUBENERGY}/api/admin/diligence/step/${diligenceStepId}/approve`,
            conclude: (diligenceStepId: number | string) =>
              `${env.API_HUBENERGY}/api/admin/diligence/step/${diligenceStepId}/conclude`,
            pendencies: (diligenceStepId: number | string) =>
              `${env.API_HUBENERGY}/api/admin/diligence/step/${diligenceStepId}/pendencies`,
            properties: (diligenceStepId: number | string) =>
              `${env.API_HUBENERGY}/api/admin/diligence/step/${diligenceStepId}/properties`,
            requestCorrection: (diligenceStepId: number | string) =>
              `${env.API_HUBENERGY}/api/admin/diligence/step/${diligenceStepId}/request-correction`,
          },
          properties: `${env.API_HUBENERGY}/api/admin/diligence/step/properties`,
          status: `${env.API_HUBENERGY}/api/admin/diligence/step/status`,
        },
        stepMonitor: `${env.API_HUBENERGY}/api/admin/diligence/step-monitor`,
        stepPendency: {
          _: `${env.API_HUBENERGY}/api/admin/diligence/step-pendency`,
          byDiligenceStepPendencyId: (
            diligenceStepPendencyId: number | string,
          ) =>
            `${env.API_HUBENERGY}/api/admin/diligence/step-pendency/${diligenceStepPendencyId}`,
          notes: {
            _: `${env.API_HUBENERGY}/api/admin/diligence/step-pendency/notes`,
            byNoteId: (noteId: number | string) =>
              `${env.API_HUBENERGY}/api/admin/diligence/step-pendency/notes/${noteId}`,
          },
          status: `${env.API_HUBENERGY}/api/admin/diligence/step-pendency/status`,
        },
        stepProperty: {
          _: `${env.API_HUBENERGY}/api/admin/diligence/step-property`,
          byDiligenceStepPropertyId: {
            _: (diligenceStepPropertyId: number | string) =>
              `${env.API_HUBENERGY}/api/admin/diligence/step-property/${diligenceStepPropertyId}`,
            documentTemplate: (diligenceStepPropertyId: number | string) =>
              `${env.API_HUBENERGY}/api/admin/diligence/step-property/${diligenceStepPropertyId}/document-template`,
          },
          documentTemplate: `${env.API_HUBENERGY}/api/admin/diligence/step-property/document-template`,
        },
        updateMigrationDate: `${env.API_HUBENERGY}/api/admin/diligence/update-migration-date`,
      },
      distributors: {
        adequacyOptions: `${env.API_HUBENERGY}/api/admin/distributors/adequacy-options`,
        byDistributorId: {
          adequacyOptions: (distributorId: number | string) =>
            `${env.API_HUBENERGY}/api/admin/distributors/${distributorId}/adequacy-options`,
          diligenceConfig: (distributorId: number | string) =>
            `${env.API_HUBENERGY}/api/admin/distributors/${distributorId}/diligence-config`,
        },
        config: `${env.API_HUBENERGY}/api/admin/distributors/config`,
        diligenceConfig: `${env.API_HUBENERGY}/api/admin/distributors/diligence-config`,
      },
      documents: {
        documentTypes: {
          _: `${env.API_HUBENERGY}/api/admin/documents/document-types`,
          byDocumentTypeId: (documentTypeId: number | string) =>
            `${env.API_HUBENERGY}/api/admin/documents/document-types/${documentTypeId}`,
        },
        moveFile: `${env.API_HUBENERGY}/api/admin/documents/move-file`,
        sendToPaperclip: `${env.API_HUBENERGY}/api/admin/documents/send-to-paperclip`,
      },
      entities: `${env.API_HUBENERGY}/api/admin/entities`,
      files: {
        download: `${env.API_HUBENERGY}/api/admin/files/download`,
      },
      guarantors: {
        maritalStatus: `${env.API_HUBENERGY}/api/admin/guarantors/marital-status`,
      },
      importConsumerUnits: {
        _: `${env.API_HUBENERGY}/api/admin/import-consumer-units`,
        byConsumerUnitsImportDataId: {
          _: (consumerUnitsImportDataId: number | string) =>
            `${env.API_HUBENERGY}/api/admin/import-consumer-units/${consumerUnitsImportDataId}`,
          reprocess: (consumerUnitsImportDataId: number | string) =>
            `${env.API_HUBENERGY}/api/admin/import-consumer-units/${consumerUnitsImportDataId}/reprocess`,
        },
        executeImport: `${env.API_HUBENERGY}/api/admin/import-consumer-units/execute-import`,
        parseSpreadsheet: `${env.API_HUBENERGY}/api/admin/import-consumer-units/parse-spreadsheet`,
        syncDiligencesWithLegacy: `${env.API_HUBENERGY}/api/admin/import-consumer-units/sync-diligences-with-legacy`,
      },
      invoices: {
        _: `${env.API_HUBENERGY}/api/admin/invoices`,
        byInvoiceId: (invoiceId: number | string) =>
          `${env.API_HUBENERGY}/api/admin/invoices/${invoiceId}`,
        byTaxId: (taxId: number | string) =>
          `${env.API_HUBENERGY}/api/admin/invoices/${taxId}`,
      },
      tools: {
        cleanUpDistributorDocuments: `${env.API_HUBENERGY}/api/admin/tools/clean-up-distributor-documents`,
        reprocessLeadOperatorOriginator: `${env.API_HUBENERGY}/api/admin/tools/reprocess-lead-operator-originator`,
      },
      trades: {
        byTradeId: {
          _: (tradeId: number | string) =>
            `${env.API_HUBENERGY}/api/admin/trades/${tradeId}`,
          contracts: (tradeId: number | string) =>
            `${env.API_HUBENERGY}/api/admin/trades/${tradeId}/contracts`,
          guarantors: (tradeId: number | string) =>
            `${env.API_HUBENERGY}/api/admin/trades/${tradeId}/guarantors`,
          possibleDocuments: (tradeId: number | string) =>
            `${env.API_HUBENERGY}/api/admin/trades/${tradeId}/possible-documents`,
        },
        createDocuments: `${env.API_HUBENERGY}/api/admin/trades/create-documents`,
        createOperation: `${env.API_HUBENERGY}/api/admin/trades/create-operation`,
        generateContract: `${env.API_HUBENERGY}/api/admin/trades/generate-contract`,
        generateMigrationDocuments: `${env.API_HUBENERGY}/api/admin/trades/generate-migration-documents`,
        previewContract: `${env.API_HUBENERGY}/api/admin/trades/preview-contract`,
        previewMigrationDocument: `${env.API_HUBENERGY}/api/admin/trades/preview-migration-document`,
        recreate: `${env.API_HUBENERGY}/api/admin/trades/recreate`,
        reserveOperation: `${env.API_HUBENERGY}/api/admin/trades/reserve-operation`,
        status: `${env.API_HUBENERGY}/api/admin/trades/status`,
        tradePendingSignatureSignatoriesNotification: `${env.API_HUBENERGY}/api/admin/trades/trade-pending-signature-signatories-notification`,
      },
    },
    byChannel: {
      adequacyOption: {
        diligence: {
          _: (channel: string) =>
            `${env.API_HUBENERGY}/api/${channel}/adequacy-option/diligence`,
          byDiligenceId: (channel: string, diligenceId: number | string) =>
            `${env.API_HUBENERGY}/api/${channel}/adequacy-option/diligence/${diligenceId}`,
        },
        notify: (channel: string) =>
          `${env.API_HUBENERGY}/api/${channel}/adequacy-option/notify`,
      },
      billingReport: {
        byBillingReportId: {
          _: (channel: string, billingReportId: number | string) =>
            `${env.API_HUBENERGY}/api/${channel}/billing-report/${billingReportId}`,
          invoices: (channel: string, billingReportId: number | string) =>
            `${env.API_HUBENERGY}/api/${channel}/billing-report/${billingReportId}/invoices`,
        },
        getConsumptionSavingsDashboard: (channel: string) =>
          `${env.API_HUBENERGY}/api/${channel}/billing-report/get-consumption-savings-dashboard`,
        getCurrentBilling: (channel: string) =>
          `${env.API_HUBENERGY}/api/${channel}/billing-report/get-current-billing`,
        getEvolutionBillings: (channel: string) =>
          `${env.API_HUBENERGY}/api/${channel}/billing-report/get-evolution-billings`,
        getMonthReport: (channel: string) =>
          `${env.API_HUBENERGY}/api/${channel}/billing-report/get-month-report`,
        getMonthReports: (channel: string) =>
          `${env.API_HUBENERGY}/api/${channel}/billing-report/get-month-reports`,
      },
      customer: {
        consumerUnits: (channel: string) =>
          `${env.API_HUBENERGY}/api/${channel}/customer/consumer-units`,
      },
      diligence: {
        consumerUnit: {
          byConsumerUnitId: {
            _: (channel: string, consumerUnitId: number | string) =>
              `${env.API_HUBENERGY}/api/${channel}/diligence/consumer-unit/${consumerUnitId}`,
            byStepName: {
              _: (
                channel: string,
                consumerUnitId: number | string,
                stepName: string,
              ) =>
                `${env.API_HUBENERGY}/api/${channel}/diligence/consumer-unit/${consumerUnitId}/${stepName}`,
              sendToAnalysis: (
                channel: string,
                consumerUnitId: number | string,
                stepName: string,
              ) =>
                `${env.API_HUBENERGY}/api/${channel}/diligence/consumer-unit/${consumerUnitId}/${stepName}/send-to-analysis`,
            },
          },
        },
        consumerUnitsDiligences: (channel: string) =>
          `${env.API_HUBENERGY}/api/${channel}/diligence/consumer-units-diligences`,
        properties: (channel: string) =>
          `${env.API_HUBENERGY}/api/${channel}/diligence/properties`,
      },
      entities: {
        logged: (channel: string) =>
          `${env.API_HUBENERGY}/api/${channel}/entities/logged`,
      },
      file: {
        download: (channel: string) =>
          `${env.API_HUBENERGY}/api/${channel}/file/download`,
        remove: (channel: string) =>
          `${env.API_HUBENERGY}/api/${channel}/file/remove`,
        upload: (channel: string) =>
          `${env.API_HUBENERGY}/api/${channel}/file/upload`,
      },
      trade: {
        consumerUnits: (channel: string) =>
          `${env.API_HUBENERGY}/api/${channel}/trade/consumer-units`,
        documentsExternalIds: (channel: string) =>
          `${env.API_HUBENERGY}/api/${channel}/trade/documents-external-ids`,
        documentsStatus: (channel: string) =>
          `${env.API_HUBENERGY}/api/${channel}/trade/documents-status`,
        guarantors: (channel: string) =>
          `${env.API_HUBENERGY}/api/${channel}/trade/guarantors`,
        params: (channel: string) =>
          `${env.API_HUBENERGY}/api/${channel}/trade/params`,
        proposalDetails: (channel: string) =>
          `${env.API_HUBENERGY}/api/${channel}/trade/proposal-details`,
        summarySteps: (channel: string) =>
          `${env.API_HUBENERGY}/api/${channel}/trade/summary-steps`,
      },
    },
    diligence: {
      adequacy: {
        confirmation: {
          byGuid: (guid: string) =>
            `${env.API_HUBENERGY}/api/diligence/adequacy/confirmation/${guid}`,
        },
      },
      byDiligenceId: (diligenceId: number | string) =>
        `${env.API_HUBENERGY}/api/diligence/${diligenceId}`,
    },
    distributors: {
      _: `${env.API_HUBENERGY}/api/distributors`,
      batch: `${env.API_HUBENERGY}/api/distributors/batch`,
      byDistributorId: (distributorId: number | string) =>
        `${env.API_HUBENERGY}/api/distributors/${distributorId}`,
    },
    events: {
      publish: `${env.API_HUBENERGY}/api/events/publish`,
      publishList: `${env.API_HUBENERGY}/api/events/publish-list`,
    },
    file: {
      download: `${env.API_HUBENERGY}/api/file/download`,
      upload: `${env.API_HUBENERGY}/api/file/upload`,
    },
  },
  contacts: {
    adequacyOption: {
      byAdequacyOptionId: (adequacyOptionId: number | string) =>
        `${env.API_HUBENERGY}/contacts/adequacy-option/${adequacyOptionId}`,
    },
    byContactId: {
      adequacyOption: {
        byAdequacyOptionId: (
          contactId: number | string,
          adequacyOptionId: number | string,
        ) =>
          `${env.API_HUBENERGY}/contacts/${contactId}/adequacy-option/${adequacyOptionId}`,
      },
      customer: {
        byCustomerId: (
          contactId: number | string,
          customerId: number | string,
        ) =>
          `${env.API_HUBENERGY}/contacts/${contactId}/customer/${customerId}`,
      },
      distributor: {
        byDistributorId: (
          contactId: number | string,
          distributorId: number | string,
        ) =>
          `${env.API_HUBENERGY}/contacts/${contactId}/distributor/${distributorId}`,
      },
    },
    customer: {
      byCustomerId: (customerId: number | string) =>
        `${env.API_HUBENERGY}/contacts/customer/${customerId}`,
    },
    distributor: {
      byDistributorId: (distributorId: number | string) =>
        `${env.API_HUBENERGY}/contacts/distributor/${distributorId}`,
    },
  },
  odata: {
    _: `${env.API_HUBENERGY}/odata`,
    activeCustomersByChannel: (channel: string) =>
      `${env.API_HUBENERGY}/odata/active-customers(Channel=${channel})`,
    customersInDiligenceByChannel: (channel: string) =>
      `${env.API_HUBENERGY}/odata/customers-in-diligence(Channel=${channel})`,
    consumerUnits: `${env.API_HUBENERGY}/odata/consumer-units`,
    diligenceTasks: `${env.API_HUBENERGY}/odata/diligence-tasks`,
    metadata: `${env.API_HUBENERGY}/odata/$metadata`,
    trades: `${env.API_HUBENERGY}/odata/trades`,
  },
};
