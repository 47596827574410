import * as yup from "yup";
import { EditTaxasForm } from "./edit-taxas.types";

export const editTaxasFormDefaultValues = {
  // currency: "BRL",
  calendar: null,
  capitalizationBase: null,
  capitalizationType: null,
  casasDecimais: undefined,
  configType: null,
  dataBasePeriod: 1,
  daysToDataBase: 0,
  forceUpdate: null,
  index: null,
  rebateBase: null,
  rebateType: null,
  rebateValue: 0,
  limitFutureDays: null,
  taxasColunas: [],
  emailsTaxas: [],
  taxaPrazos: [
    {
      fromDay: null,
      toDay: null,
      fromDate: null,
      toDate: null,
      spread: 0,
      taxa: 0,
      diMais: 0,
      pnlStrategyOverride: null
    },
  ],
} as any;

const isSemRebate = (rebateType: string) =>
  ["SemRebate", "SemRebateComTrava"].includes(rebateType) || !rebateType;

const requiredString = yup.string().required("Campo obrigatório");
const requiredNumber = yup
  .number()
  .typeError("O valor deve ser numérico")
  .required("Campo obrigatório");

export const editTaxasValidationSchema = yup.object<
  EditTaxasForm,
  Partial<yup.ObjectSchema<EditTaxasForm>["fields"]>
>({
  configType: requiredString,
  index: requiredString,
  capitalizationType: requiredString,
  capitalizationBase: requiredString,
  casasDecimais: requiredNumber
    .min(1, "O valor deve ser no mínimo 1")
    .max(13, "O valor deve ser no máximo 13"),
  currency: requiredString,
  daysToDataBase: requiredNumber
    .min(0, "O valor deve ser no mínimo 0")
    .max(7, "O valor deve ser no máximo 7"),
  dataBasePeriod: requiredNumber
    .min(1, "O valor deve ser no mínimo 1")
    .max(8, "O valor deve ser no máximo 8"),
  rebateBase: yup.string().when("rebateType", {
    is: isSemRebate,
    then: (schema) => schema.notRequired(),
    otherwise: (schema) => schema.required("Campo obrigatório"),
  }),
  rebateType: yup.string().required("Campo obrigatório"),
  rebateValue: yup
    .number()
    .typeError("O valor deve ser numérico")
    .required("Campo obrigatório")
    .min(0, "O valor deve ser no mínimo 0")
    .max(100, "O valor deve ser no máximo 100"),
});
