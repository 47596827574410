import { YesNoRadioField } from "./yes-no-radio-field.component";

export const NeedsFilesForReceivablesField = () => {
  return (
    <YesNoRadioField
      label="Habilita a obrigação de upload de arquivos no envio do borderô"
      name="needsFilesForReceivables"
      description="Quando habilitado, valida se os arquivos foram enviados no borderô"
    />
  );
};
