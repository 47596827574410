import { OLoader } from "@maestro/react";
import { RefreshGridButton } from "components/refresh-grid-button";
import { SelectSearchField } from "components/select-search";
import { useDiligenceDocumentSelector } from "./diligence-document-selector.hook";
import { GetDiligenceDocumentsReturn } from "./diligence-documents-selector.types";

interface DiligenceDocumentSelectorProps {
  id: string;
  // for useDiligenceDocumentSelector()
  getDocumentsCallService: GetDiligenceDocumentsReturn["callService"];
  getDocumentsLoading: GetDiligenceDocumentsReturn["loading"];
  getDocumentsValue: GetDiligenceDocumentsReturn["value"];
  getDocumentsHasError: GetDiligenceDocumentsReturn["hasError"];
}

export const DiligenceDocumentSelector = ({
  id,
  getDocumentsCallService,
  getDocumentsLoading,
  getDocumentsValue,
  getDocumentsHasError,
}: DiligenceDocumentSelectorProps) => {
  const { documents, getDiligenceDocuments, hasFetchError, loading } =
    useDiligenceDocumentSelector(
      getDocumentsCallService,
      getDocumentsLoading,
      getDocumentsValue,
      getDocumentsHasError,
    );

  return (
    <div className="d-flex align-items-center flex-fill">
      <SelectSearchField
        options={documents}
        id={id}
        name="destinationDocumentId"
        label="Selecione o documento destino"
        placeholder="Selecionar"
      />

      {loading && <OLoader size="sm" />}
      {hasFetchError && !loading && (
        <RefreshGridButton onClick={getDiligenceDocuments} />
      )}
    </div>
  );
};
