import { OCol, ORFieldInput } from "@maestro/react";

export const NomeField = () => {
  return (
    <OCol xs={12} md={6}>
      <ORFieldInput
        dataAction="geral:texto:descricao"
        dataLabel="descricao"
        id="nome"
        name="nome"
        tag="text"
        placeholder="Preencher"
        label="Descrição"
        description="Nome da estratégia comercial"
        required
      />
    </OCol>
  );
};
