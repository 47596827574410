import { OCol, ORFieldInput } from "@maestro/react";

export const GracePeriodField = () => {
  return (
    <OCol xs={12} md={6}>
      <ORFieldInput
        dataAction="geral:texto:grace_period"
        dataLabel="grace_period"
        id="gracePeriod"
        name="gracePeriod"
        tag="text"
        placeholder="Preencher"
        label="Prazo de cura"
        maxlength={3}
        description="(N) dias após o vencimento em que a liquidação pode ser realizada sem penalidade por atraso"
      />
    </OCol>
  );
};
