import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { service } from "services";
import {
  EstrategiaRelationshipForm,
  LoadedDataValues,
} from "../../../../../components";
import { useEstrategiaTemplate } from "../_compose";

export const useEstrategiaTemplatesRelationship = () => {
  const { estrategia, estrategiaId, getCompanyEstrategia, hasError, loading } =
    useEstrategiaTemplate();

  const { callService, loading: submitLoading } = useServiceCall(
    service.quickfin.editEstrategiaRelationship,
  );

  const form = useForm<EstrategiaRelationshipForm>();

  const { handleSubmit, reset } = form;

  const submit = useMemo(
    () => (updateEstrategias: boolean, updateCedentes: boolean) =>
      handleSubmit(async (values) => {
        const { success } = await callService(
          estrategiaId,
          String(updateEstrategias),
          String(updateCedentes),
          {
            antecipaAutomaticamente: values.antecipaAutomaticamente === "yes",
            permiteOverride: values.permiteOverride === "yes",
            tipoContratoRelacionamento: values.tipoContratoRelacionamento,
            contractTemplateId: values.contractTemplateId,
            tipoContratoRelacionamentoAntecipaAutomaticamente:
              values.tipoContratoRelacionamentoAntecipaAutomaticamente,
            contractTemplateIdAntecipaAutomaticamente:
              values.contractTemplateIdAntecipaAutomaticamente,
          },
        );

        if (success)
          OToastManager.success(
            "Cadastro de relacionamentos salvo com sucesso",
          );
        else
          OToastManager.danger("Erro ao salvar o cadastro de relacionamentos");
      }),
    [callService, estrategiaId, handleSubmit],
  );

  useEffect(() => {
    const initial =
      estrategia?.configuracoesCadastroRelacionamento as LoadedDataValues;
    if (initial) {
      reset({
        antecipaAutomaticamente: initial.antecipaAutomaticamente ? "yes" : "no",
        permiteOverride: initial.permiteOverride ? "yes" : "no",
        tipoContratoRelacionamento: initial.tipoContratoRelacionamento,
        contractTemplateId: initial.contractTemplateId,
        tipoContratoRelacionamentoAntecipaAutomaticamente:
          initial.tipoContratoRelacionamentoAntecipaAutomaticamente,
        contractTemplateIdAntecipaAutomaticamente:
          initial.contractTemplateIdAntecipaAutomaticamente,
      });
    }
  }, [estrategia, reset]);

  return {
    estrategiaName: estrategia?.nome,
    form,
    getCompanyEstrategia,
    hasError,
    loading,
    submit,
    submitLoading,
  };
};
