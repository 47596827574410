import * as yup from "yup";
import { LoanContractsConfigFormValues } from "./loan-contracts-config-form.component";

export const loanContractsConfigValidationFormSchema: yup.ObjectSchema<LoanContractsConfigFormValues> =
  yup.object({
    product: yup.string().required("Este campo é obrigatório"),
    currency: yup.string().required("Este campo é obrigatório"),
    currencyQuoteType: yup.string().nullable().optional(),
    indexCode: yup.string().optional(),
    collateralTypes: yup.array().default([]),
    partnerSystemId: yup.number().optional(),
  });
