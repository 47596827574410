import { GetCompanyProductsResponse } from "services/hubloan/models";
import { useRoles } from "hooks/roles";
import { productMap } from "../../offers.utils";

interface ProductsProps {
  availableProducts: GetCompanyProductsResponse[];
}

export const Products = ({ availableProducts }: ProductsProps) => {
  const { hasRole } = useRoles();

  const filteredProducts = availableProducts.filter(
    (product) =>
      product.identification in productMap &&
      hasRole(productMap[product.identification]?.roles),
  );

  return (
    <>
      {filteredProducts.map((product) => {
        const props = productMap[product.identification];

        if (props?.content)
          return (
            <div key={product.id}>
              {props.content({
                ...props,
                id: product.id,
                limit: product.availableAmount,
                key: props.identification,
              })}
            </div>
          );

        return null;
      })}
    </>
  );
};
