export const options = [
  "Agendado",
  "AguardandoRegistro",
  "AprovadoBanco",
  "AprovadoCedente",
  "AprovadoLimiteSacado",
  "AprovadoOnboarding",
  "AprovadoSacado",
  "DisponivelParaCedente",
  "EmCancelamento",
  "EmCancelamentoPagfor",
  "ErrorValidacao",
  "FundingProprioRecusado",
  "IndisponivelParaCedente",
  "Inicial",
  "PagforCancelado",
  "Pago",
  "PendenteAgendamento",
  "PendenteAprovacaoBanco",
  "PendenteAprovacaoCedente",
  "PendenteAprovacaoSacado",
  "PendenteAprovacaoSacadoPosSolicitacaoCedente",
  "PendenteContratacao",
  "PendenteEligibilidade",
  "PendenteLimiteSacados",
  "PendenteOnboarding",
  "PendenteSolicitacaoTrade",
  "PendenteReleaseTrade",
  "PendenteValidacaoTaxas",
  "Recusado",
  "Removido",
  "TradeDesembolsado",
  "TradeEmAtraso",
  "TradeLiquidado",
  "TradeSolicitado",
];
