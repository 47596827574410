import { OToastManager } from "@maestro/core";
import { useCallback, useMemo } from "react";
import { service } from "services";
import { ProcessorConfig } from "services/hubcreditmanager/models/types/workflow/workflow.types";
import { BatchTemplatePropertyField } from "./_compose/batch-template-field/batch-template-field.component";
import { BooleanPropertyField } from "./_compose/boolean-property-field/boolean-property-field.component";
import { ConditionPropertyField } from "./_compose/condition-property-field/condition-property-field.component";
import { CreditLineField } from "./_compose/credit-line-field/credit-line-field.component";
import { FilePropertyField } from "./_compose/file-property-field/file-property-field.component";
import { FormPropertyField } from "./_compose/form-property-field/form-property-field.component";
import { GenericPropertyField } from "./_compose/generic-property-field/generic-property-field.component";
import { ProcessorConfigField } from "./_compose/processor-config-field/processor-config-field.component";
import { WorkflowConfigField } from "./_compose/workflow-config-field/workflow-config-field.component";

interface PropertyFieldComponentProps {
  processorConfigProperty: ProcessorConfig["properties"][number];
}

export const PropertyFieldComponent = ({
  processorConfigProperty,
}: PropertyFieldComponentProps) => {
  const handleSubmitProperty = useCallback(
    async (value: string, processorConfigPropertyId: number) => {
      try {
        await service.hubCreditManager.updateProcessorConfigProperty({
          processorConfigPropertyId,
          value: value === null ? null : String(value),
        });
      } catch {
        OToastManager.danger("Não foi possível atualizar a propriedade.");
      }
    },
    [],
  );

  const fields = useMemo(() => {
    switch (processorConfigProperty.processorProperty.type) {
      case "FORM":
        return (
          <FormPropertyField
            processorConfigProperty={processorConfigProperty}
            handleSubmitProperty={handleSubmitProperty}
          />
        );
      case "FILE":
        return (
          <FilePropertyField
            processorConfigProperty={processorConfigProperty}
          />
        );
      case "CONDITION":
        return (
          <ConditionPropertyField
            processorConfigProperty={processorConfigProperty}
            handleSubmitProperty={handleSubmitProperty}
          />
        );
      case "BOOLEAN":
        return (
          <BooleanPropertyField
            processorConfigProperty={processorConfigProperty}
            handleSubmitProperty={handleSubmitProperty}
          />
        );
      case "BATCH_TEMPLATE":
        return (
          <BatchTemplatePropertyField
            processorConfigProperty={processorConfigProperty}
            handleSubmitProperty={handleSubmitProperty}
          />
        );
      case "WORKFLOW_CONFIG":
        return (
          <WorkflowConfigField
            processorConfigProperty={processorConfigProperty}
            handleSubmitProperty={handleSubmitProperty}
          />
        );
      case "PROCESSOR_CONFIG":
        return (
          <ProcessorConfigField
            processorConfigProperty={processorConfigProperty}
            handleSubmitProperty={handleSubmitProperty}
          />
        );
      case "CREDIT_LINE":
        return (
          <CreditLineField
            processorConfigProperty={processorConfigProperty}
            handleSubmitProperty={handleSubmitProperty}
          />
        );
      default:
        return (
          <GenericPropertyField
            processorConfigProperty={processorConfigProperty}
            handleSubmitProperty={handleSubmitProperty}
          />
        );
    }
  }, [handleSubmitProperty, processorConfigProperty]);

  return <>{fields}</>;
};
