import { OToastManager } from "@maestro/core";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { service } from "services";
import { SimulationSummary } from "./simulation.types";

export interface UseSummaryProps {
  type?: string;
  id?: string;
}

export const useSummary = ({ type, id }: UseSummaryProps) => {
  const [summary, setSummary] = useState<SimulationSummary>();
  const [summaryLoading, setSummaryLoading] = useState(false);

  const hideForm = useMemo(
    () =>
      !summary?.isFirstPaymentDateChangeAvailable &&
      !summary?.isTermsChangeAvailable &&
      !summary?.agreement.partialLimitConsumeAllowed,
    [summary],
  );

  const getSummary = useCallback(async () => {
    try {
      if (!type || !id) return;

      setSummaryLoading(true);

      const { data } = await service.hubLoan.getSimulationSummary(type, id);

      setSummary(data);
    } catch (error) {
      const errorMessage = "Erro ao buscar resumo";
      OToastManager.danger(errorMessage);
      throw new Error(errorMessage);
    } finally {
      setSummaryLoading(false);
    }
  }, [type, id]);

  /**
   * Inicialização do Resumo, realizada apenas no primeiro render
   */
  const initialized = useRef(false);
  useEffect(() => {
    if (type && !initialized.current) {
      initialized.current = true;
      getSummary();
    }
  }, [type, getSummary]);

  return { summary, hideForm, summaryLoading };
};
