export const productTypes: Record<string, string> = {
  AGRO: "Crédito Agro",
  COMPRA_VENDA_IMOVEL: "Compra e Venda de Imóvel",
  FIDC: "Fidc",
  FORMALIZACAO_GARANTIAS: "Formalização de Garantias",
  FUMACA: "Fumaça",
  FUMACAPEAC: "Fumaça PEAC",
  IMPACTO_SOCIAL: "Impacto Social",
  KGIRO: "Capital de Giro",
  KGIROPEAC: "Capital de Giro PEAC",
  LIMITE_MAIS: "Limite Mais",
  RENEGOCIACAO: "Renegociação",
  SOLAR: "Crédito Solar",
  SOFTWARE_FINANCING: "Financiamento de Software",
};

export const settlementTypeMap: Record<string, string> = {
  DebitoEmConta: "Débito em Conta",
  Boleto: "Boleto",
};

export const rateTypeLabel: Record<string, string> = {
  ANUAL: "(a.a.)",
  MENSAL: "(a.m.)",
};

export const loanOperationsContractStatusOptions = [
  "Pending",
  "Requested",
  "WaitingSignatures",
  "FullySigned",
  "Completed",
  "Expired",
  "Canceled",
  "Error",
  "Refused",
  "WaitingApproval",
  "Uploaded",
];
