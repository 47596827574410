import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useCustomer } from "contexts/customer";
import { useServiceCall } from "hooks/service-call";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { PostEstrategiaComercialByCompanyIdBody } from "services/quickfin/models";
import { getValueFromMap } from "utils/get-value-from-map";
import {
  EstrategiaForm,
  addEstrategiaFormDefaultValues,
  addEstrategiaFormValidationSchema,
  productFormMap,
} from "../../../../../../components/estrategia-form";
import { buildPayload } from "./add-company-estrategia.utils";
import { isAxiosError } from "axios";

export const useAddCompanyEstrategia = () => {
  const {
    customer: { quickfinId },
  } = useCustomer();

  const { callService, loading } = useServiceCall(
    service.quickfin.addCompanyEstrategia,
  );

  const navigate = useNavigate();

  const form = useForm<EstrategiaForm>({
    defaultValues: addEstrategiaFormDefaultValues,
    resolver: yupResolver(addEstrategiaFormValidationSchema),
  });

  const { handleSubmit, watch } = form;

  const tipoProdutoWatch = watch("tipoProduto");

  const Form = getValueFromMap(productFormMap, tipoProdutoWatch);

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success, error } = await callService(
          quickfinId,
          buildPayload(values) as PostEstrategiaComercialByCompanyIdBody,
        );
        if (success) {
          OToastManager.success("Estratégia criada com sucesso");
          navigate(
            corporateRouter.routes.recebiveis.customer.estrategiaComercial.path,
          );
        } else {
          let message = "";
          if (isAxiosError(error)) {
            message = error.response?.data.message ?? "";
          } 
          OToastManager.danger("Não foi possível criar a estratégia. "+ message);
        }
      }),
    [callService, handleSubmit, navigate, quickfinId],
  );

  return {
    form,
    Form,
    loading,
    submit,
  };
};
