import * as yup from "yup";
import { EstrategiaForm, TipoProduto } from "./estrategia-form.types";

export const addEstrategiaFormDefaultValues = {
  id: null,
  tipoProduto: null,
  nome: null,
  modeloDeTaxa: null,
  exigeAprovacaoBanco: null,
  prazoLimiteOperacao: undefined,
  aprovacaoAposAntecipacaoSacado: "no",
  biometryRequired: "no",
  cancelaNaoAntecipadasD0: "no",
  cessaoOffshore: "no",
  collateralIdentification: null,
  collateralRequired: "no",
  comandoOperacao: null,
  contractTemplateId: null,
  diasCampanhaNotasNaoAntecipadasCedente: null,
  diasParaVencimentoDataRepasseRiscoCedente: null,
  diasParaVencimentoSacado: null,
  eligibilityType: null,
  emailsSacadoNotification: null,
  exigeAprovacaoSacado: "no",
  exigeIndicacaoHedge: "no",
  fccIdSacado: undefined,
  fundConfig: null,
  fundRelatedName: null,
  habilitaAntecipacoesParciais: null,
  habilitaPagFor: "no",
  hasGuarantors: "no",
  hasPayerValidation: "no",
  hiringContractTemplateId: null,
  hiringContractType: null,
  horarioLimiteOperacao: null,
  idContaDeRetirada: null,
  interestArrearsBase: null,
  interestArrearsRate: null,
  isLoanAssignment: "no",
  linkTaggeado: null,
  marketplaceId: null,
  portfolioId: null,
  creditorId: null,
  needsFilesForOperation: "no",
  needsFilesForReceivables: "no",
  notaDisponivelCedente: "no",
  notificacaoOnboardingSacado: "no",
  notificacaoRecebiveisAntecipados: "no",
  notificacaoTedDevolvidaCedente: "no",
  notificacaoTedDevolvidaSacado: "no",
  penaltyFee: null,
  permiteTaxaParticular: "no",
  pnlBook: undefined,
  pnlStrategy: undefined,
  rebateAlongaPrazo: "no",
  rebateRebarbaSacado: "no",
  rebateTolerance: undefined,
  reporteDiarioRiscoCedente: "no",
  reporteDiarioSacado: "no",
  reporteDiarioCCC: "no",
  reporteNotasNaoAntecipadasCedente: "no",
  requiresPMEAccount: null,
  riscoOperacao: null,
  sacadoDefineContaDesembolso: "no",
  sefazValidation: "no",
  sendErrorCallback: "no",
  tipoContrato: null,
  tipoOnboarding: null,
  tipoTaxaParticular: null,
  toCredit: "no",
  typeFIDC: null,
  visibilidadePrazoVencimentoCedente: null,
  visualizaRebateSacado: "no",
  worksiteEnvConfig: null,
  automaticAnticipation: "no",
  gracePeriod: null,
} as Record<keyof EstrategiaForm, unknown> as any;

const stringRequired = yup.string().required("Campo obrigatório");

const numberRequired = yup
  .number()
  .typeError("Valor deve ser um número")
  .required("Campo obrigatório");

const numberRequiredBiggerThanZero = numberRequired.moreThan(
  0,
  "Valor deve ser maior que zero",
);

const notRequired = yup.mixed().notRequired();

const arrayRequired = (schema: yup.AnySchema) =>
  yup
    .array()
    .of(schema)
    .required("Campo obrigatório")
    .min(1, "Campo obrigatório");

const validateWhenProductType = (
  products: TipoProduto[],
  schema: yup.AnySchema,
) =>
  yup.lazy((_, { parent }) =>
    products.includes(parent.tipoProduto) ? schema : notRequired,
  );

const validateWhenHasContract = (schema: yup.AnySchema) =>
  yup.lazy((_, { parent }) =>
    (parent as EstrategiaForm).tipoContrato &&
    (parent as EstrategiaForm).tipoContrato !== "SemContrato"
      ? schema
      : notRequired,
  );

const validateWhenHasHiringContract = (schema: yup.AnySchema) =>
  yup.lazy((_, { parent }) =>
    (parent as EstrategiaForm).hiringContractType &&
    (parent as EstrategiaForm).hiringContractType !== "SemContrato"
      ? schema
      : notRequired,
  );

const validateWhenRadio = (
  radio: keyof EstrategiaForm,
  schema: yup.AnySchema,
) =>
  yup.lazy((_, { parent }) =>
    (parent as EstrategiaForm)[radio] === "yes" ? schema : notRequired,
  );

export const addEstrategiaFormValidationSchema = yup.object<EstrategiaForm>({
  // aways required
  tipoProduto: stringRequired,
  nome: stringRequired,
  exigeAprovacaoBanco: stringRequired,
  prazoLimiteOperacao: numberRequired,
  portfolioId: numberRequired,
  instrumentCodeId: numberRequired,

  // sometimes required
  fccIdSacado: validateWhenProductType(
    ["Offshore", "FundingProprio"],
    numberRequired,
  ),
  idContaDeRetirada: validateWhenProductType(
    ["FundingProprio"],
    stringRequired,
  ),
  tipoOnboarding: validateWhenProductType(["RiscoSacado"], stringRequired),
  exigeAprovacaoSacado: validateWhenProductType(
    ["RiscoSacado"],
    stringRequired,
  ),
  comandoOperacao: validateWhenProductType(
    ["RiscoSacado", "FIDC", "Cartao"],
    stringRequired,
  ),
  typeFIDC: validateWhenProductType(["FIDC"], stringRequired),
  eligibilityType: validateWhenProductType(["FIDC"], stringRequired),
  riscoOperacao: validateWhenProductType(["Cartao"], stringRequired),
  penaltyFee: validateWhenProductType(["CCC"], numberRequired),
  interestArrearsRate: validateWhenProductType(["CCC"], numberRequired),
  interestArrearsBase: validateWhenProductType(["CCC"], stringRequired),
  hiringContractType: validateWhenProductType(
    ["CCC", "RiscoCedente"],
    stringRequired,
  ),
  tipoContrato: validateWhenProductType(
    ["RiscoCedente", "CCC"],
    stringRequired,
  ),

  // fields that show up when another is selected
  diasParaVencimentoDataRepasseRiscoCedente: validateWhenRadio(
    "reporteDiarioRiscoCedente",
    arrayRequired(stringRequired),
  ),
  collateralIdentification: validateWhenRadio(
    "collateralRequired",
    stringRequired,
  ),
  diasParaVencimentoSacado: validateWhenRadio(
    "reporteDiarioSacado",
    arrayRequired(stringRequired),
  ),
  diasCampanhaNotasNaoAntecipadasCedente: validateWhenRadio(
    "reporteNotasNaoAntecipadasCedente",
    arrayRequired(stringRequired),
  ),
  contractTemplateId: validateWhenHasContract(numberRequired),
  hiringContractTemplateId: validateWhenHasHiringContract(numberRequired),
  filesForOperation: validateWhenRadio(
    "needsFilesForOperation",
    arrayRequired(stringRequired),
  ),
  filesForReceivables: validateWhenRadio(
    "needsFilesForReceivables",
    arrayRequired(stringRequired),
  ),
  pnlBook: validateWhenRadio("isLoanAssignment", numberRequiredBiggerThanZero),
  pnlStrategy: validateWhenRadio(
    "isLoanAssignment",
    numberRequiredBiggerThanZero,
  ),
});
