import { YesNoRadioField } from "./yes-no-radio-field.component";

export const HabilitaAntecipacoesParciaisField = () => {
  return (
    <YesNoRadioField
      label="Habilitar antecipação parcial dos recebíveis"
      name="habilitaAntecipacoesParciais"
      description="Configuração que permite que o fornecedor possa escolher o valor a ser antecipado de um recebível. Essa configuração deve ser habilitada somente para o produto de cartão de crédito"
    />
  );
};
