import {
  GetApiAdminBigNumbersResponse,
  GetApiAdminConditionResponse,
  GetApiAdminConfigurableStatusResponse,
  GetApiAdminMinimumCriteriaResponse,
} from "services/hubcreditmanager/models/responses";
import { GetApiAdminCommonDomainResponse } from "services/hubcreditmanager/models/responses/get-api-admin-common-domain.response";

export const workflowRouteParams = {
  id: "id",
  documentId: "documentId",
  processorContextId: "processorContextId",
  proposedCreditLineId: "proposedCreditLineId",
  unitId: "unitId",
  batchTemplateColumnId: "batchTemplateColumnId",
  offerId: "offerId",
  gridId: "gridId",
  workflowConfigViewId: "workflowConfigViewId",
  viewBigNumberId: "viewBigNumberId",
  processorId: "processorId",
  scoreModuleId: "scoreModuleId",
  scoreBenefitId: "scoreBenefitId",
} as const;

type WorkflowParams = Record<keyof typeof workflowRouteParams, string>;

export type WorkflowProdutoFormById = Pick<WorkflowParams, "id">;
export type WorkflowProdutoLeadById = Pick<WorkflowParams, "id">;
export type WorkflowProdutoLeadByIdDocumentsByDocumentId = Pick<
  WorkflowParams,
  "id" | "documentId"
>;
export type WorkflowProdutoLeadByIdContextsByProcessorContextId = Pick<
  WorkflowParams,
  "id" | "processorContextId" | "processorId"
>;
export type WorkflowProdutoLeadByProposedCreditLineId = Pick<
  WorkflowParams,
  "id" | "proposedCreditLineId"
>;
export type WorkflowProdutoLeadByIdConsumerUnitsByUnitId = Pick<
  WorkflowParams,
  "id" | "unitId"
>;
export type WorkflowProdutoBatchByIdBatchFileTemplateColumnByColumnId = Pick<
  WorkflowParams,
  "id" | "batchTemplateColumnId"
>;
export type WorkflowProdutoWorkflowConfigByIdWorkflowConfigViewById = Pick<
  WorkflowParams,
  "id" | "workflowConfigViewId"
>;

export type WorkflowProdutoCondicoesById = Pick<WorkflowParams, "id">;
export type WorkflowProdutoWorkflowConfigById = Pick<WorkflowParams, "id">;
export type WorkflowProdutoProcessadoresById = Pick<WorkflowParams, "id">;
export type WorkflowProdutoMinimumCriteriaById = Pick<WorkflowParams, "id">;
export type WorkflowProdutoStatusById = Pick<WorkflowParams, "id">;
export type WorkflowBigNumbersById = Pick<WorkflowParams, "id">;
export type WorkflowProdutoCommonDomainById = Pick<WorkflowParams, "id">;
export type WorkflowProdutoScoreModuleById = Pick<
  WorkflowParams,
  "scoreModuleId"
>;
export type WorkflowProdutoScoreBenefitById = Pick<
  WorkflowParams,
  "scoreBenefitId"
>;

export interface WorkflowProdutoLeadsRouteQueryParams {
  selectedWorkflowConfig: string;
}

export interface WorkflowProdutoLeadsBatchRequestsRouteQueryParams {
  selectedWorkflowConfig: string;
}

export type WorkflowProdutoCondicoesDetalhesRouteQueryParms =
  GetApiAdminConditionResponse[number];

export type WorkflowProdutoStatusRouteQueryParms =
  GetApiAdminConfigurableStatusResponse[number];

export type WorkflowProdutoMinimumCriteriaRouteQueryParms =
  GetApiAdminMinimumCriteriaResponse[number];

export type WorkflowProdutoBigNumbersRouteQueryParms =
  GetApiAdminBigNumbersResponse[number];
export type WorkflowProdutoCommonDomainRouteQueryParms =
  GetApiAdminCommonDomainResponse[number];

export type WorkflowProdutoBatchRequestById = Pick<WorkflowParams, "id">;
export type WorkflowProdutoGridById = Pick<WorkflowParams, "gridId">;
export type WorkflowProdutoWorkflowConfigViewById = Pick<
  WorkflowParams,
  "workflowConfigViewId"
>;

export type WorkflowProdutoWorkflowConfigByIdWorkflowConfigViewByIdViewBigNumberById =
  Pick<WorkflowParams, "id" | "viewBigNumberId" | "workflowConfigViewId">;
