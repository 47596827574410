import { MappedStatus } from "./status-maps.types";

export const pendencyStatusMap = {
  CRIADA: { type: "info", text: "Criada" },
  PENDENTE: { type: "warning", text: "Pendente" },
  EM_ANALISE: { type: "warning", text: "Em análise" },
  PENDENTE_ENVIO_AUTOMACAO: { type: "warning", text: "Pendente automação" }, // automation
  EM_AUTOMACAO: { type: "info", text: "Em automação" }, // automation
  ANOTACAO_NAO_LIDA: { type: "warning", text: "Anotação não lida" }, // automation
  CONCLUIDA: { type: "success", text: "Concluída" },
  CANCELADA: { type: "danger", text: "Cancelada" },
} satisfies Record<HubEnergy.EDiligenceStepPendencyStatus, MappedStatus>;
