import { OTypography } from "@maestro/react";
import { masks } from "@maestro/utils";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { RouterButton } from "components/router-button";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";

export const creditLineConfigurationsDataSource =
  dataSourceCustomStoreGenerator(async () => {
    return service.hubCreditManager.getCreditLineConfigurations().catch(() => {
      throw new Error(
        "Um erro ocorreu ao tentar buscar as configurações das linhas de crédito.",
      );
    });
  });

export const creditLineConfigurationsGrid = {
  datagrid: {
    noDataText: "Nenhuma configuração de linhas de crédito foi encontrada.",
    headerFilter: { visible: true, allowSearch: true },
    paging: {
      pageSize: 10,
    },
  },
  columns: [
    {
      sortOrder: "asc",
      dataField: "id",
      caption: "Id",
      allowEditing: false,
    },
    {
      dataField: "creditLine.name",
      caption: "Nome",
      alignment: "left",
    },
    {
      dataField: "creditLine.identification",
      caption: "Identificação",
      alignment: "left",
    },
    {
      dataField: "creditLine.product",
      caption: "Produto",
      alignment: "left",
    },
    {
      dataField: "partner",
      caption: "Parceiro",
      alignment: "left",
      customizeText: ({ value }) => masks.cnpj(value),
    },
    {
      dataField: "collateralTypes",
      caption: "Garantias",
      alignment: "left",
    },
    {
      caption: "Book / Estratégia",
      alignment: "left",
      cellRender: ({ data }) => {
        return data?.bookStrategy ? (
          <div className="d-flex flex-column">
            <OTypography>
              {`${data.bookStrategy.book.bookName} (${data.bookStrategy.book.bookCr})`}
            </OTypography>
            <OTypography>
              {`${data.bookStrategy.strategy.strategyName} (${data.bookStrategy.strategy.strategyCr})`}
            </OTypography>
          </div>
        ) : (
          ""
        );
      },
    },
    {
      alignment: "left",
      role: roles.emprestimos.product
        .contingencyCreditLineConfigurationsUpdatePage.role,
      cellRender: ({ data }) => (
        <RouterButton
          dataAction="lista_processadores:botao:editar"
          dataLabel="editar"
          href={corporateRouter.routes.emprestimos.product.contingency.creditLinesConfigurations.update.path(
            {
              id: Number(data.id),
            },
          )}
          outline
        >
          Editar
        </RouterButton>
      ),
    },
  ],
} satisfies ODataGridGeneratorConfig<
  NonNullable<
    Awaited<
      ReturnType<typeof service.hubCreditManager.getCreditLineConfigurations>
    >["data"]
  >[number]
>;
