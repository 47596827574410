import { OCol, ORFieldInput, ORow, OTypography } from "@maestro/react";
import { useWorkflow } from "../../../../../../../../../../../../components/hooks/use-workflow.context";

export const ProcessorConfigBasicData = () => {
  const {
    processorConfig: { value: selectedProcessorConfig },
  } = useWorkflow();

  return (
    <div className="d-flex flex-column gap-4">
      <OTypography size="lg">Dados básicos</OTypography>
      <ORow
        className="d-flex gap-5"
        key={`basic-data-${selectedProcessorConfig?.id}`}
      >
        <OCol>
          <ORFieldInput
            id="processorName"
            name="processorName"
            tag="text"
            label="Nome"
            labelSize="md"
          />
        </OCol>
        <OCol className="d-flex flex-column gap-3">
          <OTypography>Tipo</OTypography>
          <OTypography>{selectedProcessorConfig?.processor?.type}</OTypography>
        </OCol>
      </ORow>
      <ORow>
        <OCol>
          <ORFieldInput
            id="description"
            name="description"
            tag="text"
            label="Descrição"
            labelSize="md"
          />
        </OCol>
      </ORow>
    </div>
  );
};
