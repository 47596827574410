import { OOption, ORFieldSelect } from "@maestro/react";
import { useMemo } from "react";
import { getProcessorPropertyFieldId } from "../../../utils";
import { GenericPropertyFieldProps } from "../../property-field.types";
import { useFormPropertyField } from "./form-property-field.hook";

export const FormPropertyField = ({
  processorConfigProperty,
  handleSubmitProperty,
}: GenericPropertyFieldProps) => {
  const { processorProperty, id } = processorConfigProperty;
  const { formList } = useFormPropertyField({
    processorConfigProperty,
    handleSubmitProperty,
  });

  const processorPropertyFieldId = getProcessorPropertyFieldId(
    processorProperty.name,
    id,
  );

  const formPropertyField = useMemo(() => {
    return (
      <ORFieldSelect
        key={processorProperty.name + id}
        id={processorPropertyFieldId}
        name={processorPropertyFieldId}
        placeholder="Selecione"
        label={processorProperty.description}
      >
        <div>
          {formList?.map((form) => (
            <OOption key={form.identification} value={form.identification}>
              {form.name}
            </OOption>
          ))}
          <OOption
            value=""
            key={`${processorProperty.name.toLowerCase()}-form-property-null`}
          >
            Nenhum
          </OOption>
        </div>
      </ORFieldSelect>
    );
  }, [processorProperty, formList, id]);

  return <>{formPropertyField}</>;
};
