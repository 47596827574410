import { OToastManager } from "@maestro/core";
import { isAxiosError } from "axios";
import {
  EstrategiaJson,
  PatchTemplateEstrategiaComercialBody,
  PostTemplateEstrategiaComercialBody,
} from "services/quickfin/models";
import { getValueFromMap } from "utils/get-value-from-map";
import { EstrategiaForm } from "../../../../../../components/estrategia-form";
import { errorMessageMap, radioToBoolean } from "../../../../../../utils";

export const toastErrors = (err: unknown) => {
  if (isAxiosError<{ key: string; message: string }[]>(err)) {
    const errors = err.response?.data;
    if (errors?.[0].key && errors?.[0].message) {
      errors.forEach((e) => {
        const message =
          getValueFromMap(errorMessageMap, e.message) ??
          errorMessageMap.default;
        OToastManager.danger(message);
      });
    }
  }
};

const cleanObject = <T extends object>(obj: T): T =>
  Object.fromEntries(
    Object.entries(obj).reduce((curr, [key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        if (Array.isArray(value)) {
          curr.push([
            key,
            value.filter(
              (val) => val !== null && val !== undefined && val !== "",
            ),
          ]);
        } else if (typeof value === "object") {
          curr.push([key, cleanObject(value)]);
        } else {
          curr.push([key, value]);
        }
      }

      return curr;
    }, [] as [unknown, unknown][]),
  );

export const buildPayload = (
  values: EstrategiaForm,
  updateEstrategias: boolean,
  withId?: boolean,
):
  | PatchTemplateEstrategiaComercialBody
  | PostTemplateEstrategiaComercialBody => {
  const estrategiaComercial: PostTemplateEstrategiaComercialBody["estrategiaComercial"] =
    {
      ...(withId && { id: values.id }),
      nome: values.nome,
      modeloDeTaxa: values.modeloDeTaxa,
      exigeAprovacaoBanco: radioToBoolean(values.exigeAprovacaoBanco) ?? false,
      prazoLimiteOperacao: values.prazoLimiteOperacao,
      instrumentCodeId: values.instrumentCodeId,

      // contaLiquidacaoId: null,
      configuracoesCadastroRelacionamento: {
        contractTemplateId: values.contractTemplateId,
      },
      horarioLimiteOperacao: values.horarioLimiteOperacao,
      tipoOnboarding: values.tipoOnboarding,
      biometryRequired: radioToBoolean(values.biometryRequired),
      pnlBook: values.pnlBook,
      pnlStrategy: values.pnlStrategy,
      penaltyFee: values.penaltyFee,
      interestArrearsRate: values.interestArrearsRate,
      approvalGroups: values.approvalGroups,
      fccIdSacado: values.fccIdSacado,
      exigeIndicacaoHedge: radioToBoolean(values.exigeIndicacaoHedge),
      exigeAprovacaoSacado: radioToBoolean(values.exigeAprovacaoSacado),
      reporteDiarioSacado: radioToBoolean(values.reporteDiarioSacado),
      diasParaVencimentoSacado: values.diasParaVencimentoSacado?.map(Number),
      emailsSacadoNotification: values.emailsSacadoNotification,
      marketplaceId: values.marketplaceId === -1 ? null : values.marketplaceId,
      toCredit: radioToBoolean(values.toCredit),
      portfolioId: values.portfolioId,
      creditorId: values.creditorId,
      tipoContrato: values.tipoContrato,
      contractTemplateId: values.contractTemplateId,
      cessaoOffshore: radioToBoolean(values.cessaoOffshore),
      reporteDiarioRiscoCedente: radioToBoolean(
        values.reporteDiarioRiscoCedente,
      ),
      reporteDiarioCCC: radioToBoolean(values.reporteDiarioCCC),
      diasParaVencimentoDataRepasseRiscoCedente:
        values.diasParaVencimentoDataRepasseRiscoCedente,
      habilitaPagFor: radioToBoolean(values.habilitaPagFor),
      comandoOperacao: values.comandoOperacao,
      aprovacaoAposAntecipacaoSacado: radioToBoolean(
        values.aprovacaoAposAntecipacaoSacado,
      ),
      permiteTaxaParticular: radioToBoolean(values.permiteTaxaParticular),
      tipoTaxaParticular: values.tipoTaxaParticular,
      sacadoDefineContaDesembolso: radioToBoolean(
        values.sacadoDefineContaDesembolso,
      ),
      cancelaNaoAntecipadasD0: radioToBoolean(values.cancelaNaoAntecipadasD0),
      visualizaRebateSacado: radioToBoolean(values.visualizaRebateSacado),
      rebateRebarbaSacado: radioToBoolean(values.rebateRebarbaSacado),
      rebateAlongaPrazo: radioToBoolean(values.rebateAlongaPrazo),
      visibilidadePrazoVencimentoCedente:
        values.visibilidadePrazoVencimentoCedente,
      rebateTolerance: values.rebateTolerance,
      sendErrorCallback: radioToBoolean(values.sendErrorCallback),
      collateralRequired: radioToBoolean(values.collateralRequired),
      collateralIdentification: values.collateralIdentification,
      notificacaoOnboardingSacado: radioToBoolean(
        values.notificacaoOnboardingSacado,
      ),
      notificacaoTedDevolvidaSacado: radioToBoolean(
        values.notificacaoTedDevolvidaSacado,
      ),
      notaDisponivelCedente: radioToBoolean(values.notaDisponivelCedente),
      reporteNotasNaoAntecipadasCedente: radioToBoolean(
        values.reporteNotasNaoAntecipadasCedente,
      ),
      diasCampanhaNotasNaoAntecipadasCedente:
        values.diasCampanhaNotasNaoAntecipadasCedente?.map(Number),
      notificacaoRecebiveisAntecipados: radioToBoolean(
        values.notificacaoRecebiveisAntecipados,
      ),
      notificacaoTedDevolvidaCedente: radioToBoolean(
        values.notificacaoTedDevolvidaCedente,
      ),
      needsFilesForReceivables: radioToBoolean(values.needsFilesForReceivables),
      filesForReceivables: values.filesForReceivables,
      needsFilesForOperation: radioToBoolean(values.needsFilesForOperation),
      filesForOperation: values.filesForOperation,
      sefazValidation: radioToBoolean(values.sefazValidation),
      typeFIDC: values.typeFIDC,
      eligibilityType: values.eligibilityType,
      worksiteEnvConfig: values.worksiteEnvConfig,
      fundConfig: values.fundConfig,
      fundRelatedName: values.fundRelatedName,
      habilitaAntecipacoesParciais: radioToBoolean(
        values.habilitaAntecipacoesParciais,
      ),
      riscoOperacao: values.riscoOperacao,
      requiresPMEAccount: radioToBoolean(values.requiresPMEAccount),
      linkTaggeado: values.linkTaggeado,
      idContaDeRetirada: values.idContaDeRetirada,
      interestArrearsBase: values.interestArrearsBase,
      hasGuarantors: radioToBoolean(values.hasGuarantors),
      hasPayerValidation: radioToBoolean(values.hasPayerValidation),
      gracePeriod: values.gracePeriod,
    };

  const estrategiaJSON: EstrategiaJson = {
    tipoProduto: values.tipoProduto,
    notificacoes: {
      reporteDiarioSacado: radioToBoolean(values.reporteDiarioSacado),
      reporteDiarioCCC: radioToBoolean(values.reporteDiarioCCC),
      reporteDiarioRiscoCedente: radioToBoolean(
        values.reporteDiarioRiscoCedente,
      ),
      diasParaVencimentoSacado: values.diasParaVencimentoSacado?.map(Number),
      diasParaVencimentoDataRepasseRiscoCedente:
        values.diasParaVencimentoDataRepasseRiscoCedente?.map(Number),
      emailsSacadoNotification: values.emailsSacadoNotification,
      notaDisponivelCedente: radioToBoolean(values.notaDisponivelCedente),
      reporteNotasNaoAntecipadasCedente: radioToBoolean(
        values.reporteNotasNaoAntecipadasCedente,
      ),
      diasCampanhaNotasNaoAntecipadasCedente:
        values.diasCampanhaNotasNaoAntecipadasCedente?.map(Number),
      notificacaoRecebiveisAntecipados: radioToBoolean(
        values.notificacaoRecebiveisAntecipados,
      ),
      notificacaoOnboardingSacado: radioToBoolean(
        values.notificacaoOnboardingSacado,
      ),
      notificacaoTedDevolvidaSacado: radioToBoolean(
        values.notificacaoTedDevolvidaSacado,
      ),
      notificacaoTedDevolvidaCedente: radioToBoolean(
        values.notificacaoTedDevolvidaCedente,
      ),
    },
    collateralIdentification: values.collateralIdentification,
    exigeAprovacaoSacado: radioToBoolean(values.exigeAprovacaoSacado),
    sacadoDefineContaDesembolso: radioToBoolean(
      values.sacadoDefineContaDesembolso,
    ),
    cancelaNaoAntecipadasD0: radioToBoolean(values.cancelaNaoAntecipadasD0),
    idContaDeRetirada: values.idContaDeRetirada,

    tipoContrato: values.tipoContrato,
    contractTemplateId: values.contractTemplateId,
    hiringContractType: values.hiringContractType,
    hiringContractTemplateId: values.hiringContractTemplateId,
    cessaoOffshore: radioToBoolean(values.cessaoOffshore),
    collateralRequired: radioToBoolean(values.collateralRequired),
    habilitaPagFor: radioToBoolean(values.habilitaPagFor),
    comandoOperacao: values.comandoOperacao,
    aprovacaoAposAntecipacaoSacado: radioToBoolean(
      values.aprovacaoAposAntecipacaoSacado,
    ),
    permiteTaxaParticular: radioToBoolean(values.permiteTaxaParticular),
    tipoTaxaParticular: values.tipoTaxaParticular,
    visualizaRebateSacado: radioToBoolean(values.visualizaRebateSacado),
    rebateRebarbaSacado: radioToBoolean(values.rebateRebarbaSacado),
    rebateAlongaPrazo: radioToBoolean(values.rebateAlongaPrazo),
    visibilidadePrazoVencimentoCedente:
      values.visibilidadePrazoVencimentoCedente,
    rebateTolerance: values.rebateTolerance,
    sendErrorCallback: radioToBoolean(values.sendErrorCallback),
    needsFilesForReceivables: radioToBoolean(values.needsFilesForReceivables),
    filesForReceivables: values.filesForReceivables,
    needsFilesForOperation: radioToBoolean(values.needsFilesForOperation),
    filesForOperation: values.filesForOperation,
    sefazValidation: radioToBoolean(values.sefazValidation),
    typeFIDC: values.typeFIDC,
    eligibilityType: values.eligibilityType,
    worksiteEnvConfig: values.worksiteEnvConfig,
    fundConfig: values.fundConfig,
    fundRelatedName: values.fundRelatedName,
    habilitaAntecipacoesParciais: radioToBoolean(
      values.habilitaAntecipacoesParciais,
    ),
    riscoOperacao: values.riscoOperacao,
    requiresPMEAccount: radioToBoolean(values.requiresPMEAccount),
    isLoanAssignment: radioToBoolean(values.isLoanAssignment),
    linkTaggeado: values.linkTaggeado,
    penaltyFee: values.penaltyFee,
    interestArrearsRate: values.interestArrearsRate,
    interestArrearsBase: values.interestArrearsBase,
    hasGuarantors: radioToBoolean(values.hasGuarantors),
    hasPayerValidation: radioToBoolean(values.hasPayerValidation),
    automaticAnticipation: radioToBoolean(values.automaticAnticipation),
  };

  return cleanObject({
    atualizaEstrategiasVinculadas: updateEstrategias,
    estrategiaComercial,
    estrategiaJson: JSON.stringify(estrategiaJSON),
  });
};
