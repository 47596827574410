import { OCol, OOption, ORFieldSelect } from "@maestro/react";

const options = [
  {
    value: "SemContrato",
    label: "Sem contrato",
  },
  {
    value: "PorOperacao",
    label: "Por operação",
  },
];

const optionsCCC = [
  {
    value: "PorOperacao",
    label: "Por operação",
  },
  {
    value: "CessaoFutura",
    label: "Cessao futura",
  },
];

interface TipoContratoFieldProps {
  required?: boolean;
  type?: string;
}

export const TipoContratoField = ({
  required,
  type,
}: TipoContratoFieldProps) => {
  return (
    <OCol xs={12} md={6}>
      <ORFieldSelect
        dataAction="geral:select:tipo_contrato"
        dataLabel="tipo_contrato"
        id="tipoContrato"
        name="tipoContrato"
        label="Tipo de contrato"
        placeholder="Selecionar"
        required={required}
      >
        {type === "CCC"
          ? optionsCCC.map(({ label, value }) => (
              <OOption key={value} value={value}>
                {label}
              </OOption>
            ))
          : options.map(({ label, value }) => (
              <OOption key={value} value={value}>
                {label}
              </OOption>
            ))}
      </ORFieldSelect>
    </OCol>
  );
};
