import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { validationSchema } from "../_compose/profile-form/profile-form.form";
import { FormValuesType } from "../_compose/profile-form/profile-form.types";

export const useGatekeeperProfilesAdd = () => {
  const navigate = useNavigate();
  const { routes } = corporateRouter;

  const [loading, setLoading] = useState(false);

  const form = useForm<FormValuesType>({
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit } = form;

  const submit = handleSubmit(async (values) => {
    try {
      setLoading(true);

      const {
        isBuiltIn,
        isCustom,
        portal,
        product,
        profile,
        unavailablePermissionsDescription: unavailable,
      } = values;

      const payload = {
        profile: {
          ...values,
          group: values.groupName,
          unavailablePermissionsDescription: unavailable ?? "",
          name: `${portal}:${product}.${profile}`,
          isAvailableForCustomProfiles: isCustom === "true",
          isBuiltIn: isBuiltIn === "true",
        },
      };

      await service.gatekeeper.addProfile(payload);

      OToastManager.success("Profile criado com sucesso");

      navigate(routes.ferramentas.product.gatekeeper.profiles.path);
    } catch (err) {
      OToastManager.danger("Erro para salvar os dados");
    } finally {
      setLoading(false);
    }
  });

  return {
    form,
    submit,
    loading,
  };
};
