import { OOption, ORFieldSelect } from "@maestro/react";
import { cases, masks } from "@maestro/utils";
import { DetailsFields } from "components/details-card";
import { useRoles } from "hooks/roles";
import { GetApiAdminLoanByIdDetailsResponse } from "services/hubloan/models";
import { currencySymbol, mapCurrencySymbol } from "utils/currency";
import { format } from "utils/date";
import { loanStatus } from "../../../../../components/utils/loan-status.utils";
import { emprestimosRoles } from "../../../../../roles/emprestimos.roles";
import {
  productTypes,
  rateTypeLabel,
  settlementTypeMap,
} from "../../loan-operations.utils";
import { LoanStatusCards } from "./_compose/loan-status-cards.component";

type LoanOperationsData = NonNullable<
  NonNullable<GetApiAdminLoanByIdDetailsResponse>
>;

export const OperationsDetailsGenerator = (loanData: LoanOperationsData) => {
  const { hasRole } = useRoles();
  return {
    details: {
      items: [
        {
          label: "Nome da empresa",
          value: loanData?.company?.name ?? "-",
          grid: { md: 4, xl: 3 },
        },
        {
          label: "CPF/CNPJ",
          value: masks.cpfCnpj(loanData?.company?.taxId) ?? "-",
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Produto",
          value:
            productTypes[loanData?.product || ""] ||
            cases.title(loanData?.product),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Status Atual",
          value: (
            <ORFieldSelect
              id="loanStatus"
              name="loanStatus"
              disabled={
                !hasRole(
                  emprestimosRoles.customer.operationsDetailsConfirmStatus.role,
                )
              }
            >
              {Object.values(loanStatus).map((option) => (
                <OOption key={option.value} value={option.value}>
                  {option.text}
                </OOption>
              ))}
            </ORFieldSelect>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Data de requisição",
          value: format.dateTime(loanData?.createdDate) ?? "-",
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Data de expiração",
          value: format.dateTime(loanData?.expireDate) ?? "-",
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Valor Solicitado",
          value: masks.currency(
            loanData?.amount,
            mapCurrencySymbol(loanData?.currency),
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: `Taxa ${
            rateTypeLabel[loanData?.interest?.interestRateType] ?? ""
          }`,
          value: `${
            loanData.interest?.indexCode === "CDIE" ? "CDI + " : ""
          }${masks.percentage(loanData?.rate ?? 0)}`,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Impostos",
          value:
            masks.currency(
              loanData?.settlement?.iofAmount,
              currencySymbol.BRL,
            ) ?? "-",
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Quantidade de parcelas",
          value: loanData?.term,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Saldo devedor atual",
          value: masks.currency(
            loanData?.settlement?.curveValue,
            mapCurrencySymbol(loanData?.currency),
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Forma de pagamento",
          value: loanData?.settlement.settlementType
            ? settlementTypeMap[loanData?.settlement.settlementType || ""] ||
              loanData?.settlement.settlementType
            : "Não informado",
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Garantias",
          value: loanData?.warranties?.length
            ? loanData.warranties.join(", ")
            : "-",
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Identificador",
          value: loanData?.identification ?? "-",
          grid: { md: 4, xl: 3 },
        },
      ],
    },
    status: {
      title: "Histórico de Status",
      visible: !!loanData?.loanTypeConfiguration,
      items: [
        {
          value: <LoanStatusCards loanData={loanData} />,
        },
      ],
    },
  } satisfies DetailsFields;
};
