import { masks } from "@maestro/utils";
import {
  dataSourceODataStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import {
  calculateNameAndTaxIdFilterExpression,
  NameAndTaxIdFormatted,
} from "components/name-and-tax-id-formatted";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { hubEnergyEndpoints } from "services/hubenergy/hubenergy.endpoints";
import { buildCustomerLink } from "utils/build-customer-link";
import { fastCpfCnpjMask } from "utils/mask/fast-cpf-cnpj-mask";
import { gridStorage } from "utils/storage";
import {
  configureHeaderFilterDataSource,
  diligenceStepStatusMap,
  disableHeaderFilterPagination,
  energyTradeStatusMap,
  MappedStatus,
  odataDateFilterWorkaround,
  tradeProductTypeMap,
} from "../../utils";
import { EntityOnboardingStatusBadge } from "../entity-onboarding-status-badge";
import { MappedStatusBadge } from "../mapped-status-badge";
import {
  calculateMigrationStatusCellValue,
  exportOnlyColumnNames,
  exportOnlyColumns,
  highlightNearMigrationDate,
} from "./consumer-units.utils";
import { DiligenceStatusesCell } from "./_compose";

export const buildDataSource = (customerTaxId?: string) =>
  dataSourceODataStoreGenerator<HubEnergy.ConsumerUnitDetailsResponse>(
    hubEnergyEndpoints.odata.consumerUnits,
    {
      odataStoreOptions: {
        // Set filter this way so that it can't be cleared
        beforeSend: ({ params }) => {
          if (customerTaxId) {
            if (params.$filter) {
              // eslint-disable-next-line no-param-reassign
              params.$filter = `(customerTaxId eq '${customerTaxId}') and (${params.$filter})`;
            } else {
              // eslint-disable-next-line no-param-reassign
              params.$filter = `customerTaxId eq '${customerTaxId}'`;
            }
          }
          odataDateFilterWorkaround(params);
        },
      },
    },
  );

const buildDetailsLink = (data: HubEnergy.ConsumerUnitODataResponse) =>
  buildCustomerLink(
    data.customerTaxId,
    data.diligenceStatus
      ? corporateRouter.routes.energia.customer.consumerUnit.diligence.path({
          consumerUnitId: data.id,
        })
      : corporateRouter.routes.energia.customer.consumerUnit.details.path({
          consumerUnitId: data.id,
        }),
  );

const [postProcessConsumerUnit, calculateConsumerUnitCellValue] =
  configureHeaderFilterDataSource<HubEnergy.ConsumerUnitODataResponse>()(
    [
      "consumerUnitOfficialName",
      "consumerUnitTaxId",
      "installationNumber",
    ] as const,
    (name, taxId, instNumber) =>
      `${name} - ${fastCpfCnpjMask(taxId)} - ${instNumber}`,
  );

const [postProcessDistributor, calculateDistributorCellValue] =
  configureHeaderFilterDataSource<HubEnergy.ConsumerUnitODataResponse>()(
    [
      "distributorName",
      "distributorTaxId",
      "consumerUnitIsEnergyOnboardingCompleted",
    ] as const,
    (name, taxId) => `${name} - ${fastCpfCnpjMask(taxId)}`,
  );

export const buildConsumerUnitsGrid = (
  isCustomerView: boolean,
): ODataGridGeneratorConfig<HubEnergy.ConsumerUnitODataResponse> => ({
  datagrid: {
    noDataText: "Nenhuma unidade consumidora",
    filterRow: { visible: true },
    headerFilter: { visible: true },
    pager: { showPageSizeSelector: true },
    stateStoring: gridStorage(`mle-consumerUnitsGrid-${isCustomerView}`),
    onRowPrepared: highlightNearMigrationDate,
    exportGrid: {
      onExporting: ({ component }) => {
        component?.beginUpdate();
        exportOnlyColumnNames.forEach((columnName) => {
          component?.columnOption(columnName, "visible", true);
        });
      },
      onExported: ({ component }) => {
        exportOnlyColumnNames.forEach((columnName) => {
          component?.columnOption(columnName, "visible", false);
        });
        component?.endUpdate();
      },
    },
    columnAutoWidth: false,
    columnResizingMode: "nextColumn",
    syncLookupFilterValues: false,
  },
  columns: [
    {
      dataField: "id",
      dataType: "number",
      role: roles.energia.customer.consumerUnitDetails.role,
      cellRender: ({ data }) => <DetailsLink href={buildDetailsLink(data)} />,
      caption: "",
      allowFiltering: true,
      allowHeaderFiltering: false,
      allowSorting: true,
      width: "auto",
      alignment: "left",
      allowExporting: false,
    },
    ...exportOnlyColumns,
    {
      dataField: "consumerUnitOfficialName",
      dataType: "string",
      caption: "Unidade consumidora",
      calculateFilterExpression: calculateNameAndTaxIdFilterExpression(
        ["consumerUnitOfficialName", "consumerUnitName", "installationNumber"],
        "consumerUnitTaxId",
      ),
      cellRender: ({ data }) =>
        isCustomerView ? (
          <NameAndTaxIdFormatted
            name={data.alias}
            subtexts={[
              data.consumerUnitOfficialName,
              masks.cpfCnpj(data.consumerUnitTaxId),
              `Nº instalação: ${data.installationNumber}`,
            ]}
          />
        ) : (
          <NameAndTaxIdFormatted
            name={data.consumerUnitOfficialName}
            subtexts={[
              masks.cpfCnpj(data.consumerUnitTaxId),
              `Nº instalação: ${data.installationNumber}`,
            ]}
          />
        ),
      calculateCellValue: calculateConsumerUnitCellValue,
      headerFilter: {
        dataSource: (options) => {
          postProcessConsumerUnit(options);
          disableHeaderFilterPagination(options);
        },
      },
      allowHeaderFiltering: !isCustomerView,
      allowSorting: true,
      allowExporting: false,
      minWidth: 180,
    },
    {
      visible: isCustomerView,
      dataType: "boolean",
      dataField: "isMainUnit",
      caption: "Unidade principal",
      headerFilter: { dataSource: disableHeaderFilterPagination },
    },
    {
      dataField: "distributorName",
      dataType: "string",
      caption: "Distribuidora",
      calculateFilterExpression: calculateNameAndTaxIdFilterExpression(
        ["distributorOfficialName", "distributorName"],
        "distributorTaxId",
      ),
      cellRender: ({ data }) => (
        <NameAndTaxIdFormatted
          name={data.distributorName}
          taxId={data.distributorTaxId}
        />
      ),
      calculateCellValue: calculateDistributorCellValue,
      headerFilter: {
        dataSource: (options) => {
          postProcessDistributor(options);
          disableHeaderFilterPagination(options);
        },
      },
      allowSorting: true,
      allowExporting: false,
      minWidth: 180,
    },
    {
      dataField: "migrationDate",
      dataType: "date",
      format: "shortDate",
      caption: "Data da migração",
      width: "auto",
      headerFilter: { dataSource: disableHeaderFilterPagination },
    },
    {
      dataField: "tradeProductType",
      caption: "Produto",
      dataType: "string",
      headerFilter: {
        dataSource: Object.entries(tradeProductTypeMap).map(
          ([key, value]: [string, string]) => ({
            text: value,
            value: key,
          }),
        ),
      },
      lookup: {
        dataSource: Object.entries(tradeProductTypeMap).map(
          ([key, value]: [string, string]) => ({
            text: value,
            value: key,
          }),
        ),
        displayExpr: "text",
        valueExpr: "value",
      },
      customizeText: ({ value }) => value ?? "-",
      width: "auto",
    },
    {
      visible: isCustomerView,
      dataField: "consumerUnitIsEnergyOnboardingCompleted",
      dataType: "boolean",
      caption: "Onboarding",
      cellRender: ({ data }) => (
        <EntityOnboardingStatusBadge
          isEnergyOnboardingCompleted={
            data.consumerUnitIsEnergyOnboardingCompleted
          }
        />
      ),
      width: "auto",
      allowExporting: false,
      headerFilter: { dataSource: disableHeaderFilterPagination },
    },
    {
      visible: !isCustomerView,
      dataField: "tradeStatus",
      dataType: "string",
      caption: "Contratação",
      width: "auto",
      cellRender: ({ data }) =>
        data.tradeStatus ? (
          <MappedStatusBadge
            map={energyTradeStatusMap}
            status={data.tradeStatus}
          />
        ) : (
          "-"
        ),
      headerFilter: {
        dataSource: Object.entries(energyTradeStatusMap).map(
          (item: [string, MappedStatus]) => ({
            text: item[1].text,
            value: item[0],
          }),
        ),
      },
      lookup: {
        dataSource: Object.entries(energyTradeStatusMap).map(
          (item: [string, MappedStatus]) => ({
            text: item[1].text,
            value: item[0],
          }),
        ),
        displayExpr: "text",
        valueExpr: "value",
      },
      ...(!isCustomerView && {
        selectedFilterOperation: "=",
        filterValue: "EM_DILIGENCIA",
      }),
    },
    {
      dataType: "string",
      caption: "Status da migração",
      cssClass: "dx-word-wrap",
      calculateCellValue: ({ diligenceStepsStatuses, diligenceStatus }) =>
        calculateMigrationStatusCellValue(
          diligenceStatus,
          diligenceStepsStatuses,
        ),
      cellRender: ({ data }) => (
        <DiligenceStatusesCell
          diligenceStatus={data.diligenceStatus}
          diligenceStepsStatuses={data.diligenceStepsStatuses}
        />
      ),
      allowFiltering: true,
      headerFilter: {
        dataSource: Object.entries(diligenceStepStatusMap).map(
          (item: [string, MappedStatus]) => ({
            text: item[1].text,
            value: item[1].text,
          }),
        ),
        searchMode: "contains",
      },
      calculateFilterExpression(...args) {
        if (this.defaultCalculateFilterExpression) {
          const def = this.defaultCalculateFilterExpression(...args) as any[];
          def[1] = "contains";
          return def;
        }
        return "";
      },
      width: "auto",
    },
  ],
});
