import { OLoader } from "@maestro/react";
import React from "react";
import { useAuthorization } from "contexts/auth";

interface AuthorizationGuardProps {
  children: React.ReactNode;
}

/**
 * Prevent children from rendering (and trying to make requests) while there is no authorization
 */
export const AuthorizationGuard = ({ children }: AuthorizationGuardProps) => {
  const { authorization } = useAuthorization();

  if (!authorization && !window.location.pathname.startsWith("/acesso")) {
    return <OLoader absolute data-component="AuthorizationGuard" />;
  }

  return children as JSX.Element;
};
