import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { MleByPendencyTypeIdRouteParams } from "../../../../../routes/energia.route-params";
import { getValidationMessages } from "../../../../../utils";
import {
  PendencyTypeForm,
  pendencyTypeFormDefaultValues,
  pendencyTypeFormValidationSchema,
} from "../_compose";

export const useEditPendencyType = () => {
  const { pendencyTypeId } = useParams<MleByPendencyTypeIdRouteParams>();
  if (!pendencyTypeId) throw new Error("No pendencyTypeId");

  const {
    callService: _fetch,
    loading: fetchLoading,
    hasError: fetchHasError,
    error: fetchError,
    value: fetchValue,
  } = useServiceCall(service.hubEnergy.getDiligenceStepPendencyTypeById);

  const { callService: _submit, loading: submitLoading } = useServiceCall(
    service.hubEnergy.updateDiligenceStepPendencyType,
  );

  const navigate = useNavigate();

  const form = useForm<PendencyTypeForm>({
    defaultValues: pendencyTypeFormDefaultValues,
    resolver: yupResolver(pendencyTypeFormValidationSchema),
  });

  const { handleSubmit, reset } = form;

  const getPendencyType = useCallback(
    () => _fetch(pendencyTypeId),
    [_fetch, pendencyTypeId],
  );

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success, error } = await _submit({
          id: Number(pendencyTypeId),
          description: values.description,
          name: values.name,
        });

        if (success) {
          OToastManager.success("Tipo de pendência salvo");
          navigate(
            corporateRouter.routes.energia.product.diligenceConfig.pendencyTypes
              .path,
          );
        } else {
          OToastManager.danger(
            getValidationMessages(error)?.[0].ErrorMessage ??
              "Erro ao salvar tipo de pendência",
          );
        }
      }),
    [_submit, handleSubmit, navigate, pendencyTypeId],
  );

  useEffect(() => {
    getPendencyType();
  }, [getPendencyType]);

  useEffect(() => {
    if (fetchValue?.response)
      reset({
        description: fetchValue.response.description,
        name: fetchValue.response.name,
      });
  }, [reset, fetchValue]);

  return {
    fetchError,
    fetchHasError,
    fetchLoading,
    form,
    getPendencyType,
    submit,
    submitLoading,
  };
};
