import { OCol } from "@maestro/react";
import { InputMultiple } from "../../input-multiple/input-multiple.component";

export const EmailsSacadoNotificationField = () => {
  return (
    <OCol xs={12} md={6}>
      <InputMultiple
        dataAction="geral:texto:emails_notificacao"
        dataLabel="emails_notificacao"
        id="emailsSacadoNotification"
        name="emailsSacadoNotification"
        label="Emails para receber notificações"
        description="E-mails dos sacados de que receberão notificações"
      />
    </OCol>
  );
};
