import { useCallback, useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { getProcessorPropertyFieldId } from "../../../utils";
import { GenericPropertyFieldProps } from "../../property-field.types";

export const useGenericPropertyField = ({
  processorConfigProperty: { processorProperty, value, id },
  handleSubmitProperty,
}: GenericPropertyFieldProps) => {
  const fieldInitialized = useRef<boolean>(false);
  const { watch, setValue, reset, getValues } = useFormContext();

  const processorPropertyFieldId = getProcessorPropertyFieldId(
    processorProperty.name,
    id,
  );

  const fieldWatch = watch(processorPropertyFieldId);

  const handleFocusOut = useCallback(() => {
    return handleSubmitProperty(getValues(processorPropertyFieldId), id);
  }, [getValues, handleSubmitProperty, id, processorProperty.name]);

  useEffect(() => {
    if (!value) return;
    setValue(processorPropertyFieldId, value);
  }, [value, setValue, processorProperty, id]);

  useEffect(() => {
    if (!fieldInitialized.current) {
      fieldInitialized.current = true;
      return;
    }

    if (
      (!fieldWatch && fieldWatch !== "") ||
      !processorProperty.allowedValues.length ||
      fieldWatch === value
    )
      return;

    handleSubmitProperty(fieldWatch, id);
  }, [fieldWatch, processorProperty, handleSubmitProperty, id, reset, value]);

  useEffect(() => {
    if (processorProperty.allowedValues.length) return;

    const propertyInput = document.getElementById(processorPropertyFieldId);

    if (propertyInput) {
      propertyInput.addEventListener("focusout", handleFocusOut);

      return () =>
        propertyInput.removeEventListener("focusout", handleFocusOut);
    }

    return () => {};
  }, [handleFocusOut, id, processorProperty]);

  return;
};
