import { YesNoRadioField } from "./yes-no-radio-field.component";

export const AutomaticAnticipationField = () => {
  return (
    <YesNoRadioField
      label="Antecipa automaticamente"
      name="automaticAnticipation"
    />
  );
};
