import {
  OCol,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
  OTypography,
} from "@maestro/react";
import { useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import {
  FeesType,
  MapperFeesType,
} from "services/admin-bankinghub/collection/models/types";
import { UpsertCollectionAgreementFormFields } from "../../upsert-collection-agreement.form";
import { FieldName, PercentOrAmountInput } from "../percent-or-amount-input";
import { assembleDescription } from "./discount-fields.utils";

export const DiscountFields = () => {
  const { setValue, watch } =
    useFormContext<UpsertCollectionAgreementFormFields>();
  const typeWatched = watch("settings.discount.type");
  const discountValidityInDaysWatched = watch(
    "settings.discount.discountValidityInDays",
  );

  const disabled = useMemo(
    () => !typeWatched || typeWatched === FeesType.NOT_APPLICABLE,
    [typeWatched],
  );

  const description = useMemo(
    () => assembleDescription(disabled, discountValidityInDaysWatched),
    [disabled, discountValidityInDaysWatched],
  );

  const handleSelect = useCallback(
    (option: FeesType) => {
      if (option === FeesType.NOT_APPLICABLE) {
        setValue("settings.discount.percentOrAmount", undefined, {
          shouldValidate: true,
        });
        setValue("settings.discount.discountValidityInDays", undefined, {
          shouldValidate: true,
        });
        return;
      }
      setValue("settings.discount.percentOrAmount", 0);
    },
    [setValue],
  );

  return (
    <div className="d-flex flex-column gap-3">
      <OTypography type="dark">Desconto</OTypography>
      <ORow align="center" gap={3}>
        <OCol>
          <ORFieldSelect
            id="settings.discount.type"
            name="settings.discount.type"
            label="Tipo de desconto"
            placeholder="Selecione uma opção"
          >
            {Object.values(FeesType).map((value) => (
              <OOption
                value={value}
                key={value}
                onClick={() => handleSelect(value)}
              >
                {MapperFeesType[value] ?? "Sem desconto"}
              </OOption>
            ))}
          </ORFieldSelect>
        </OCol>
        <PercentOrAmountInput
          fieldName={FieldName.Discount}
          typeWatched={typeWatched}
          disabled={disabled}
        />
        <OCol>
          <ORFieldInput
            id="settings.discount.discountValidityInDays"
            name="settings.discount.discountValidityInDays"
            label="Validade do desconto (em dias)"
            placeholder={disabled ? "Não se aplica" : "Insira o número de dias"}
            description={description}
            disabled={disabled}
            tag="number"
          />
        </OCol>
      </ORow>
    </div>
  );
};
