import { OLoader } from "@maestro/react";
import { SelectSearchMultiple } from "components/form";
import { RefreshGridButton } from "components/refresh-grid-button";
import { SelectSearchFieldOption } from "components/select-search/field/select-search-field.types";

interface PendencyTypeSelectorProps {
  id: string;
  pendencyTypeOptions: SelectSearchFieldOption<number>[];
  getPendencyTypes: () => void;
  loading: boolean;
  hasFetchError: boolean;
}

export const PendencyTypeSelector = ({
  id,
  getPendencyTypes,
  hasFetchError,
  loading,
  pendencyTypeOptions,
}: PendencyTypeSelectorProps) => {
  return (
    <div className="d-flex align-items-center flex-fill">
      <SelectSearchMultiple
        options={pendencyTypeOptions}
        id={id}
        name="approvedPendencyTypeIds"
        label="Tipos de pendência"
        placeholder="Selecionar"
      />
      {loading && <OLoader size="sm" />}
      {hasFetchError && !loading && (
        <RefreshGridButton onClick={getPendencyTypes} />
      )}
    </div>
  );
};
