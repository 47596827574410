import {
  IIcon,
  OButton,
  OCard,
  OCardBody,
  OCardHeader,
  OIcon,
  OTypography,
} from "@maestro/react";
import { masks } from "@maestro/utils";
import React from "react";
import { useNavigate } from "react-router";
import { CardContainer } from "./products-card-style";

interface ProductCardProps {
  id: number;
  title: string;
  subtitle: string;
  iconName: IIcon;
  limit?: number;
  redirectPath: string;
}

export const ProductCard: React.FC<ProductCardProps> = (props) => {
  const { id, title, iconName, limit = 0, redirectPath } = props;

  const navigate = useNavigate();

  if (limit <= 0) return <div />;

  return (
    <CardContainer>
      <OCard>
        <OCardHeader>
          <div className="d-flex align-items-center gap-2 mb-2">
            <OIcon category="orq" icon={iconName} size="xl" type="info" />
            <div className="position-relative w-100">
              <OTypography type="dark" size="lg">
                {title}
              </OTypography>
            </div>
          </div>
        </OCardHeader>
        <OCardBody>
          <div className="d-flex flex-column gap-3">
            <div>
              <OTypography type="default">Limite pré-aprovado</OTypography>
              <OTypography type="info" size="xl" key={`${limit}-${id}`}>
                {masks.currency(limit, "R$", ".")}
              </OTypography>
            </div>
            <OButton
              onClick={() => navigate(`${redirectPath}/${id}`)}
              type="dark"
              bordered={false}
              outline
            >
              <div className="d-flex align-items-baseline gap-2">
                <OTypography type="info">Ver oferta</OTypography>
                <OIcon
                  category="fal"
                  icon="fa-chevron-right"
                  type="info"
                  size="sm"
                />
              </div>
            </OButton>
          </div>
        </OCardBody>
      </OCard>
    </CardContainer>
  );
};
