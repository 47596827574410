import { YesNoRadioField } from "./yes-no-radio-field.component";

export const RebateAlongaPrazoField = () => {
  return (
    <YesNoRadioField
      label="Rebate alonga prazo"
      name="rebateAlongaPrazo"
      description="O rebate vira retorno em prazo de pagamento"
    />
  );
};
