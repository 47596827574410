import { OToastManager } from "@maestro/core";
import { useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";
import { getFormData } from "utils/file/file.utils";
import { ProcessorConfigPropertyProps } from "../../property-field.types";

export const useFilePropertyField = ({
  processorConfigProperty: { processorProperty, value, id },
}: ProcessorConfigPropertyProps) => {
  const { setValue } = useFormContext();

  const handleSubmitFile = useCallback(
    async (
      file: File,
      processorConfigPropertyId: number,
      resolve: (file: File) => void,
    ) => {
      try {
        const formData = await getFormData(file, "");

        const { data } = await service.hubCreditManager.upload(formData);

        await service.hubCreditManager.updateProcessorConfigProperty({
          processorConfigPropertyId,
          value: data,
        });

        resolve(file);
      } catch {
        OToastManager.danger(
          "Não foi possível enviar o arquivo. Por favor, tente novamente mais tarde.",
        );
      }
    },
    [],
  );

  const getInitialFile = useCallback(async () => {
    try {
      if (!value) return;

      const { data: blob } = await service.hubCreditManager.download({
        key: value,
      });

      const splittedKey = value?.split("/");

      setValue(`properties.${processorProperty.name}_${id}`, [
        new File([blob], splittedKey?.at(-1) ?? ""),
      ]);
    } catch {
      OToastManager.danger("Um erro ocorreu ao buscar pelo arquivo.");
    }
  }, [setValue, value, processorProperty, id]);

  useEffect(() => {
    if (value) {
      getInitialFile();
    }
  }, [getInitialFile, value]);

  return { handleSubmitFile };
};
