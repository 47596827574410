import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { currencySymbol } from "utils/currency";
import { getValueFromMap } from "utils/get-value-from-map";
import {
  amortizationFrequencyCodeMap,
  spreadDayCountCodeMap,
} from "./loan-agreement.utils";

export const loanAgreementsGrid = {
  datagrid: {
    noDataText: "Nenhuma convênio encontrado.",
    headerFilter: { visible: true, allowSearch: true },
  },
  columns: [
    {
      allowExporting: false,
      type: "buttons",
      role: roles.emprestimos.product.agreementsDetailsEditPage.role,
      cellRender: ({ data }) =>
        data.id && (
          <DetailsLink
            href={`${corporateRouter.routes.emprestimos.product.agreements.details.edit.path(
              {
                id: data.id,
              },
            )}?data=${encodeURIComponent(JSON.stringify(data))}`}
          />
        ),
    },
    {
      dataField: "id",
    },
    {
      dataField: "name",
      caption: "Nome",
    },
    {
      dataField: "identification",
      caption: "Identificação",
    },
    {
      dataField: "partialLimitConsumeAllowed",
      caption: "Consumo parcial de limite permitido?",
      cellRender: ({ data }) => {
        return data.partialLimitConsumeAllowed ? "Sim" : "Não";
      },
    },
    {
      dataField: "paymentDayType",
      caption: "Dia do pagamento",
    },
    {
      dataField: "defaultGracePeriod",
      caption: "Período de carência recomendado (em meses)",
    },
    {
      dataField: "maxGracePeriod",
      caption: "Período de carência máxima (em dias)",
    },
    {
      dataField: "isIofExempt",
      caption: "É isento de IOF?",
      cellRender: ({ data }) => {
        return data.isIofExempt ? "Sim" : "Não";
      },
    },
    {
      dataField: "iofFinance",
      caption: "Há financiamento da taxa de IOF?",
      cellRender: ({ data }) => {
        return data.iofFinance ? "Sim" : "Não";
      },
    },
    {
      dataField: "hasGuarantee",
      caption: "Tem garantia?",
      cellRender: ({ data }) => {
        return data.hasGuarantee ? "Sim" : "Não";
      },
    },
    {
      dataField: "isSocialImpact",
      caption: "É de impacto social?",
      cellRender: ({ data }) => {
        return data.isSocialImpact ? "Sim" : "Não";
      },
    },
    {
      dataField: "valorizationMethod",
      caption: "Modo de cálculo",
    },
    {
      dataField: "lockDisbursement",
      caption: "Método de trava de desembolso",
    },
    {
      dataField: "bookCr",
      caption: "Book",
    },
    {
      dataField: "strategyCr",
      caption: "Estratégia",
    },
    {
      dataField: "minimumGuarantors",
      caption: "Quantidade mínima de avalistas",
    },
    {
      dataField: "maximumLimitForSimpleGuarantor",
      caption: "Limite máximo para avalista simples",
    },
    {
      dataField: "minimumParticipationForGuarantor",
      caption: "Participação mínima para avalista",
    },
    {
      dataField: "settlementAgencyCode",
      caption: "Código da agência de liquidação",
    },
    {
      dataField: "amortizationFrequencyCode",
      caption: "Código da frequência de amortização",
      format: (value) =>
        getValueFromMap(amortizationFrequencyCodeMap, value) || "",
    },
    {
      dataField: "spreadDayCountCode",
      caption: "Base de cálculo de juros",
      format: (value) => getValueFromMap(spreadDayCountCodeMap, value) || "",
    },
    {
      dataField: "warrantyDocumentSystem",
      caption: "Sistema de documento de garantia",
    },
    {
      dataField: "useInstallmentFlow",
      caption: "Fluxo parcela",
      cellRender: ({ data }) => {
        return data.useInstallmentFlow ? "Sim" : "Não";
      },
    },
    {
      dataField: "automaticallyApproves",
      caption: "Aprova automaticamente",
      cellRender: ({ data }) => {
        return data.automaticallyApproves ? "Sim" : "Não";
      },
    },
    {
      dataField: "minRate",
      caption: "Taxa mínima",
    },
    {
      dataField: "maxRate",
      caption: "Taxa máxima",
    },
    {
      dataField: "minAmount",
      caption: "Valor mínimo",
      format: (value) => {
        return masks.currency(value, currencySymbol.BRL);
      },
    },
    {
      dataField: "maxAmount",
      caption: "Valor máximo",
      format: (value) => {
        return masks.currency(value, currencySymbol.BRL);
      },
    },
    {
      dataField: "minTerm",
      caption: "Prazo mínimo (em meses)",
    },
    {
      dataField: "maxTerm",
      caption: "Prazo máximo (em meses)",
    },
  ],
} satisfies ODataGridGeneratorConfig<
  NonNullable<
    Awaited<ReturnType<typeof service.hubLoan.getAgreement>>["data"]
  >[number]
>;
