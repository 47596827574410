import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { ValidationErrorComponent } from "../../../../../components/validation-error-component";
import { StepConfigFormComponent } from "../_compose";
import { useEditStepConfig } from "./edit-step-config.hook";

export const EditStepConfigPage = () => {
  const {
    fetchError,
    fetchHasError,
    fetchLoading,
    fetchValue,
    form,
    getStepConfig,
    submit,
    submitLoading,
  } = useEditStepConfig();

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Editar configuração de step" />}
      actions={
        <LoadingButton loading={submitLoading} onClick={submit}>
          Salvar
        </LoadingButton>
      }
    >
      <ContentTemplate
        loading={fetchLoading}
        hasError={fetchHasError}
        errorComponent={
          <ValidationErrorComponent
            callback={getStepConfig}
            error={fetchError}
            title="Erro ao buscar a configuração de step"
          />
        }
        value={fetchValue?.response}
        render={() => (
          <FormProvider {...form}>
            <StepConfigFormComponent />
          </FormProvider>
        )}
      />
    </FormTemplate>
  );
};
