import {
  OButton,
  OCheckbox,
  OLabel,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OOption,
  ORFieldCheckboxGroup,
  ORFieldInput,
  ORFieldSelect,
  ORow,
  OTypography,
  modalManager,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { FormProvider } from "react-hook-form";
import { ModalWithSelectOverflow } from "../../../../../components/modal-with-select-overflow";
import { pendencyStatusMap } from "../../../../../utils";
import {
  ConsumerUnitSelector,
  DiligenceStepPropertySelector,
  DiligenceStepSelector,
  PendencyTypeSelector,
} from "./_compose";
import {
  addPendencyFormFields,
  checkboxValue,
} from "./add-pendency-modal.form";
import { useAddPendencyModal } from "./add-pendency-modal.hook";
import { addPendencyModalId } from "./add-pendency-modal.utils";

interface AddPendencyModalProps {
  onSubmit: () => void;
}

export const AddPendencyModal = ({ onSubmit }: AddPendencyModalProps) => {
  const {
    diligence,
    diligenceHasError,
    diligenceLoading,
    form,
    getDiligence,
    submit,
    submitLoading,
  } = useAddPendencyModal(onSubmit);

  return (
    <ModalWithSelectOverflow id={addPendencyModalId} position="center">
      <OModalHeader>
        <OTypography size="lg">Adicionar tarefa</OTypography>
      </OModalHeader>
      <OModalBody>
        <FormProvider {...form}>
          <div>
            <ORow>
              <ConsumerUnitSelector />
            </ORow>
            <ORow>
              <DiligenceStepSelector
                diligenceHasError={diligenceHasError}
                diligenceLoading={diligenceLoading}
                diligence={diligence}
                getDiligence={getDiligence}
              />
            </ORow>
            <ORow>
              <DiligenceStepPropertySelector
                diligenceHasError={diligenceHasError}
                diligenceLoading={diligenceLoading}
                diligence={diligence}
              />
            </ORow>
            <ORow>
              <PendencyTypeSelector />
            </ORow>
            <ORow className="w-100">
              <ORFieldSelect {...addPendencyFormFields.status}>
                {Object.entries(pendencyStatusMap).map(([k, v]) => (
                  <OOption key={k} value={k}>
                    {v.text}
                  </OOption>
                ))}
              </ORFieldSelect>
            </ORow>
            <ORow>
              <ORFieldCheckboxGroup {...addPendencyFormFields.isPublic}>
                <div className="d-flex align-items-center gap-2">
                  <OCheckbox
                    id="isPublic-checkbox"
                    value={checkboxValue}
                    size="sm"
                  />
                  <OLabel htmlFor="isPublic-checkbox">Mostra cliente</OLabel>
                </div>
              </ORFieldCheckboxGroup>
            </ORow>
            <ORow className="w-100">
              <ORFieldInput {...addPendencyFormFields.message} />
            </ORow>
          </div>
        </FormProvider>
      </OModalBody>
      <OModalFooter>
        <div className="d-flex gap-3">
          <LoadingButton loading={submitLoading} onClick={submit}>
            Salvar
          </LoadingButton>
          <OButton
            type="dark"
            outline
            onClick={() => modalManager.hide(addPendencyModalId)}
          >
            Cancelar
          </OButton>
        </div>
      </OModalFooter>
    </ModalWithSelectOverflow>
  );
};
