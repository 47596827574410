import {
  OCol,
  OLabel,
  OOption,
  ORFieldInput,
  ORFieldRadioGroup,
  ORFieldSelect,
  ORadio,
  ORow,
} from "@maestro/react";
import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { service } from "services";
import {
  amortizationFrequencyCodeMap,
  spreadDayCountCodeMap,
} from "./loan-agreement.utils";

export interface LoanAgreementFormValues {
  name: string;
  defaultGracePeriod: number;
  maxGracePeriod: number;
  partialLimitConsumeAllowed: boolean;
  paymentDayType: string;
  isIofExempt: boolean;
  iofFinance: boolean;
  hasGuarantee: boolean;
  isSocialImpact: boolean;
  product: string;
  valorizationMethod: string;
  lockDisbursement?: string | null;
  bookCr: number;
  strategyCr: number;
  minimumGuarantors?: number | null;
  maximumLimitForSimpleGuarantor?: number | null;
  minimumParticipationForGuarantor?: number | null;
  settlementAgencyCode: number;
  amortizationFrequencyCode: string;
  spreadDayCountCode: string;
  automaticallyApproves: boolean;
  useInstallmentFlow: boolean;
  minRate?: number | null;
  maxRate?: number | null;
  minAmount?: number | null;
  maxAmount?: number | null;
  minTerm?: number | null;
  maxTerm?: number | null;
}

export const LoanAgreementForm = () => {
  const {
    callService: callServiceSettlement,
    loading: loadingSettlementTypes,
    value: settlementTypes,
  } = useServiceCall(service.hubLoan.getSettlementTypes);

  const {
    callService: callServiceProducts,
    loading: loadingProductTypes,
    value: loanProductOptions,
  } = useServiceCall(service.hubLoan.getProductTypes);

  useEffect(() => {
    callServiceSettlement();
    callServiceProducts();
  }, [callServiceSettlement, callServiceProducts]);

  return (
    <>
      <OCol>
        <ORFieldInput
          tag="text"
          id="name"
          name="name"
          dataAction="emprestimos_convenios_form:text:nome"
          dataLabel="nome"
          label="Nome"
        />
      </OCol>
      <ORow>
        <OCol>
          <ORFieldInput
            tag="number"
            id="defaultGracePeriod"
            name="defaultGracePeriod"
            dataAction="emprestimos_convenios_form:number:periodo_carencia_recomendada"
            dataLabel="periodo_carencia_recomendada"
            label="Período de carência recomendado (em meses)"
          />
        </OCol>
        <OCol>
          <ORFieldInput
            tag="number"
            id="maxGracePeriod"
            name="maxGracePeriod"
            dataAction="emprestimos_convenios_form:number:periodo_carencia_maxima"
            dataLabel="periodo_carencia_maxima"
            label="Período de carência máxima (em dias)"
          />
        </OCol>
        <OCol>
          <ORFieldRadioGroup
            id="partialLimitConsumeAllowed"
            name="partialLimitConsumeAllowed"
            dataAction="emprestimos_convenios_form:radio:consumo_parcial_de_limite_permitido"
            dataLabel="consumo_parcial_de_limite_permitido"
            label="Consumo parcial de limite é permitido?"
          >
            <div className="d-flex gap-4">
              <div className="d-flex align-items-center gap-2 mb-2">
                <ORadio id="yes" value="true" />
                <OLabel htmlFor="yes">Sim</OLabel>
              </div>
              <div className="d-flex align-items-center gap-2 mb-2">
                <ORadio id="no" value="false" />
                <OLabel htmlFor="no">Não</OLabel>
              </div>
            </div>
          </ORFieldRadioGroup>
        </OCol>
      </ORow>
      <ORow>
        <OCol>
          <ORFieldSelect
            id="paymentDayType"
            name="paymentDayType"
            dataAction="emprestimos_convenios_form:select:dia_pagamento"
            dataLabel="dia_pagamento"
            label="Dia do pagamento"
          >
            {[
              {
                value: "FIRST_DAY_OF_MONTH",
                label: "Primeiro dia do mês",
              },
              {
                value: "LAST_DAY_OF_MONTH",
                label: "Último dia do mês",
              },
              {
                value: "ANY",
                label: "Qualquer um",
              },
            ].map((paymentDay) => (
              <OOption key={paymentDay.value} value={paymentDay.value}>
                {paymentDay.label}
              </OOption>
            ))}
          </ORFieldSelect>
        </OCol>
        <OCol>
          <ORFieldRadioGroup
            id="isIofExempt"
            name="isIofExempt"
            dataAction="emprestimos_convenios_form:radio:isento_iof"
            dataLabel="isento_iof"
            label="É isento de IOF?"
          >
            <div className="d-flex gap-4">
              <div className="d-flex align-items-center gap-2 mb-2">
                <ORadio id="yes" value="true" />
                <OLabel htmlFor="yes">Sim</OLabel>
              </div>
              <div className="d-flex align-items-center gap-2 mb-2">
                <ORadio id="no" value="false" />
                <OLabel htmlFor="no">Não</OLabel>
              </div>
            </div>
          </ORFieldRadioGroup>
        </OCol>
        <OCol>
          <ORFieldRadioGroup
            id="iofFinance"
            name="iofFinance"
            dataAction="emprestimos_convenios_form:radio:financiamento_taxa_iof"
            dataLabel="financiamento_taxa_iof"
            label="Há financiamento da taxa de IOF?"
          >
            <div className="d-flex gap-4">
              <div className="d-flex align-items-center gap-2 mb-2">
                <ORadio id="yes" value="true" />
                <OLabel htmlFor="yes">Sim</OLabel>
              </div>
              <div className="d-flex align-items-center gap-2 mb-2">
                <ORadio id="no" value="false" />
                <OLabel htmlFor="no">Não</OLabel>
              </div>
            </div>
          </ORFieldRadioGroup>
        </OCol>
      </ORow>
      <ORow>
        <OCol>
          <ORFieldRadioGroup
            id="hasGuarantee"
            name="hasGuarantee"
            dataAction="emprestimos_convenios_form:radio:tem_garantia"
            dataLabel="tem_garantia"
            label="Tem garantia?"
          >
            <div className="d-flex gap-4">
              <div className="d-flex align-items-center gap-2 mb-2">
                <ORadio id="yes" value="true" />
                <OLabel htmlFor="yes">Sim</OLabel>
              </div>
              <div className="d-flex align-items-center gap-2 mb-2">
                <ORadio id="no" value="false" />
                <OLabel htmlFor="no">Não</OLabel>
              </div>
            </div>
          </ORFieldRadioGroup>
        </OCol>
        <OCol>
          <ORFieldRadioGroup
            id="isSocialImpact"
            name="isSocialImpact"
            dataAction="emprestimos_convenios_form:radio:impacto_social"
            dataLabel="impacto_social"
            label="É impacto social?"
          >
            <div className="d-flex gap-4">
              <div className="d-flex align-items-center gap-2 mb-2">
                <ORadio id="yes" value="true" />
                <OLabel htmlFor="yes">Sim</OLabel>
              </div>
              <div className="d-flex align-items-center gap-2 mb-2">
                <ORadio id="no" value="false" />
                <OLabel htmlFor="no">Não</OLabel>
              </div>
            </div>
          </ORFieldRadioGroup>
        </OCol>
        <OCol>
          <ORFieldSelect
            id="product"
            name="product"
            dataAction="emprestimos_convenios_form:select:produto"
            dataLabel="produto"
            label="Produto"
            key={loanProductOptions?.map((lpo) => lpo.value).join("-")}
          >
            {loanProductOptions?.map((product) => (
              <OOption key={product?.value} value={product.value}>
                {product.text}
              </OOption>
            ))}
          </ORFieldSelect>
        </OCol>
      </ORow>
      <ORow>
        <OCol>
          <ORFieldSelect
            id="valorizationMethod"
            name="valorizationMethod"
            dataAction="emprestimos_convenios_form:select:modulo_de_calculo"
            dataLabel="modulo_de_calculo"
            label="Modo de Cálculo"
          >
            {[
              {
                value: "PRIC",
                label: "Tabela Price",
              },
              {
                value: "BAND",
                label: "Saldo devedor",
              },
              {
                value: "INST",
                label: "Parcela",
              },
            ].map((valorization) => (
              <OOption key={valorization.value} value={valorization.value}>
                {valorization.label}
              </OOption>
            ))}
          </ORFieldSelect>
        </OCol>
        <OCol>
          <ORFieldSelect
            id="lockDisbursement"
            name="lockDisbursement"
            dataAction="emprestimos_convenios_form:select:metodo_trava_desembolso"
            dataLabel="metodo_trava_desembolso"
            label="Método de trava de desembolso"
          >
            {[
              {
                value: "PRE",
                label: "PRE",
              },
              {
                value: "POS",
                label: "POS",
              },
            ].map((lock) => (
              <OOption key={lock.value} value={lock.value}>
                {lock.label}
              </OOption>
            ))}
          </ORFieldSelect>
        </OCol>
        <OCol>
          <ORFieldInput
            tag="number"
            id="bookCr"
            name="bookCr"
            dataAction="emprestimos_convenios_form:number:book"
            dataLabel="book"
            label="Book das operações"
          />
        </OCol>
      </ORow>
      <ORow>
        <OCol>
          <ORFieldInput
            tag="number"
            id="strategyCr"
            name="strategyCr"
            dataAction="emprestimos_convenios_form:number:estrategia"
            dataLabel="estrategia"
            label="Estratégia das operações"
          />
        </OCol>
        <OCol>
          <ORFieldInput
            tag="number"
            id="minimumGuarantors"
            name="minimumGuarantors"
            dataAction="emprestimos_convenios_form:number:quantidade_minima_avalistas"
            dataLabel="quantidade_minima_avalistas"
            label="Quantidade mínima de avalistas"
          />
        </OCol>
        <OCol>
          <ORFieldInput
            tag="number"
            id="maximumLimitForSimpleGuarantor"
            name="maximumLimitForSimpleGuarantor"
            dataAction="emprestimos_convenios_form:number:limite_maximo_avalista_simples"
            dataLabel="limite_maximo_avalista_simples"
            label="Limite máximo para avalista simples"
          />
        </OCol>
      </ORow>
      <ORow>
        <OCol>
          <ORFieldInput
            tag="number"
            id="minimumParticipationForGuarantor"
            name="minimumParticipationForGuarantor"
            dataAction="emprestimos_convenios_form:number:participacao_minima_avalista"
            dataLabel="participacao_minima_avalista"
            label="Participação mínima para avalista"
          />
        </OCol>
        <OCol>
          <ORFieldSelect
            id="settlementAgencyCode"
            name="settlementAgencyCode"
            dataAction="emprestimos_convenios_form:select:codigo_agencia_liquidacao"
            dataLabel="codigo_agencia_liquidacao"
            label="Código da agência de liquidação"
          >
            {[1, 50].map((agency) => (
              <OOption key={agency} value={agency}>
                {agency}
              </OOption>
            ))}
          </ORFieldSelect>
        </OCol>
        <OCol>
          <ORFieldSelect
            id="amortizationFrequencyCode"
            name="amortizationFrequencyCode"
            dataAction="emprestimos_convenios_form:select:codigo_frequencia_amortizacao"
            dataLabel="codigo_frequencia_amortizacao"
            label="Código da frequência de amortização"
          >
            {Object.entries(amortizationFrequencyCodeMap).map(
              ([value, label]) => (
                <OOption key={value} value={value}>
                  {label}
                </OOption>
              ),
            )}
          </ORFieldSelect>
        </OCol>
      </ORow>
      <ORow>
        <OCol>
          <ORFieldSelect
            id="spreadDayCountCode"
            name="spreadDayCountCode"
            dataAction="emprestimos_convenios_form:select:base_calculo_juros"
            dataLabel="base_calculo_juros"
            label="Base de cálculo de juros"
          >
            {Object.entries(spreadDayCountCodeMap).map(([value, label]) => (
              <OOption key={value} value={value}>
                {label}
              </OOption>
            ))}
          </ORFieldSelect>
        </OCol>
        <OCol>
          <ORFieldRadioGroup
            id="automaticallyApproves"
            name="automaticallyApproves"
            dataAction="emprestimos_convenios_form:radio:aprova_automaticamente"
            dataLabel="aprova_automaticamente"
            label="Aprova automaticamente"
          >
            <div className="d-flex gap-4">
              <div className="d-flex align-items-center gap-2 mb-2">
                <ORadio id="yes" value="true" />
                <OLabel htmlFor="yes">Sim</OLabel>
              </div>
              <div className="d-flex align-items-center gap-2 mb-2">
                <ORadio id="no" value="false" />
                <OLabel htmlFor="no">Não</OLabel>
              </div>
            </div>
          </ORFieldRadioGroup>
        </OCol>
        <OCol>
          <ORFieldRadioGroup
            id="useInstallmentFlow"
            name="useInstallmentFlow"
            dataAction="emprestimos_convenios_form:radio:fluxo_parcela"
            dataLabel="fluxo_parcela"
            label="Fluxo parcela"
          >
            <div className="d-flex gap-4">
              <div className="d-flex align-items-center gap-2 mb-2">
                <ORadio id="yes" value="true" />
                <OLabel htmlFor="yes">Sim</OLabel>
              </div>
              <div className="d-flex align-items-center gap-2 mb-2">
                <ORadio id="no" value="false" />
                <OLabel htmlFor="no">Não</OLabel>
              </div>
            </div>
          </ORFieldRadioGroup>
        </OCol>
      </ORow>
      <ORow>
        <OCol>
          <ORFieldInput
            tag="number"
            id="minRate"
            name="minRate"
            dataAction="emprestimos_convenios_form:number:taxa_minima"
            dataLabel="taxa_minima"
            label="Taxa mínima"
          />
        </OCol>
        <OCol>
          <ORFieldInput
            tag="number"
            id="maxRate"
            name="maxRate"
            dataAction="emprestimos_convenios_form:number:taxa_maxima"
            dataLabel="taxa_maxima"
            label="Taxa máxima"
          />
        </OCol>
        <OCol>
          <ORFieldInput
            tag="number"
            id="minAmount"
            name="minAmount"
            dataAction="emprestimos_convenios_form:number:valor_minimo"
            dataLabel="valor_minimo"
            label="Valor mínimo"
          />
        </OCol>
      </ORow>
      <ORow>
        <OCol>
          <ORFieldInput
            tag="number"
            id="maxAmount"
            name="maxAmount"
            dataAction="emprestimos_convenios_form:number:valor_maximo"
            dataLabel="valor_maximo"
            label="Valor máximo"
          />
        </OCol>
        <OCol>
          <ORFieldInput
            tag="number"
            id="minTerm"
            name="minTerm"
            dataAction="emprestimos_convenios_form:number:prazo_minimo"
            dataLabel="prazo_minimo"
            label="Prazo mínimo (em meses)"
          />
        </OCol>
        <OCol>
          <ORFieldInput
            tag="number"
            id="maxTerm"
            name="maxTerm"
            dataAction="emprestimos_convenios_form:number:prazo_maximo"
            dataLabel="prazo_maximo"
            label="Prazo máximo (em meses)"
          />
        </OCol>
      </ORow>
      <ORow>
        <OCol>
          <ORFieldSelect
            id="defaultSettlementType"
            name="defaultSettlementType"
            dataAction="emprestimos_convenios_form:select:metodo_liquidacao_padrao"
            dataLabel="metodo_liquidacao_padrao"
            label="Método de liquidação padrão"
            disabled={loadingProductTypes || loadingSettlementTypes}
          >
            {settlementTypes?.map((settlementType: string) => (
              <OOption key={settlementType} value={settlementType}>
                {settlementType}
              </OOption>
            ))}
          </ORFieldSelect>
        </OCol>
      </ORow>
    </>
  );
};
