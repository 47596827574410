import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { getValidationMessages } from "../../../../../utils";
import {
  StepConfigForm,
  checkboxValue,
  stepConfigFormDefaultValues,
  stepConfigFormValidationSchema,
} from "../_compose";

export const useAddStepConfig = () => {
  const { callService, loading } = useServiceCall(
    service.hubEnergy.createDiligenceStepConfig,
  );

  const navigate = useNavigate();

  const form = useForm<StepConfigForm>({
    defaultValues: stepConfigFormDefaultValues,
    resolver: yupResolver(stepConfigFormValidationSchema),
  });

  const { handleSubmit } = form;

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success, error } = await callService({
          approvedPendencyTypeIds: values.approvedPendencyTypeIds,
          defaultPendenciesConfigs: values.defaultPendenciesConfigs,
          isPublic: values.isPublic.includes(checkboxValue),
          maximumDuration: values.maximumDuration,
          maximumDurationType: values.maximumDurationType,
          name: values.name,
          needsPreApproval: values.needsPreApproval.includes(checkboxValue),
          propertiesConfigs: values.propertiesConfigs,
          dependencyTypeIds: values.dependencyTypeIds,
          typeId: values.typeId,
        });

        if (success) {
          OToastManager.success("Configuração de step adicionada");
          navigate(
            corporateRouter.routes.energia.product.diligenceConfig.stepConfigs
              .path,
          );
        } else {
          OToastManager.danger(
            getValidationMessages(error)?.[0].ErrorMessage ??
              "Erro ao adicionar configuração de step",
          );
        }
      }),
    [callService, handleSubmit, navigate],
  );

  return {
    form,
    loading,
    submit,
  };
};
