import { OCol } from "@maestro/react";
import { InputMultiple } from "../../input-multiple/input-multiple.component";

interface DiasParaVencimentoSacadoFieldProps {
  required?: boolean;
}

export const DiasParaVencimentoSacadoField = ({
  required,
}: DiasParaVencimentoSacadoFieldProps) => {
  return (
    <OCol xs={12} md={6}>
      <InputMultiple
        dataAction="geral:texto:dias_vencimento"
        dataLabel="dias_vencimento"
        id="diasParaVencimentoSacado"
        name="diasParaVencimentoSacado"
        label="Dias para vencimento"
        description="Quantidade de dias antes do vencimento para receber notificação"
        required={required}
      />
    </OCol>
  );
};
