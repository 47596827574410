import {
  OButton,
  OIcon,
  OLoader,
  OModal,
  OModalFooter,
  OModalHeader,
  OTooltip,
  OTypography,
} from "@maestro/react";
import { PROCESSOR_CONFIG_MODAL_ID } from "../../config-proposal-workflow.utils";
import { ProcessorConfigMenu } from "./_compose/processor-config-menu/processor-config-menu.component";
import { useProcessorConfigModal } from "./processor-config-modal.hook";
import { StyledOModalBody } from "./processor-config-modal.styles";

export const ProcessorConfigModal = () => {
  const {
    form,
    saving,
    selectedProcessorConfig,
    handleSubmitGeneralInfo,
    onModalClose,
  } = useProcessorConfigModal();

  return (
    <OModal
      id={PROCESSOR_CONFIG_MODAL_ID}
      position="center"
      size="xl"
      onModalClose={onModalClose}
    >
      <OModalHeader closeButton>
        <div className="d-flex align-items-center gap-3 p-0">
          <OTypography tag="h2" weight="normal" size="xl">
            Configuração de Processador
          </OTypography>

          {selectedProcessorConfig?.validationStatus === "INVALID" && (
            <OTooltip type="info-light" position="bottom" arrow>
              <span slot="tooltip-content">
                {selectedProcessorConfig.validationMessages.map((message) => (
                  <OTypography key={message}>{message}</OTypography>
                ))}
              </span>
              <OIcon type="danger" category="orq" icon="orq-status-warning" />
            </OTooltip>
          )}
        </div>
      </OModalHeader>
      <StyledOModalBody>
        <ProcessorConfigMenu form={form} />
      </StyledOModalBody>
      <OModalFooter>
        <div className="d-flex justify-content-end">
          <OButton
            type="dark"
            onClick={handleSubmitGeneralInfo}
            disabled={saving}
          >
            {saving ? (
              <div className="d-flex align-items-center justify-content-center">
                <OLoader size="sm" />
              </div>
            ) : (
              "Salvar"
            )}
          </OButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
