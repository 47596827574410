import { useParams } from "react-router-dom";
import { SimulationProvider } from "../../compose/simulation/simulation/simulation.context";
import { GuarantorsSetupProvider } from "../../offers-guarantors-hook.component";
import { productMap } from "../../offers.utils";

export const Simulation = () => {
  const { type } = useParams();

  return (
    <GuarantorsSetupProvider>
      <SimulationProvider>
        {type &&
          productMap[type.toUpperCase()].simulation.component(
            productMap[type.toUpperCase()],
          )}
      </SimulationProvider>
    </GuarantorsSetupProvider>
  );
};
