import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { MleByStepConfigIdRouteParams } from "../../../../../routes/energia.route-params";
import { getValidationMessages } from "../../../../../utils";
import {
  StepConfigForm,
  checkboxValue,
  stepConfigFormDefaultValues,
  stepConfigFormValidationSchema,
} from "../_compose";

export const useEditStepConfig = () => {
  const { stepConfigId } = useParams<MleByStepConfigIdRouteParams>();
  if (!stepConfigId) throw new Error("No stepConfigId");

  const {
    callService: _fetch,
    loading: fetchLoading,
    hasError: fetchHasError,
    error: fetchError,
    value: fetchValue,
  } = useServiceCall(service.hubEnergy.getDiligenceStepConfigById);

  const { callService: _submit, loading: submitLoading } = useServiceCall(
    service.hubEnergy.updateDiligenceStepConfig,
  );

  const navigate = useNavigate();

  const form = useForm<StepConfigForm>({
    defaultValues: stepConfigFormDefaultValues,
    resolver: yupResolver(stepConfigFormValidationSchema),
  });

  const { handleSubmit, reset } = form;

  const getStepConfig = useCallback(
    () => _fetch(stepConfigId),
    [_fetch, stepConfigId],
  );

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success, error } = await _submit({
          id: Number(stepConfigId),
          approvedPendencyTypeIds: values.approvedPendencyTypeIds,
          defaultPendenciesConfigs: values.defaultPendenciesConfigs,
          isPublic: values.isPublic.includes(checkboxValue),
          maximumDuration: values.maximumDuration,
          maximumDurationType: values.maximumDurationType,
          name: values.name,
          needsPreApproval: values.needsPreApproval.includes(checkboxValue),
          propertiesConfigs: values.propertiesConfigs,
          dependencyTypeIds: values.dependencyTypeIds,
          typeId: values.typeId,
        });

        if (success) {
          OToastManager.success("Configuração de step editada");
          navigate(
            corporateRouter.routes.energia.product.diligenceConfig.stepConfigs
              .path,
          );
        } else {
          OToastManager.danger(
            getValidationMessages(error)?.[0].ErrorMessage ??
              "Erro ao editar configuração de step",
          );
        }
      }),
    [_submit, handleSubmit, navigate, stepConfigId],
  );

  useEffect(() => {
    getStepConfig();
  }, [getStepConfig]);

  useEffect(() => {
    if (fetchValue?.response)
      reset({
        approvedPendencyTypeIds:
          fetchValue.response.approvedPendencyTypes.map(({ id }) => id) ?? [],
        defaultPendenciesConfigs:
          fetchValue.response.defaultPendenciesConfigs ?? [],
        isPublic: fetchValue.response.isPublic ? [checkboxValue] : [],
        maximumDuration: fetchValue.response.maximumDuration,
        maximumDurationType: fetchValue.response.maximumDurationType,
        name: fetchValue.response.name,
        needsPreApproval: fetchValue.response.needsPreApproval
          ? [checkboxValue]
          : [],
        propertiesConfigs: fetchValue.response.propertiesConfigs ?? [],
        dependencyTypeIds:
          fetchValue.response.dependencies.map(({ id }) => id) ?? [],
        typeId: fetchValue.response.typeId,
      });
  }, [reset, fetchValue]);

  return {
    fetchError,
    fetchHasError,
    fetchLoading,
    fetchValue,
    form,
    getStepConfig,
    submit,
    submitLoading,
  };
};
