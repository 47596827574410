import { OCol, OOption, ORFieldSelect } from "@maestro/react";

const options = [
  {
    value: "SemContrato",
    label: "Sem contrato",
  },
  {
    value: "CessaoFutura",
    label: "Cessao futura",
  },
];
const optionsCCC = [
  {
    value: "SemContrato",
    label: "Sem contrato",
  },
  {
    value: "Duplicatas",
    label: "Duplicatas",
  },
  {
    value: "CessaoFutura",
    label: "Cessao futura",
  },
];

interface HiringContractTypeFieldProps {
  type?: string;
}

export const HiringContractTypeField = ({
  type,
}: HiringContractTypeFieldProps) => {
  return (
    <OCol xs={12} md={6}>
      <ORFieldSelect
        dataAction="geral:select:hiring_contract_type"
        dataLabel="hiring_contract_type"
        id="hiringContractType"
        name="hiringContractType"
        label="Tipo de contrato de contratação"
        placeholder="Selecionar"
        required
      >
        {(type === "CCC" ? optionsCCC : options).map(({ label, value }) => (
          <OOption key={value} value={value}>
            {label}
          </OOption>
        ))}
      </ORFieldSelect>
    </OCol>
  );
};
