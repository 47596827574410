import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";
import { getProcessorPropertyFieldId } from "../../../utils";
import { GenericPropertyFieldProps } from "../../property-field.types";

export const useFormPropertyField = ({
  processorConfigProperty: { processorProperty, id, value },
  handleSubmitProperty,
}: GenericPropertyFieldProps) => {
  const fieldInitialized = useRef(false);
  const { watch, setValue, reset } = useFormContext();
  const {
    value: formList,
    callService: getFormList,
    hasError: hasErrorFormList,
  } = useServiceCall(service.hubCreditManager.getFormsList);

  const processorPropertyFieldId = useMemo(
    () => getProcessorPropertyFieldId(processorProperty.name, id),
    [],
  );

  const fieldWatch = watch(processorPropertyFieldId);

  useEffect(() => {
    hasErrorFormList &&
      OToastManager.danger(
        "Não foi buscar as opções de formulário. Por favor, tente novamente mais tarde.",
      );
  }, [hasErrorFormList]);

  useEffect(() => {
    if (!formList?.length) {
      getFormList();
      return;
    }

    if (!value) return;
    setValue(processorPropertyFieldId, value);
  }, [formList, value, setValue, processorProperty, getFormList, reset, id]);

  useEffect(() => {
    if (!fieldInitialized.current) {
      fieldInitialized.current = true;
      return;
    }

    if ((!fieldWatch && fieldWatch !== "") || fieldWatch === value) return;

    handleSubmitProperty(fieldWatch, id);
  }, [fieldWatch, processorProperty, handleSubmitProperty, id, value]);

  return { formList };
};
