import { YesNoRadioField } from "./yes-no-radio-field.component";

interface ExigeAprovacaoSacadoFieldProps {
  required?: boolean;
}

export const ExigeAprovacaoSacadoField = ({
  required,
}: ExigeAprovacaoSacadoFieldProps) => {
  return (
    <YesNoRadioField
      label="Exige aprovação do sacado"
      name="exigeAprovacaoSacado"
      description="Aprovação para o cadastro e cancelamento de notas no portal"
      required={required}
    />
  );
};
