import { normalizeObjectKeys } from "utils/parse-spreadsheet";
import { readBufferAsSpreadsheet } from "utils/read-buffer-as-spreadsheet";
import {
  filterValues,
  formatExcelDate,
  parseNumber,
  toBoolean,
} from "../../../../../utils";
import {
  Receivable,
  ReceivableInstallment,
} from "../../batch-upload-receivables.types";
import { utils as xlsxUtils } from "xlsx";

export const parseReceivablesSpreadsheet = (
  buffer: FileReader["result"],
): Receivable[] => {
  let { workbook, firstSheetJson: sheetData } = readBufferAsSpreadsheet(buffer);

  let installments: ReceivableInstallment[] | null = null;
  if (workbook.SheetNames.length > 1) {
    sheetData = xlsxUtils.sheet_to_json<Record<string, unknown>>(
      workbook.Sheets["Recebiveis"] ?? workbook.Sheets["Dados"] ?? workbook.Sheets["Sheet1"],
    );

    installments = xlsxUtils
      .sheet_to_json<Record<string, unknown>>(workbook.Sheets["Parcelas"])
      .map((row) => {
        const cleanRow = normalizeObjectKeys(row);

        return filterValues({
          receivableIdentification: cleanRow["numero da nota"],
          number: parseNumber(cleanRow["numero da parcela"]),
          amount: parseNumber(cleanRow["valor da parcela"]),
          maturityDate: formatExcelDate(cleanRow["data vencimento da parcela"]),
        });
      }) as ReceivableInstallment[];
  }

  return sheetData.map((row) => {
    const cleanRow = normalizeObjectKeys(row);

    return filterValues({
      identification: cleanRow["numero da nota"],
      legalName: cleanRow.fornecedor,
      taxId: cleanRow["identificação (cnpj/cpf/codigofornecedor)"],
      currency: cleanRow.moeda,
      disbursementDate: formatExcelDate(cleanRow["data desembolso"]),
      maturityDate: formatExcelDate(cleanRow["data vencimento"]),
      dataRepasse: formatExcelDate(cleanRow["data repasse"]),
      rebate: parseNumber(cleanRow.rebate),
      bankCode: cleanRow["código do banco"],
      agency: cleanRow.agência,
      accountNumber: cleanRow.conta,
      accountDigit: cleanRow["dígito"],
      pixKey: cleanRow["chave pix"],
      pixType: cleanRow["tipo pix"],
      documentType: cleanRow["tipo do documento"],
      disbursementAmount: parseNumber(cleanRow["valor desembolso"]),
      maturityAmount: parseNumber(cleanRow["valor da nota"]),
      hasHedge: toBoolean(cleanRow["tem hedge"]),
      iof: parseNumber(cleanRow.iof),
      exchangeRate: parseNumber(cleanRow["taxa de câmbio"]),
      repository: cleanRow["registradora"],
      registerNumber: cleanRow["número de registro"],
      propertyName: cleanRow["nome do empreendimento"],
      propertyUnit: cleanRow["unidade do empreendimento"],
      propertyBlock: cleanRow["bloco do empreendimento"],
      loanRate: parseNumber(cleanRow["taxa do empréstimo"]),
      installments: installments?.filter(
        (item) => item.receivableIdentification === cleanRow["numero da nota"],
      ),
      externalId: cleanRow["origin id"],
    });
  });
};
