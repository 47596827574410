export const cleanTaxId = (taxId: string | number) =>
  taxId.toString().trim().replace(/\D/g, "").padStart(14, "0");

/** Returns an object with keys trimmed and lowercase */
export const normalizeObjectKeys = (obj: Record<string, unknown>) =>
  Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
      key.trim().toLowerCase(),
      value,
    ]),
  ) as Record<Lowercase<string>, any>;
