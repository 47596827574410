import { OTypography } from "@maestro/react";
import { DetailsFields } from "components/details-card";
import { GetApiAdminLoanContractsConfigByIdResponse } from "services/hubloan/models/responses/get-api-admin-loan-contracts-config-by-id.response";

export const loanContractsConfigDetailsGenerator = (
  contractConfig: GetApiAdminLoanContractsConfigByIdResponse,
) => {
  return {
    beneficiary: {
      title: "Configurações de contratos de empréstimo",
      items: [
        {
          label: "Produto",
          value: contractConfig.product ?? "-",
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Moeda",
          value: contractConfig.currency ?? "-",
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Conversão no momento do desembolso",
          value: contractConfig.currencyQuoteType ?? "-",
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Índice de remuneração",
          value: contractConfig.indexCode ?? "-",
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Parceiro",
          value: contractConfig.partnerSystem?.name ?? "-",
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Tipos de Garantias",
          value: contractConfig.collateralTypes.map((collateralType) => (
            <OTypography key={collateralType}>{collateralType}</OTypography>
          )),
          grid: { md: 4, xl: 3 },
        },
      ],
    },
  } satisfies DetailsFields;
};
