import { accRoles } from "pages/acc/roles/acc.roles";
import { antecipacaoAFornecedorRoles } from "pages/antecipacao-a-fornecedor/roles/antecipacao-a-forncedor.roles";
import { antecipacaoDeCartoesRoles } from "pages/antecipacao-de-cartoes/roles/antecipacao-de-cartoes.roles";
import { b2bRoles } from "pages/finders/roles/b2b.roles";
import { parceirosRoles } from "pages/parceiros/roles/parceiros.roles";
import { bankingRoles } from "pages/banking/roles/banking.roles";
import { billingRoles } from "pages/billing/roles/billing.roles";
import { cadastroRoles } from "pages/cadastro/roles/cadastro.roles";
import { cambioRoles } from "pages/cambio/roles/cambio.roles";
import { cartoesRoles } from "pages/cartoes/roles/cartoes.roles";
import { cobrancasRoles } from "pages/cobrancas/roles/cobrancas.roles";
import { duplicatasRoles } from "pages/duplicatas/roles/duplicatas.roles";
import { empresasRoles } from "pages/empresas/roles/empresas.roles";
import { emprestimosRoles } from "pages/emprestimos/roles/emprestimos.roles";
import { ferramentasRoles } from "pages/ferramentas/roles/ferramentas.roles";
import { fidcRoles } from "pages/fidc/roles/fidc.roles";
import { fopaRoles } from "pages/folha-de-pagamento/roles/folha-de-pagamento.roles";
import { energiaRoles } from "pages/energia/roles/energia.roles";
import { recebiveisRoles } from "pages/recebiveis/roles/recebiveis.roles";
import { renegociacoesRoles } from "pages/renegociacoes/roles/renegociacoes.roles";
import { usuariosRoles } from "pages/usuarios/roles/usuarios.roles";
import { workflowRmAdminRoles } from "pages/workflow-rmadmin/roles/workflow-rmadmin.roles";
import { workflowRoles } from "pages/workflow/roles/workflow.roles";
import { developersRoles } from "pages/developers/roles/developers.roles";
import { Roles } from "./roles.types";

export const roles = {
  acc: accRoles,
  antecipacaoAFornecedor: antecipacaoAFornecedorRoles,
  antecipacaoDeCartoes: antecipacaoDeCartoesRoles,
  b2b: b2bRoles,
  banking: bankingRoles,
  billing: billingRoles,
  cadastro: cadastroRoles,
  cambio: cambioRoles,
  cartoes: cartoesRoles,
  duplicatas: duplicatasRoles,
  empresas: empresasRoles,
  emprestimos: emprestimosRoles,
  energia: energiaRoles,
  ferramentas: ferramentasRoles,
  fidc: fidcRoles,
  fopa: fopaRoles,
  parceiros: parceirosRoles,
  recebiveis: recebiveisRoles,
  renegociacao: renegociacoesRoles,
  usuarios: usuariosRoles,
  workflow: workflowRoles,
  workflowRmAdmin: workflowRmAdminRoles,
  cobrancas: cobrancasRoles,
  developers: developersRoles,
} satisfies Record<string, Roles>;
