import {
  OCol,
  OField,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  ORow,
  OTypography,
  modalManager,
} from "@maestro/react";
import { masks } from "@maestro/utils";
import { ODataGridGenerator } from "components/data-grid";
import { LoadingButton } from "components/loading-button";
import { ReceivablesReprocess } from "services/quickfin/models";
import { reprocessModalGrid } from "./reprocess-modal.grid";
import { reprocessConfirmationModalId } from "./reprocess-modal.utils";

interface ReprocessModalGridProps {
  reprocessReceivables: () => Promise<void>;
  loading: boolean;
  reducedReceivables: ReceivablesReprocess[];
  selectedReceivables: ReceivablesReprocess[];
  totalSum: number;
}

export const ReprocessModal = ({
  reprocessReceivables,
  loading,
  reducedReceivables,
  selectedReceivables,
  totalSum,
}: ReprocessModalGridProps) => {
  return (
    <OModal
      id={reprocessConfirmationModalId}
      size="lg"
      position="center"
    >
      <OModalHeader closeButton>
        <OTypography tag="h2" size="lg" weight="normal">
          Confirma o reprocessamento dos seguintes títulos?
        </OTypography>
      </OModalHeader>
      <OModalBody>
        <div className="d-flex flex-column gap-3">
          <ODataGridGenerator
            grid={reprocessModalGrid}
            dataSource={reducedReceivables}
          />
          <ORow>
            <OCol xs={12} md={6}>
              <OField htmlFor="total-soma" label="Soma">
                <OTypography id="total-soma">
                  {masks.currency(totalSum)}
                </OTypography>
              </OField>
            </OCol>
            <OCol xs={12} md={6}>
              <OField htmlFor="total-notas" label="Total de notas">
                <OTypography id="total-notas">
                  {selectedReceivables.length}
                </OTypography>
              </OField>
            </OCol>
          </ORow>
        </div>
      </OModalBody>
      <OModalFooter divider>
        <div className="d-flex justify-content-end gap-2">
          <LoadingButton
            loading={loading}
            dataAction="aprovacao_recebiveis_modal:botao:cancelar"
            dataLabel="cancelar"
            outline
            onClick={() => modalManager.hide(reprocessConfirmationModalId)}
          >
            Cancelar
          </LoadingButton>
          <LoadingButton
            loading={loading}
            dataAction="aprovacao_recebiveis_modal:botao:confirmar"
            dataLabel="confirmar"
            type="tertiary"
            onClick={() => reprocessReceivables()}
          >
            Confirmar
          </LoadingButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
