import { OToastManager } from "@maestro/core";
import { LoadingButton } from "components/loading-button";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { service } from "services";
import { getValidationMessages } from "../../../../utils";

interface MarkAsReadButtonProps {
  pendencyId: number;
}

export const MarkAsReadButton = ({ pendencyId }: MarkAsReadButtonProps) => {
  const { callService, loading } = useServiceCall(
    service.hubEnergy.updatePendencyStatus,
  );

  const markAsRead = useCallback(async () => {
    const { success, error } = await callService({
      diligenceStepPendencyId: pendencyId,
      status: "EM_AUTOMACAO",
    });

    if (success) {
      OToastManager.success("Anotações marcadas como lidas");
    } else {
      OToastManager.danger(
        getValidationMessages(error)?.[0].ErrorMessage ??
          "Não foi possível marcar as anotações como lidas",
      );
    }
  }, [callService, pendencyId]);

  return (
    <LoadingButton loading={loading} onClick={markAsRead}>
      Marcar como lidas
    </LoadingButton>
  );
};
