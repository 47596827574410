import { YesNoRadioField } from "./yes-no-radio-field.component";

export const ReporteDiarioSacadoField = () => {
  return (
    <YesNoRadioField
      label="Notificações de reporte diário"
      name="reporteDiarioSacado"
      description="E-mail de notificação ao sacado das próximas liquidações"
    />
  );
};
