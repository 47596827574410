import { ORFieldUploadInput } from "@maestro/react";
import { helpers } from "@maestro/utils";
import { useMemo } from "react";
import { getProcessorPropertyFieldId } from "../../../utils";
import { ProcessorConfigPropertyProps } from "../../property-field.types";
import { useFilePropertyField } from "./file-property-field.hook";

export const FilePropertyField = ({
  processorConfigProperty,
}: ProcessorConfigPropertyProps) => {
  const { processorProperty, id } = processorConfigProperty;
  const { handleSubmitFile } = useFilePropertyField({
    processorConfigProperty,
  });

  const processorPropertyFieldId = getProcessorPropertyFieldId(
    processorProperty.name,
    id,
  );

  const filePropertyField = useMemo(() => {
    return (
      <ORFieldUploadInput
        key={processorProperty.name + id}
        id={processorPropertyFieldId}
        name={processorPropertyFieldId}
        label={processorProperty.description}
        inputLabel="Selecionar documento"
        accept={[
          ".xls",
          ".xlsx",
          ".csv",
          ".jpg",
          ".jpeg",
          ".bmp",
          ".pdf",
          ".png",
          ".doc",
          ".docx",
        ].join(",")}
        multiple={false}
        handleAddFile={(e, resolve) => handleSubmitFile(e.detail, id, resolve)}
        handleDownload={async (file) => {
          const base64 = await helpers.convertFileToBase64(file);
          helpers.downloadBase64(base64, file.name);
        }}
        handleRemove={(file, resolve) => {
          resolve(file);
        }}
      />
    );
  }, [processorProperty, id, handleSubmitFile]);

  return <>{filePropertyField}</>;
};
