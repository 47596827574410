import { OToastManager } from "@maestro/core";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { service } from "services";
import { gridStorage } from "utils/storage";
import { MappedStatusBadge } from "../../../../../../../../components/mapped-status-badge";
import { PendencyNotesMasterDetail } from "../../../../../../../../components/pendency-notes";
import {
  getValidationMessages,
  pendencyStatusLookup,
  pendencyStatusMap,
} from "../../../../../../../../utils";
import { PropertyLabel } from "./step-pendencies.types";
import { GridActions } from "./_compose";

export const buildDataSource = (diligenceStepId: number | string) =>
  dataSourceCustomStoreGenerator<HubEnergy.DiligenceStepPendencyWithPropertyResponse>(
    () =>
      service.hubEnergy
        .getDiligenceStepByIdPendencies(diligenceStepId)
        .then(({ data }) => data?.response)
        .catch((err) => {
          const errorMessage =
            getValidationMessages(err)?.[0].ErrorMessage ??
            "Não foi possível buscar as pendências";
          throw new Error(errorMessage);
        }),
    {
      customStoreOptions: {
        insert: async (values) => {
          try {
            await service.hubEnergy.addDiligenceStepPendency({
              stepId: Number(diligenceStepId),
              typeId: values.type.id,
              propertyId: values?.property?.id ?? null,
              isAutomation: false,
              isPublic: values.isPublic,
              message: values.message,
              status: values.status,
            });

            OToastManager.success("Pendência adicionada com sucesso.");

            return values;
          } catch (error) {
            const errorMessage =
              getValidationMessages(error)?.[0]?.ErrorMessage ??
              "Erro ao adicionar a pendência";

            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          }
        },
        update: async (original, updated) => {
          try {
            const newData = { ...original, ...updated };

            await service.hubEnergy.updateDiligenceStepPendency({
              stepId: Number(diligenceStepId),
              pendencyId: newData.id,
              typeId: newData.type.id,
              propertyId: newData.property?.id,
              isAutomation: original.isAutomation,
              isPublic: newData.isPublic,
              message: newData.message,
              status: newData.status,
            });

            OToastManager.success("Pendência editada com sucesso.");

            return newData;
          } catch (error) {
            const errorMessage =
              getValidationMessages(error)?.[0]?.ErrorMessage ??
              "Erro ao editar a pendência";

            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          }
        },
        remove: async ({ id }) => {
          try {
            await service.hubEnergy.removePendency(id);

            OToastManager.success("Pendência excluída com sucesso.");
          } catch (error) {
            const errorMessage =
              getValidationMessages(error)?.[0]?.ErrorMessage ??
              "Erro ao excluir a pendência";

            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          }
        },
      },
    },
  );

export const stepPendenciesGrid = (
  canEditPendency: boolean,
  pendencyTypes: HubEnergy.DiligenceStepPendencyTypeResponse[],
  propertyLabels: PropertyLabel[],
): ODataGridGeneratorConfig<HubEnergy.DiligenceStepPendencyWithPropertyResponse> => ({
  datagrid: {
    noDataText: "Nenhuma pendência",
    filterRow: { visible: true },
    headerFilter: { visible: true },
    pager: { showPageSizeSelector: true },
    stateStoring: gridStorage("mle-stepPendenciesGrid"),
    editing: {
      texts: {
        confirmDeleteMessage: "Tem certeza que deseja excluir esta pendência?",
      },
    },
    onInitNewRow: (e) => {
      e.data.status = "PENDENTE";
      e.data.isAutomation = false;
      e.data.isPublic = false;
    },
    onEditorPreparing: (e) => {
      e.editorOptions = {
        ...e.editorOptions,
        dropDownOptions: {
          ...e.editorOptions?.dropDownOptions,
          minWidth: 400,
        },
        itemTemplate: (itemData, _, itemElement) => {
          (itemElement as HTMLElement).setAttribute("title", itemData.label);
          return itemData.label;
        },
      };
      // https://supportcenter.devexpress.com/ticket/details/t560733/how-to-disable-a-cell-editor-based-on-a-value-of-another-cell
      // disable editing cells if `isAutomation`
      if (
        e.parentType === "dataRow" &&
        e.dataField &&
        ["type.id", "property.id"].includes(e.dataField) &&
        e.row?.data.isAutomation
      ) {
        e.editorOptions.disabled = true;
      }
    },
  },
  columns: [
    {
      caption: "Tipo",
      dataField: "type.id",
      lookup: {
        dataSource: pendencyTypes.map(({ id, name }) => ({ id, label: name })),
        displayExpr: "label",
        valueExpr: "id",
      },
    },
    {
      caption: "Descrição",
      dataField: "type.description",
      allowEditing: false,
    },
    {
      caption: "Mensagem",
      dataField: "message",
      cssClass: "dx-word-wrap",
    },
    {
      caption: "Propriedade",
      dataField: "property.id",
      lookup: {
        dataSource: propertyLabels,
        displayExpr: "label",
        valueExpr: "id",
        allowClearing: true,
      },
      cssClass: "dx-word-wrap",
    },
    {
      caption: "Mostra Cliente",
      dataField: "isPublic",
      dataType: "boolean",
    },
    {
      caption: "Automação",
      dataField: "isAutomation",
      dataType: "boolean",
      allowEditing: false,
    },
    {
      caption: "Status",
      dataField: "status",
      alignment: "center",
      lookup:
        pendencyStatusLookup<HubEnergy.DiligenceStepPendencyWithPropertyResponse>(),
      cellRender: ({ data }) => (
        <MappedStatusBadge map={pendencyStatusMap} status={data.status} />
      ),
    },
    {
      cellRender: ({ data, component, rowIndex, row }) => (
        <GridActions
          pendency={data}
          component={component}
          rowIndex={rowIndex}
          isEditing={!!row.isEditing}
          canEditPendency={canEditPendency}
        />
      ),
    },
  ],
  masterDetail: {
    enabled: true,
    component: ({ data }) => (
      <PendencyNotesMasterDetail
        pendencyId={data.data.id}
        pendencyStatus={data.data.status}
        notes={data.data.notes}
      />
    ),
  },
});
