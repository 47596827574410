import { useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { useWorkflow } from "../../../../../../../../../../../../../../../components/hooks/use-workflow.context";
import { getProcessorPropertyFieldId } from "../../../utils";
import { GenericPropertyFieldProps } from "../../property-field.types";

export const useBatchTemplatePropertyField = ({
  processorConfigProperty: { processorProperty, id, value },
  handleSubmitProperty,
}: GenericPropertyFieldProps) => {
  const fieldInitialized = useRef<boolean>(false);
  const { watch, setValue, reset } = useFormContext();
  const {
    batchTemplates: { value: allBatchTemplates },
  } = useWorkflow();

  const processorPropertyFieldId = getProcessorPropertyFieldId(
    processorProperty.name,
    id,
  );

  const fieldWatch = watch(processorPropertyFieldId);

  useEffect(() => {
    if (!value) return;
    setValue(processorPropertyFieldId, value);
  }, [value, setValue, processorProperty, reset, id]);

  useEffect(() => {
    if (!fieldInitialized.current) {
      fieldInitialized.current = true;
      return;
    }

    if (!fieldWatch || fieldWatch === value) return;

    handleSubmitProperty(fieldWatch, id);
  }, [fieldWatch, processorProperty, handleSubmitProperty, id, value]);

  return { allBatchTemplates };
};
