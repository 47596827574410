import { ORFieldInput } from "@maestro/react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { getValueFromMap } from "utils/get-value-from-map";
import { CheckboxField } from "../../../../../../../../components/checkbox-field";
import {
  DocumentTypeSelector,
  PendencyStatusSelector,
  PendencyTypeSelector,
  PropertyTypeSelector,
  StepStatusSelector,
  StepTypeSelector,
} from "../../../selects";
import { ActionEditorForm, actionEditorFormFields } from "./action-editor.form";

interface ActionEditorField {
  key: keyof typeof actionEditorFormFields;
  Component: () => JSX.Element;
  availableActionTypes: HubEnergy.EDiligenceEventActionType[];
  availableEventTypes?: HubEnergy.EDiligenceEventType[];
}

export const actionEditorFields: ActionEditorField[] = [
  {
    key: "targetStepTypes",
    Component: () => {
      const { watch } = useFormContext<ActionEditorForm>();
      const targetSameStepWatcher = watch("targetSameStep");

      return (
        <StepTypeSelector
          {...actionEditorFormFields.targetStepTypes}
          disabled={targetSameStepWatcher}
        />
      );
    },
    availableActionTypes: [
      "CHANGE_PENDENCY_STATUS",
      "CHANGE_STEP_STATUS",
      "CREATE_PENDENCY",
      "CREATE_PROPERTY",
    ],
  },
  {
    key: "targetStepStatuses",
    Component: () => {
      const { watch } = useFormContext<ActionEditorForm>();
      const targetSameStepWatcher = watch("targetSameStep");
      return (
        <StepStatusSelector
          {...actionEditorFormFields.targetStepStatuses}
          disabled={targetSameStepWatcher}
        />
      );
    },
    availableActionTypes: [
      "CHANGE_PENDENCY_STATUS",
      "CHANGE_STEP_STATUS",
      "CREATE_PENDENCY",
      "CREATE_PROPERTY",
    ],
  },
  {
    key: "targetPendencyTypes",
    Component: () => {
      const { watch } = useFormContext<ActionEditorForm>();
      const targetSamePendencyWatcher = watch("targetSamePendency");

      return (
        <PendencyTypeSelector
          {...actionEditorFormFields.targetPendencyTypes}
          valueType="name"
          disabled={targetSamePendencyWatcher}
        />
      );
    },
    availableActionTypes: ["CHANGE_PENDENCY_STATUS"],
  },
  {
    key: "targetPendencyStatuses",
    Component: () => {
      const { watch } = useFormContext<ActionEditorForm>();
      const targetSamePendencyWatcher = watch("targetSamePendency");

      return (
        <PendencyStatusSelector
          {...actionEditorFormFields.targetPendencyStatuses}
          disabled={targetSamePendencyWatcher}
        />
      );
    },
    availableActionTypes: ["CHANGE_PENDENCY_STATUS"],
  },
  {
    key: "targetSamePendency",
    Component: () => {
      const { watch, setValue } = useFormContext<ActionEditorForm>();
      const targetSamePendencyWatcher = watch("targetSamePendency");

      useEffect(() => {
        if (targetSamePendencyWatcher) {
          setValue("targetPendencyTypes", []);
          setValue("targetPendencyStatuses", []);
        }
      }, [setValue, targetSamePendencyWatcher]);

      return <CheckboxField {...actionEditorFormFields.targetSamePendency} />;
    },
    availableActionTypes: ["CHANGE_PENDENCY_STATUS"],
    availableEventTypes: ["PENDENCY_CREATED", "PENDENCY_CHANGED_STATUS"],
  },
  {
    key: "targetSameStep",
    Component: () => {
      const { watch, setValue } = useFormContext<ActionEditorForm>();
      const targetSameStepWatcher = watch("targetSameStep");

      useEffect(() => {
        if (targetSameStepWatcher) {
          setValue("targetStepTypes", []);
          setValue("targetStepStatuses", []);
        }
      }, [setValue, targetSameStepWatcher]);

      return <CheckboxField {...actionEditorFormFields.targetSameStep} />;
    },
    availableActionTypes: [
      "CHANGE_PENDENCY_STATUS",
      "CHANGE_STEP_STATUS",
      "CREATE_PENDENCY",
      "CREATE_PROPERTY",
    ],
  },
  {
    key: "isEnabled",
    Component: () => <CheckboxField {...actionEditorFormFields.isEnabled} />,
    availableActionTypes: [
      "CHANGE_PENDENCY_STATUS",
      "CHANGE_STEP_STATUS",
      "CREATE_PENDENCY",
      "CREATE_PROPERTY",
    ],
  },
];

export const actionEditorPayloadFields: ActionEditorField[] = [
  // CHANGE_PENDENCY_STATUS
  {
    key: "payload.changePendencyStatusActionPayload.status",
    Component: () => (
      <PendencyStatusSelector
        {...actionEditorFormFields[
          "payload.changePendencyStatusActionPayload.status"
        ]}
      />
    ),
    availableActionTypes: ["CHANGE_PENDENCY_STATUS"],
  },
  // CHANGE_STEP_STATUS
  {
    key: "payload.changeStepStatusActionPayload.status",
    Component: () => (
      <StepStatusSelector
        {...actionEditorFormFields[
          "payload.changeStepStatusActionPayload.status"
        ]}
        dropdownPosition="top"
      />
    ),
    availableActionTypes: ["CHANGE_STEP_STATUS"],
  },
  // CREATE_PENDENCY
  {
    key: "payload.createPendencyActionPayload.isPublic",
    Component: () => (
      <CheckboxField
        {...actionEditorFormFields[
          "payload.createPendencyActionPayload.isPublic"
        ]}
      />
    ),
    availableActionTypes: ["CREATE_PENDENCY"],
  },
  {
    key: "payload.createPendencyActionPayload.isAutomation",
    Component: () => (
      <CheckboxField
        {...actionEditorFormFields[
          "payload.createPendencyActionPayload.isAutomation"
        ]}
      />
    ),
    availableActionTypes: ["CREATE_PENDENCY"],
  },
  {
    key: "payload.createPendencyActionPayload.type",
    Component: () => (
      <PendencyTypeSelector
        {...actionEditorFormFields["payload.createPendencyActionPayload.type"]}
        valueType="name"
      />
    ),
    availableActionTypes: ["CREATE_PENDENCY"],
  },
  {
    key: "payload.createPendencyActionPayload.status",
    Component: () => (
      <PendencyStatusSelector
        {...actionEditorFormFields[
          "payload.createPendencyActionPayload.status"
        ]}
      />
    ),
    availableActionTypes: ["CREATE_PENDENCY"],
  },
  {
    key: "payload.createPendencyActionPayload.message",
    Component: () => (
      <ORFieldInput
        {...actionEditorFormFields[
          "payload.createPendencyActionPayload.message"
        ]}
      />
    ),
    availableActionTypes: ["CREATE_PENDENCY"],
  },
  // CREATE_PROPERTY
  {
    key: "payload.createPropertyActionPayload.isPublic",
    Component: () => (
      <CheckboxField
        {...actionEditorFormFields[
          "payload.createPropertyActionPayload.isPublic"
        ]}
      />
    ),
    availableActionTypes: ["CREATE_PROPERTY"],
  },
  {
    key: "payload.createPropertyActionPayload.isAutomation",
    Component: () => (
      <CheckboxField
        {...actionEditorFormFields[
          "payload.createPropertyActionPayload.isAutomation"
        ]}
      />
    ),
    availableActionTypes: ["CREATE_PROPERTY"],
  },
  {
    key: "payload.createPropertyActionPayload.type",
    Component: () => (
      <PropertyTypeSelector
        {...actionEditorFormFields["payload.createPropertyActionPayload.type"]}
        valueType="name"
      />
    ),
    availableActionTypes: ["CREATE_PROPERTY"],
  },
  {
    key: "payload.createPropertyActionPayload.documentType",
    Component: () => (
      <DocumentTypeSelector
        {...actionEditorFormFields[
          "payload.createPropertyActionPayload.documentType"
        ]}
        valueType="name"
      />
    ),
    availableActionTypes: ["CREATE_PROPERTY"],
  },
];

export const payloadTypeMap = {
  CHANGE_PENDENCY_STATUS: "changePendencyStatusActionPayload",
  CHANGE_STEP_STATUS: "changeStepStatusActionPayload",
  CREATE_PENDENCY: "createPendencyActionPayload",
  CREATE_PROPERTY: "createPropertyActionPayload",
} satisfies Record<
  HubEnergy.EDiligenceEventActionType,
  keyof HubEnergy.AbstractUpsertDiligenceEventActionPayload
>;

export const pickPayload = (values: ActionEditorForm) => {
  const clone = { ...values };

  const payloadType = getValueFromMap(payloadTypeMap, values.actionType);
  if (!payloadType) throw new Error("Unexpected action type");

  clone.payload = {
    [payloadType]: clone.payload[payloadType],
  } as typeof clone.payload;

  return clone;
};
