import {
  OButton,
  OCol,
  OIcon,
  OLoader,
  OOption,
  OOptionMultiple,
  ORFieldSelect,
  ORFieldSelectMultiple,
  OToastManager,
} from "@maestro/react";
import { useCallback, useEffect, useState } from "react";
import { service } from "services";
import { loanProductOptions } from "../utils/loan-products.utils";
import {
  collateralTypesOptions,
  currencyOptions,
  currencyQuoteTypeOptions,
  indexCodeOptions,
} from "./loan-contracts-config-form.utils";

export interface LoanContractsConfigFormValues {
  product: string;
  currency: string;
  currencyQuoteType?: string | null;
  indexCode?: string | null;
  collateralTypes: string[];
  partnerSystemId?: number | null;
}

export const LoanContractsConfigForm = () => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [partners, setPartners] =
    useState<Awaited<ReturnType<typeof service.hubLoan.getPartner>>["data"]>();

  const getPartners = useCallback(async () => {
    try {
      setHasError(false);
      setLoading(true);
      const { data } = await service.hubLoan.getPartner();

      setPartners(data);
    } catch {
      setHasError(true);
      OToastManager.danger("Erro ao buscar parceiros");
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getPartners();
  }, [getPartners]);

  return (
    <>
      <OCol>
        <ORFieldSelect
          id="product"
          name="product"
          dataAction="emprestimos_template_contratto:select:produto"
          dataLabel="produto"
          label="Produto"
        >
          {loanProductOptions.map((productOption) => (
            <OOption key={productOption.value} value={productOption.value}>
              {productOption.text}
            </OOption>
          ))}
        </ORFieldSelect>
      </OCol>
      <OCol>
        <ORFieldSelect
          id="currency"
          name="currency"
          dataAction="emprestimos_template_contratto:select:moeda"
          dataLabel="moeda"
          label="Moeda"
        >
          {currencyOptions.map((currencyOption) => (
            <OOption key={currencyOption} value={currencyOption}>
              {currencyOption}
            </OOption>
          ))}
        </ORFieldSelect>
      </OCol>
      <OCol>
        <ORFieldSelect
          id="currencyQuoteType"
          name="currencyQuoteType"
          dataAction="emprestimos_template_contratto:select:conversao"
          dataLabel="conversao"
          label="Conversão no momento do desembolso"
        >
          {currencyQuoteTypeOptions.map((currencyQuoteTypeOption) => (
            <OOption
              key={currencyQuoteTypeOption}
              value={currencyQuoteTypeOption}
            >
              {currencyQuoteTypeOption}
            </OOption>
          ))}
        </ORFieldSelect>
      </OCol>
      <OCol>
        <ORFieldSelect
          id="indexCode"
          name="indexCode"
          dataAction="emprestimos_template_contratto:select:indice_de_remuneracao"
          dataLabel="indice_de_remuneracao"
          label="Índice de remuneração"
        >
          {indexCodeOptions.map((indexCodeOption) => (
            <OOption key={indexCodeOption} value={indexCodeOption}>
              {indexCodeOption}
            </OOption>
          ))}
        </ORFieldSelect>
      </OCol>
      <OCol className="align-items-center">
        <ORFieldSelect
          id="partnerSystemId"
          name="partnerSystemId"
          dataAction="emprestimos_template_contratto:select:tipo_parceiro"
          dataLabel="tipo_parceiro"
          label="Parceiro"
          key={partners?.length}
        >
          {partners?.map((partner) => (
            <OOption key={partner.id} value={partner.id}>
              {partner.name}
            </OOption>
          ))}
        </ORFieldSelect>
        {loading && <OLoader />}
        {hasError && (
          <OButton
            type="default"
            outline
            dataLabel="tentar_novamente_parceiros"
            dataAction="emprestimos_template_contratto:botao:tentar_novamente_parceiros"
            onClick={() => getPartners()}
          >
            <OIcon category="fa" icon="fa-repeat" size="sm" type="default" />
          </OButton>
        )}
      </OCol>
      <OCol>
        <ORFieldSelectMultiple
          id="collateralTypes"
          name="collateralTypes"
          dataAction="emprestimos_template_contrato:select:tipos_de_garantia"
          dataLabel="tipos_de_garantia"
          label="Tipos de garantia"
        >
          {collateralTypesOptions.map((collateralTypesOption) => (
            <OOptionMultiple
              key={collateralTypesOption}
              value={collateralTypesOption}
            >
              {collateralTypesOption}
            </OOptionMultiple>
          ))}
        </ORFieldSelectMultiple>
      </OCol>
    </>
  );
};
