import { OLoader, OOption, ORFieldSelect } from "@maestro/react";
import { useMemo } from "react";
import { getProcessorPropertyFieldId } from "../../../utils";
import { GenericPropertyFieldProps } from "../../property-field.types";
import { useProcessorConfigField } from "./processor-config-field.hook";

export const ProcessorConfigField = ({
  processorConfigProperty,
  handleSubmitProperty,
}: GenericPropertyFieldProps) => {
  const { processorProperty, id } = processorConfigProperty;
  const { chidProcessorConfigs, loading } = useProcessorConfigField({
    processorConfigProperty,
    handleSubmitProperty,
  });

  const processorPropertyFieldId = getProcessorPropertyFieldId(
    processorProperty.name,
    id,
  );

  const processorConfigField = useMemo(() => {
    return (
      <div className="d-flex align-items-center">
        <ORFieldSelect
          key={processorProperty.name + id}
          id={processorPropertyFieldId}
          name={processorPropertyFieldId}
          placeholder="Selecione"
          label={processorProperty.description}
        >
          <div>
            {chidProcessorConfigs?.map((config) => (
              <OOption key={config.id} value={config.id?.toString()}>
                {config.name}
              </OOption>
            ))}
          </div>
        </ORFieldSelect>
        {loading && <OLoader />}
      </div>
    );
  }, [
    processorProperty?.name,
    processorProperty?.description,
    id,
    chidProcessorConfigs,
    loading,
  ]);

  return <>{processorConfigField}</>;
};
