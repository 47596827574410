import { OCol, OLoader, OOption, ORFieldSelect } from "@maestro/react";
import { RefreshGridButton } from "components/refresh-grid-button";
import { useCreditorIdField } from "./creditor-id-field.hook";

export const CreditorIdField = () => {
  const { creditors, getCreditors, loading, hasFetchError } =
    useCreditorIdField();

  return (
    <OCol xs={12} md={6} align="center" className="flex-fill">
      <ORFieldSelect
        dataAction="geral:select:financiadores"
        dataLabel="financiadores"
        id="creditorId"
        name="creditorId"
        label="Financiador"
        placeholder="Selecionar"
        disabled={hasFetchError || loading}
        key={creditors?.length}
      >
        {creditors?.map((creditor) => (
          <OOption key={creditor.id} value={creditor.id}>
            {creditor.name}
          </OOption>
        ))}
      </ORFieldSelect>
      {loading && <OLoader size="sm" />}
      {hasFetchError && !loading && (
        <RefreshGridButton
          dataAction="financiadores:botao:tentar_novamente"
          dataLabel="tentar_novamente"
          onClick={() => getCreditors()}
        />
      )}
    </OCol>
  );
};
