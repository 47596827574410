import {
  OCheckbox,
  OCol,
  OLabel,
  OOptionMultiple,
  ORFieldCheckboxGroup,
  ORFieldInput,
  ORFieldSelectMultiple,
  ORow,
  OTypography,
} from "@maestro/react";
import { SelectSearchField } from "components/select-search";
import { ProcessorConfigGeneralInfoProps } from "./processor-config-user-actions.types";

export const ProcessorConfigUserActions = ({
  workflowConfigViews,
  processorConfig,
  iconOptions,
  buttonTypeOptions,
}: ProcessorConfigGeneralInfoProps) => {
  return (
    <div className="d-flex flex-column gap-4">
      <OTypography size="lg">Ações de usuário</OTypography>
      {processorConfig?.processor.hasUserAction && (
        <>
          <ORow>
            <OCol md={6} sm={12}>
              <ORFieldInput
                id="actionButtonName"
                name="actionButtonName"
                label="Descrição do botão de ação"
                labelSize="md"
                tag="text"
                placeholder="Preencher"
              />
            </OCol>
            <OCol md={6} sm={12}>
              <SelectSearchField
                id="actionIcon"
                name="actionIcon"
                label="Ícone"
                labelSize="md"
                key={`action-icon-${iconOptions?.length}`}
                options={iconOptions.map((icon) => ({
                  label: icon.value,
                  value: icon.value,
                }))}
              />
            </OCol>
          </ORow>
          <ORow>
            <OCol md={6} sm={12}>
              <ORFieldSelectMultiple
                id="viewUserActions"
                name="viewUserActions"
                label="Ação disponível em qual view?"
                labelSize="md"
                key={`general-processor-config-views-${processorConfig.id}`}
              >
                <div>
                  {workflowConfigViews?.map((wfView) => (
                    <OOptionMultiple
                      key={`processor-config-view-option-${processorConfig.id}-${wfView.id}`}
                      value={wfView.view.type}
                    >
                      {wfView.view.name}
                    </OOptionMultiple>
                  ))}
                </div>
              </ORFieldSelectMultiple>
            </OCol>
            <OCol md={6} sm={12}>
              <SelectSearchField
                id="actionButtonType"
                name="actionButtonType"
                label="Cor do botão"
                labelSize="md"
                key={`action-button-type-${buttonTypeOptions?.length}`}
                options={buttonTypeOptions.map((button) => ({
                  label: button.value,
                  value: button.value,
                }))}
              />
            </OCol>
          </ORow>
          <ORow>
            <OCol md={6} sm={12}>
              <ORFieldCheckboxGroup
                id="hasActionWithoutContext"
                name="hasActionWithoutContext"
              >
                <div className="d-flex align-items-center gap-2 mb-2">
                  <OCheckbox
                    size="xs"
                    id="has-action-without-context-checkbox"
                    value="1"
                  />
                  <OLabel htmlFor="has-action-without-context-checkbox">
                    Ação disponível sem contexto?
                  </OLabel>
                </div>
              </ORFieldCheckboxGroup>
            </OCol>
            <OCol md={6} sm={12}>
              <ORFieldCheckboxGroup
                id="isActionButtonOutlined"
                name="isActionButtonOutlined"
              >
                <div className="d-flex align-items-center gap-2 mb-2">
                  <OCheckbox
                    size="xs"
                    id="is-action-button-outlined-checkbox"
                    value="1"
                  />
                  <OLabel htmlFor="is-action-button-outlined-checkbox">
                    Retirar fundo do botão?
                  </OLabel>
                </div>
              </ORFieldCheckboxGroup>
            </OCol>
          </ORow>
        </>
      )}
    </div>
  );
};
