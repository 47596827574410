import { useCustomer } from "contexts/customer";
import { useCallback } from "react";
import { logger } from "utils/logger";
import { PaymentUtils, assembleFilters } from "./_compose";
import { usePaymentsAndTransfersContext } from "./payments-and-transfers.context";
import {
  INITIAL_PAGE_NUMBER,
  INITIAL_PAGE_SIZE,
  MetaData,
  Pagination,
} from "./payments-and-transfers.types";

export const usePaymentsAndTransfers = () => {
  const {
    updateDataSource,
    setPageNumber,
    pageSize,
    filtersSubmitted,
    pageCursors: [previousPageCursors, nextPageCursors],
    pageCursorOrigin,
    setClickedRow,
  } = usePaymentsAndTransfersContext();

  const {
    customer: { identification },
  } = useCustomer();

  const initialUpdateDataSource = useCallback(async () => {
    try {
      await updateDataSource({
        pagination: {
          pageSize: INITIAL_PAGE_SIZE,
          pageNumber: INITIAL_PAGE_NUMBER,
        },
      });
    } catch (error) {
      logger.warn(error);
    } finally {
      setPageNumber(INITIAL_PAGE_NUMBER);
    }
  }, [updateDataSource, setPageNumber]);

  const submitFilters = useCallback(
    async (data: Record<string, unknown>) => {
      try {
        const filters = assembleFilters(data);

        await updateDataSource({
          filters,
          pagination: {
            pageNumber: INITIAL_PAGE_NUMBER,
            pageSize,
          },
        });
      } catch (error) {
        logger.error(error);
      } finally {
        setPageNumber(INITIAL_PAGE_NUMBER);
      }
    },
    [updateDataSource, pageSize, setPageNumber],
  );

  const handleRefreshDataSource = useCallback(async () => {
    try {
      const filters = assembleFilters({ ...filtersSubmitted });

      await updateDataSource({
        filters,
        pagination: {
          pageNumber: INITIAL_PAGE_NUMBER,
          pageSize,
        },
      });
    } catch (error) {
      logger.warn(error);
    } finally {
      setPageNumber(INITIAL_PAGE_NUMBER);
    }
  }, [filtersSubmitted, pageSize, setPageNumber, updateDataSource]);

  const updateDataSourceByPagination = useCallback(
    async (pagination: Pagination) => {
      try {
        await updateDataSource({
          filters: filtersSubmitted,
          pagination,
        });
      } catch (error) {
        logger.error(error);
      } finally {
        setPageNumber(INITIAL_PAGE_NUMBER);
      }
    },
    [updateDataSource, filtersSubmitted, setPageNumber],
  );

  const handlePageSize = (value: number) => {
    updateDataSourceByPagination({
      pageSize: value,
      pageNumber: INITIAL_PAGE_NUMBER,
    });
  };

  const updateDataSourceByCursor = useCallback(
    async (cursor: string, cursorOrigin: string) => {
      try {
        await updateDataSource({
          cursor,
          cursorOrigin,
        });
      } catch (error) {
        logger.error(error);
      }
    },
    [updateDataSource],
  );

  const handleNext = () => {
    updateDataSourceByCursor(nextPageCursors, pageCursorOrigin);
  };

  const handlePrevious = () => {
    const requireLoadInitialPage = !previousPageCursors;

    if (requireLoadInitialPage) {
      updateDataSourceByPagination({
        pageSize,
        pageNumber: INITIAL_PAGE_NUMBER,
      });
    } else {
      updateDataSourceByCursor(previousPageCursors, pageCursorOrigin);
    }
  };

  const seeDetails = useCallback(
    (clickedRow: MetaData) => {
      setClickedRow(clickedRow);

      // TODO: temporarily remove page preview
      // navigate(
      //   corporateRouter.routes.banking.customer.paymentsAndTransfers.details
      //     .path,
      // );
    },
    [setClickedRow],
  );

  const downloadSingleReceipt = useCallback(
    (id: string) => {
      PaymentUtils.handleDownloadPaymentsReceipt(identification, [id]);
    },
    [identification],
  );

  return {
    initialUpdateDataSource,
    submitFilters,
    handleRefreshDataSource,
    handlePageSize,
    handleNext,
    handlePrevious,
    seeDetails,
    downloadSingleReceipt,
  };
};
