import { modalManager, OButton } from "@maestro/react";
import {
  dataSourceODataStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { endpoints } from "services/endpoints";
import { GetOdataReceivableReprocessOdataViewResponse } from "services/quickfin/models";
import { GridTemplate } from "templates/grid-template";
import { useReprocess } from "./reprocess.hook";
import { ReprocessModal } from "./modal-reprocess";
import { reprocessConfirmationModalId } from "./modal-reprocess/reprocess-modal.utils";

const dataSource = dataSourceODataStoreGenerator<
  GetOdataReceivableReprocessOdataViewResponse["value"][number]
>(endpoints.quickfin.odata.receivableReprocessODataView);

export const Reprocess = () => {
  const {
    reprocessReceivables,
    grid,
    gridRef,
    loading,
    reducedReceivables,
    selectedReceivables,
    totalSum,
  } = useReprocess();

  return (
    <GridTemplate
      pageTitle={
        <PageTitle
          title="Reprocessamento"
          description="Visualize todos os títulos passíveis de reprocessamento"
        />
      }
      gridRef={gridRef}
      actions={
        <OButton
          disabled={!(selectedReceivables && selectedReceivables.length > 0)}
          onClick={() => modalManager.show(reprocessConfirmationModalId)}
        >
          Reprocessar
        </OButton>
      }
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource}
      />
      <ReprocessModal
        reprocessReceivables={reprocessReceivables}
        loading={loading}
        reducedReceivables={reducedReceivables}
        selectedReceivables={selectedReceivables ?? []}
        totalSum={totalSum}
      />
    </GridTemplate>
  );
};
