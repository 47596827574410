import { OCol, OOption, ORFieldSelect } from "@maestro/react";

const options = [
  {
    value: "Cedente",
    label: "Cedente",
  },
  {
    value: "Sacado",
    label: "Sacado/Âncora",
  },
];

interface ComandoOperacaoFieldProps {
  required?: boolean;
}

export const ComandoOperacaoField = ({
  required,
}: ComandoOperacaoFieldProps) => {
  return (
    <OCol xs={12} md={6}>
      <ORFieldSelect
        dataAction="geral:select:comando_operacao"
        dataLabel="comando_operacao"
        id="comandoOperacao"
        name="comandoOperacao"
        label="Comando da antecipação"
        description="Definição de quem irá realizar a solicitação de antecipação dos recebíveis"
        placeholder="Selecionar"
        required={required}
      >
        {options.map(({ label, value }) => (
          <OOption key={value} value={value}>
            {label}
          </OOption>
        ))}
      </ORFieldSelect>
    </OCol>
  );
};
