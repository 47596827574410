import {
  modalManager,
  OButton,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OToastManager,
  OTypography,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { useCallback } from "react";
import { service } from "services";
import { REDIRECT_MODAL } from "../../redirect-signature-modal/redirect-signature.component";
import { useSimulation } from "../../simulation/simulation.context";
import { Confirmation } from "../accept-footer.types";

interface AcceptModalProps {
  acceptLoading: boolean;
  setAcceptLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CONFIRMATION_MODAL = "confirmation-modal";

export const AcceptModal = ({
  acceptLoading,
  setAcceptLoading,
}: AcceptModalProps) => {
  const {
    selectedBank,
    selectedSimulationResult,
    selectedSettlementType,
    selectedSettlementBank,
  } = useSimulation();

  const handleAccept = useCallback(async () => {
    try {
      setAcceptLoading(true);

      const hasDisbursement = !!selectedSimulationResult?.bankAccount;

      const payload = {
        identification: selectedSimulationResult?.identification,
        settlementType: selectedSettlementType,
      } as Confirmation;

      if (!hasDisbursement && !!selectedBank) {
        payload.accountDigit = selectedBank.accountDigit;
        payload.accountNumber = selectedBank.accountNumber;
        payload.agency = selectedBank.agency;
        payload.agencyDigit = selectedBank.agencyDigit;
        payload.bankCode = selectedBank.bankCode;
      }

      if (
        !!selectedSettlementType &&
        selectedSettlementType === "DebitoEmConta" &&
        !!selectedSettlementBank
      ) {
        payload.settlementAccountDigit = selectedSettlementBank.accountDigit;
        payload.settlementAccountNumber = selectedSettlementBank.accountNumber;
        payload.settlementAgency = selectedSettlementBank.agency;
        payload.settlementAgencyDigit = selectedSettlementBank.agencyDigit;
        payload.settlementBankCode = selectedSettlementBank.bankCode;
      }

      await service.hubLoan.postConfirmSimulation(payload);

      modalManager.hide(CONFIRMATION_MODAL);
      modalManager.show(REDIRECT_MODAL);
    } catch (e) {
      OToastManager.danger({
        title: "Não foi possível realizar a contratação",
        message: "Tente novamente mais tarde",
      });
    } finally {
      setAcceptLoading(false);
    }
  }, [
    setAcceptLoading,
    selectedSimulationResult,
    selectedBank,
    selectedSettlementType,
    selectedSettlementBank,
  ]);

  return (
    <OModal
      className="modal-show text-center position-relative"
      id={CONFIRMATION_MODAL}
      backdrop={!acceptLoading}
      position="center"
      size="xs"
    >
      <OModalHeader>
        <OTypography size="lg">Confirmar aprovação</OTypography>
      </OModalHeader>

      <OModalBody>
        <div className="d-flex flex-column align-items-center justify-content-between">
          <OTypography size="md">
            Será enviada uma solicitação de assinatura dos contratos aos
            aprovadores da empresa e garantidores da operação. Deseja continuar?
          </OTypography>
        </div>
      </OModalBody>
      <OModalFooter>
        <div className="d-flex justify-content-end gap-2 align-items-center">
          <OButton
            type="light"
            disabled={acceptLoading}
            onClick={() => modalManager.hide(CONFIRMATION_MODAL)}
          >
            Fechar
          </OButton>
          <LoadingButton
            type="dark"
            loading={acceptLoading}
            onClick={() => handleAccept()}
          >
            Enviar
          </LoadingButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
