import dayjs from "dayjs";
import { PostAdminReceivablesSummaryBody } from "services/quickfin/models";
import { NotesFilterForm } from "./note-filter.types";

export const productTypeMap = {
  Cartao: "Cartão",
  RiscoCedente: "Cessão de crédito",
  CCC: "Cessão com coobrigação",
  FIDC: "FIDC",
  FundingProprio: "Funding próprio",
  Offshore: "Offshore",
  RiscoSacado: "Risco Sacado",
};

export const statesOptions = [
  "Inicial",
  "PendenteAgendamento",
  "Agendado",
  "PendenteAprovacaoSacado",
  "AprovadoSacado",
  "AprovadoCedente",
  "PendenteAprovacaoBanco",
  "AprovadoBanco",
  "DisponivelParaCedente",
  "IndisponivelParaCedente",
  "PendenteAprovacaoCedente",
  "PendenteOnboarding",
  "AprovadoOnboarding",
  "PendenteSolicitacaoTrade",
  "PendenteReleaseTrade",
  "TradeSolicitado",
  "TradeDesembolsado",
  "TradeLiquidado",
  "TradeEmAtraso",
  "Recusado",
  "Removido",
  "Pago",
  "EmCancelamento",
  "EmCancelamentoPagfor",
  "PagforCancelado",
  "PendenteAprovacaoSacadoPosSolicitacaoCedente",
  "FundingProprioRecusado",
  "PendenteContratacao",
  "PendenteValidacaoTaxas",
  "AguardandoRegistro",
  "PendenteEligibilidade",
  "ErrorValidacao",
  "PendenteLimiteSacados",
  "AprovadoLimiteSacado"
];

export const bodyInitialValue: PostAdminReceivablesSummaryBody = {
  receivableIdentification: [],
  externalIds: [],
  contractNumbers: [],
  strategyGuids: [],
  receivableId: [],
  tradeId: [],
  operationId: [],
  borderoId: [],
  tipoProdutoList: [
    "RiscoSacado",
    "RiscoCedente",
    "Offshore",
    "FundingProprio",
    "CCC",
  ],
  currentStateList: [],
  fromTradeDate: "",
  toTradeDate: "",
  fromDisbursementDate: "",
  toDisbursementDate: "",
  fromMaturityDate: "",
  toMaturityDate: "",
  fromCreatedAt: "",
  toCreatedAt: "",
  fromUpdatedAt: dayjs().format("YYYY-MM-DD"),
  toUpdatedAt: dayjs().format("YYYY-MM-DD"),
  sacadoIdList: [],
  cedenteIdList: [],
  creditorIds: [],
  marketplaceIds: [],
  portfolioIds: [],
};

export const noteFilterModalId = "note-filter-modal";

export const buildPayload = (
  values: NotesFilterForm,
): PostAdminReceivablesSummaryBody => ({
  borderoId: values.borderoId,
  cedenteIdList: values.cedenteIdList,
  currentStateList: values.currentStateList,
  fromCreatedAt: values.createdAt
    ? values.createdAt[0].format("YYYY/MM/DD")
    : "",
  fromDisbursementDate: values.disbursementDate
    ? values.disbursementDate[0].format("YYYY/MM/DD")
    : "",
  fromMaturityDate: values.maturityDate
    ? values.maturityDate[0].format("YYYY/MM/DD")
    : "",
  fromTradeDate: values.tradeDate
    ? values.tradeDate[0].format("YYYY/MM/DD")
    : "",
  fromUpdatedAt: values.updatedAt
    ? values.updatedAt[0].format("YYYY/MM/DD")
    : "",
  operationId: values.operationId,
  receivableId: values.receivableId,
  receivableIdentification: values.receivableIdentification,
  externalIds: values.externalIds,
  contractNumbers: values.contractNumbers,
  strategyGuids: values.strategyGuids,
  sacadoIdList: values.sacadoIdList,
  tipoProdutoList: values.tipoProdutoList,
  toCreatedAt: values.createdAt ? values.createdAt[1].format("YYYY/MM/DD") : "",
  toDisbursementDate: values.disbursementDate
    ? values.disbursementDate[1].format("YYYY/MM/DD")
    : "",
  toMaturityDate: values.maturityDate
    ? values.maturityDate[1].format("YYYY/MM/DD")
    : "",
  toTradeDate: values.tradeDate ? values.tradeDate[1].format("YYYY/MM/DD") : "",
  toUpdatedAt: values.updatedAt ? values.updatedAt[1].format("YYYY/MM/DD") : "",
  tradeId: values.tradeId,
  creditorIds: values.creditorIds,
  marketplaceIds: values.marketplaceIds,
  portfolioIds: values.portfolioIds,
});
