import { YesNoRadioField } from "./yes-no-radio-field.component";

export const AprovacaoAposAntecipacaoSacadoField = () => {
  return (
    <YesNoRadioField
      label="Requer aprovação do sacado após antecipação"
      name="aprovacaoAposAntecipacaoSacado"
      description="Represamento de trades solicitados até que o sacado aprove"
    />
  );
};
