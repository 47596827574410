import { YesNoRadioField } from "./yes-no-radio-field.component";

export const ReporteDiarioRiscoCedenteField = () => {
  return (
    <YesNoRadioField
      label="Notificações de reporte diário"
      name="reporteDiarioRiscoCedente"
      description="E-mail de notificação ao cedente das próximas datas de repasse"
    />
  );
};
