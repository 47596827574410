import { OCol, OOption, ORFieldSelect } from "@maestro/react";

const options = [
  {
    value: "PrazoOriginal",
    label: "Prazo Original",
  },
  {
    value: "PrazoAlongado",
    label: "Prazo Alongado",
  },
  {
    value: "Ambos",
    label: "Ambos",
  },
];

interface VisibilidadePrazoVencimentoCedenteFieldProps {
  disabled?: boolean;
}

export const VisibilidadePrazoVencimentoCedenteField = ({
  disabled,
}: VisibilidadePrazoVencimentoCedenteFieldProps) => {
  return (
    <OCol xs={12} md={6}>
      <ORFieldSelect
        dataAction="geral:select:prazo_que_o_cedente_visualiza"
        dataLabel="prazo_que_o_cedente_visualiza"
        id="visibilidadePrazoVencimentoCedente"
        name="visibilidadePrazoVencimentoCedente"
        label="Prazo que o cedente visualiza"
        placeholder="Selecionar"
        disabled={disabled}
      >
        {options.map(({ label, value }) => (
          <OOption key={value} value={value}>
            {label}
          </OOption>
        ))}
      </ORFieldSelect>
    </OCol>
  );
};
