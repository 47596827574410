import { OBadge } from "@maestro/react";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";

export const loanContractsConfigGrid = {
  datagrid: {
    noDataText: "Nenhum template de contrato encontrado.",
    headerFilter: { visible: true, allowSearch: true },
    dataSource: dataSourceCustomStoreGenerator(() => {
      return service.hubLoan.getLoanContractsConfigAll().catch(() => {
        throw new Error("Erro ao buscar templates de contrato.");
      });
    }),
  },
  columns: [
    {
      type: "buttons",
      cellRender: ({ data }) =>
        data.id && (
          <DetailsLink
            href={corporateRouter.routes.emprestimos.product.contingency.contractsConfig.details.path(
              {
                loanContractsId: data.id,
              },
            )}
          />
        ),
    },
    {
      dataField: "id",
      caption: "Id",
    },
    {
      sortOrder: "desc",
      dataField: "product",
      caption: "Produto",
    },
    {
      dataField: "currency",
      caption: "Moeda",
    },
    {
      dataField: "partnerSystem.name",
      caption: "Parceiro",
    },
    {
      caption: "Conversão no momento do desembolso",
      dataField: "currencyQuoteType",
      customizeText: ({ value }) => value || " - ",
    },
    {
      caption: "Índice de remuneração",
      dataField: "indexCode",
      customizeText: ({ value }) => value || " - ",
    },
    {
      caption: "Tipos de garantia",
      dataField: "collateralTypes",
      format: (value) => value?.join("-"),
    },
    {
      caption: "Templates de contrato",
      cellRender: ({ data }) => {
        const contractTemplates = data.contractTemplates?.length;
        return contractTemplates > 0 ? (
          <OBadge type="success" size="sm">{`${contractTemplates} ${
            contractTemplates > 1
              ? "templates configurados"
              : "template configurado"
          }`}</OBadge>
        ) : (
          <OBadge type="warning" size="sm">
            Pendente configuração
          </OBadge>
        );
      },
    },
  ],
} satisfies ODataGridGeneratorConfig<
  NonNullable<
    Awaited<
      ReturnType<typeof service.hubLoan.getLoanContractsConfigAll>
    >["data"]
  >[number]
>;
