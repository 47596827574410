declare global {
  interface XMLHttpRequest {
    method: string;
    url: string;
    headers: {
      [name: string]: string;
    };
  }
}

const xhrEventTypes = ["beforeSend", "afterSend"] as const;

type XHREventTypes = (typeof xhrEventTypes)[number];

const emptyEventCallbacks = xhrEventTypes.reduce(
  (callbacksObject, eventType) => {
    // eslint-disable-next-line no-param-reassign
    callbacksObject[eventType] = [];
    return callbacksObject;
  },
  {} as Record<XHREventTypes, CallbackFunction[]>,
);

export type CallbackFunction = (request: XMLHttpRequest) => void;

class XHRInterceptor {
  private static _instance: XHRInterceptor = new XHRInterceptor();

  private eventCallbacks = emptyEventCallbacks;

  constructor() {
    // preventing multiple instantiation
    if (XHRInterceptor._instance) {
      throw new Error(
        "Error: Instantiation failed: Use XHRInterceptor.getInstance() instead of new.",
      );
    }
    XHRInterceptor._instance = this;

    // storing reference to interceptor instance
    const { eventCallbacks } = this;

    // ----------------------------------------------------------------------
    // -------------------------- patching .open() --------------------------
    // ----------------------------------------------------------------------

    const originalXhrOpen = window.XMLHttpRequest.prototype.open;

    window.XMLHttpRequest.prototype.url = "";

    // `arguments` is going to capture all args in call, so it'll capture the missing ones
    window.XMLHttpRequest.prototype.open = function open(method, url) {
      this.addEventListener("readystatechange", () => {
        switch (this.readyState) {
          // readyState OPENED: when open() has been called
          case 1:
            this.method = method;
            this.url = url as string;
            break;

          // readyState DONE: when the operation is complete
          case 4:
            eventCallbacks.afterSend.forEach((callback) => {
              callback(this);
            });
            this.headers = {};
            this.method = "";
            this.url = "";
            break;

          default:
            break;
        }
      });

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore couldn't figure out the overloads
      // eslint-disable-next-line prefer-rest-params
      return originalXhrOpen.apply(this, arguments);
    };

    // ----------------------------------------------------------------------
    // -------------------------- patching .send() --------------------------
    // ----------------------------------------------------------------------

    const originalXhrSend = window.XMLHttpRequest.prototype.send;

    window.XMLHttpRequest.prototype.send = function send(body) {
      eventCallbacks.beforeSend.forEach((callback) => {
        callback(this);
      });
      return originalXhrSend.apply(this, [body]);
    };

    // ----------------------------------------------------------------------
    // --------------------  patching .setRequestHeader() -------------------
    // ----------------------------------------------------------------------

    const originalXhrSetReqHeader =
      window.XMLHttpRequest.prototype.setRequestHeader;

    window.XMLHttpRequest.prototype.headers = {};

    window.XMLHttpRequest.prototype.setRequestHeader =
      function setRequestHeader(name, value) {
        const newHeaders = { ...this.headers };
        newHeaders[name] = value;
        this.headers = newHeaders;
        return originalXhrSetReqHeader.apply(this, [name, value]);
      };
  }

  public static getInstance(): XHRInterceptor {
    return XHRInterceptor._instance;
  }

  public listen(
    eventType: "beforeSend" | "afterSend",
    callback: CallbackFunction,
  ) {
    this.eventCallbacks[eventType].push(callback);
  }

  public removeListener(
    eventType: "beforeSend" | "afterSend",
    callback: CallbackFunction,
  ) {
    this.eventCallbacks[eventType] = this.eventCallbacks[eventType].filter(
      (_callback) => _callback !== callback,
    );
  }
}

// Singleton to avoid initializing multiple times
// ref: https://stackoverflow.com/a/31154320
export const xhrInterceptor = XHRInterceptor.getInstance();
