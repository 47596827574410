import { OToastManager, modalManager } from "@maestro/react";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { service } from "services";
import { WorkflowConfigView } from "services/hubcreditmanager/models/types/workflow/workflow.types";
import { useCanvas } from "../../../../../../../../components/hooks/use-canvas/use-canvas.context";
import { useWorkflow } from "../../../../../../../../components/hooks/use-workflow.context";
import { PROCESSOR_CONFIG_MODAL_ID } from "../../config-proposal-workflow.utils";

export const useProcessorConfigModal = () => {
  const { getCanvasData } = useCanvas();
  const {
    setSelectedConditions,
    processorConfig: { value: selectedProcessorConfig },
    workflowConfigViews: { value: workflowConfigViews },
  } = useWorkflow();

  const [saving, setSaving] = useState(false);

  const form = useForm();
  const { getValues, reset, unregister } = form;

  const getPropValue = (value?: boolean) => {
    return value ? ["1"] : [];
  };

  const onModalClose = useCallback(async () => {
    getCanvasData();

    modalManager.hide(PROCESSOR_CONFIG_MODAL_ID);

    unregister("properties");
    reset({});
  }, [getCanvasData, reset, unregister]);

  const handleSubmitGeneralInfo = useCallback(async () => {
    if (!selectedProcessorConfig) return;

    setSaving(true);

    try {
      const {
        processorName: name,
        description,
        actionButtonName,
        cardActionButtonDescription,
        channels,
        isWfEntry: isWfEntryValue,
        leadsCanBeCreated: leadsCanBeCreatedValue,
        leadsCanBeDeleted: leadsCanBeDeletedValue,
        leadsCanBeExpired: leadsCanBeExpiredValue,
        entryIcon,
        entryViews,
        entryShowOnActions,
        entryShowOnTabs,
        executeOnSingleParentOutput: executeOnSingleParentOutputValue,
        viewUserActions,
        actionIcon,
        actionButtonType,
        hasActionWithoutContext: hasActionWithoutContextValue,
        isActionButtonOutlined: isActionButtonOutlinedValue,
      } = getValues();

      const isWfEntry = !!isWfEntryValue?.length;
      const leadsCanBeCreated = !!leadsCanBeCreatedValue?.length;
      const leadsCanBeDeleted = !!leadsCanBeDeletedValue?.length;
      const leadsCanBeExpired = !!leadsCanBeExpiredValue?.length;
      const hasActionWithoutContext = !!hasActionWithoutContextValue?.length;
      const isActionButtonOutlined = !!isActionButtonOutlinedValue?.length;
      const showOnActions = !!entryShowOnActions?.length;
      const showOnTabs = !!entryShowOnTabs?.length;
      const executeOnSingleParentOutput =
        !!executeOnSingleParentOutputValue?.length;

      const updateProcessorConfigGeneralInfosPayload = {
        processorConfigId: selectedProcessorConfig.id,
        name,
        viewUserActions,
        actionIcon,
        description,
        actionButtonName,
        isWfEntry,
        leadsCanBeCreated,
        leadsCanBeDeleted,
        leadsCanBeExpired,
        executeOnSingleParentOutput,
        entry:
          !!selectedProcessorConfig?.processor.canBeWfEntry && isWfEntry
            ? {
                cardActionButtonDescription: cardActionButtonDescription ?? "",
                entryChannels:
                  channels
                    ?.filter((c: string) => !!c)
                    .filter(
                      (item: string, index: number, arr: string[]) =>
                        arr.indexOf(item) === index,
                    )
                    .map((c: string) => ({
                      channelId: Number(c),
                    })) ?? [],
                icon: entryIcon,
                entryViews,
                showOnActions,
                showOnTabs,
              }
            : null,
        canvasData: {
          positionY: selectedProcessorConfig?.canvasData?.positionY,
          positionX: selectedProcessorConfig?.canvasData?.positionX,
        },
        actionButtonType,
        hasActionWithoutContext,
        isActionButtonOutlined,
      };

      await service.hubCreditManager.updateProcessorConfigGeneralInfos(
        updateProcessorConfigGeneralInfosPayload,
      );

      OToastManager.success("Configurações salvas com sucesso.");
    } catch {
      OToastManager.danger(
        "Um erro ocorreu ao tentar atualizar o processador. Por favor, tente novamente mais tarde",
      );
    } finally {
      setSaving(false);
    }
  }, [getValues, selectedProcessorConfig, setSaving]);

  useEffect(() => {
    if (!selectedProcessorConfig) return;

    const viewActions =
      !!selectedProcessorConfig.processorConfigViews &&
      !!workflowConfigViews?.length &&
      selectedProcessorConfig.processor.hasUserAction
        ? workflowConfigViews
            .filter((wcView: WorkflowConfigView) =>
              selectedProcessorConfig.processorConfigViews.some(
                (pc) =>
                  pc.workflowConfigView.view.type === wcView.view.type &&
                  pc.hasUserAction,
              ),
            )
            .map((wcView: WorkflowConfigView) => wcView.view.type)
        : [];

    // Dados gerais do processor config
    const initialValues: Record<
      string,
      string | string[] | number | null | undefined
    > = {
      processorName: selectedProcessorConfig.name,
      actionButtonName: selectedProcessorConfig.actionButtonName,
      description: selectedProcessorConfig.description,
      configurableStatusId:
        selectedProcessorConfig.configurableStatusId?.toString() ?? "",
      isWfEntry: getPropValue(selectedProcessorConfig?.isWfEntry),
      leadsCanBeCreated: getPropValue(
        selectedProcessorConfig?.leadsCanBeCreated,
      ),
      leadsCanBeDeleted: getPropValue(
        selectedProcessorConfig?.leadsCanBeDeleted,
      ),
      leadsCanBeExpired: getPropValue(
        selectedProcessorConfig?.leadsCanBeExpired,
      ),
      entryIcon: selectedProcessorConfig.entry?.icon,
      executeOnSingleParentOutput: getPropValue(
        selectedProcessorConfig?.executeOnSingleParentOutput,
      ),
      channels: selectedProcessorConfig.entry?.entryChannels?.map((ec) =>
        ec.id.toString(),
      ),
      entryViews: selectedProcessorConfig?.entry?.entryViews?.map(
        (ev) => ev.type,
      ),
      entryShowOnActions: getPropValue(
        selectedProcessorConfig?.entry?.showOnActions,
      ),
      entryShowOnTabs: getPropValue(selectedProcessorConfig?.entry?.showOnTabs),
      cardActionButtonDescription:
        selectedProcessorConfig.entry?.cardActionButtonDescription,
      viewUserActions: viewActions,
      actionIcon: selectedProcessorConfig.actionIcon ?? "",
      actionButtonType: selectedProcessorConfig.actionButtonType ?? "",
      hasActionWithoutContext: getPropValue(
        selectedProcessorConfig?.hasActionWithoutContext,
      ),
      isActionButtonOutlined: getPropValue(
        selectedProcessorConfig?.isActionButtonOutlined,
      ),
    };

    reset(initialValues);
    setSelectedConditions(selectedProcessorConfig.conditions);
  }, [
    selectedProcessorConfig,
    workflowConfigViews,
    reset,
    setSelectedConditions,
  ]);

  return {
    form,
    saving,
    selectedProcessorConfig,
    handleSubmitGeneralInfo,
    onModalClose,
  };
};
