import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { service } from "services";

export const usePropertiesGrid = () => {
  const {
    callService: getPropertyTypes,
    loading: propertyTypesLoading,
    value: propertyTypesValue,
  } = useServiceCall(service.hubEnergy.getDiligenceStepPropertyTypes);

  const {
    callService: getDocumentTypes,
    loading: documentTypesLoading,
    value: documentTypesValue,
  } = useServiceCall(service.hubEnergy.getDocumentTypes);

  useEffect(() => {
    getPropertyTypes();
  }, [getPropertyTypes]);

  useEffect(() => {
    getDocumentTypes();
  }, [getDocumentTypes]);

  return {
    documentTypesLoading,
    documentTypesValue,
    propertyTypesLoading,
    propertyTypesValue,
  };
};
