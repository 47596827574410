import { YesNoRadioField } from "./yes-no-radio-field.component";

export const NotificacaoTedDevolvidaSacadoField = () => {
  return (
    <YesNoRadioField
      label="Notificações de TED devolvida Sacado"
      name="notificacaoTedDevolvidaSacado"
      description="E-mail de notificação ao sacado quando ocorrer erros na TED de desembolso"
    />
  );
};
