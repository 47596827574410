import { IIcon, modalManager } from "@maestro/react";
import { useCallback, useEffect, useState } from "react";
import { ViewType } from "services/hubcreditmanager/models/types/workflow/workflow.types";
import { useWorkflow } from "../../../../../../../../../../components/hooks/use-workflow.context";
import {
  buttonTypes,
  orqIcons,
} from "../../../../../../../../configuracoes/workflow-configuration.utils";
import { PROCESSOR_CONFIG_MODAL_ID } from "../../../../config-proposal-workflow.utils";
import {
  MenuItems,
  ProcessorConfigMenuProps,
} from "./processor-config-menu.types";

export const MonitoringItemDefaultValue = "ADMIN";

export const useProcessorConfigMenu = ({ form }: ProcessorConfigMenuProps) => {
  const {
    processorConfig: { value: selectedProcessorConfig },
    workflowConfigViews: { value: workflowConfigViews },
  } = useWorkflow();

  const [activeItem, setActiveItem] = useState(MenuItems.Default);
  const [activeMonitoringItem, setActiveMonitoringItem] = useState<
    string | ViewType
  >();

  const { watch, setValue } = form;
  const isWfEntryWatch = watch("isWfEntry");

  const iconOptions = orqIcons.map((icon: IIcon) => ({
    value: icon,
    label: icon,
    onSelect: (value: string) => setValue("icon", value),
  }));

  const buttonTypeOptions = buttonTypes.map((type) => ({
    value: type,
    label: type,
    onSelect: (value: string) => setValue("type", value),
  }));

  const resetActiveSubMenus = useCallback((menuItem: MenuItems) => {
    setActiveMonitoringItem(undefined);
    setActiveItem(menuItem);
  }, []);

  useEffect(() => {
    const cleanup = modalManager.on(
      PROCESSOR_CONFIG_MODAL_ID,
      "modalOpen",
      () => {
        setActiveItem(MenuItems.Default);
        setActiveMonitoringItem(undefined);
      },
    );

    return cleanup;
  }, []);

  return {
    activeItem,
    activeMonitoringItem,
    buttonTypeOptions,
    iconOptions,
    isWfEntryWatch,
    selectedProcessorConfig,
    workflowConfigViews,
    resetActiveSubMenus,
    setActiveItem,
    setActiveMonitoringItem,
  };
};
