import { YesNoRadioField } from "./yes-no-radio-field.component";

export const ExigeIndicacaoHedgeField = () => {
  return (
    <YesNoRadioField
      label="Exige indicação Hedge"
      name="exigeIndicacaoHedge"
      description="Configuração que represa os recebíveis para indicação de Hedge, logo após a aprovação das notas pelo sacado"
    />
  );
};
