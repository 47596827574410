import { useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { getProcessorPropertyFieldId } from "../../../utils";
import { GenericPropertyFieldProps } from "../../property-field.types";

export const useBooleanPropertyField = ({
  processorConfigProperty: { processorProperty, value, id },
  handleSubmitProperty,
}: GenericPropertyFieldProps) => {
  const fieldInitialized = useRef(false);
  const valueRef = useRef("");
  const { watch, setValue, reset } = useFormContext();

  const processorPropertyFieldId = getProcessorPropertyFieldId(
    processorProperty.name,
    id,
  );

  const fieldWatch = watch(processorPropertyFieldId);

  useEffect(() => {
    if (!value) return;

    valueRef.current = value;

    setValue(processorPropertyFieldId, value === "true" ? ["true"] : []);
  }, [value, setValue, processorProperty, id]);

  useEffect(() => {
    if (!fieldInitialized.current) {
      fieldInitialized.current = true;
      return;
    }

    if (
      !fieldWatch ||
      (!fieldWatch.length && valueRef.current === "false") ||
      (fieldWatch.length && valueRef.current === "true")
    )
      return;

    valueRef.current = fieldWatch?.length ? "true" : "false";
    handleSubmitProperty(valueRef.current, id);
  }, [fieldWatch, processorProperty, handleSubmitProperty, id, reset, value]);

  return;
};
