import {
  ICategory,
  OCard,
  OCardBody,
  OCardFooter,
  OCardHeader,
  OCol,
  OIcon,
  OLoader,
  OOption,
  OOptionMultiple,
  ORFieldInput,
  ORFieldSelect,
  ORFieldSelectMultiple,
  ORow,
  OTooltip,
  OTypography,
} from "@maestro/react";
import { useMemo } from "react";
import { useCanvas } from "../../../../../../../../../../../../components/hooks/use-canvas/use-canvas.context";
import { useProcessorConfigMonitoringView } from "./processor-config-view.hook";
import { StyledOButton, Subheader } from "./processor-config-view.styles";
import { ProcessorConfigMonitoringContentProps } from "./processor-config-view.types";
import { getOutputComponentsIds } from "./processor-config-view.utils";

export const ProcessorConfigMonitoringView = ({
  workflowConfigView,
  form,
}: ProcessorConfigMonitoringContentProps) => {
  if (!workflowConfigView) throw new Error("WorkflowConfigView not found");

  const { processorConfigsOnCanvas } = useCanvas();
  const {
    bigNumbers,
    configurableStatus,
    loadingUpsertOutputConfigBigNumbers,
    loadingUpsertOutputConfigStatus,
    loadingUpsertSimultaneousProcessorConfig,
    processorConfig,
    saving,
    simultaneousMonitoringId,
    updateProcessorConfigViewProperties,
  } = useProcessorConfigMonitoringView({
    workflowConfigView,
    form,
  });

  const saveButton = useMemo(
    () => (
      <OTooltip maxWidth="250px" position="bottom-left">
        <div slot="tooltip-content">
          Salva configurações de monitoramento simultâneo, e status e big
          numbers nas saídas para a visão {workflowConfigView.view.type}
        </div>
        <StyledOButton
          onClick={updateProcessorConfigViewProperties}
          disabled={saving}
        >
          {saving ? (
            <div className="d-flex align-items-center justify-content-center">
              <OLoader size="sm" />
            </div>
          ) : (
            "Salvar configuração de monitoramento"
          )}
        </StyledOButton>
      </OTooltip>
    ),
    [workflowConfigView, saving, updateProcessorConfigViewProperties],
  );

  return (
    <OCard className="mb-4">
      <OCardHeader>
        <OTypography>{workflowConfigView.view.type}</OTypography>
        <ORow>
          <OCol sm={9}>
            <Subheader>
              {`OBS: Não se esqueça de clicar em "Salvar configuração de
              monitoramento" caso tenha mudado alguma configuração aqui.`}
            </Subheader>
          </OCol>
          <OCol sm={3}>{saveButton}</OCol>
        </ORow>
      </OCardHeader>
      <OCardBody>
        {(loadingUpsertOutputConfigBigNumbers ||
          loadingUpsertOutputConfigStatus ||
          loadingUpsertSimultaneousProcessorConfig) && <OLoader absolute />}

        <ORow>
          <OCol>
            <ORFieldSelectMultiple
              id={simultaneousMonitoringId}
              name={simultaneousMonitoringId}
              label="Monitoramento simultâneo"
              labelSize="md"
              tooltip="Processadores"
              key={simultaneousMonitoringId}
            >
              {processorConfigsOnCanvas.map((pc) => (
                <OOptionMultiple
                  value={String(pc.id)}
                  key={`processor-option-${pc.id}`}
                >
                  <OTypography>
                    {`${pc.name} (${pc.processor.type})`}
                  </OTypography>
                </OOptionMultiple>
              ))}
            </ORFieldSelectMultiple>
          </OCol>
        </ORow>
        <OTypography className="my-3">Saídas dos processadores</OTypography>
        {processorConfig?.processor.outputs?.map((output) => {
          const outputComponentsIds = getOutputComponentsIds(
            workflowConfigView.view,
            output.id,
          );

          return (
            <ORow
              key={`processor-output-${output.id}-${workflowConfigView.view.type}`}
            >
              <OCol sm={4}>
                <ORFieldInput
                  id={outputComponentsIds.type}
                  name={outputComponentsIds.type}
                  tag="text"
                  label="Tipo da saída"
                  labelSize="md"
                  readonly
                />
              </OCol>
              <OCol sm={4} key={outputComponentsIds.status + 1}>
                <ORFieldSelect
                  id={outputComponentsIds.status}
                  name={outputComponentsIds.status}
                  label="Status"
                  labelSize="md"
                  key={outputComponentsIds.status}
                >
                  <OOption value={0} key={`status-option-${0}`}>
                    <div className="d-flex gap-2">
                      <OTypography>Nenhum</OTypography>
                    </div>
                  </OOption>
                  {configurableStatus?.map((status) => (
                    <OOption
                      value={status.id}
                      key={`status-option-${status.id}`}
                    >
                      <div className="d-flex gap-2">
                        <OTypography>{status.name}</OTypography>
                        <OIcon
                          category={status.iconName.split("-")[0] as ICategory}
                          icon={status.iconName}
                          size="md"
                        />
                      </div>
                    </OOption>
                  ))}
                </ORFieldSelect>
              </OCol>
              <OCol sm={4}>
                <ORFieldSelectMultiple
                  id={outputComponentsIds.bigNumber}
                  name={outputComponentsIds.bigNumber}
                  label="Big numbers"
                  labelSize="md"
                  key={outputComponentsIds.bigNumber}
                >
                  {bigNumbers?.map((bigNumber) => (
                    <OOptionMultiple
                      value={String(bigNumber.id)}
                      key={`big-number-option-${bigNumber.id}`}
                    >
                      <div className="d-flex gap-2">
                        <OTypography>{bigNumber.name}</OTypography>
                        <OIcon
                          category={
                            bigNumber.iconName.split("-")[0] as ICategory
                          }
                          icon={bigNumber.iconName}
                          size="md"
                        />
                      </div>
                    </OOptionMultiple>
                  ))}
                </ORFieldSelectMultiple>
              </OCol>
            </ORow>
          );
        })}
      </OCardBody>
      <OCardFooter>
        <div className="d-flex justify-content-end">{saveButton}</div>
      </OCardFooter>
    </OCard>
  );
};
