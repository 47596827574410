import {
  OCol,
  OOption,
  OOptionMultiple,
  ORFieldInput,
  ORFieldSelect,
  ORFieldSelectMultiple,
  ORow,
} from "@maestro/react";
import {
  GetAdminCreditLinesResponse,
  GetApiAdminBookStrategiesResponse,
} from "services/hubcreditmanager/models/responses";
import { collateralTypesOptions } from "./credit-line-configuration-form.utils";

interface CreditLineConfigurationFormProps {
  creditLines: GetAdminCreditLinesResponse | undefined;
  bookStrategies: GetApiAdminBookStrategiesResponse | undefined;
  loadingCreditLines: boolean;
  hasErrorCreditLines: boolean;
  loadingBookStrategies: boolean;
  hasErrorBookStrategies: boolean;
}

export const CreditLineConfigurationForm = ({
  bookStrategies,
  creditLines,
  hasErrorBookStrategies,
  hasErrorCreditLines,
  loadingBookStrategies,
  loadingCreditLines,
}: CreditLineConfigurationFormProps) => {
  return (
    <form spellCheck="false">
      <ORow>
        <OCol sm={12}>
          <ORFieldSelect
            id="creditLineId"
            name="creditLineId"
            label="Linha de crédito"
            labelSize="md"
            dataAction="adicionar-configuracao-de-linha-de-credito:select:creditLineId"
            dataLabel="creditLineId"
            placeholder="Selecione"
            disabled={loadingCreditLines || hasErrorCreditLines}
          >
            {creditLines?.map((creditLine) => (
              <OOption
                value={creditLine.id}
                key={`creditLine-${creditLine.id}`}
              >
                {creditLine.name}
              </OOption>
            ))}
          </ORFieldSelect>
        </OCol>
      </ORow>
      <ORow>
        <OCol sm={6}>
          <ORFieldInput
            id="partner"
            name="partner"
            tag="cnpj"
            label="Parceiro"
            labelSize="md"
            dataAction="adicionar-configuracao-de-linha-de-credito:cnpj:parceiro"
            dataLabel="parceiro"
            placeholder="Preencher"
          />
        </OCol>
        <OCol sm={6}>
          <ORFieldSelect
            id="shouldSyncGuarantors"
            name="shouldSyncGuarantors"
            label="Avalistas devem ser sincronizados"
            labelSize="md"
            dataAction="adicionar-configuracao-de-linha-de-credito:select:sincronizacao-avalistas"
            dataLabel="sincronizacao-avalistas"
            placeholder="Selecionar"
          >
            <OOption value={0}>Não</OOption>
            <OOption value={1}>Sim</OOption>
          </ORFieldSelect>
        </OCol>
      </ORow>
      <ORow>
        <OCol sm={6}>
          <ORFieldInput
            id="associateGuarantorMinimumShare"
            name="associateGuarantorMinimumShare"
            tag="number"
            label="Participação mínima de sócio avalista"
            labelSize="md"
            dataAction="adicionar-configuracao-de-linha-de-credito:number:participacao-minima-socio-avalista"
            dataLabel="participacao-minima-socio-avalista"
            placeholder="Preencher"
          />
        </OCol>
        <OCol sm={6}>
          <ORFieldInput
            id="maximumLimitForSimpleGuarantor"
            name="maximumLimitForSimpleGuarantor"
            tag="number"
            label="Limite máximo para avalista simples"
            labelSize="md"
            dataAction="adicionar-configuracao-de-linha-de-credito:number:limite-maximo-avalista-simples"
            dataLabel="limite-maximo-avalista-simples"
            placeholder="Preencher"
          />
        </OCol>
      </ORow>
      <ORow>
        <OCol sm={6}>
          <ORFieldSelect
            id="automaticallyApproveOnFts"
            name="automaticallyApproveOnFts"
            label="Aprovação automática no FTS"
            labelSize="md"
            dataAction="adicionar-configuracao-de-linha-de-credito:select:aprovacao-automatica-fts"
            dataLabel="aprovacao-automatica-fts"
            placeholder="Selecionar"
          >
            <OOption value={0}>Não</OOption>
            <OOption value={1}>Sim</OOption>
          </ORFieldSelect>
        </OCol>
      </ORow>
      <ORow>
        <OCol sm={12}>
          <ORFieldSelectMultiple
            id="collateralTypes"
            name="collateralTypes"
            label="Garantias"
            labelSize="md"
            dataAction="adicionar-configuracao-de-linha-de-credito:select:garantias"
            dataLabel="garantias"
            placeholder="Selecione"
          >
            {collateralTypesOptions.map((option) => (
              <OOptionMultiple
                value={option.value}
                key={`collateral-type-${option.value}`}
              >
                {option.description}
              </OOptionMultiple>
            ))}
          </ORFieldSelectMultiple>
        </OCol>
      </ORow>
      <ORow>
        <OCol sm={12}>
          <ORFieldSelect
            id="bookStrategyId"
            name="bookStrategyId"
            label="Book / Estratégia"
            labelSize="md"
            dataAction="adicionar-configuracao-de-linha-de-credito:select:bookStrategyId"
            dataLabel="bookStrategyId"
            placeholder="Selecione"
            disabled={loadingBookStrategies || hasErrorBookStrategies}
          >
            {bookStrategies?.map((bookStrategy) => (
              <OOption
                value={bookStrategy.id}
                key={`book-strategy-${bookStrategy.id}`}
              >
                {`${bookStrategy.book.bookName} (${bookStrategy.book.bookCr}) / ${bookStrategy.strategy.strategyName} (${bookStrategy.strategy.strategyCr})`}
              </OOption>
            ))}
          </ORFieldSelect>
        </OCol>
      </ORow>
    </form>
  );
};
