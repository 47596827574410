import { YesNoRadioField } from "./yes-no-radio-field.component";

export const CancelaNaoAntecipadasD0Field = () => {
  return (
    <YesNoRadioField
      label="Cancela não antecipadas em D0"
      name="cancelaNaoAntecipadasD0"
      description="Cancelamento de todos os recebíveis cadastrados que não foram antecipados ao final do dia"
    />
  );
};
