import { YesNoRadioField } from "./yes-no-radio-field.component";

export const PermiteTaxaParticularField = () => {
  return (
    <YesNoRadioField
      label="Permitir definição de taxa Sacado/Cedente"
      name="permiteTaxaParticular"
      description="Configuração que permite que o sacado configure taxas que serão aplicadas para fornecedores específicos. Essa taxa configurada pelo Sacado, será utilizada para o cálculo do rebate sobre operações desse cedente com esse sacado. Para que essa configuração seja possível na tela do Sacado, é necessário que tenham taxas cadastradas para essa estratégia. Caso as taxas cadastradas pelo Sacado estejam configuradas aqui para serem taxas FINAIS, então elas devem SEMPRE ser maiores do que a maior taxa mensal dada para o BTG para a estratégia"
    />
  );
};
