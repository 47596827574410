import { OTooltip } from "@maestro/react";
import { masks } from "@maestro/utils";
import {
  dataSourceODataStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { endpoints } from "services/endpoints";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { currencySymbol } from "utils/currency";
import { getValueFromMap } from "utils/get-value-from-map";

const limitRequestStatusMap = {
  APPROVED: {
    description: "Limite aprovado, disponível para contratação",
  },
  CONSUMED: { description: "" },
  PARTIALLY_CONSUMED: { description: "" },
  REJECTED: {
    description: "Limite rejeitado, não disponível para contratação",
  },
  EXPIRED: {
    description: "Limite passou da data limite de contratação",
  },
  ERROR: {
    description:
      "Limite com erro, motor retornou um status não esperado e/ou com erro",
  },
  CREATING_LIMIT: { description: "" },
  STARTING_WORKFLOW: { description: "" },
  PENDING_ACCEPT: { description: "" },
  ACCEPTED: { description: "" },
  PENDING_APPROVAL: { description: "" },
  INVALID_CONFIGURATION: { description: "" },
  PENDING_AGROMATIC_DILIGENCE: {
    description: "",
  },
  WAITING_DILIGENCE: { description: "" },
  DILIGENCE_VALIDATED: { description: "" },
  DILIGENCE_DOCUMENTS_AVAILABLE: {
    description: "",
  },
  INCONSISTENT: { description: "" },
  REJECTED_DILIGENCE: { description: "" },
  SYNC_CPR: { description: "" },
};

export const limitsGrid = {
  datagrid: {
    noDataText: "Nenhum limite encontrado.",
    headerFilter: { visible: true, allowSearch: true },
    onDataErrorOccurred: (e) => {
      if (e.error) e.error.message = "Erro ao buscar limites.";
    },
    filterRow: {
      visible: true,
    },
    dataSource: dataSourceODataStoreGenerator(
      endpoints.hubCreditManager.odata.limitsAdmin,
    ),
  },
  columns: [
    {
      allowSearch: false,
      allowExporting: false,
      type: "buttons",
      role: roles.emprestimos.product.limitManagerLimitsDetailsSummaryPage.role,
      cellRender: ({ data }) =>
        data.CurrentLimitRequestId && (
          <DetailsLink
            href={corporateRouter.routes.emprestimos.product.limitManager.limits.details.summary.path(
              {
                id: data.CurrentLimitRequestId,
              },
            )}
          />
        ),
    },
    {
      dataField: "CurrentLimitRequestId",
      dataType: "number",
      caption: "Id da solicitação",
      sortOrder: "desc",
    },
    {
      caption: "Nome",
      dataField: "CustomerName",
    },
    {
      caption: "CPF / CNPJ",
      dataField: "CustomerTaxId",
      format: (value) => masks.cpfCnpj(value),
      calculateFilterExpression: calculateTaxIdFilterExpression,
    },
    {
      caption: "Identificação",
      dataField: "CreditLineIdentification",
    },
    {
      caption: "Limite total",
      dataField: "Amount",
      cellRender: ({ data }) =>
        masks.currency(
          data.Amount,
          getValueFromMap(currencySymbol, data.CurrencyType) ??
            currencySymbol.BRL,
          ".",
        ),
    },
    {
      caption: "Limite disponível",
      dataField: "AvailableAmount",
      cellRender: ({ data }) =>
        masks.currency(
          data.AvailableAmount,
          getValueFromMap(currencySymbol, data.CurrencyType) ??
            currencySymbol.BRL,
          ".",
        ),
    },
    {
      caption: "Data de criação",
      dataField: "CreatedDate",
      dataType: "datetime",
    },
    {
      caption: "Data de vencimento",
      dataField: "ExpirationDate",
      dataType: "datetime",
    },
    {
      caption: "Status",
      dataField: "LimitRequestStatus",
      cellRender: ({ data }) =>
        getValueFromMap(limitRequestStatusMap, data.LimitRequestStatus)
          ?.description ? (
          <OTooltip floating position="top-left">
            <span slot="tooltip-content">
              {
                getValueFromMap(limitRequestStatusMap, data.LimitRequestStatus)
                  ?.description
              }
            </span>
            {data.LimitRequestStatus}
          </OTooltip>
        ) : (
          data.LimitRequestStatus
        ),
    },
  ],
} satisfies ODataGridGeneratorConfig<
  NonNullable<
    Awaited<
      ReturnType<typeof service.hubCreditManager.odata.getLimitsAdmin>
    >["data"]["value"]
  >[number]
>;
