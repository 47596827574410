import { OCol, ORFieldInput } from "@maestro/react";

interface FccIdSacadoFieldProps {
  required?: boolean;
}

export const FccIdSacadoField = ({ required }: FccIdSacadoFieldProps) => {
  return (
    <OCol xs={12} md={6}>
      <ORFieldInput
        dataAction="geral:texto:fcc_id_sacado"
        dataLabel="fcc_id_sacado"
        id="fccIdSacado"
        name="fccIdSacado"
        tag="number"
        placeholder="Preencher"
        label="FCC"
        required={required}
      />
    </OCol>
  );
};
