import { OCol } from "@maestro/react";
import { InputMultiple } from "../../input-multiple/input-multiple.component";

export const DiasParaVencimentoDataRepasseRiscoCedenteField = () => {
  return (
    <OCol xs={12} md={6}>
      <InputMultiple
        dataAction="geral:texto:dias_repasse"
        dataLabel="dias_repasse"
        id="diasParaVencimentoDataRepasseRiscoCedente"
        name="diasParaVencimentoDataRepasseRiscoCedente"
        label="Dias para data repasse"
        description="Quantidade de dias antes da data repasse para receber notificação"
        required
      />
    </OCol>
  );
};
