/* eslint-disable no-param-reassign */
import { datadogRum } from "@datadog/browser-rum";
import { env } from "../environments/env";
import { logger } from "../logger";
import { isDatadogEnabled } from "./enabled";

const reAxiosHeaders = /"headers":\s?(\{[^}]*?\},?\n?)/gm;

export const initDatadog = () => {
  if (isDatadogEnabled) {
    datadogRum.init({
      applicationId: "999adb19-9924-4db6-86c3-3896c2fd6138",
      clientToken: "pub76119d0a7ba871ab1896f79c8f841d2f",
      service: "portal-corporativo",
      env: env.PROJECT_ENV,
      site: "datadoghq.com",
      sampleRate: 100,
      trackInteractions: true,
      trackFrustrations: true,
      trackSessionAcrossSubdomains: true,
      beforeSend: (event) => {
        if (event.type === "error") {
          /** redact axios headers */
          if (event.error.message.match(reAxiosHeaders)) {
            event.error.message = event.error.message.replace(
              Array.from(event.error.message.matchAll(reAxiosHeaders))[0][1],
              '"<REDACTED>",\n',
            );
          }

          if (
            event.error.source === "console" &&
            (event.error.message.match(/%c\[logger\]/) ||
              event.error.message.match(/\[webpack-dev-server\]/))
          ) {
            return false;
          }
        }
      },
      allowedTracingUrls: [
        /https:\/\/.*\.btgpactual\.com/,
        /https:\/\/.*\.empresas\.btgpactual\.com/,
        /https:\/\/.*\.btgpactualbusiness\.com/,
        /https:\/\/.*\.quickfin\.com\.br/,
      ],
    });

    logger.debug("[datadog] initialized");
  }
};
