import {
  OButton,
  OCheckbox,
  OCol,
  OFieldError,
  OIcon,
  OLabel,
  ORFieldCheckboxGroup,
  ORow,
  OTypography,
} from "@maestro/react";
import { SelectSearchField } from "components/select-search";
import { useEnergyDistributorDiligenceFieldsForm } from "./field-form.hook";

export const EnergyDistributorDiligenceFieldsForm = () => {
  const {
    diligenceStepConfigIds,
    diligenceStepConfigLoading,
    diligenceStepConfigValues,
    fields,
    appendField,
    getValues,
    remove,
  } = useEnergyDistributorDiligenceFieldsForm();

  return (
    <div className="d-flex flex-column gap-2">
      <ORow>
        <OCol xs={12} md={6}>
          <ORFieldCheckboxGroup
            id="needsPreApproval"
            name="needsPreApproval"
            label=""
          >
            <div className="d-flex align-items-center gap-2">
              <OCheckbox id="needsPreApprovalTrue" value="true" size="sm" />
              <OLabel htmlFor="needsPreApprovalTrue">
                Precisa de pré aprovação ?
              </OLabel>
            </div>
          </ORFieldCheckboxGroup>
        </OCol>
        <OCol xs={12} md={6}>
          <ORFieldCheckboxGroup
            id="needsPostApproval"
            name="needsPostApproval"
            label=""
          >
            <div className="d-flex align-items-center gap-2">
              <OCheckbox id="needsPostApprovalTrue" value="true" size="sm" />
              <OLabel htmlFor="needsPostApprovalTrue">
                Precisa de pós aprovação ?
              </OLabel>
            </div>
          </ORFieldCheckboxGroup>
        </OCol>
      </ORow>

      <div className="d-flex flex-column flex-fill gap-2">
        {!!diligenceStepConfigIds?.message && (
          <OFieldError visible>{diligenceStepConfigIds.message}</OFieldError>
        )}
        {fields.map((field, index) => {
          const stepTypes = diligenceStepConfigValues?.response;
          const fieldValue = getValues(`diligenceStepConfigIds.${index}.id`);
          const stepDetail = stepTypes?.find((item) => item.id === fieldValue);

          return (
            <ORow className="flex-fill align-items-center" key={field.id}>
              <OCol xs={12} md={4}>
                <SelectSearchField
                  id={`diligenceStepConfigIds.${index}.id`}
                  name={`diligenceStepConfigIds.${index}.id`}
                  description=""
                  label="Configuração do Step"
                  disabled={diligenceStepConfigLoading}
                  options={
                    stepTypes?.map((item) => ({
                      label: item.name,
                      value: item.id,
                    })) ?? []
                  }
                />
              </OCol>
              <OCol xs={12} md={1}>
                <OButton
                  type="danger"
                  disabled={fields.length < 1}
                  onClick={() => remove(index)}
                >
                  <OIcon category="orq" icon="orq-edit-trash" />
                </OButton>
              </OCol>

              {!!stepDetail && (
                <OCol xs={12} md={7} className="d-flex flex-column gap-1">
                  <OTypography type="dark">
                    Público: {stepDetail.isPublic ? "Sim" : "Não"}
                  </OTypography>

                  <OTypography type="dark">
                    Step: {stepDetail.type.name} | {stepDetail.type.description}
                  </OTypography>

                  <OTypography type="dark">
                    Dependencias:{" "}
                    {stepDetail.dependencies.map(({ name }) => name).join(", ")}
                  </OTypography>
                </OCol>
              )}
            </ORow>
          );
        })}

        {!!fields.length && (
          <div className="d-flex justify-content-end">
            <OButton onClick={appendField}>
              <OIcon category="orq" icon="orq-add-plus" />
            </OButton>
          </div>
        )}
      </div>
    </div>
  );
};
