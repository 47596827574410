import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import {
  AddCreditLineConfigurationFormValues,
  creditLineConfigurationFormDefaultValues,
  creditLineConfigurationFormValidationSchema,
} from "../credit-line-configuration.schema";

export const useAddCreditLineConfiguration = () => {
  const form = useForm<AddCreditLineConfigurationFormValues>({
    resolver: yupResolver(creditLineConfigurationFormValidationSchema),
    defaultValues: creditLineConfigurationFormDefaultValues,
  });

  const navigate = useNavigate();

  const {
    value: bookStrategies,
    callService: getBookStrategies,
    loading: loadingBookStrategies,
    hasError: hasErrorBookStrategies,
  } = useServiceCall(service.hubCreditManager.getBookStrategies);

  const {
    value: creditLines,
    callService: getCreditLines,
    loading: loadingCreditLines,
    hasError: hasErrorCreditLines,
  } = useServiceCall(service.hubCreditManager.getCreditLines);

  const {
    callService: callAddCreditLineConfiguration,
    loading: loadingAddCreditLineConfiguration,
  } = useServiceCall(service.hubCreditManager.addCreditLineConfiguration);

  const createCreditLineConfiguration = useCallback(
    async ({
      associateGuarantorMinimumShare,
      bookStrategyId,
      collateralTypes,
      creditLineId,
      maximumLimitForSimpleGuarantor,
      partner,
      shouldSyncGuarantors,
      automaticallyApproveOnFts,
    }: AddCreditLineConfigurationFormValues) => {
      const { success } = await callAddCreditLineConfiguration({
        associateGuarantorMinimumShare,
        bookStrategyId,
        collateralTypes,
        partner,
        creditLineId,
        maximumLimitForSimpleGuarantor,
        shouldSyncGuarantors: !!shouldSyncGuarantors,
        automaticallyApproveOnFts: !!automaticallyApproveOnFts,
      });

      if (success) {
        OToastManager.success(
          "A configuração de linha de crédito foi adicionada com sucesso.",
        );

        navigate(
          corporateRouter.routes.emprestimos.product.contingency
            .creditLinesConfigurations.path,
        );
      } else {
        OToastManager.danger(
          "Um erro ocorreu ao tentar salvar a configuração de linha de crédito. Por favor, tente novamente mais tarde.",
        );
      }
    },
    [callAddCreditLineConfiguration, navigate],
  );

  const getAllData = useCallback(() => {
    getCreditLines();
    getBookStrategies();
  }, [getBookStrategies, getCreditLines]);

  useEffect(() => {
    hasErrorBookStrategies &&
      OToastManager.danger(
        "Um erro ocorreu ao tentar receber as configurações de book / estratégia. Por favor, tente novamente mais tarde.",
      );
  }, [hasErrorBookStrategies]);

  useEffect(() => {
    hasErrorCreditLines &&
      OToastManager.danger(
        "Um erro ocorreu ao tentar receber as linhas de crédito. Por favor, tente novamente mais tarde.",
      );
  }, [hasErrorCreditLines]);

  useEffect(() => {
    getAllData();
  }, [getAllData]);

  return {
    getAllData,
    form,
    creditLines,
    bookStrategies,
    loadingAddCreditLineConfiguration,
    loadingBookStrategies,
    loadingCreditLines,
    createCreditLineConfiguration,
    hasErrorBookStrategies,
    hasErrorCreditLines,
  };
};
