import { yupResolver } from "@hookform/resolvers/yup";
import {
  OCol,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
  OToastManager,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { SelectSearchField } from "components/select-search/field/select-search-field.component";
import { useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { orqIcons } from "../../configuracoes/workflow-configuration.utils";
import {
  addProposalWorkflowFormDefaultValues,
  addProposalWorkflowFormValidationSchema,
} from "./add-proposal-workflow-form.schemas";

export const AddProposalWorkflowPage = () => {
  const form = useForm({
    resolver: yupResolver(addProposalWorkflowFormValidationSchema),
    defaultValues: addProposalWorkflowFormDefaultValues,
  });
  const { handleSubmit, setValue } = form;
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const onSubmit = useMemo(
    () =>
      handleSubmit(async (values) => {
        try {
          setLoading(true);

          await service.hubCreditManager.createWorkflowConfig(values);

          OToastManager.success("Workflow adicionado com sucesso!");

          navigate(
            corporateRouter.routes.workflow.product.workflowsConfig.path,
          );
        } catch {
          OToastManager.danger(
            "Não foi possível criar o Workflow. Por favor, tente novamente mais tarde.",
          );
        } finally {
          setLoading(false);
        }
      }),
    [handleSubmit, navigate],
  );

  const iconOptions = orqIcons.map((icon) => ({
    value: icon,
    label: icon,
    onSelect: (value: string) => setValue("icon", value),
  }));

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Criar workflow" />}
      actions={
        <LoadingButton loading={loading} onClick={() => onSubmit()}>
          Criar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <form spellCheck="false">
            <ORow>
              <OCol sm={6}>
                <ORFieldInput id="name" name="name" tag="text" label="Nome" />
              </OCol>
              <OCol sm={6}>
                <ORFieldInput
                  id="tabName"
                  name="tabName"
                  tag="text"
                  label="Label da Tab"
                />
              </OCol>
              <OCol sm={12}>
                <ORFieldInput
                  id="description"
                  name="description"
                  tag="text"
                  label="Descrição"
                />
              </OCol>
            </ORow>
            <ORow>
              <OCol sm={6}>
                <ORFieldSelect
                  id="detailsPageType"
                  name="detailsPageType"
                  label="Tipo da página de detalhes"
                >
                  <OOption value="AGRO" key="details-page-type-option-agro">
                    Agro
                  </OOption>
                  <OOption value="KGIRO" key="details-page-type-option-kgiro">
                    Capital de giro
                  </OOption>
                  <OOption
                    value="ENERGIA"
                    key="details-page-type-option-energia"
                  >
                    Energia
                  </OOption>
                </ORFieldSelect>
              </OCol>
              <OCol sm={6}>
                <SelectSearchField
                  id="icon"
                  name="icon"
                  label="Ícone"
                  placeholder="Selecionar"
                  options={iconOptions}
                  onClear={() => setValue("icon", "")}
                  maxOptions={50}
                />
              </OCol>
            </ORow>
          </form>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
