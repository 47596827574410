import { OCol } from "@maestro/react";
import { useUnsafeCustomer } from "contexts/customer";
import { ContractTemplateSelector } from "../../contract-template-selector";

interface HiringContractTemplateIdFieldProps {
  required?: boolean;
}

export const HiringContractTemplateIdField = ({
  required,
}: HiringContractTemplateIdFieldProps) => {
  const { customer } = useUnsafeCustomer();

  return (
    <OCol xs={12} md={6}>
      <ContractTemplateSelector
        companyId={customer?.quickfinId ?? null}
        name="hiringContractTemplateId"
        label="Template de contrato de contratação"
        required={required}
      />
    </OCol>
  );
};
