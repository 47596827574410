import { OTypography } from "@maestro/react";
import { useWorkflow } from "../../../../../../../../../../../../components/hooks/use-workflow.context";
import { PropertyFieldComponent } from "./_compose/property-field.component";

export const ProcessorConfigProperties = () => {
  const {
    processorConfig: { value: selectedProcessorConfig },
  } = useWorkflow();

  const properties = selectedProcessorConfig?.properties;

  return (
    <div className="d-flex flex-column gap-4">
      <OTypography size="lg">Propriedades</OTypography>
      <div className="d-flex gap-4 flex-wrap justify-content-between align-items-center">
        {properties?.map((prop) => (
          <div className="w-48" key={`processor-config-property-${prop.id}`}>
            <PropertyFieldComponent
              key={prop.processorProperty.name}
              processorConfigProperty={prop}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
