import { YesNoRadioField } from "./yes-no-radio-field.component";

export const NeedsFilesForOperationField = () => {
  return (
    <YesNoRadioField
      label="Habilita a obrigação de upload de arquivos na solicitação da operação"
      name="needsFilesForOperation"
      description="Quando habilitado, valida se os arquivos foram enviados na solicitação da operação"
    />
  );
};
