import { SelectSearchFieldOption } from "components/select-search/field/select-search-field.types";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";
import { StepConfigForm } from "../../step-config-form.types";

export const usePendencyTypeSelector = () => {
  const { callService, loading, value, hasError } = useServiceCall(
    service.hubEnergy.getDiligenceStepPendencyTypes,
  );

  const { clearErrors, setError } = useFormContext<StepConfigForm>();

  const getPendencyTypes = useCallback(async () => {
    const { success } = await callService();
    if (success) clearErrors("approvedPendencyTypeIds");
    else
      setError("approvedPendencyTypeIds", {
        message:
          "Erro ao buscar os tipos de pendência. Clique no botão ao lado para tentar novamente.",
      });
  }, [callService, clearErrors, setError]);

  useEffect(() => {
    getPendencyTypes();
  }, [getPendencyTypes]);

  const pendencyTypeOptions = useMemo(
    () =>
      value?.response.map<SelectSearchFieldOption<number>>(({ name, id }) => ({
        label: name,
        value: id,
      })) ?? [],
    [value?.response],
  );

  return {
    pendencyTypeOptions,
    pendencyTypes: value?.response ?? [],
    getPendencyTypes,
    loading,
    hasFetchError: hasError,
  };
};
